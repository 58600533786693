import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { ModalConfirm } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';

import DocumentsModal from './modal/DocumentsModal';
import DocumentsShareModal from './modal/DocumentsShareModal';
import Folders from './Folders';
import Files from './Files';


export const Documents = ({ user, loading, layout, documents, homeUsers, homesUsersFamilies, ...props }, { t: translate }) => {
  const confirmModalType = !_.isEmpty(props.fileToDelete) ? 'file' : 'folder';
  const haveIFolders = (documents.folders.my && documents.folders.my.length > 0) || (documents.folders.shared && documents.folders.shared.length > 0);
  const modalTypes = { file: translate('file'), folder: translate('folder') };

  return (
    <SignedInLayout
      page="Documents"
      currentPath="/documents"
      user={user}
      loading={loading}
      layout={layout}
    >

      <DocumentsModal
        homesUsersFamilies={homesUsersFamilies}
        {...props}
        folders={documents.folders}
        error={props.error}
        loading={loading}
      />

      <DocumentsShareModal
        modalShareOpened={props.modalShareOpened}
        fileLink={props.fileLink}
        closeModal={props.closeModal}
      />

      <ModalConfirm
        isOpen={props.modalConfirmOpened}
        closeModal={props.closeModal}
        question={translate('Are you sure you want to delete {confirmModalType}?', { confirmModalType: modalTypes[confirmModalType] })}
        deleteFunction={e => props.deleteObject(e, confirmModalType,
          confirmModalType === 'folder' ? props.folderToDelete : props.fileToDelete)}
        dataE2E={'documents-confirm-modal-delete'}
      />

      <div className="container">
        <div className="folders-container">
          <Folders
            folders={documents.folders}
            folderActive={props.folderActive}
            editFolderData={props.editFolderData}
            handleFormChange={props.handleFormChange}
            editFolder={props.editObject}
            setToDelete={props.setToDelete}
            folderReadyToDelete={props.folderReadyToDelete}
            setFolderActive={props.setFolderActive}
            error={props.error}
          />
        </div>
        {haveIFolders ? (
          <div className="files-container">
            <Files
              files={documents.documents}
              user={user}
              homeUsers={homeUsers}
              saveDocumentFile={props.saveDocumentFile}
              editFile={props.editObject}
              createShareLink={props.createShareLink}
              setToDelete={props.setToDelete}
              isWalkthroughOpen={props.isWalkthroughOpen}
              error={props.error}
            />
          </div>
        ) : (
          <div className="pull-left files-container">
            <div className="empty-view-message">{translate('Please create at least one folder to upload files.')}</div>
          </div>
        )}
      </div>
    </SignedInLayout>
  );
};


Documents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Documents;
