import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Badge, Modal, Notification } from './../../../../components';
import { handleFormKeyPress } from './../../../../utils/tools';


export const SplitRequestsModal = ({ user, ...props }, { t: translate }) => {
  const possibleStatuses = [];
  if(props.editFormData.owner_id === user.id) {
    possibleStatuses.push('paid');
  }
  if(props.editFormData.recipient_id === user.id) {
    possibleStatuses.push('accepted');
    possibleStatuses.push('rejected');
  }

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#splits-modal-form button[type="submit"]');
  };

  return (
    <Modal
      isOpen={props.modalOpened}
      label={`Split-Requests ${props.editFormData.note ? 'With-Note' : ''}`}
      closeModal={() => props.closeModal(false)}
      dataChanged={false}
    >

      <div
        className="finance-modal split-requests-modal"
        tabIndex="0"
        onKeyPress={handleKeyPress}
      >
        {props.editFormData.note && (
          <div className="split-requests-modal-note">
            <span className="w600 block">{translate('Note')}</span>
            <span className="block">{props.editFormData.note}</span>
          </div>
        )}

        <div className="badges-section">
          <div className="title m-r-10">{translate('Change status to')}</div>
          {/* <Badge label={translate('pending')}
                        active={props.editFormData.status === 'pending'}
                        activeColor="bg-darkgrey"
                        onClick={() => props.handleFormChange('status', 'pending')} /> */}
          {possibleStatuses.indexOf('accepted') > -1 && (
            <Badge
              label={translate('accepted / waiting for payment')}
              active={props.editFormData.status === 'accepted'}
              activeColor="bg-darkgrey"
              onClick={() => props.handleFormChange('status', 'accepted')}
            />
          )}
          {possibleStatuses.indexOf('paid') > -1 && (
            <Badge
              label={translate('paid')}
              active={props.editFormData.status === 'paid'}
              activeColor="bg-green"
              onClick={() => props.handleFormChange('status', 'paid')}
            />
          )}
          {possibleStatuses.indexOf('rejected') > -1 && (
            <Badge
              label={translate('rejected')}
              active={props.editFormData.status === 'rejected'}
              activeColor="bg-red"
              onClick={() => props.handleFormChange('status', 'rejected')}
            />
          )}
        </div>

        <Form id="splits-modal-form" onSubmit={props.saveSplit} loading={false}>
          <Form.Summary>
            {props.error && (
              <div className="center f13">
                <Notification type="error" text={props.error} />
              </div>
            )}

            <Button
              type="submit"
              label={translate('Save')}
              align="center"
              data-e2e="split-requests-save-button"
            />
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


SplitRequestsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequestsModal;
