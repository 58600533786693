import React from 'react';
import PropTypes from 'prop-types';

import { FileUploader, Notification } from '../../../components';

import FilesList from './FilesList';


export const Files = ({ files, user, homeUsers, saveDocumentFile, editFile, createShareLink, setToDelete, isWalkthroughOpen, error }, { t: translate }) => {
  return (
    <div>
      <FileUploader handleUploadFile={saveDocumentFile} translate={translate} type="dropzone" />

      <div className="files-list">
        <FilesList
          files={files}
          user={user}
          homeUsers={homeUsers}
          editFile={editFile}
          createShareLink={createShareLink}
          setFileToDelete={setToDelete}
          isWalkthroughOpen={isWalkthroughOpen}
        />
        {error ? (
          <div className="notification-container">
            <Notification
              type="error"
              text={error}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};


Files.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Files;
