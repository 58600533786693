import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DEFAULT_DISPLAYED_DATE_FORMAT } from './../../../utils/date';
import { setNumberFormat } from './../../../utils/tools';


export const BudgetTableRow = ({ row, filterActive, homeUsers, ...props }, { t: translate }) => {
  const amountClass = row.type === 'income' ? ' success' : ' error';
  const amountWithIndicator = (row.type === 'income' ? '+' : '') + setNumberFormat(row.amount, 2);
  const date = moment(row.date).format(DEFAULT_DISPLAYED_DATE_FORMAT);

  const style = {
    display: 'table-row',
  };
  if(
    (filterActive === 'income' && amountClass === ' error') ||
        (filterActive === 'expenses' && amountClass === ' success')
  ) {
    style.display = 'none';
  }

  const getAuthor = () => {
    const author = homeUsers && Object.keys(homeUsers).length > 0 && homeUsers[row.owner_id] && (`@${homeUsers[row.owner_id].nickname}` || `@${homeUsers[row.owner_id].first_name.toLowerCase()}`);
    const authorColor = homeUsers && row.owner_id && homeUsers[row.owner_id] && homeUsers[row.owner_id].color;

    return {
      author,
      authorColor,
    };
  };

  const getKid = () => {
    const kidName = homeUsers && row.user_id && homeUsers[row.user_id] && (homeUsers[row.user_id].nickname ? `@${homeUsers[row.user_id].nickname}` : `@${homeUsers[row.user_id].first_name.toLowerCase()}`);
    const kidColor = homeUsers && row.user_id && homeUsers[row.user_id] && homeUsers[row.user_id].color;

    return {
      kidName,
      kidColor,
    };
  };

  const author = getAuthor();
  const kid = getKid();

  row.total_amount = row.amount;
  return (
    <div className="trow" style={style} data-e2e={`budget-history-row-${row.type}-${row.amount}`}>
      <div className="tcell medium right">{date}</div>
      <div className={'tcell right p-r-15' + amountClass}>{amountWithIndicator}</div>
      <div className="tcell" style={{ color: author.authorColor }}>{author.author}</div>
      <div className="tcell">{row.tag_key}</div>
      <div className="tcell" style={{ color: kid.kidColor }}>{kid.kidName}</div>
      <div className="tcell center" title={row.access}><i className={`zmdi zmdi-${row.access === 'private' ? 'eye-off' : 'eye'}`} /></div>
      <div className="tcell budget-notes">{row.note}</div>
      <div className="tcell icons">
        <i className="zmdi zmdi-edit" title={translate('Edit')} style={{ visibility: props.isWalkthroughOpen ? 'visible' : 'hidden' }}
          onClick={() => props.editData(row)}
          data-e2e={`budget-history-row-${row.type}-${row.amount}-edit-button`}
        />
        <i className="zmdi zmdi-delete" title={translate('Delete')} style={{ marginLeft: 10, visibility: props.isWalkthroughOpen ? 'visible' : 'hidden' }}
          onClick={() => props.setRecordToDelete(row)}
          data-e2e={`budget-history-row-${row.type}-${row.amount}-delete-button`}
        />
      </div>
    </div>
  );
};


BudgetTableRow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BudgetTableRow;
