import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AvatarPlaceholder, Badge, Button, Card, ColorPicker, Form, Notification } from '../../../components';
import { prepareFileUrl } from '../../../utils/file';


export const CaretakerPersonalInfo = ({ editMode, caretaker, handleFormChange, saveCaretaker, loading, error }, { t: translate }) => {
  const birthDate = caretaker.birthdate && moment(caretaker.birthdate).format('DD-MM-YYYY');

  return (
    <Card
      className={`kid-profile-container ${editMode ? 'edited' : ''}`}
      deleteIcon="close"
      onDelete={() => window.history.back()}
      title={translate('Profile information')}
    >
      <div className="kid-profile-column">
        <div className="avatar" style={{ borderColor: caretaker.color || 'transparent' }}>
          {caretaker.file_url ? (
            <img src={prepareFileUrl(caretaker.file_url)} alt="" />
          ) : (
            <AvatarPlaceholder user={caretaker} />
          )}
        </div>
      </div>
      <div className="kid-profile-column">
        <Form loading={loading}>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('first name')}:</div>
              <div className="f15">{caretaker.first_name}</div>
            </div>
          </Form.Row>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('last name')}:</div>
              <div className="f15">{caretaker.last_name}</div>
            </div>
          </Form.Row>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('nickname')}:</div>
              <div className="f15">
                @{caretaker.nickname || caretaker.first_name.toLowerCase() || ''}
              </div>
            </div>
          </Form.Row>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('color')}:</div>
              <div className="color-picker-container">
                <ColorPicker
                  color={caretaker.color}
                  onChange={color => handleFormChange('color', color)}
                />
              </div>
            </div>
          </Form.Row>
        </Form>
      </div>
      <div className="kid-profile-column">
        <Form>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('gender')}:</div>
              <Badge
                label={translate('male')}
                active={caretaker.gender === 'male'}
                activeColor="bg-darkgrey"
              />
              <Badge
                label={translate('female')}
                active={caretaker.gender === 'female'}
                activeColor="bg-darkgrey"
              />
            </div>
          </Form.Row>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('date of birth')}:</div>
              <div className="f15">{birthDate}</div>
            </div>
          </Form.Row>
          <Form.Row>
            <div className="info-field">
              <div className="title inline-block m-r-15 f12 grey3">{translate('email')}:</div>
              <div className="f15">{caretaker.email}</div>
            </div>
          </Form.Row>
        </Form>
      </div>

      {editMode && (
        <div className="kid-profile-submit-changes caretaker-personal-form-submit">
          {error && (
            <div className="pull-left f14 m-b-20">
              <Notification type="error" text={error} />
            </div>
          )}
          <Button onClick={saveCaretaker} buttonClassName="clean small" label={translate('save changes')} />
        </div>
      )}
    </Card>
  );
};


CaretakerPersonalInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CaretakerPersonalInfo;
