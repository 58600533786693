import React from 'react';
import PropTypes from 'prop-types';

import { AuthForm, Button, Form, Inner, Input, Link, Notification } from '../../components';
import { isEdge, isMobile } from '../../utils/browser';
import { getLanguages } from '../../utils/lang';

const SignUpView = ({
  country, isFormSubmited, firstName, email, password, passwordDefaultLength,
  error, loading, handleFieldChange, handleSubmit, trialLength,
}, { t: translate }) => {
  const passwordHowManyMore = (passwordDefaultLength - password.length > 0) ? (passwordDefaultLength - password.length) : 0;
  const languages = getLanguages();

  const renderBottomContent = () => (
    <div>
      <span className="m-r-10">{translate('Already have an account?')}</span>
      <Link to="/sign-in" style={{ textDecoration: 'underline' }}>{translate('Log in')}</Link>
    </div>
  );

  return (
    <Inner>
      <AuthForm
        type={isFormSubmited ? 'sent' : 'hello'}
        title={!isFormSubmited ? translate('Create new account') : translate("On it's way...")}
        bottomContent={!isMobile() ? renderBottomContent() : null}
      >
        {isFormSubmited ? (
          <AuthForm.Paragraph>
            <p data-e2e="signup-email-send-message">
              {translate('Please check your inbox')}<br />{translate('to confirm your new account.')}
              <br /><br />
              {translate('A message should arrive within a couple of minutes.')}
            </p>
          </AuthForm.Paragraph>
        ) : (
          <Form
            onSubmit={handleSubmit}
            loading={loading}
          >

            <AuthForm.Paragraph>
              <p>{translate('Start your {trialLength} free trial period today', { trialLength })}</p>
            </AuthForm.Paragraph>

            <Notification type="error" text={error} />

            <Form.Row>
              <input
                required
                type="text"
                value={firstName || ''}
                onChange={handleFieldChange('firstName')}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('First Name')}</label>
            </Form.Row>
            <Form.Row>
              <input
                required
                type="email"
                value={email || ''}
                onChange={handleFieldChange('email')}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('Email')}</label>
            </Form.Row>
            <Form.Row>
              <input
                required
                type="password"
                value={password || ''}
                onChange={handleFieldChange('password')}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('Password')}</label>
              {passwordHowManyMore > 0 && password !== '' && (
                <p className="form__field-info">{passwordHowManyMore} more symbol{passwordHowManyMore > 1 ? 's' : ''}</p>
              )}
              {passwordHowManyMore === 0 && (
                <p className="form__field-info">{translate('Already strong!')}</p>
              )}
            </Form.Row>
            <Form.Row>
              <Input
                isSearchable
                error={error}
                label={`${translate('country')}:`}
                name="country"
                onChange={val => handleFieldChange('country', val)}
                options={languages}
                placeholder={translate('Select country')}
                type="select"
                value={country}
              />
            </Form.Row>

            <Form.Row>
              <input
                required
                className="pull-left"
                type="checkbox"
              />
              <span className="f13 sign-up-checkbox">
                {translate('I accept {Privacy Policy} and {Terms of Service}', {
                  ['Privacy Policy']: <Link href="https://www.2homes.com/privacy-policy/" target="_blank">{translate('Privacy Policy')}</Link>,
                  ['Terms of Service']: <Link href="https://www.2homes.com/terms-of-service/" target="_blank">{translate('Terms of Service')}</Link>,
                })}
              </span>
            </Form.Row>

            <Form.Row className="m-t-0">
              <input
                required
                className="pull-left"
                type="checkbox"
              />
              <span className="f13 sign-up-checkbox">{translate('I agree that you may use the information I have provided for the purposes necessary for 2homes services.')}</span>
            </Form.Row>

            <Form.Summary style={{ marginTop: 0 }}>
              <Button
                type="submit"
                label={translate('Start my free trial')}
                align="center"
                data-e2e="sign-in-button"
              />
            </Form.Summary>
          </Form>
        )}
      </AuthForm>
    </Inner>
  );
};

SignUpView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SignUpView;
