import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from './../../../../components';


export const ModalCard = ({ ...props }, { t: translate }) => {
  return (
    <Form.Row>
      <Input
        type="text"
        name="name"
        label={translate('title')}
        value={props.editFormData.title || ''}
        onChange={props.handleFormChange('card', 'title')}
        error={props.error}
      />
    </Form.Row>
  );
};


ModalCard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModalCard;
