import React from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';

const NicknameField = ({ disabled, name, value, label, onChange, error }, { t: translate }) => {
  let nickname = (value.substr(0, 1) === '@' ? value : `@${value}`).replace(/ /g, '');

  if(nickname.length > 11) {
    nickname = nickname.substr(0, 11);
  }

  return (
    <Input
      disabled={disabled}
      type="text"
      name={name}
      label={label}
      value={nickname}
      onChange={onChange}
      hint={translate('Username is used for notifications and mentions. You can change it to how members of your family call you at home. Nickname should have between 2 and 10 characters.')}
      error={error}
    />
  );
};


NicknameField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NicknameField;
