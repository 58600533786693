import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changePassword } from '../../../store/actions/auth';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import ChangePassword from '../components/ChangePassword';

class UserProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeated: '',
      success: false,
    };
  }

  componentDidMount() {
    this.props.fetchHomesIfNeeded();
  }

  componentWillUnmount() {
    this.props.cleanError('newpassword');
  }

  validatePassword = () => {
    const { oldPassword, newPassword, newPasswordRepeated } = this.state;
    return oldPassword !== '' && (newPassword === newPasswordRepeated && newPassword !== '');
  }

  handleFieldChange = name => {
    return event => {
      this.setState({ [name]: event.target.value });
    };
  }

  handleSubmitPassword = event => {
    event.preventDefault();
    this.props.cleanError('newpassword');
    const { oldPassword, newPassword } = this.state;
    this.props.changePassword({ oldPassword, newPassword })
      .then(() => {
        this.setState({
          success: true,
        });
      });
  }

  render() {
    return (
      <ChangePassword
        {...this.props}
        passwords={this.state}
        validatePassword={this.validatePassword}
        onFieldChange={this.handleFieldChange}
        onSubmitPassword={this.handleSubmitPassword}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    error: state.errors.newpassword,
  };
};

const mapDispatchToProps = {
  changePassword,
  fetchHomesIfNeeded,
  cleanError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileContainer);
