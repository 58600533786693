import React from 'react';
import classnames from 'classnames';

import Notification from '../Notification';

import Select from './Select';
import { isEdge } from './../../utils/browser';


class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.error !== this.props.error) {
      this.getFieldError();
    }
  }

  getFieldError = () => {
    const { error, name } = this.props;
    if(error) {
      if(typeof error === 'string') {
        error.split(' ').forEach(err => {
          if(err.toLowerCase().indexOf(name) > -1) {
            this.setState({
              hasError: true,
              errorMessage: error,
            });
          }
        });
      } else {
        error.forEach(err => {
          if(err.toLowerCase().indexOf(name) > -1) {
            this.setState({
              hasError: true,
              errorMessage: err,
            });
          }
        });
      }
    } else {
      this.setState({
        hasError: false,
        errorMessage: '',
      });
    }
  };

  removeError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  }

  handleClick = () => {
    if(typeof this.props.onClick === 'function') {
      this.removeError();
      this.props.onClick();
    }
  }

  render() {
    const {
      type, value, onChange, label, className,
      disabled, hint, mode, options = [], placeholder, isSearchable,
      readOnly = false, withHintQuestionMark = true, withDefaultValue = false,
    } = this.props;
    const { hasError, errorMessage } = this.state;

    return type !== 'select' ? (
      <div className="InputWrapper">
        <input
          autoComplete="off"
          type={type}
          value={value}
          onClick={() => this.handleClick()}
          onChange={onChange}
          onKeyUp={() => this.removeError()}
          className={classnames(className, { error: errorMessage })}
          disabled={disabled}
          data-e2e={this.props['data-e2e']}
          readOnly={readOnly}
          placeholder={placeholder}
        />
        {hint && (
          <span className="hint">
            {withHintQuestionMark && <span>?</span>}
            <span>{hint}</span>
          </span>
        )}
        {label && (
          <label className={classnames({ edge: isEdge })}>{label}</label>
        )}

        {hasError && (<Notification type="error" text={errorMessage} />)}
      </div>
    ) : (
      <Select
        isSearchable={isSearchable}
        disabled={disabled}
        label={label}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        mode={mode}
        value={options.find(option => option.value === value)}
        withDefaultValue={withDefaultValue}
      />
    );
  }
}


export default Input;
