import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import CalendarBig from './views/CalendarBig';
import { isEdge } from './../../../utils/browser';
import { setOrderedFamilyMembers } from './../../../utils/tools';


export const CalendarInnerDay = ({ user, calendar, caretakers, columnWidth, calendarPeople, ...props }, { t: translate }) => {
  const calendarPeopleSorted = setOrderedFamilyMembers(user, calendarPeople);
  const cells = document.querySelectorAll('.rbc-time-slot');
  cells.forEach(cell => { cell.setAttribute('data-value', `+ ${translate('add event')}`); });

  return (
    <div className="calendar-inner">
      {calendarPeopleSorted && calendarPeopleSorted.map((usr, idx) => {
        const width = idx === 0 ? `${columnWidth + 86}px` : `${isEdge ? columnWidth - 1 : columnWidth}px`;
        const personType = Object.keys(caretakers).indexOf(usr.id) > -1 ? 'caretaker' : 'kid';

        const userEvents = _.find(calendar.usersEvents, userEvent => {
          if(userEvent.id === usr.id) {
            userEvent.calendar_events.forEach(calendarEvent => {
              calendarEvent.person = usr;
            });
            return userEvent;
          }
        });

        return (
          <div className={`calendar-container ${isEdge ? 'edge' : ''}`} key={idx} style={{ width: width }}>
            <CalendarBig
              user={user}
              events={props.filterEvents(userEvents && userEvents.calendar_events)}
              caretakers={caretakers}
              calendarPeople={calendarPeople}
              calendar={calendar}
              setEventColor={props.setEventColor}
              editEvent={event => props.editEvent(event, usr.id)}
              handleSelectedSlot={slotInfo => {
                setTimeout(() => {
                  props.handleSelectedSlot(slotInfo, usr.id, personType);
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

CalendarInnerDay.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarInnerDay;
