import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Button, Input, Form, Modal } from '../../../../components';
import { handleFormKeyPress } from '../../../../utils/tools';


export const DocumentsModal = (props, { t: translate }) => {
  const type = props.editFolderData.id !== '' ? 'folder' : 'file';

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#documents-modal-form button[type="submit"]');
  };

  return (
    <Modal
      small
      isOpen={props.modalOpened}
      label="Documents"
      closeModal={() => props.closeModal()}
    >

      <div
        tabIndex="0"
        onKeyPress={handleKeyPress}
        className="documents-modal"
      >
        <Form
          id="documents-modal-form"
          onSubmit={e => props.onModalFormSubmit(e, type)}
          loading={false}
        >
          {type === 'folder' && (
            <div>
              {props.homesUsersFamilies && props.homesUsersFamilies.currentFamily && props.homesUsersFamilies.currentFamily.exists && (
                <Form.Row>
                  <div className="badges-section">
                    <div className="title">{translate('Visibility')}</div>
                    <Badge label={translate('Visible')}
                      active={props.editFolderData.visible}
                      activeColor="bg-darkgrey"
                      onClick={props.handleFormChange('folder', 'visible', true)}
                    />
                    <Badge label={translate('Not visible')}
                      active={!props.editFolderData.visible}
                      activeColor="bg-darkgrey"
                      onClick={props.handleFormChange('folder', 'visible', false)}
                    />
                  </div>
                </Form.Row>
              )}
              <div className={(props.homesUsersFamilies && props.homesUsersFamilies.currentFamily && props.homesUsersFamilies.currentFamily.exists) ? 'm-t-20' : 'm-t-50'}>
                <Form.Row>
                  <Input
                    type="text"
                    name="name"
                    label={translate('folder name')}
                    value={props.editFolderData.name || ''}
                    onChange={props.handleFormChange('folder', 'name')}
                    error={props.error}
                    data-e2e="documents-modal-folder-name"
                  />
                </Form.Row>
              </div>
            </div>
          )}

          {type === 'file' && (
            <div>
              <Form.Row>
                <div className="badges-section">
                  <div className="title">{translate('Folder')}</div>
                  {props.folders && props.folders.my.map((folder, idx) => {
                    return (
                      <Badge key={idx} label={folder.name}
                        active={folder.id === props.editFileData.folder_id}
                        activeColor="bg-darkgrey"
                        onClick={props.handleFormChange('file', 'folder_id', folder.id)}
                      />
                    );
                  })}
                </div>
              </Form.Row>
              <Form.Row>
                <Input
                  type="text"
                  name="name"
                  label={translate('filename')}
                  value={props.editFileData.filename}
                  onChange={props.handleFormChange('file', 'filename')}
                  error={props.error}
                  data-e2e="documents-modal-file-name"
                />
              </Form.Row>
              <Form.Row>
                <div className="textarea-container">
                  <textarea className="textarea-note" placeholder={translate('+ add a note')}
                    onChange={props.handleFormChange('file', 'note')}
                    value={props.editFileData.note || ''}
                    data-e2e="documents-modal-note-field"
                  />
                </div>
              </Form.Row>
            </div>
          )}

          <Form.Summary>
            <Button
              type="submit"
              label={translate('Save')}
              align="center"
              data-e2e="documents-modal-confirm-button"
              disabled={props.loading}
            />
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


DocumentsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DocumentsModal;
