import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AvatarPlaceholder, Badge, Button, DatePicker, FileUploader, Form, Input, NicknameField, Notification } from '../../../components';
import { DEFAULT_BIRTHDATE_FORMAT } from '../../../utils/date';


const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';


export const CompleteYourProfile = ({ profile, loading, error, onSubmit, onFieldChange, onAvatarChange }, { t: translate }) => {
  const birthDate = profile.birthDate && moment(profile.birthDate);

  return (
    <div className="CompleteYourProfile">
      <section>
        <h2>{translate('Please complete your profile')}</h2>
        <Form
          onSubmit={onSubmit}
          loading={loading}
        >
          <div className="avatar">
            {profile.avatar.fullpath !== '' ? (
              <img src={profile.avatar.fullpath} alt="" />
            ) : (
              <AvatarPlaceholder user={profile} />
            )}
            <FileUploader handleUploadFile={onAvatarChange} translate={translate} />
          </div>
          <div className="form__row">
            <Input
              type="text"
              name="first name"
              label={translate('name')}
              value={profile.firstName}
              onChange={onFieldChange('firstName')}
              error={error}
            />
          </div>
          <div className="form__row">
            <Input
              type="text"
              name="last name"
              label={translate('surname')}
              value={profile.lastName}
              onChange={onFieldChange('lastName')}
              error={error}
            />
          </div>
          <div className="form__row">
            <NicknameField
              name="nickname"
              label={translate('nickname')}
              value={profile.username || profile.firstName}
              onChange={onFieldChange('username')}
              error={error}
            />
          </div>
          <div className="form__row">
            <div className="badges-section">
              <div className="title inline-block m-r-15">{translate('gender')}:</div>
              <Badge
                label={translate('male')}
                active={profile.gender === 'male'}
                activeColor="bg-darkgrey"
                onClick={() => onFieldChange('gender', 'male')}
              />
              <Badge
                label={translate('female')}
                active={profile.gender === 'female'}
                activeColor="bg-darkgrey"
                onClick={() => onFieldChange('gender', 'female')}
              />
            </div>
          </div>

          <div className="form__row">
            <DatePicker
              clearDefaultDate
              dateFormat={DEFAULT_BIRTHDATE_FORMAT}
              error={error}
              label={translate('date of birth')}
              locale={profile.language || 'en-gb'}
              onChange={date => onFieldChange('birthDate', date.format(DEFAULT_DATE_FORMAT))}
              selected={birthDate}
              showWeekNumbers={false}
            />
          </div>

          <Notification
            type="error"
            text={error}
            exclude={['first name', 'last name', 'nickname', 'birthdate']}
          />

          <Form.Summary>
            <Button
              type="submit"
              label={translate('Continue >')}
              align="center"
            />
          </Form.Summary>
        </Form>
      </section>
    </div>
  );
};


CompleteYourProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CompleteYourProfile;
