import React from 'react';

const AuthFormBottomContent = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div className="auth-form__bottom-content">
      {children}
    </div>
  );
};

export default AuthFormBottomContent;
