import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from 'src/hooks/useStore';
import { fetchNewNotifications, clearNewNotifications } from 'src/store/actions/notifications';


const MessagesNotificator = (props, { t: translate }) => {
  const [count, setCount] = useState(0);
  const [state, dispatch] = useStore();

  useEffect(() => {
    dispatch(fetchNewNotifications());

    const fetchInterval = setInterval(() => {
      dispatch(fetchNewNotifications());
    }, 300000);

    return () => clearInterval(fetchInterval);
  }, []);

  useEffect(() => {
    if(state.notifications.countNew && state.notifications.countNew !== count) setCount(state.notifications.countNew);
  }, [state.notifications?.countNew]);

  const clearCounter = () => {
    dispatch(clearNewNotifications());
  };

  const styles = count === 0 ? { opacity: 0.7 } : {};

  return (
    <div className="MessagesNotificator" style={styles}>
      <Link to="/notifications" onClick={() => clearCounter()}>
        <i
          className="zmdi zmdi-notifications"
          title={translate('Show notifications')}

        />
        {count > 0 && (<span>{count}</span>)}
      </Link>
    </div>
  );
};

MessagesNotificator.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MessagesNotificator;
