import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';

import { DEFAULT_DISPLAYED_DATE_FORMAT } from '../../../utils/date';
import { prepareFileUrl } from '../../../utils/file';
import { setNumberFormat } from '../../../utils/tools';


class FinancesHistoryTableRow extends React.Component {
  constructor(props) {
    super(props);
  }

  editHistoryFile = () => {
    const { row } = this.props;
    const editData = _.cloneDeep(row);
    editData.total_amount = parseFloat(editData.amount);
    editData.transaction_date = moment(row.date);
    this.props.editFile(editData, 'history');
  }

  editSplitRequest = () => {
    const { row } = this.props;
    const editData = _.cloneDeep(row);

    this.props.setModalType('split');
    if(editData.split) {
      editData.split_amount = parseFloat(editData.split);
      editData.split_type = 'amount';
      editData.split_caretaker_id = editData.recipient_id;
    }
    this.props.editFile(editData);
  }

  getAuthor = () => {
    const { row, caretakers, homeUsers } = this.props;
    const author = homeUsers && Object.keys(homeUsers).length > 0 && homeUsers[row.owner_id] && (`@${homeUsers[row.owner_id].nickname}` || `@${homeUsers[row.owner_id].first_name.toLowerCase()}`);
    const authorColor = caretakers && row.owner_id && caretakers[row.owner_id] && caretakers[row.owner_id].color;

    return {
      author,
      authorColor,
    };
  }

  getRecipient = () => {
    const { row, caretakers, homeUsers } = this.props;
    const recipient = homeUsers && row.recipient_id && homeUsers[row.recipient_id] && (homeUsers[row.recipient_id].nickname ? `@${homeUsers[row.recipient_id].nickname}` : `@${homeUsers[row.recipient_id].first_name.toLowerCase()}`);
    const recipientColor = caretakers && row.recipient_id && caretakers[row.recipient_id] && caretakers[row.recipient_id].color;

    return {
      recipient,
      recipientColor,
    };
  }

  getKid = () => {
    const { row, homeUsers } = this.props;
    const kidName = homeUsers && row.user_id && homeUsers[row.user_id] && (homeUsers[row.user_id].nickname ? `@${homeUsers[row.user_id].nickname}` : `@${homeUsers[row.user_id].first_name.toLowerCase()}`);
    const kidColor = homeUsers && row.user_id && homeUsers[row.user_id] && homeUsers[row.user_id].color;
    const kidWhichFamily = homeUsers && row.user_id && homeUsers[row.user_id] && homeUsers[row.user_id].whichFamily;

    return {
      kidName,
      kidColor,
      kidWhichFamily,
    };
  }

  setStyles = amountClass => {
    const { filterActive } = this.props;
    const style = {
      display: 'table-row',
    };
    if(filterActive === 'income' && amountClass === ' error') {
      style.display = 'none';
    }
    if(filterActive === 'expenses' && amountClass === ' success') {
      style.display = 'none';
    }
    return style;
  }

  isExFamilyExists = () => {
    const { caretakers, homeUsers, homesUsersFamilies } = this.props;

    let exCaretakers = [];
    if(homeUsers) {
      exCaretakers = Object.values(homeUsers).filter(user => caretakers[user.id] && user.visibility && user.familyStatus === 'ex');
    }
    return homesUsersFamilies && homesUsersFamilies.exFamily && homesUsersFamilies.exFamily.exists && exCaretakers.length > 0;
  }

  hasSecondParent = () => {
    const { caretakers, kids } = this.props;
    const kid = kids[this.props.row.user_id];

    if(!kid) return false;

    return kid.parents.find(parent => caretakers[parent.id] && caretakers[parent.id].familyStatus === 'ex');
  }

  render() {
    const { row, showIcon, user } = this.props;
    const { t: translate } = this.context;

    const amountClass = row.type === 'income' ? ' success' : ' error';
    const amountWithIndicator = (row.type === 'income' ? '+' : '') + setNumberFormat(row.amount, 2);
    const date = moment(row.date).format(DEFAULT_DISPLAYED_DATE_FORMAT);

    const style = this.setStyles(amountClass);
    const author = this.getAuthor();
    const recipient = this.getRecipient();
    const kid = this.getKid();

    const splitWithMe = row.recipient_id === user.id;

    const colorStatus = (row.status === 'pending' || row.status === 'default') ? 'yellow' : (
      row.status === 'rejected' ? 'error' : 'success'
    );

    const exFamilyExists = this.isExFamilyExists();
    const hasSecondParent = this.hasSecondParent();
    const useFileIcon = window.innerWidth < 1280;

    const canIEdit = row.status !== 'paid' && row.status !== 'accepted';
    const allowCreateSplit = !splitWithMe && !row.split && exFamilyExists && kid && hasSecondParent && kid.kidWhichFamily === 'ex' && row.type === 'expense';

    return (
      <div className="trow" style={style} data-e2e={`accounting-history-row-${row.type}-${row.amount}`} >
        <div className="tcell medium right">{date}</div>
        <div className={'tcell right p-r-15' + amountClass}>{amountWithIndicator}</div>
        <div className="tcell hidden-small-res">{author.author}</div>
        <div className="tcell right p-r-15 relative">
          {row.split > 0 && (<span>{setNumberFormat(row.split, 2)}</span>)}
          {row.split > 0 && (
            <span className="block">
              <span className="m-r-10 split-status-dot" title={translate('Status {status}', { status: row.status })}>
                <i className={`zmdi zmdi-circle f9 ${colorStatus}`} />
              </span>
              {recipient.recipient}
            </span>
          )}
        </div>
        <div className="tcell">{row.tag_key}</div>
        <div className="tcell" style={{ color: kid.kidColor }}>{kid.kidName}</div>
        <div className="tcell center" title={row.access}><i className={`zmdi zmdi-${row.access === 'private' ? 'eye-off' : 'eye'}`} /></div>
        <div className={`tcell pointer m-w-100 ${useFileIcon ? 'center' : ''}`}>
          {!useFileIcon && (
            <span className="inline-block text-overflow m-w-100">
              <a href={prepareFileUrl(row.file_url)} target="_blank" className="black">{row.filename || row.file_url}</a>
            </span>
          )}
          {useFileIcon && (row.filename || row.file_url) && (
            <a href={prepareFileUrl(row.file_url)} target="_blank" className="black"><i className="zmdi zmdi-file" /></a>
          )}
        </div>
        <div className="tcell text-overflow m-w-140" title={row.note}>{row.note}</div>
        <div className="tcell icons">
          {allowCreateSplit && (
            <i className="zmdi zmdi-arrow-split" title={translate('Split costs')} style={{ visibility: showIcon ? 'visible' : 'hidden' }}
              onClick={() => this.editSplitRequest()}
              data-e2e={`accounting-history-row-${row.type}-${row.amount}-share-button`}
            />
          )}
          {!splitWithMe && canIEdit && (
            <i className="zmdi zmdi-edit" title={translate('Edit')} style={{ marginLeft: 10, visibility: showIcon ? 'visible' : 'hidden' }}
              onClick={() => this.editHistoryFile()}
              data-e2e={`accounting-history-row-${row.type}-${row.amount}-edit-button`}
            />
          )}
          {!splitWithMe && canIEdit && (
            <i className="zmdi zmdi-delete" title={translate('Delete')} style={{ marginLeft: 10, visibility: showIcon ? 'visible' : 'hidden' }}
              onClick={() => this.props.setFileToDelete(row)}
              data-e2e={`accounting-history-row-${row.type}-${row.amount}-delete-button`}
            />
          )}
        </div>
      </div>
    );
  }
}


FinancesHistoryTableRow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesHistoryTableRow;
