import React from 'react';
import PropTypes from 'prop-types';

import { Button, FinancialFilters } from './../../../components';
import FinancesHistoryTable from './FinancesHistoryTable';


const FinancesHistory = ({ finance, homeUsers, homesUsersFamilies, user, caretakers, ...props }, { t: translate }) => {
  return (
    <div className="finance-history-container">
      <div className="add-new-finances">
        <Button
          type="button"
          label={translate('+ Add new transaction')}
          onClick={() => props.editFile({ type: 'expense' }, 'pending')}
          data-e2e="accounting-add-new-button"
        />
      </div>

      <div className="section-title center">
        <span>{translate('History of')}</span>
      </div>

      <FinancialFilters
        filterActive={props.filterActive}
        setFilter={props.setHistoryFilter}
      />

      <div className="finances-history">
        <FinancesHistoryTable
          historyData={finance.historyData}
          homeUsers={homeUsers}
          homesUsersFamilies={homesUsersFamilies}
          isWalkthroughOpen={props.isWalkthroughOpen}
          user={user}
          caretakers={caretakers}
          editFile={props.editFile}
          setFileToDelete={props.setFileToDelete}
          filterActive={props.filterActive}
          setModalType={props.setModalType}
          kids={props.kids}
        />
      </div>
    </div>
  );
};


FinancesHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesHistory;
