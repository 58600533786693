import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from './../../../components/';


const ReportsMultiSelect = ({ name, keyParam, list, filters, withAll, handleFormChange }, { t: translate }) => {
  const handleAll = () => {
    if(filters[keyParam].length === list.length) {
      handleFormChange(keyParam, [], true);
    } else {
      handleFormChange(keyParam, list, true);
    }
  };

  return (
    <div className="inline-block">
      <label className="block">{name}</label>
      <div className="inline-block m-t-5">
        {withAll && (
          <Badge
            inline
            label={translate('all')}
            active={filters[keyParam].length === list.length && list.length > 0}
            activeColor="bg-darkgrey"
            onClick={() => handleAll()}
          />
        )}
        {list && list.map((element, idx) => {
          return (
            <Badge
              inline
              key={idx}
              label={element}
              active={filters[keyParam].indexOf(element) > -1}
              activeColor="bg-darkgrey"
              onClick={() => handleFormChange(keyParam, element)}
            />
          );
        })}
      </div>
    </div>
  );
};


ReportsMultiSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportsMultiSelect;
