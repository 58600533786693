import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  // Avatar, AvatarPlaceholder, FileUploader,
  Badge, Button, DatePicker,
  Form, Input, NicknameField, Notification,
} from '../../../components';
import { DEFAULT_BIRTHDATE_FORMAT } from '../../../utils/date';


const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const datePickerMaxDate = moment().subtract(1, 'd');


const InviteKidForm = ({ first_name, last_name, nickname, birthdate, gender, onFieldChange, onSubmit, loading, cancelForm, error, user }, { t: translate }) => (
  <div className="InvitePartnerForm CompleteYourProfile">
    <Form
      onSubmit={onSubmit}
      loading={loading}
    >
      <div className="pull-right invite-close pointer" onClick={() => cancelForm()}><i className="zmdi zmdi-close" /></div>
      <h2>{translate("Create kid's profile")}</h2>

      <Form.Row>
        <Input
          type="text"
          name="first name"
          label={translate('first name')}
          value={first_name}
          onChange={onFieldChange('first_name')}
          error={error}
        />
      </Form.Row>
      <Form.Row>
        <Input
          type="text"
          name="last name"
          label={translate('last name')}
          value={last_name}
          onChange={onFieldChange('last_name')}
          error={error}
        />
      </Form.Row>
      <Form.Row>
        <NicknameField
          name="nickname"
          value={nickname || first_name.toLowerCase()}
          label={translate('nickname')}
          onChange={onFieldChange('nickname')}
          error={error}
        />
      </Form.Row>
      <Form.Row>
        <div className="badges-section">
          <div className="title inline-block m-r-15">{translate('gender')}:</div>
          <Badge
            label={translate('boy')}
            active={gender === 'male'}
            activeColor="bg-darkgrey"
            onClick={() => onFieldChange('gender', 'male', true)}
          />
          <Badge
            label={translate('girl')}
            active={gender === 'female'}
            activeColor="bg-darkgrey"
            onClick={() => onFieldChange('gender', 'female', true)}
          />
        </div>
      </Form.Row>
      <Form.Row>
        <DatePicker
          dateFormat={DEFAULT_BIRTHDATE_FORMAT}
          locale={user.language || 'en-gb'}
          maxDate={datePickerMaxDate}
          onChange={date => onFieldChange('birthdate', date.format(DEFAULT_DATE_FORMAT), true)}
          selected={birthdate}
        />
      </Form.Row>

      <Notification type="error" text={error} exclude={['first name', 'last name', 'nickname', 'email', 'birthdate']} />

      <Form.Summary>
        <Button
          type="submit"
          label={translate('Continue')}
          align="center"
        />
      </Form.Summary>
    </Form>
  </div>
);


InviteKidForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InviteKidForm;
