import { api, BASE_URL } from '../../utils/net';
import * as DateHelper from '../../utils/date';
import { isEdge } from '../../utils/browser';

import {
  SET_CALENDAR_VIEW,
  SET_CALENDAR_DATE,
  SET_CALENDAR_EVENT_SUCCESS,
  ADD_CALENDAR_EVENT_SUCCESS,
  UPDATE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT_SUCCESS,
  GENERATE_ICS_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';

export function setCalendarEventSuccess(calendarEvents) {
  return {
    type: SET_CALENDAR_EVENT_SUCCESS,
    payload: calendarEvents,
  };
}

export function setCalendarView(view) {
  return {
    type: SET_CALENDAR_VIEW,
    payload: view,
  };
}

export function setCalendarDate(date) {
  return {
    type: SET_CALENDAR_DATE,
    payload: date,
  };
}

export function addCalendarEventSuccess(newEvent) {
  return {
    type: ADD_CALENDAR_EVENT_SUCCESS,
    payload: newEvent,
  };
}

export function updateCalendarEventSuccess(event, eventId) {
  return {
    type: UPDATE_CALENDAR_EVENT_SUCCESS,
    event,
    eventId,
  };
}

export function deleteCalendarEventSuccess(eventId, userId) {
  return {
    type: DELETE_CALENDAR_EVENT_SUCCESS,
    eventId,
    userId,
  };
}

export function generateICSFileSuccess(file) {
  return {
    type: GENERATE_ICS_SUCCESS,
    payload: file,
  };
}


export const fetchCalendarEvents = (startDate, rangeType, reCall = false) => async(dispatch, getState) => {
  dispatch(showLoader());

  try {
    const dateRanges = DateHelper.getDateRanges(startDate, rangeType);
    const { data } = await api.get(`${BASE_URL}calendar_events`, dateRanges);

    const { homeUsers, caretakers, kids } = getState();
    const usersEvents = data.users;
    usersEvents.map(userEvent => {
      const userDetails = homeUsers[userEvent.id];
      userEvent.first_name = userDetails.first_name;
      userEvent.last_name = userDetails.last_name;
      userEvent.avatar = userDetails.avatar;
      userEvent.calendar_events.map(event => {
        event.kid_ids = [];
        event.caretaker_ids = [];
        event.participant_ids.forEach(participant => {
          if(Object.keys(caretakers).indexOf(participant) > -1) {
            event.caretaker_ids.push(participant);
          }
          if(Object.keys(kids).indexOf(participant) > -1) {
            event.kid_ids.push(participant);
          }
        });
        formatEventsDates(event);
      });
    });
    dispatch(setCalendarEventSuccess(usersEvents));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchCalendarEvents(startDate, rangeType, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('calendar', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const addCalendarEvent = (eventData, viewType, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const {
      alerts_intervals, title, note, type,
      start_datetime, end_datetime,
      caretaker_ids, kid_ids, user_id,
      repeatable, repeatable_end, repeatable_type,
      ...rest
    } = prepareDatesForCall(eventData);

    const calendarEvent = Object.assign({}, {
      alerts_intervals,
      long_term: eventData.type === 'care',
      caretaker_ids,
      start_datetime,
      end_datetime,
      kid_ids,
      note,
      repeatable,
      repeatable_end,
      repeatable_type,
      title,
      type,
      user_id,
      private: rest.private,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    await api.post(`${BASE_URL}calendar_events/`, {
      calendar_event: calendarEvent,
    });
    dispatch(addCalendarEventSuccess(calendarEvent));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(addCalendarEvent(eventData, viewType, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('calendar', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const updateCalendarEvent = (eventData, eventId, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const {
      alerts_intervals, title, note,
      start_datetime, end_datetime,
      caretaker_ids, kid_ids, user_id,
      repeatable, repeatable_end, repeatable_type,
      repeatable_action,
      ...rest
    } = prepareDatesForCall(eventData);

    const calendarEvent = {
      alerts_intervals,
      title,
      note,
      start_datetime,
      end_datetime,
      long_term: eventData.type === 'care',
      caretaker_ids,
      kid_ids,
      user_id,
      repeatable,
      private: rest.private,
    };
    if(repeatable) {
      calendarEvent.repeatable_action = repeatable_action;
      calendarEvent.repeatable_end = repeatable_end;
      calendarEvent.repeatable_type = repeatable_type;
    }
    await api.patch(`${BASE_URL}calendar_events/${eventId}`, {
      calendar_event: calendarEvent,
    });
    dispatch(updateCalendarEventSuccess(eventData, eventId));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(updateCalendarEvent(eventData, eventId, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('calendar', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteCalendarEvent = (event, startDate, rangeType, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const eventToDelete = {
      user_id: event.userId,
    };
    if(event.repeatable) {
      eventToDelete.repeatable_action = event.repeatable_action;
    }

    await api.deleteWithParam(`${BASE_URL}calendar_events/${event.eventId}`, eventToDelete);
    if(event.repeatable) {
      dispatch(fetchCalendarEvents(startDate, rangeType, true));
    } else {
      dispatch(deleteCalendarEventSuccess(event.eventId, event.userId));
      dispatch(hideLoader());
    }
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(deleteCalendarEvent(event, startDate, rangeType, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('calendar', message));
    }
  }
};


export const generateICSFile = userCalendarToken => async dispatch => {
  try {
    const { data } = await api.get(`${BASE_URL}calendar_events/export/${userCalendarToken}`);
    const fileContent = data;
    const file = new Blob([data], { type: 'text/ics' });
    const fileURL = URL.createObjectURL(file);

    dispatch(generateICSFileSuccess({
      url: fileURL,
      content: fileContent,
    }));
    if(isEdge) {
      window.navigator.msSaveBlob(file, 'calendar.ics');
    }
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('calendar', message));
  }
};


function formatEventsDates(event) {
  if(event.duration) {
    const range = event.duration.replace(/ UTC/g, 'Z').split('..');
    event.start_time = DateHelper.getFormattedTime(range[0]);
    event.start_date = DateHelper.getFormattedDate(range[0]);
    event.end_time = DateHelper.getFormattedTime(range[1]);
    event.end_date = DateHelper.getFormattedDate(range[1]);
  }
  return event;
}

function prepareDatesForCall(eventData) {
  eventData.start_datetime = DateHelper.getUTCFormatDate(`${eventData.start_date} ${eventData.start_time}`);
  eventData.end_datetime = DateHelper.getUTCFormatDate(`${eventData.end_date} ${eventData.end_time}`);
  eventData.alerts_intervals = eventData.alertsIntervals;

  return eventData;
}
