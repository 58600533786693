export default {
  tooltip: {
    borderRadius: 0,
    fontSize: 10,
    padding: 10,
  },
  tooltipContent: {
    padding: 10,
    paddingTop: 25,
  },
  tooltipFooter: {
    marginTop: 0,
  },
  buttonClose: {
    padding: 10,
  },
  options: {
    primaryColor: '#E59907',
    textColor: '#8a8a8a',
    beaconSize: 56,
  },
  spotlight: {
    borderRadius: 3,
  },
};
