import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { cleanError } from '../../../store/actions/errors';
import { invitePartner } from '../../../store/actions/homes';
import { hideLoader } from '../../../store/actions/loading';
import { removeWhitespace } from '../../../utils/string';
import InviteFamilyMember from '../components/InviteFamilyMember';

class InviteFamilyMemberContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: '',
      email: '',
      note: '',
      home_type: 'current',
      home_id: '',
      isNoteVisible: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { t: translate } = this.context;
    const urlParams = new URLSearchParams(location.search);
    const current = urlParams.get('current');
    const ex = urlParams.get('ex');

    if(current) {
      this.setState({
        home_type: 'current',
        home_id: current !== 'undefined' ? current : '',
      });
    }

    if(ex) {
      this.setState({
        note: translate('I´d like to invite you to join 2homes, an app that aims to simplify the lives of part-time parents.'),
        home_type: 'ex',
        home_id: ex !== 'undefined' ? ex : '',
      });
    }

    this.props.hideLoader();
  }

  componentWillUnmount() {
    this.props.cleanError('homes');
  }

  handleFieldChange = name => {
    return event => {
      let value = event.target.value;
      if (event.target.tagName.toUpperCase() === 'A') {
        value = true;
      }
      if(name === 'email') value = removeWhitespace(value);

      this.setState({ [name]: value });
    };
  }

  cancelForm = () => {
    this.props.history.push('/home');
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.cleanError('homes');
    const { first_name, email, note, home_type, home_id } = this.state;
    this.props.invitePartner({ first_name, email, note, home_type, home_id });
  }

  render() {
    const { first_name, email, note } = this.state;
    return (
      <InviteFamilyMember
        {...this.props}
        formData={{ first_name, email, note }}
        isNoteVisible={this.state.isNoteVisible}
        onFieldChange={this.handleFieldChange}
        cancelForm={this.cancelForm}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

InviteFamilyMemberContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  return {
    user: state.user,
    error: state.errors.homes,
    layout: state.layout,
    loading: state.loading,
  };
};

const mapDispatchToProps = {
  invitePartner,
  hideLoader,
  cleanError,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteFamilyMemberContainer);
