import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { da, enGB as en, nb as nn, sv } from 'date-fns/locale';

import { DATEPICKER_DISPLAYED_DATE_FORMAT } from '../../utils/date';
import { isMobile } from '../../utils/browser';
import { DatePickerCustom } from '../';

import 'react-datepicker/dist/react-datepicker.css';


const DPicker = ({ dateFormat, clearDefaultDate, disabled, error, includeDates, label, locale, maxDate,  onChange, popperPlacement, selected, showWeekNumbers = true }, { t: translate }) => {
  const locales = {
    da,
    en,
    nn,
    sv,
  };
  const localeDateFns = locales[locale];
  const maximumDate = maxDate || moment();
  const defaultDate = clearDefaultDate ? null : new Date();

  return (
    <DatePicker
      scrollableYearDropdown
      showMonthDropdown
      showYearDropdown
      showWeekNumbers={showWeekNumbers}
      customInput={<DatePickerCustom label={label} error={error} />}
      dateFormat={dateFormat || DATEPICKER_DISPLAYED_DATE_FORMAT}
      disabled={disabled}
      dropdownMode="scroll"
      includeDates={includeDates}
      locale={localeDateFns}
      maxDate={new Date(maximumDate)}
      onChange={date => onChange(moment(date))}
      popperPlacement={popperPlacement}
      selected={selected ? new Date(selected) : defaultDate}
      yearDropdownItemNumber={90}
      weekLabel={translate('week')}
      withPortal={isMobile()}
    />
  );
};

DPicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DPicker;
