import React from 'react';
import { connect } from 'react-redux';

import { generateICSFile } from '../../../store/actions/calendar';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';


class CalendarICSContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ics: {
        url: '',
        content: '',
      },
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    if(token) {
      await this.props.fetchHomesIfNeeded();
      await this.props.generateICSFile(token);
      const { ics } = this.props.calendar;
      this.setState({
        ics,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.ics !== this.state.ics) {
      this.download();
    }
  }

  download = () => {
    const { ics } = this.state;
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = 'calendar.ics';
    link.href = ics.url;
    link.click();
  }

  render() {
    return (
      <pre>
        {this.state.ics.content}
      </pre>
    );
  }
}

const mapDispatchToProps = {
  generateICSFile,
  fetchHomesIfNeeded,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    calendar: state.calendar,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarICSContainer);
