import { OPERATION_SUCCESS, CLEAN_OPERATION } from './../types';


export default (state = {}, action) => {
  if (action.type === OPERATION_SUCCESS) {
    return {
      ...state,
      [action.payload]: true,
    };
  } else if (action.type === CLEAN_OPERATION) {
    return {
      ...state,
      [action.payload]: null,
    };
  }
  return state;
};
