import React from 'react';
import classnames from 'classnames';


const Card = ({ children, deleteIcon = 'delete', isWalkthroughOpen, ...props }) => {
  return (
    <div className={classnames('card', props.className)} style={isWalkthroughOpen ? { backgroundColor: '#E0E6EB' } : {} }>
      <div className="header relative">
        <span>{props.title}</span>
        <div className="pull-right card-actions">
          {props.onEdit && (
            <i className="zmdi zmdi-edit pointer" onClick={props.onEdit} />
          )}
          {props.onDelete && (
            <i className={`zmdi zmdi-${deleteIcon} p-l-10 pointer`} onClick={props.onDelete} />
          )}
        </div>
      </div>
      <div className="card-content">
        {children}
      </div>
    </div>
  );
};

export default Card;
