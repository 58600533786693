import React from 'react';

import ReactFileUploader from './ReactFileUploader';

const FileUploader = ({ accept, handleUploadFile, linkLabel, noMargin, type = 'link', translate }) => {
  const inputLabelStyle = {
    width: noMargin ? '100%' : '90%',
    margin: noMargin ? 0 : '20px auto 0',
    display: 'flex',
  };

  return (
    <ReactFileUploader
      accept={accept}
      handleUploadFile={handleUploadFile}
      style={{ inputLabelStyle: type === 'link' ? {} : inputLabelStyle }}
      uploadButtonLabel={translate('Upload')}
    >
      {type === 'link' ? <span className="FileUploaderCustomButton">{linkLabel || translate('edit avatar')}</span> : (
        <div className="DropzonePlaceholder"><h5>{translate('Click to upload files')}</h5></div>
      )}
    </ReactFileUploader>
  );
};

export default FileUploader;
