import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import I18n, { setLanguage } from 'redux-i18n';
import moment from 'moment';
import 'moment/locale/nn';
import 'moment/locale/en-gb';
import { registerLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb/index';
import { GlobalModals } from 'src/components';

import { SIGNIN_SUCCESS } from './store/types';
import { api } from './utils/net';
import { translations } from './translations';
import './styles/index.scss';
import configureStore, { history } from './store/store';
import Routes from './routes';


const store = configureStore();

const defaultLanguage = 'en';

registerLocale('nb', nb);

if(api.isLoggedIn()) {
  store.dispatch({
    type: SIGNIN_SUCCESS,
    payload: api.user,
  });

  const userLanguage = store.getState().user.language;
  const language = userLanguage || defaultLanguage;

  store.dispatch(setLanguage(language));

  moment.locale(language);

  if(language === 'nn') {
    moment.updateLocale('nn', {
      weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
      weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
    });
  }
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <Provider store={store}>
    <I18n translations={translations} initialLang={'en'} fallbackLang="en">
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <Routes />
        <GlobalModals />
      </ConnectedRouter>
    </I18n>
  </Provider>,
  document.getElementById('root'),
);
