import React from 'react';


const Inner = ({ children }) => (
  <div className="inner">
    {children}
  </div>
);

export default Inner;
