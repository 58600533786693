import {
  SET_DOCUMENTS_SUCCESS,
  SAVE_DOCUMENTS_FILE_SUCCESS,
  DELETE_DOCUMENTS_FILE_SUCCESS,
  SET_FOLDERS_SUCCESS,
  UPDATE_DOCUMENTS_FILE_SUCCESS,
  ADD_NEW_FOLDER_SUCCESS,
  UPDATE_FOLDER_SUCCESS,
  DELETE_FOLDER_SUCCESS,
} from './../types';


const DEFAULT_STATE = {
  folders: {
    my: [],
    shared: [],
  },
  documents: [],
};


export function documentsReducer(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case SET_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        documents: action.payload,
      });

    case SAVE_DOCUMENTS_FILE_SUCCESS:
      return Object.assign({}, state, {
        documents: [
          ...state.documents,
          action.payload,
        ],
      });

    case UPDATE_DOCUMENTS_FILE_SUCCESS:
      const newDocuments = [];
      state.documents.map(document => {
        if(document.id === action.payload.id) {
          if(action.payload.folder_id === document.folder_id) {
            newDocuments.push(action.payload);
          }
        } else {
          newDocuments.push(document);
        }
      });
      return Object.assign({}, state, {
        documents: newDocuments,
      });

    case DELETE_DOCUMENTS_FILE_SUCCESS:
      return Object.assign({}, state, {
        documents: state.documents.filter(document => {
          return document.id !== action.payload.id;
        }),
      });

    case SET_FOLDERS_SUCCESS:
      return Object.assign({}, state, {
        folders: {
          my: action.payload.my_folders,
          shared: action.payload.shared_folders,
        },
      });

    case ADD_NEW_FOLDER_SUCCESS:
      const newFolders = state.folders;
      newFolders.my.push(action.payload);
      return Object.assign({}, state, {
        folders: newFolders,
      });

    case UPDATE_FOLDER_SUCCESS:
      const updatedFolders = [];
      state.folders.my.map(folder => {
        updatedFolders.push((folder.id !== action.payload.id) ? folder : action.payload);
      });
      return Object.assign({}, state, {
        folders: {
          my: updatedFolders,
          shared: state.folders.shared,
        },
      });

    case DELETE_FOLDER_SUCCESS:
      const deletedFolders = [];
      state.folders.my.map(folder => {
        if(folder.id !== action.payload.id) {
          deletedFolders.push(folder);
        }
      });
      return Object.assign({}, state, {
        folders: {
          my: deletedFolders,
          shared: state.folders.shared,
        },
      });

    default:
      return state;
  }
}


export function documentsInFoldersReducer(state = {}, action) {
  switch(action.type) {
    case SET_DOCUMENTS_SUCCESS:
      action.payload.map(document => {
        state[document.folder_id] = action.payload.length;
      });
      return Object.assign({}, state);

    case SAVE_DOCUMENTS_FILE_SUCCESS:
      state[action.payload.folder_id] += 1;
      return Object.assign({}, state);

    case UPDATE_DOCUMENTS_FILE_SUCCESS:
      state[action.previousFolderId] -= 1;
      state[action.payload.folder_id] += 1;
      return Object.assign({}, state);

    case DELETE_DOCUMENTS_FILE_SUCCESS:
      state[action.payload.folder_id] -= 1;
      return Object.assign({}, state);

    case SET_FOLDERS_SUCCESS:
      action.payload.my_folders.map(folder => {
        state[folder.id] = 0;
      });
      return Object.assign({}, state);

    case ADD_NEW_FOLDER_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: 0,
      });

    default:
      return state;
  }
}
