import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Button, Card } from 'src/components';
import VisaBadge from 'src/static/assets/visa-badge.jpg';
import MCBadge from 'src/static/assets/mc-badge.png';
import { MODAL_NAMES } from 'src/utils/modals';


const cardBrands = ['visa', 'mastercard'];
const availableBadges = {
  visa: VisaBadge,
  mastercard: MCBadge,
};

const SubscriptionPlan = ({ renewSubscription, subscription, showModal }, { t: translate }) => {
  const { push } = useHistory();
  const priceAmount = Number(subscription.price).toFixed(2);
  const subscriptionPrice = `${priceAmount} ${subscription.currency}`;
  const trialEndDate = moment(subscription.trial_ends_at).format('DD.MM.YYYY');
  const isInTrialPeriod = moment(subscription?.trial_ends_at).isSameOrAfter();
  const cardLastDigits = subscription?.card?.last4;
  const cardBrand = subscription?.card?.brand;
  const withBadge = cardBrands.indexOf(cardBrand) > -1;
  const badge = availableBadges[cardBrand];
  const hasPendingCancelation = subscription.status === 'pending_cancelation';
  const currentPeriodEndDate = moment(subscription.cancel_at).format('DD.MM.YYYY');
  const hasActiveSubscription = subscription.status === 'active';
  const hasStripeSubscription = subscription.provider === 'stripe';

  const openSubscriptionCancelModal = () => {
    const modalName = hasStripeSubscription ?
      MODAL_NAMES.SUBSCRIPTION_CANCEL_CONFIRM :
      MODAL_NAMES.SUBSCRIPTION_CANCEL_INFO;
    showModal({ name: modalName });
  };

  return (
    <>
      <Card className="SubscriptionCard kid-profile-container" title={translate('Subscription plan')}>
        <div className="PlansContainer flex space-between">
          {isInTrialPeriod && !hasPendingCancelation && <h3 className="f18">{translate('You are currently free trial user')}</h3>}

          {hasPendingCancelation && <h3 className="f18">{translate('You have cancelled subscription')}</h3>}

          {isInTrialPeriod && !hasPendingCancelation && (
            <div className="SubscriptionRow m-t-30">
              <div className="SubscriptionRowLeft">
                <p>{translate('You have access to all features and functionalities. Your trial period ends:')}</p>
              </div>
              <div className="SubscriptionRowRight">
                <p>{trialEndDate}</p>
              </div>
            </div>
          )}

          {hasPendingCancelation && (
            <div className="SubscriptionRow m-t-30">
              <div className="SubscriptionRowLeft">
                <p>{translate('You will lose access to all features and functionalities after payment period. Your payment period ends:')}</p>
              </div>
              <div className="SubscriptionRowRight space-between">
                <p>{currentPeriodEndDate}</p>
                <Button
                  type="button"
                  onClick={() => renewSubscription(subscription.id)}
                  buttonClassName="small"
                  label={translate('renew subscription')}
                />
              </div>
            </div>
          )}

          {!hasPendingCancelation && (
            <div className="SubscriptionRow m-t-20">
              <div className="SubscriptionRowLeft">
                {isInTrialPeriod ? (
                  <p>{translate('Subscription costs after trial:')}</p>
                ) : (
                  <p>{translate('Subscription costs:')}</p>
                )}
              </div>
              <div className="SubscriptionRowRight">
                <p>{`${subscriptionPrice}/${translate('month')}`}</p>
              </div>
            </div>
          )}

          <div className="SubscriptionRow m-t-20">
            <div className="SubscriptionRowLeft">
              <p>{translate('Payment method:')}</p>
            </div>
            <div className="SubscriptionRowRight">
              {hasStripeSubscription ? (
                <>
                  {cardLastDigits && <p>{`•••• •••• •••• ${cardLastDigits}`}</p>}
                  {withBadge && (
                    <img
                      className="BrandBadge"
                      src={badge}
                    />
                  )}

                  {hasActiveSubscription && (
                    <Button
                      type="button"
                      onClick={() => push('/subscription?mode=edit')}
                      buttonClassName="EditButton small m-l-15"
                      label={translate('edit')}
                    />
                  )}
                </>
              ) : (
                <p>Apple</p>
              )}
            </div>
          </div>

        </div>
        <footer>
          <p>{translate('Have a question? Contact as at')} <a href="mailto:support@2homes.com">support@2homes.com</a></p>
        </footer>
      </Card>

      {!hasPendingCancelation && (
        <Button
          align="right"
          type="button"
          onClick={openSubscriptionCancelModal}
          buttonClassName="link delete m-r-15"
          label={translate('Cancel subscription')}
        />
      )}
    </>
  );
};

SubscriptionPlan.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SubscriptionPlan;
