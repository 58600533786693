import React from 'react';
import PropTypes from 'prop-types';
import useStore from 'src/hooks/useStore';
import { hideModal } from 'src/store/actions/layout';

import { Button, Logo, Modal } from '../../';

const InfoModal = ({
  children, buttonLabel, imgType, name, onClose,
}, { t: translate }) => {
  const [state, dispatch] = useStore();

  const handleOnClick = () => {
    dispatch(hideModal());

    if(typeof onClose === 'function') onClose();
  };

  return (
    <Modal
      isOpen={state.modal?.name === name}
      label="Info"
      closeModal={() => dispatch(hideModal())}
    >
      <div className="InfoModalContent">
        <Logo alt="2homes" type={imgType} />

        {children}

        <Button
          align="center"
          buttonClassName="m-t-50 m-b-30"
          label={buttonLabel || translate('Close')}
          onClick={handleOnClick}
        />
      </div>
    </Modal>
  );
};


InfoModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InfoModal;
