import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { AccessDenied } from '../components';

import { PERMISSION } from './permissions';

const PermissionsCheck = (Component, allowedRoles) => {
  const Page = props => {
    const { replace } = useHistory();
    const user = useSelector(({ user }) => user);
    const hasUserPermissionsForPage = allowedRoles.includes(user.permission);

    useEffect(() => {
      const hasUserCompleteData = user.birthdate && user.last_name && user.gender;
      const isPermissionsWithFirstSteps = user.permission === PERMISSION.INITIAL ||
        user.permission === PERMISSION.INVITED_EX ||
        user.permission === PERMISSION.INVITED_CURRENT;

      if (isPermissionsWithFirstSteps && !hasUserCompleteData) {
        return replace('/first-steps/1');
      }

      if (user.permission === PERMISSION.INITIAL && hasUserCompleteData) {
        return replace('/first-steps/3');
      }
    }, []);

    if (hasUserPermissionsForPage) {
      return (
        <Component {...props} />
      );
    }

    return (
      <AccessDenied />
    );
  };

  return Page;
};

export default PermissionsCheck;
