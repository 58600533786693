import * as _ from 'lodash';

import {
  SET_HOMES_SUCCESS,
  UPDATE_KID_PROFILE_SUCCESS,
  UPDATE_CUSTOM_PROFILE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  SET_PARTNER_DATA_SUCCESS,
  DELETE_USER_SUCCESS,
} from './../types';


export function homeCurrentReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      return {
        ...action.payload.my_home,
      };

    default:
      return state;
  }
}

export function homesExReducer(state = [], action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      return [
        ...action.payload.my_ex_homes,
      ];

    default:
      return state;
  }
}

export function homesUsersReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      const kidsArray = [];
      if(action.payload.my_home) {
        kidsArray.push([
          ...action.payload.my_home.kid_ids,
        ]);
      }
      if(action.payload.my_ex_homes.length > 0) {
        kidsArray.push([
          ...action.payload.my_ex_homes.map(home => home.kid_ids),
        ]);
      }
      if(action.payload.partner_ex_homes && action.payload.partner_ex_homes.length > 0) {
        kidsArray.push([
          ...action.payload.partner_ex_homes.map(home => home.kid_ids),
        ]);
      }
      const kidsList = _.uniq(
        _.flattenDeep(kidsArray).sort(),
      );

      const caretakersArray = [];
      if(action.payload.my_home === null) {
        caretakersArray.push(action.currentUser.id);
      } else {
        if(action.payload.my_home) {
          caretakersArray.push([
            ...action.payload.my_home.caretaker_ids,
          ]);
        }
      }

      const currentList = _.uniq(
        _.flattenDeep(caretakersArray).sort(),
      );

      const homeUsers = Object.keys(action.payload.users).length > 0 ?
        action.payload.users :
        { [action.currentUser.id]: { ...action.currentUser } };

      _.map(homeUsers, (user, id) => {
        user.id = id;
        user.status = action.payload.invited_user_ids.indexOf(id) > -1 ? 'pending' : 'active';
        user.familyStatus = kidsList.indexOf(id) > -1 ? 'kid' : (
          currentList.indexOf(id) > -1 ? 'current' : 'ex'
        );

        // My CURRENT home KIDS
        if(action.payload.my_home && user.familyStatus === 'kid') {
          if(action.payload.my_home.kid_ids.indexOf(id) > -1) {
            user.whichFamily = 'current';
          }
        }

        // My EX home KIDS
        if(action.payload.my_ex_homes.length > 0) {
          const exKids = [
            ...action.payload.my_ex_homes.map(home => home.kid_ids),
          ];
          const filteredExKids = exKids.filter(ex => ex.indexOf(id) > -1);
          if(filteredExKids.length > 0) {
            user.whichFamily = 'ex';
          }
        }

        // My Partner's EX home KIDS and CARETAKERS
        if(action.payload.partner_ex_homes && action.payload.partner_ex_homes.length > 0) {
          // KIDS
          const partnerExKids = [
            ...action.payload.partner_ex_homes.map(home => home.kid_ids),
          ];
          const filteredExKids = partnerExKids.filter(ex => ex.indexOf(id) > -1);
          if(filteredExKids.length > 0) {
            user.whichFamily = 'partnerEx';
          }
          // CARETAKERS
          const partnerExCaretakers = [
            ...action.payload.partner_ex_homes.map(home => home.caretaker_ids),
          ];
          const filteredExCaretakers = partnerExCaretakers.filter(ex => ex.indexOf(id) > -1);
          if(filteredExCaretakers.length > 0) {
            user.whichFamily = 'partnerEx';
          }
        }

        user.color = action.currentUser.id === user.id ? action.currentUser.color : user.color;
      });

      return {
        ...homeUsers,
      };

    case UPDATE_KID_PROFILE_SUCCESS:
    case UPDATE_CUSTOM_PROFILE_SUCCESS:
    case PROFILE_UPDATE_SUCCESS:
      const updatedPerson = Object.assign({}, state[action.payload.id], {
        ...action.payload,
        avatar: action.payload.file,
      });

      return Object.assign({}, state, {
        [action.payload.id]: updatedPerson,
      });

    case SET_PARTNER_DATA_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload,
      });

    case DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload,
      });

    default:
      return state;
  }
}


const setFamilyMembers = (home, currentUser) => {
  const family = {
    secondParent: '',
    kids: [],
    homeId: '',
  };

  if(home) {
    family.secondParent = home.caretaker_ids.find(cId => cId !== currentUser.id) || '';
    family.kids = home.kid_ids || [];
    family.homeId = home.id || undefined;
  }
  return family;
};


export function homesUsersFamiliesReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      const { my_home, my_ex_homes } = action.payload;
      const newState = {
        haveIFamilies: false,
        currentFamily: {
          exists: !_.isNil(my_home) && !_.isNil(my_home.id),
          kids: (my_home && my_home.kid_ids.length) || 0,
          caretakers: (my_home && my_home.caretaker_ids.length) || 0,
        },
        exFamily: {
          exists: my_ex_homes.length > 0,
          kids: _.compact(_.map(my_ex_homes, home => home.kid_ids.length)).length,
          caretakers: _.compact(_.map(my_ex_homes, home => home.caretaker_ids.length)).length,
        },
        lists: {
          current: setFamilyMembers(my_home, action.currentUser),
          ex: my_ex_homes.map(exHome => setFamilyMembers(exHome, action.currentUser)),
        },
      };
      newState.haveIFamilies = newState.currentFamily.exists || newState.exFamily.exists;
      return newState;

    default:
      return state;
  }
}
