import React from 'react';

import CalendarWeek from './views/CalendarWeek';


export const CalendarInnerWeek = ({ ...props }) => (
  <div className="calendar-container week">
    <CalendarWeek {...props} />
  </div>
);

export default CalendarInnerWeek;
