import {
  SIGNIN_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  UNSET_USER_FIRST_STEPS,
  SIGNOUT,
} from './../types';


export default (state = {}, action) => {
  switch(action.type) {
    case SIGNIN_SUCCESS:
      return { ...action.payload };

    case PROFILE_UPDATE_SUCCESS:
      return { ...action.payload };

    case UNSET_USER_FIRST_STEPS:
      return Object.assign({}, state, {
        first_steps: false,
      });

    case SIGNOUT:
      return {};

    default:
      return state;
  }
};
