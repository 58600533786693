import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Notification } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';


export const ChangePassword = ({ user, layout, loading, passwords, error, ...props }, { t: translate }) => {
  const formValid = props.validatePassword();
  return (
    <SignedInLayout
      page="UserProfile"
      currentPath="/user_profile"
      user={user}
      layout={layout}
      loading={loading}
    >
      <div className="CompleteYourProfile">
        <section>
          <h2>{translate('Change password')}</h2>
          <p>
            {translate('Fill the form to change your password.')}
          </p>
          <Form onSubmit={props.onSubmitPassword} loading={loading}>
            <div className="form__row">
              <input
                type="password"
                value={passwords.oldPassword}
                onChange={props.onFieldChange('oldPassword')}
              />
              <label>{translate('Enter old password')}</label>
            </div>
            <div className="form__row">
              <input
                type="password"
                value={passwords.newPassword}
                onChange={props.onFieldChange('newPassword')}
              />
              <label>{translate('Enter new password')}</label>
            </div>
            <div className="form__row">
              <input
                type="password"
                value={passwords.newPasswordRepeated}
                onChange={props.onFieldChange('newPasswordRepeated')}
              />
              <label>{translate('Repeat new password')}</label>
            </div>

            {(!props.validatePassword() && passwords.newPassword !== '' && passwords.newPasswordRepeated !== '' && (passwords.newPassword !== passwords.newPasswordRepeated)) ? (
              <Notification type="error" text={translate('New passwords must be the same')} />
            ) : (
              <Notification type="error" text={error} />
            )}

            {passwords.success && (<Notification type="success" text={translate('Your password has been successfully changed')} />)}

            <Form.Summary>
              <Button
                type="submit"
                label={translate('Save')}
                align="center"
                disabled={!formValid}
                title={!formValid && translate('Please fill the form')}
              />
            </Form.Summary>
          </Form>
        </section>
      </div>
    </SignedInLayout>
  );
};


ChangePassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChangePassword;
