import React from 'react';

import Logo from '../Logo';

import AuthFormBottomContent from './AuthFormBottomContent';

const AuthForm = ({ title, children, bottomContent, type }) => (
  <div className={`auth-form ${type}`}>
    <div className="auth-form__content">
      <Logo alt="2homes" type={type || 'hello'} />
      <h1 className="auth-form__title">{title}</h1>
      {children}
    </div>
    <AuthFormBottomContent>
      {bottomContent}
    </AuthFormBottomContent>
  </div>
);

export default AuthForm;
