import React from 'react';
import PropTypes from 'prop-types';

import Badge from './../Badge';

const showMonthName = month => {
  if(window.innerWidth < 1500) {
    return month.substr(0, 3);
  }
  return month;
};

const DateSelector = ({ selectedYear, selectedMonth, activeDate, setDate }, { t: translate }) => {
  const MONTHS = [
    translate('January'),
    translate('February'),
    translate('March'),
    translate('April'),
    translate('May'),
    translate('June'),
    translate('July'),
    translate('August'),
    translate('September'),
    translate('October'),
    translate('November'),
    translate('December'),
  ];

  const formatMonthNo = monthNo => {
    return `0${monthNo + 1}`.slice(-2);
  };

  return (
    <div className="date-filter">
      <div
        className="chevron chevron-left"
        onClick={() => setDate(selectedYear - 1, formatMonthNo(selectedMonth - 1), true)}
      />
      <div className="month-selection">
        <span className="year">{selectedYear}</span>
        {MONTHS.map((month, idx) => {
          const active = activeDate.getMonth() === idx;
          return (
            <Badge key={idx}
              label={showMonthName(month)}
              active={active}
              activeColor="bg-darkgrey"
              onClick={() => setDate(selectedYear, formatMonthNo(idx).slice(-2), true)}
            />
          );
        })}
      </div>
      <div
        className="chevron chevron-right"
        onClick={() => setDate(selectedYear + 1, formatMonthNo(selectedMonth - 1), true)}
      />
    </div>
  );
};


DateSelector.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DateSelector;
