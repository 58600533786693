import React from 'react';
import { Redirect } from 'react-router';
import { Payment } from 'src/components';
import SignedInLayout from 'src/layouts/SignedInLayout';
import { PERMISSION } from 'src/utils/permissions';

import GettingStarted from './GettingStarted';
import CompleteYourProfile from './CompleteYourProfile';
import PaymentSuccess from './PaymentSuccess';

export const FirstSteps = ({
  history, layout, step, loading, error, profile,
  onFieldChange, onAvatarChange, onSubmit, user }) => {
  let childComponent = null;

  if(step === 1) {
    childComponent = (
      <GettingStarted
        history={history}
        {...profile}
      />
    );
  } else if (step === 2) {
    childComponent = (
      <CompleteYourProfile
        profile={profile}
        loading={loading}
        error={error}
        onFieldChange={onFieldChange}
        onAvatarChange={onAvatarChange}
        onSubmit={onSubmit}
      />
    );
  } else if (step === 3) {
    if (user.permission ===  PERMISSION.INITIAL) {
      childComponent = (
        <Payment />
      );
    } else {
      childComponent = (
        <Redirect to="/home" />
      );
    }
  } else if (step === 4) {
    childComponent = (
      <PaymentSuccess language={user.language} userNickname={user.nickname} />
    );
  } else {
    history.push('/404');
  }

  return (
    <SignedInLayout
      page="FirstSteps"
      currentPath="/first-steps"
      user={user}
      layout={layout}
    >
      {childComponent}
    </SignedInLayout>
  );
};


export default FirstSteps;
