export const LOADER_SHOW = 'LOADER_SHOW';
export const LOADER_HIDE = 'LOADER_HIDE';
export const ERROR = 'ERROR';
export const CLEAN_ERROR = 'CLEAN_ERROR';

export const OPERATION_SUCCESS = 'OPERATION_SUCCESS';
export const CLEAN_OPERATION = 'CLEAN_OPERATION';

export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const LOCATION_CHANGE_NO_LOADER = 'LOCATION_CHANGE_NO_LOADER';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';


/* AUTH */
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_RECOVER_PASSWORD_SUCCESS = 'SET_RECOVER_PASSWORD_SUCCESS';

export const SET_USER_FIRST_STEPS_NEEDED = 'SET_USER_FIRST_STEPS_NEEDED';

/* LAYOUT */
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_HIDE = 'MODAL_HIDE';
export const SET_NAVIGATION_COLLAPSED = 'SET_NAVIGATION_COLLAPSED';
export const SET_NAVIGATION_EXPANDED = 'SET_NAVIGATION_EXPANDED';
export const SET_LAYOUT_PRINTABLE = 'SET_LAYOUT_PRINTABLE';
export const UNSET_LAYOUT_PRINTABLE = 'UNSET_LAYOUT_PRINTABLE';

/* USER PROFILE */
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const UNSET_USER_FIRST_STEPS = 'UNSET_USER_FIRST_STEPS';


/* FAMILY */
export const ADD_KID_SUCCESS = 'ADD_KID_SUCCESS';
export const INVITE_PARTNER_SUCCESS = 'INVITE_PARTNER_SUCCESS';
export const SET_HOMES_SUCCESS = 'SET_HOMES_SUCCESS';
export const SET_CURRENT_KID = 'SET_CURRENT_KID';
export const SET_PARTNER_DATA_SUCCESS = 'SET_PARTNER_DATA_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const UPDATE_CUSTOM_PROFILE_SUCCESS = 'UPDATE_CUSTOM_PROFILE_SUCCESS';

/* KID PROFILE */
export const SET_KID_PROFILE_SUCCESS = 'SET_KID_PROFILE_SUCCESS';
export const UPDATE_KID_PROFILE_SUCCESS = 'UPDATE_KID_PROFILE_SUCCESS';
export const SET_KID_DOCUMENTS_SUCCESS = 'SET_KID_DOCUMENTS_SUCCESS';
export const ADD_KID_DOCUMENTS_SUCCESS = 'ADD_KID_DOCUMENTS_SUCCESS';
export const UPDATE_KID_DOCUMENTS_SUCCESS = 'UPDATE_KID_DOCUMENTS_SUCCESS';
export const DELETE_KID_DOCUMENTS_SUCCESS = 'DELETE_KID_DOCUMENTS_SUCCESS';
export const ADD_KID_CARD_SUCCESS = 'ADD_KID_CARD_SUCCESS';
export const UPDATE_KID_CARD_SUCCESS = 'UPDATE_KID_CARD_SUCCESS';
export const DELETE_KID_CARD_SUCCESS = 'DELETE_KID_CARD_SUCCESS';
export const ADD_KID_LEAF_SUCCESS = 'ADD_KID_LEAF_SUCCESS';
export const UPDATE_KID_LEAF_SUCCESS = 'UPDATE_KID_LEAF_SUCCESS';
export const DELETE_KID_LEAF_SUCCESS = 'DELETE_KID_LEAF_SUCCESS';

/* CALENDAR */
export const SET_CALENDAR_VIEW = 'SET_CALENDAR_VIEW';
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE';
export const SET_CALENDAR_EVENT_SUCCESS = 'SET_CALENDAR_EVENT_SUCCESS';
export const ADD_CALENDAR_EVENT_SUCCESS = 'ADD_CALENDAR_EVENT_SUCCESS';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const GENERATE_ICS_STARTED = 'GENERATE_ICS_STARTED';
export const GENERATE_ICS_SUCCESS = 'GENERATE_ICS_SUCCESS';

/* TAGS */
export const SET_TAG_KEYS_SUCCESS = 'SET_TAG_KEYS_SUCCESS';

/* FINANCES */
export const SET_FINANCIAL_FILTER_DATE = 'SET_FINANCIAL_FILTER_DATE';
export const SET_FINANCIAL_ACTIVE_DATE = 'SET_FINANCIAL_ACTIVE_DATE';
export const SET_FINANCIAL_DATA_SUCCESS = 'SET_FINANCIAL_DATA_SUCCESS';
export const SAVE_FINANCIAL_PENDING_FILE_SUCCESS = 'SAVE_FINANCIAL_PENDING_FILE_SUCCESS';
export const DELETE_FINANCIAL_DATA_SUCCESS = 'DELETE_FINANCIAL_DATA_SUCCESS';
export const SAVE_FINANCIAL_DATA_SUCCESS = 'SAVE_FINANCIAL_DATA_SUCCESS';
export const SAVE_SPLIT_REQUEST_SUCCESS = 'SAVE_SPLIT_REQUEST_SUCCESS';
export const SET_SPLIT_REQUEST_SUCCESS = 'SET_SPLIT_REQUEST_SUCCESS';
export const CONFIRM_SPLIT_REQUEST_SUCCESS = 'CONFIRM_SPLIT_REQUEST_SUCCESS';
export const SET_SPLIT_REQUESTS_SUCCESS = 'SET_SPLIT_REQUESTS_SUCCESS';
export const CHANGE_SPLIT_REQUESTS_SUCCESS = 'CHANGE_SPLIT_REQUESTS_SUCCESS';
export const ADD_ATTACHMENT_STARTED = 'ADD_ATTACHMENT_STARTED';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';
export const REMOVE_ATTACHMENT_STARTED = 'REMOVE_ATTACHMENT_STARTED';
export const REMOVE_ATTACHMENT_SUCCESS = 'REMOVE_ATTACHMENT_SUCCESS';

/* BUDGET */
export const SET_BUDGET_FILTER_DATE = 'SET_BUDGET_FILTER_DATE';
export const SET_BUDGET_ACTIVE_DATE = 'SET_BUDGET_ACTIVE_DATE';
export const SET_BUDGET_DATA_SUCCESS = 'SET_BUDGET_DATA_SUCCESS';
export const DELETE_BUDGET_DATA_SUCCESS = 'DELETE_BUDGET_DATA_SUCCESS';
export const SAVE_BUDGET_DATA_SUCCESS = 'SAVE_BUDGET_DATA_SUCCESS';

/* DOCUMENTS */
export const SET_DOCUMENTS_SUCCESS = 'SET_DOCUMENTS_SUCCESS';
export const SAVE_DOCUMENTS_FILE_SUCCESS = 'SAVE_DOCUMENTS_FILE_SUCCESS';
export const UPDATE_DOCUMENTS_FILE_SUCCESS = 'UPDATE_DOCUMENTS_FILE_SUCCESS';
export const DELETE_DOCUMENTS_FILE_SUCCESS = 'DELETE_DOCUMENTS_FILE_SUCCESS';
export const SET_FOLDERS_SUCCESS = 'SET_FOLDERS_SUCCESS';
export const ADD_NEW_FOLDER_SUCCESS = 'ADD_NEW_FOLDER_SUCCESS';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';

/* REPORTS */
export const SET_REPORTS_SUCCESS = 'SET_REPORTS_SUCCESS';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const FETCH_REPORT_DATA_SUCCESS = 'FETCH_REPORT_DATA_SUCCESS';

/* NOTIFICATIONS */
export const FETCH_NEW_NOTIFICATIONS_SUCCESS = 'FETCH_NEW_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const SET_NOTIFICATIONS_READ_SUCCESS = 'SET_NOTIFICATIONS_READ_SUCCESS';
export const CLEAR_NEW_NOTIFICATIONS = 'CLEAR_NEW_NOTIFICATIONS';

/*  WALKTHROUHG */
export const WALKTHROUGH_SHOW = 'WALKTHROUGH_SHOW';
export const WALKTHROUGH_HIDE = 'WALKTHROUGH_HIDE';
export const WALKTHROUGH_UPDATE_STEP_INDEX = 'WALKTHROUGH_UPDATE_STEP_INDEX';
