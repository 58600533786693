import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Modal } from '../../../components';


export const ConfirmationModal = ({ closeModal, deleteError, handleDeleteUserAccount, modalOpened, setStep, step }, { t: translate }) => {
  const isFirstStep = step === 1;

  const handleCancelClick = () => {
    if(isFirstStep) {
      closeModal();
    } else {
      setStep('previous');
    }
  };

  const handleActonClick = () => {
    if(isFirstStep) {
      setStep('next');
    } else {
      handleDeleteUserAccount();
    }
  };

  const title = isFirstStep ? translate('Sorry to see you go…') : translate('Deleting 2homes account');
  const infoText = isFirstStep ?
    translate('Before you make a final decision, make sure you understand how account removal will result in the following actions:') :
    translate('If you are sure, confirm by clicking the button below.');

  return (
    <Modal
      small
      isOpen={modalOpened}
      label={isFirstStep ? 'DeleteAccount' : 'DeleteAccount--small'}
      closeModal={() => closeModal()}
    >
      <div className="DeleteAccountModal">
        <div className="Wrapper horizontal-center-content">
          <div><img className="Logo home-logo" src="/assets/homes_print2x.png" /></div>
          <h2 className="Title">{title}</h2>
          <p className="InfoText">{infoText}</p>

          <ul className={classnames('ActionsList', { Clear: !isFirstStep })}>
            {isFirstStep ? (
              <Fragment>
                <li className="ListItem"><span className="black">{translate('Your events will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('Your documents will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('Your private folders and their content will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('All your transactions will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('All your sent invitations will be cancelled')}</span></li>
                <li className="ListItem"><span className="black">{translate('Home without any active family members will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('Children account with no caretaker assigned will be deleted')}</span></li>
                <li className="ListItem"><span className="black">{translate('Your subscription will be canceled')}</span></li>
              </Fragment>
            ) : (
              <Fragment>
                <li className="ListItem"><span className="black">{translate('You will be logged out immediately.')}</span></li>
                <li className="ListItem"><span className="black">{translate('Remember that this action cannot be reversed!')}</span></li>
              </Fragment>
            )}
          </ul>

          {isFirstStep && (
            <p className="InfoText">
              {translate('Note: If you created your subscription via AppStore it won’t be cancelled. Remember to also cancel it in AppStore after deleting your 2homes account')}
            </p>
          )}
        </div>

        <div className="ButtonsContainer">
          <Button
            type="button"
            label={isFirstStep ? translate('I UNDERSTAND') : translate('DELETE MY ACCOUNT')}
            onClick={() => handleActonClick()}
          />

          <div className="error center f13 m-t-10">{deleteError && deleteError}</div>

          <Button
            buttonClassName="link m-t-20"
            type="button"
            label={translate('CANCEL')}
            onClick={() => handleCancelClick()}
          />
        </div>
      </div>
    </Modal>
  );
};


ConfirmationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ConfirmationModal;
