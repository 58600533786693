import React from 'react';
import { connect } from 'react-redux';

import { fetchSplitRequestData, confirmSplitRequest } from '../../../store/actions/finance';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { cleanError } from '../../../store/actions/errors';
import SplitRequest from '../components/SplitRequest';


class SplitRequestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      confirmed: false,
      submitted: false,
      back: 'split-requests',
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');
    const back = urlParams.get('back');

    await this.props.fetchHomesIfNeeded();
    this.setState({
      id,
      back,
    }, () => {
      this.fetchData();
    });
  }

  componentWillUnmount() {
    this.props.cleanError('finances');
  }

  fetchData = () => {
    const { id } = this.state;
    return this.props.fetchSplitRequestData(id);
  }

  saveSplitRequest = confirmed => {
    const { id } = this.state;
    const { confirmSplitRequest, cleanError, history } = this.props;

    cleanError('finances');
    confirmSplitRequest(id, confirmed)
      .then(() => {
        this.setState({
          confirmed,
          submitted: true,
        }, () => {
          history.push('/split-requests');
        });
      });
  }

  render() {
    return (
      <SplitRequest
        {...this.props}
        {...this.state}
        saveSplitRequest={this.saveSplitRequest}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.loading,
    layout: state.layout,
    finance: state.finance,
    homeUsers: state.homeUsers,
    splitRequest: state.splitRequest,
    error: state.errors.finances,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  fetchSplitRequestData,
  confirmSplitRequest,
  cleanError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplitRequestContainer);
