import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import base64 from 'base-64';

import { Walkthrough } from '../../../components';
import {
  fetchDocuments, saveFile, updateFile,
  deleteFile, fetchFoldersList, addFolder,
  updateFolder, deleteFolder,
} from '../../../store/actions/documents';
import { cleanError, setError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { getAdditionalStepsData, showInitialWalkthrough } from '../../../utils/walkthrough';
import Documents from '../components/Documents';
import { MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_FILE_SIZE_MB } from '../../../utils/file';


const DEFAULT_FOLDER_FORM_STATE = {
  id: '',
  name: '',
  visible: false,
};

const DEFAULT_FILE_FORM_STATE = {
  id: '',
  name: '',
  folder_id: '',
  note: '',
};

class DocumentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folderActive: '',
      fileLink: '',
      fileToDelete: {},
      folderToDelete: {},
      modalOpened: false,
      modalConfirmOpened: false,
      modalShareOpened: false,
      editFolderData: _.cloneDeep(DEFAULT_FOLDER_FORM_STATE),
      editFileData: _.cloneDeep(DEFAULT_FILE_FORM_STATE),
    };
  }

  async componentDidMount() {
    this.props.cleanError('documents');
    await this.props.fetchHomesIfNeeded();
    await this.props.fetchFoldersList();
    if(this.props.documents.folders.my && this.props.documents.folders.my.length > 0) {
      const folderActive = this.props.documents.folders.my[0];
      this.setFolderActive(folderActive);
      this.props.fetchDocuments(folderActive.id);
    }
    showInitialWalkthrough('documents', this.props.showWalkthrough);
  }

  setFolderActive = folder => {
    this.setState({
      folderActive: folder.id,
    }, () => {
      this.props.fetchDocuments(folder.id);
    });
  }

  handleFormChange = (type, name, forcedValue) => {
    const { editFolderData, editFileData } = this.state;
    return event => {
      if(type === 'folder') {
        editFolderData[name] = forcedValue || event.target.value;
      } else {
        editFileData[name] = forcedValue || event.target.value;
      }
      this.setState({
        editFolderData,
        editFileData,
      });
    };
  }

  editObject = (type, object) => {
    const name = type === 'file' ? 'editFileData' : 'editFolderData';
    this.setState({
      [name]: _.clone(object),
    }, () => {
      this.props.cleanError('documents');
      this.showModal();
    });
  }

  setToDelete = (type, object) => {
    const name = type === 'file' ? 'fileToDelete' : 'folderToDelete';
    this.setState({
      [name]: object,
    }, () => {
      this.showModal('Confirm');
    });
  }

  folderReadyToDelete = folder => {
    const { user, documentsInFolders } = this.props;
    return folder.creator_id === user.id && documentsInFolders[folder.id] === 0;
  }

  deleteObject = (e, type, object) => {
    e.preventDefault();
    if(type === 'file') {
      this.props.deleteFile(object);
    } else if(this.folderReadyToDelete(object)) {
      this.props.deleteFolder(object);
    }
    this.closeModal(true);
  }

  createShareLink = fileObj => {
    const { user } = this.props;

    if(fileObj) {
      const filePath = base64.encode(fileObj.file_url);
      const fileFullPath = `${window.location.origin}/sharefile?file=${filePath}`;
      const filename = fileObj.filename.replace(/ /g, '+');
      const author = `${user.first_name}+${user.last_name}`;

      const fullPath = `${fileFullPath}&author=${author}&filename=${filename}`;

      this.setState({
        fileLink: fullPath,
      }, () => {
        this.showModal('Share');
      });
    }
  }

  showModal = modalType => {
    const name = modalType ? `modal${modalType}Opened` : 'modalOpened';
    this.setState({
      [name]: true,
    });
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      modalConfirmOpened: false,
      modalShareOpened: false,
      fileLink: '',
    }, () => {
      this.cleanState();
    });
  }

  saveDocumentFile = (file, fileType) => {
    this.props.cleanError('documents');
    const { folderActive } = this.state;

    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      return this.setTooLargeFileError();
    }

    this.props.saveFile(folderActive, file, fileType);
  }

  onModalFormSubmit = (e, type) => {
    e.preventDefault();
    this.props.cleanError('documents');
    if(type === 'folder') {
      const { editFolderData } = this.state;
      if(editFolderData.id) {
        this.props.updateFolder(editFolderData).then(() => {
          if(!this.props.error || this.props.error === null) {
            this.setFolderActive(editFolderData);
            this.cleanState();
            this.closeModal();
          }
        });
      } else {
        this.props.addFolder(editFolderData).then(() => {
          if(!this.props.error || this.props.error === null) {
            const folder = _.last(this.props.documents.folders.my);
            this.setFolderActive(folder);
            this.cleanState();
            this.closeModal();
          }
        });
      }
    }
    if(type === 'file') {
      const { folderActive, editFileData } = this.state;
      this.props.updateFile(folderActive, editFileData).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.cleanState();
          this.closeModal();
        }
      });
    }
  }

  setTooLargeFileError = () => {
    const { t: translate } = this.context;
    const { setError } = this.props;

    setError('documents', translate('File is too large. Max file size is') + ` ${MAX_UPLOAD_FILE_SIZE_MB.toFixed(0)}MB`);
  }

  cleanState = () => {
    this.props.cleanError('documents');
    this.setState({
      editedId: null,
      editFileData: _.cloneDeep(DEFAULT_FILE_FORM_STATE),
      editFolderData: _.cloneDeep(DEFAULT_FOLDER_FORM_STATE),
      fileToDelete: {},
      folderToDelete: {},
    });
  }

  generateAdditionalSteps = () => {
    const { documents: { folders: { my, shared } }, location: { pathname } } = this.props;
    const { t } = this.context;
    const additionalSteps = [];
    const steps = getAdditionalStepsData(pathname, t);

    if(my.length) {
      additionalSteps.push(steps.my);
    }

    if(shared !== null && shared.length) {
      additionalSteps.push(steps.shared);
    }

    if(my.length || (shared !== null && shared.length)) {
      additionalSteps.push(...steps.files);
    }

    return additionalSteps;
  }

  render() {
    const additionalSteps = this.generateAdditionalSteps();

    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          additionalSteps={additionalSteps}
        />

        <Documents
          {...this.props}
          {...this.state}
          handleFormChange={this.handleFormChange}
          editObject={this.editObject}
          createShareLink={this.createShareLink}
          setToDelete={this.setToDelete}
          saveDocumentFile={this.saveDocumentFile}
          deleteObject={this.deleteObject}
          closeModal={this.closeModal}
          folderReadyToDelete={this.folderReadyToDelete}
          setFolderActive={this.setFolderActive}
          onModalFormSubmit={this.onModalFormSubmit}
        />
      </div>
    );
  }
}


DocumentsContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    documents: state.documents,
    documentsInFolders: state.documentsInFolders,
    homeUsers: state.homeUsers,
    homesUsersFamilies: state.homesUsersFamilies,
    error: state.errors.documents,
    isWalkthroughOpen: state.walkthrough.isOpen,
  };
};

const mapDispatchToProps = {
  fetchDocuments,
  saveFile,
  updateFile,
  deleteFile,
  fetchFoldersList,
  addFolder,
  updateFolder,
  deleteFolder,
  fetchHomesIfNeeded,
  cleanError,
  setError,
  showWalkthrough,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentsContainer);
