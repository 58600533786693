import { ERROR, CLEAN_ERROR } from './../types';


export function setError(type, message) {
  return {
    type: ERROR,
    payload: {
      type,
      message,
    },
  };
}

export function cleanError(type) {
  return {
    type: CLEAN_ERROR,
    payload: type,
  };
}

