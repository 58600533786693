import React from 'react';
import PropTypes from 'prop-types';

import { FileUploader } from '../../../../components';

import * as file from './../../../../utils/file';


export const Attachment = ({ attachments, addNewFile, setFileToDelete, showDeleteButton, transactionId }, { t: translate }) => {
  return transactionId ? (
    <div className="attachment-section">
      <div className="title lightgrey">{translate('attachments')}</div>

      {attachments && attachments.map((attachment, idx) => {
        const { name, type } = attachment;
        const fileType = file.getFileType(type);

        return [
          <i key={`attachment_fileicon_${idx}`} className={`zmdi zmdi-${fileType.icon || 'file'}`} />,
          <span key={`attachment_filename_${idx}`} className="name">{name}</span>,
          showDeleteButton && (
            <i key={`attachment_delete_${idx}`} className="zmdi zmdi-delete inline-block pull-right pointer" onClick={() => setFileToDelete(attachment, true)} />
          ),
        ];
      })}

      {attachments.length === 0 ? (
        <div className="attachment-dropzone">
          <FileUploader linkLabel={translate('+ add new file')} handleUploadFile={addNewFile} translate={translate} />
        </div>
      ) : null}
    </div>
  ) : null;
};


Attachment.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Attachment;
