import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DEFAULT_DISPLAYED_DATE_FORMAT, DEFAULT_DISPLAYED_TIME_FORMAT } from './../../../utils/date';


export const NotificationsTableRow = ({ row, homeUsers, selectedMessages, ...props }, { t: translate }) => {
  const date = moment(row.created_at).format(DEFAULT_DISPLAYED_DATE_FORMAT);
  const time = moment(row.created_at).format(DEFAULT_DISPLAYED_TIME_FORMAT);
  const notificationsTypes = {
    Transaction: translate('Split request'),
    CalendarEvent: translate('Calendar'),
    User: translate('User'),
  };

  const getAuthor = () => {
    const author = homeUsers && Object.keys(homeUsers).length > 0 && homeUsers[row.from] && (`@${homeUsers[row.from].nickname}` || `@${homeUsers[row.from].first_name.toLowerCase()}`);
    const authorColor = homeUsers && row.from && homeUsers[row.from] && homeUsers[row.from].color;

    return {
      author,
      authorColor,
    };
  };

  const author = getAuthor();

  const isChecked = selectedMessages && selectedMessages.indexOf(row.id) > -1;
  const authorName = row.action === 'destroy_user' ? `@${row.message.split(' ')[0]}` : author.author;

  return (
    <div className={`trow ${row.status === 'read' ? '' : 'not-read'}`}>
      <div className="tcell center check">
        <input
          type="checkbox"
          value={row.id}
          checked={isChecked}
          onClick={() => props.handleSelectedMessage(row.id)}
        />
      </div>
      <div className="tcell medium right date">{date}<span className="block f12 grey">{time}</span></div>
      <div className="tcell left p-r-15 type">{notificationsTypes[row.notifiable_type]}</div>
      <div className="tcell author" style={{ color: author.authorColor }}>{authorName}</div>
      <div className="tcell message">{row.message}</div>
      <div className="tcell hidden-small-res note"><span>{row.notifiable_note}</span></div>
      <div className="tcell icons pointer" onClick={() => props.handleRedirection(row)}>
        <i className="zmdi zmdi-eye" style={{ visibility: 'hidden' }} />
      </div>
    </div>
  );
};


NotificationsTableRow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NotificationsTableRow;
