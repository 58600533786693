import React from 'react';
import { Link as RouterLink } from 'react-router-dom';


const Link = ({ to, children, ...other }) => {
  if (to) {
    return (
      <RouterLink className="link" to={to} {...other}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a className="link" {...other}>
      {children}
    </a>
  );
};


export default Link;
