import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';


const AsideMenuLink = ({ path, active, submenu, enabled, label, icon, iconActive, className, color }) => {
  const style = {
    opacity: enabled ? 1 : 0.4,
  };

  return (
    <li className={classnames(className, { active: active, submenu: submenu })} style={style}>
      {enabled ? (
        <Link
          to={path}
          className={classnames('item', { active: active, submenu: submenu })}
        >
          {className.indexOf('kid-menu') > -1 && active && color && (
            <span className="kid-menu-dot" style={{ backgroundColor: color }} />
          )}
          {icon && (<img src={active ? iconActive : icon} alt={label} title={label} />)}
          <span>{label}</span>
        </Link>
      ) : (
        <a>
          {icon && (<img src={icon} alt={label} title={label} />)}
          <span>{label}</span>
        </a>
      )}
    </li>
  );
};


export default AsideMenuLink;
