import React from 'react';
import PropTypes from 'prop-types';

import { Badge, BadgesList, Button, Form, Modal, Notification } from './../../../../components';
import { setOrderedFamilyMembers, handleFormKeyPress } from './../../../../utils/tools';
import { APP_BASE_URL, BASE_URL } from './../../../../utils/net';


class CalendarPrintModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadLink: '',
      exportLink: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const hasSelectedPrintUser = this.props.printSelectedUser && nextProps.printSelectedUser;
    const hasChangedSelectedPrintUser = this.props.printSelectedUser.id !== nextProps.printSelectedUser.id;

    if (hasSelectedPrintUser && hasChangedSelectedPrintUser) {
      this.setLinks(nextProps.printSelectedUser);
    }
  }

  submitPrinting = e => {
    e.preventDefault();
    const { rangeStartDate, printSelectedUser, printAction } = this.props;

    if(printSelectedUser.id && printAction !== 'export') {
      window.open(`/calendar-print/${rangeStartDate}/${printSelectedUser.id}/${printAction}`);
    } else if(printSelectedUser.id && printAction === 'export') {
      this.setLinks(printSelectedUser);
    } else {
      document.getElementById('print-notification-container')
        .classList.remove('hide');
    }
  }

  hideError = () => {
    document.getElementById('print-notification-container')
      .classList.add('hide');
  };

  copyLink = () => {
    const exportLinkInput = document.getElementById('export-link');
    exportLinkInput.removeAttribute('disabled');
    exportLinkInput.select();

    try {
      document.execCommand('copy');
    } catch(e) {
      console.log('Cannot copy to clipboard');
    }
    exportLinkInput.setAttribute('disabled', true);
  }

  handleKeyPress = e => {
    handleFormKeyPress(e, '#calendar-print-modal-form button[type="submit"]');
  };

  handleCloseModal = () => {
    this.setState({ downloadLink: '', exportLink: '' }, () => {
      this.props.closePrintModal();
    });
  }

  handleActionChange = type => {
    this.props.setPrintAction(type);

    if(type !== 'export' && this.state.downloadLink) {
      this.setState({ downloadLink: '', exportLink: '' });
      document.getElementsByClassName('ReactModal__Content ReactModal__Content--after-open')[0].classList.remove('extended');
    }
  }

  setLinks = selectedPrintUser => {
    const { rangeStartDate } = this.props;

    if (selectedPrintUser && selectedPrintUser.id) {
      this.setState({
        downloadLink: `${APP_BASE_URL}ics/${rangeStartDate}/${selectedPrintUser.id}?token=${selectedPrintUser.calendar_token}`,
        exportLink: `${BASE_URL}calendar_events/export/${selectedPrintUser.calendar_token}`,
      }, () => {
        document.getElementsByClassName('ReactModal__Content ReactModal__Content--after-open')[0].classList.add('extended');
      });
    }
  }

  handleClickUserBadge = (key, list) => {
    this.hideError();
    this.props.handlePrintUserChange(list[0]);
  }

  render() {
    const { downloadLink, exportLink } = this.state;
    const { t: translate } = this.context;
    const { calendarPeople = [], possibleActions, user } = this.props;

    const actionLabels = {
      download: translate('download'),
      export: translate('export to ICS'),
      print: translate('print'),
    };

    const calendarPeopleSorted = setOrderedFamilyMembers(user, calendarPeople);

    return (
      <Modal
        isOpen={this.props.modalOpened}
        label="Calendar Print"
        closeModal={this.handleCloseModal}
      >

        <div
          tabIndex="0"
          onKeyPress={this.handleKeyPress}
          className="calendar-modal"
        >
          <div className="badges-section">
            <div className="title">
              {translate('Selected calendar for')}:
            </div>
            <BadgesList
              forceOne
              inline
              label=""
              list={calendarPeopleSorted && Object.values(calendarPeopleSorted)}
              activeBadges={[this.props.printSelectedUser.id]}
              handleFormChange={this.handleClickUserBadge}
              formKey="printSelectedUser"
            />
          </div>

          <div className="badges-section">
            <div className="title">
              {translate('And')}
            </div>

            {possibleActions.map(action => (
              <Badge
                active={this.props.printAction === action}
                activeColor="bg-darkgrey"
                key={action}
                label={actionLabels[action]}
                onClick={() => this.handleActionChange(action)}
              />
            ))}
          </div>

          <Form onSubmit={this.submitPrinting} loading={false}>
            <Form.Summary>
              <div id="print-notification-container" className="error-container hide">
                <Notification type="error" text="You should select a person above" />
              </div>

              <Button
                type="submit"
                label={actionLabels[this.props.printAction]}
                align="center"
                data-e2e="calendar-event-save-button"
              />

              {downloadLink && (
                <div className="export-link-container">
                  <input disabled id="export-link" type="text" value={exportLink} />
                  <div className="flex flex-row space-around center">
                    <Button
                      align="center"
                      buttonClassName="clean small"
                      label={translate('Copy link')}
                      onClick={this.copyLink}
                    />
                    <Button
                      align="center"
                      buttonClassName="clean small downloadButton"
                      label={translate('Download file')}
                      href={downloadLink}
                      target="_blank"
                    />
                  </div>
                </div>
              )}
            </Form.Summary>
          </Form>
        </div>
      </Modal>
    );
  }
}


CalendarPrintModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarPrintModal;
