import React from 'react';
import { connect } from 'react-redux';

import { Loading } from '../../../components';


class HomeContainer extends React.PureComponent {
  componentDidMount() {
    if(this.props.authenticated) {
      this.props.history.push('/home');
    } else {
      this.props.history.push('/sign-in');
    }
  }

  render() {
    return <Loading show />;
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.authenticated,
  };
};

export default connect(mapStateToProps)(HomeContainer);
