import React from 'react';
import PropTypes from 'prop-types';

import { AuthForm, Button, Form, Inner, LanguageSelect, Link, Notification } from '../../components';

import { isEdge } from './../../utils/browser';

const SignInView = ({
  email,
  password,
  error,
  language,
  loading,
  handleLanguageChange,
  handleFieldChange,
  handleSubmit,
}, { t: translate }) => (
  <Inner>
    <AuthForm
      type="login"
      title={translate('Welcome back!')}
      bottomContent={(
        <div className="center p-l-10 p-r-10">
          <span>{translate("Don't have an account?")} </span>
          <Link to="/sign-up" style={{ textDecoration: 'underline' }}>{translate('Create it here')}</Link>
        </div>
      )}
    >
      <LanguageSelect
        language={language}
        changeLanguage={handleLanguageChange}
      />

      <Form
        onSubmit={handleSubmit}
        loading={loading}
      >
        <Notification type="error" text={error} />

        <Form.Row>
          <input
            required
            type="email"
            value={email || ''}
            onChange={handleFieldChange('email')}
          />
          <label className={isEdge ? 'edge' : ''}>{translate('email')}</label>
        </Form.Row>
        <Form.Row>
          <input
            required
            type="password"
            value={password || ''}
            onChange={handleFieldChange('password')}
          />
          <label className={isEdge ? 'edge' : ''}>{translate('password')}</label>
        </Form.Row>

        <Form.Summary>
          <Button
            type="submit"
            label={translate('Log in')}
            align="center"
            data-e2e="log-in-button"
          />
        </Form.Summary>

        <div className="form__field-info orange">
          <Link to="/password-recover">{translate('Forgot your password?')}</Link>
        </div>
      </Form>
    </AuthForm>
  </Inner>
);


SignInView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SignInView;
