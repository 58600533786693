import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import FamilyMember from './FamilyMember';

const MembersList = ({ deleteInvitation, setUserVisibility, homeUsers, kids, members = [], sectionTitle, visibilityFilter }) => {
  const { push } = useHistory();

  return (
    <section className={classnames('MembersList', kids ? 'KidsList' : 'CaretakersList')}>
      <h2 className="m-b-10">{sectionTitle}</h2>
      <ul className="members_list">
        {members.map(member => (
          <li className="list_item" key={member.id}>
            <FamilyMember
              {...member}
              homeUsers={homeUsers}
              kids={kids}
              deleteInvitation={deleteInvitation}
              onClickEdit={() => push((member || {}).redirectPath)}
              onClickHide={setUserVisibility}
              visibilityFilter={visibilityFilter}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};


MembersList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MembersList;
