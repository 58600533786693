import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Button, Logo } from '../../../components';
import { APPLE_STORE_URL, GOOGLE_STORE_URL, TWO_HOMES_APP_SCHEME } from '../../../utils/net';
import { getOS, isMobile } from '../../../utils/browser';


export const PaymentSuccess = ({ language, userNickname }, { t: translate }) => {
  const { push } = useHistory();
  const os = getOS();
  const appRedirectPath = `${TWO_HOMES_APP_SCHEME}--/app2homes?redirectPath=${'Login'}&language=${language}`;
  const storeRedirectPath = typeof os === 'string' && os === 'Android' ? GOOGLE_STORE_URL : APPLE_STORE_URL;

  useEffect(() => {
    let timeout = null;

    if(!isMobile()) {
      timeout = setTimeout(() => {
        push('/home?walkthrough');
      }, 4000);
    }

    if(timeout) return () => clearTimeout(timeout);
  }, []);

  return (
    <section className="CompleteYourProfile PaymentSection">
      <div className="vertical-center-content flex-column">
        <Logo alt="2homes" type={'hello'} />
        <h1 className="Title">
          {translate('Welcome')} <span className="color-main">{`@${userNickname}`}</span>
        </h1>

        <p className="m-t-30 f14 p-l-20 p-r-20">
          {translate('The registration process is complete!')}
        </p>
        <p className="f14 p-l-20 p-r-20">
          {translate('You can now use the 2homes app to its fullest!')}
        </p>
      </div>

      <div className="horizontal-center-content flex-column m-t-30">
        {isMobile() ? (
          <>
            <Button
              buttonClassName="RedirectButton flex"
              href={appRedirectPath}
              label={translate('Open 2homes app')}
            />
            <Button
              buttonClassName="RedirectButton flex"
              href={storeRedirectPath}
              label={translate('Download 2homes app')}
              type="button"
            />
          </>
        ) : (
          <Button
            buttonClassName="RedirectButton flex"
            label={translate('Explore 2homes app')}
            onClick={() => push('/home?walkthrough')}
          />
        )}
      </div>
    </section>
  );
};

PaymentSuccess.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaymentSuccess;
