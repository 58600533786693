import React from 'react';
import classNames from 'classnames';


const Loading = ({ show = false, children }) => {
  const className = classNames('loading', {
    'loading--visible': show,
  });

  return (
    <div className={className}>
      {children}
      <div className="loading__overlay" data-e2e="loading-animated-indicator">
        <div className="loader">
          <div className="b1"><span className="top left" /><span className="top right" /><span className="bottom left" /><span className="bottom right" /></div>
          <div className="b2"><span className="top left" /><span className="top right" /><span className="bottom left" /><span className="bottom right" /></div>
          {/* <div className="b3"><span className="top left" /><span className="top right" /><span className="bottom left" /><span className="bottom right" /></div>*/}
        </div>
      </div>
    </div>
  );
};


export default Loading;
