import React from 'react';
import PropTypes from 'prop-types';
import useStore from 'src/hooks/useStore';
import { cleanError } from 'src/store/actions/errors';
import { hideModal } from 'src/store/actions/layout';
import { cancelSubscription } from 'src/store/actions/subscription';

import { Button, Modal } from '../..';


export const SubscriptionCancelModal = ({ name }, { t: translate }) => {
  const [state, dispatch] = useStore();

  const handleModalClose = () => {
    dispatch(cleanError('subscription'));
    dispatch(hideModal());
  };

  const handleSubscriptionCancel = () => {
    dispatch(cancelSubscription(state.user?.subscription?.id));
  };

  return (
    <Modal
      small
      isOpen={state.modal?.name === name}
      label="DeleteAccount--small"
      closeModal={handleModalClose}
    >
      <div className="DeleteAccountModal">
        <div className="Wrapper horizontal-center-content">
          <div><img className="Logo home-logo" src="/assets/homes_print2x.png" /></div>
          <h2 className="Title">
            {translate('Cancel subscription')}
          </h2>
          <p className="InfoText">
            {translate('Are you sure you want to cancel subscription?')}
          </p>
        </div>

        <div className="ButtonsContainer">
          <Button
            type="button"
            label={translate('CANCEL SUBSCRIPTION')}
            onClick={handleSubscriptionCancel}
          />

          <div className="error center f13 m-t-10">
            {state.errors.subscription}
          </div>

          <Button
            buttonClassName="link m-t-20"
            type="button"
            label={translate('Close').toUpperCase()}
            onClick={handleModalClose}
          />
        </div>
      </div>
    </Modal>
  );
};


SubscriptionCancelModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SubscriptionCancelModal;
