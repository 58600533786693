import React from 'react';

import Input from '../Input';

class DatePicker extends React.PureComponent {
  render() {
    const { disabled, error, label, onChange, onClick, value } = this.props;
    return (
      <div className="relative">
        <Input
          readOnly
          disabled={disabled}
          autoComplete="off"
          type="text"
          name="birthdate"
          label={value ? '' : label}
          value={value}
          onClick={onClick}
          onChange={onChange}
          error={error}
        />
      </div>
    );
  }
}


export default DatePicker;
