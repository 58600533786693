import React from 'react';
import PropTypes from 'prop-types';

import FinancesHistoryTableRow from './FinancesHistoryTableRow';
import { isEdge } from './../../../utils/browser';

export const FinancesHistoryTable = ({ historyData, ...props }, { t: translate }) => {
  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell medium right">{translate('Date')}</div>
          <div className="tcell right p-r-15">{translate('Amount')}</div>
          <div className="tcell hidden-small-res">{translate('By')}</div>
          <div className="tcell right p-r-15">{translate('Split with')}</div>
          <div className="tcell">{translate('Category')}</div>
          <div className="tcell">{translate('For')}</div>
          <div className="tcell center">{translate('Visibility')}</div>
          <div className="tcell">{translate('File')}</div>
          <div className="tcell">{translate('Notes')}</div>
          <div className="tcell" />
        </div>
      </div>
      <div className="tbody">
        {historyData && historyData.map(row => (
          <FinancesHistoryTableRow key={row.id} showIcon={props.isWalkthroughOpen} row={row} {...props} />
        ))}
      </div>
    </div>
  );
};


FinancesHistoryTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesHistoryTable;
