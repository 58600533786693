import React from 'react';
import classnames from 'classnames';


const Badge = ({ label, active, activeColor, onClick, fontColor, bgColor, labelIcon, disabled, circle, style, className }) => {
  const styles = style ? Object.assign({}, style) : {};

  let rel = '';
  if(fontColor) {
    styles.color = fontColor;
    rel = 'animated-color';
  }
  if(bgColor && active && !disabled) {
    styles.backgroundColor = bgColor;
    styles.border = `0px solid ${bgColor}`;
    rel = 'animated-color';
  }

  styles.opacity = disabled ? 0.3 : 1;

  return (
    <div
      data-color={bgColor}
      className={classnames('badge', className, circle, { [activeColor]: active && !disabled, [labelIcon]: labelIcon && circle })}
      onClick={!disabled && onClick}
      style={styles}
      rel={rel}
    >
      {labelIcon ? (
        <span className={classnames({ white: active && !disabled })}>
          <i className={classnames('zmdi', { [`zmdi zmdi-${labelIcon}`]: labelIcon })} />
        </span>
      ) : (
        <span className={classnames({ white: active && !disabled })}>{label}</span>
      )}
    </div>
  );
};


export default Badge;
