import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DISABLED_DATEPICKER_DATE_FORMAT } from './../../../../utils/date';
import { Badge, DatePicker, Form } from './../../../../components';


export const CalendarRepeatable = ({ ...props }, { t: translate }) => {
  const REPEATABLE_TYPES = [
    { value: 'daily', label: translate('daily') },
    { value: 'weekly', label: translate('weekly') },
    { value: 'biweekly', label: translate('biweekly') },
    { value: 'monthly', label: translate('monthly') },
  ];

  const startDate = (props.eventFormData.repeatable_start && moment(props.eventFormData.repeatable_start).format(DISABLED_DATEPICKER_DATE_FORMAT)) ||
        moment(props.eventFormData.start).format(DISABLED_DATEPICKER_DATE_FORMAT);
  const endDate = (props.eventFormData.repeatable_end && moment(props.eventFormData.repeatable_end).format('YYYY-MM-DD')) || moment(props.eventFormData.start).format('YYYY-MM-DD');

  return (
    <div>
      <Form.Row>
        {REPEATABLE_TYPES.map((type, idx) => {
          return (
            <div key={idx} className="repeatable-type">
              <Badge
                label={type.label}
                active={props.eventFormData.repeatable_type === type.value || (!props.eventFormData.repeatable_type && type.value === 'daily')}
                activeColor="bg-darkgrey"
                onClick={() => !props.eventFormData.editing_locked && props.handleEventFormChange('repeatable_type', type.value)}
              />
            </div>
          );
        })}

        <div className="repeatable-datepickers">
          <div className="pull-left date-time-section">
            <label className="lightgrey">{translate('Starts:')}</label>
            <div className="left">
              <input disabled type="text" value={startDate} title={translate('Start date is the same as date of the event')} />
            </div>
          </div>
          <div className="pull-left divider center">-</div>
          <div className="pull-right date-time-section">
            <label className="lightgrey repeatable-ends">{translate('Ends:')}</label>
            <div className="right">
              <DatePicker
                label={translate('date')}
                locale={props.user.language || 'en-gb'}
                maxDate={moment().add(3, 'year')}
                onChange={date => props.handleEventFormChange('repeatable_end', date.format('YYYY-MM-DD'))}
                popperPlacement="top-end"
                selected={endDate}
              />
            </div>
          </div>
          <div className="clear" />
        </div>
      </Form.Row>
    </div>
  );
};


CalendarRepeatable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarRepeatable;
