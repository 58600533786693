import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal, Notification } from './../../../../components';
import ModalCard from './ModalCard';
import ModalLeaf from './ModalLeaf';
import ModalDocument from './ModalDocument';
import { handleFormKeyPress } from './../../../../utils/tools';


export const KidsProfileModal = (props, { t: translate }) => {
  const handleKeyPress = e => {
    handleFormKeyPress(e, '#kid-profile-modal-form button[type="submit"]');
  };

  return (
    <Modal
      dataChanged
      isOpen={props.modalOpened}
      label={`KidsProfile-${props.editFormData.type}`}
      closeModal={() => props.closeModal(false)}
    >

      <div
        tabIndex="0"
        onKeyPress={handleKeyPress}
        className="kids-profile-modal"
      >
        <Form id="kid-profile-modal-form" onSubmit={props.saveData} loading={false}>
          <Form.Row>
            <div className="f16 m-b-30 black">
              {props.editFormData.type === 'card' && !props.editFormData.id && (translate('Add new section for notes:'))}
              {props.editFormData.type === 'card' && props.editFormData.id && (translate('Change section name:'))}
              {props.editFormData.type === 'leaf' && !props.editFormData.id && (translate('Add new note-card:'))}
              {props.editFormData.type === 'leaf' && props.editFormData.id && (translate('Change note-card:'))}
            </div>
          </Form.Row>

          {props.editFormData.type === 'card' && (
            <ModalCard
              editFormData={props.editFormData}
              handleFormChange={props.handleFormChange}
              error={props.error}
            />
          )}

          {props.editFormData.type === 'leaf' && (
            <ModalLeaf
              editFormData={props.editFormData}
              handleFormChange={props.handleFormChange}
              error={props.error}
            />
          )}

          {props.editFormData.type === 'document' && (
            <ModalDocument
              editFormData={props.editFormData}
              handleFormChange={props.handleFormChange}
              error={props.error}
            />
          )}

          {props.error && (
            <div className="error center f14 p-t-5">
              <Notification type="error" text={props.error} exclude={['key', 'name', 'first name', 'last name', 'nickname']} />
            </div>
          )}

          <Form.Summary>
            <Button
              type="submit"
              label={props.editFormData.id ? translate('Change') : translate('Create')}
              align="center"
              disabled={props.loading}
            />
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


KidsProfileModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KidsProfileModal;
