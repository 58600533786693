import React from 'react';


const ReportChart = ({ chartData, total }) => {
  const totalChartWidth = 324;

  const expensePercentage = Math.round(chartData.amount * 100 / total);
  const expenseWidth = Math.round(expensePercentage / 100 * totalChartWidth);


  const renderMonoValue = number => {
    const splitNumber = number && number.toString().split('');
    return (
      <span className="monos">
        {splitNumber && splitNumber.map((element, idx) => (
          <span key={idx} className={`mono letter${splitNumber.length - idx}`}>{element}</span>
        ))}
      </span>
    );
  };

  return (
    <div className="category">
      <div className="category-name">{chartData.name}</div>
      <div className="chart" style={{ width: totalChartWidth }}>
        <div className="path" />
        <div className="bar" style={{ width: expenseWidth }} />
      </div>
      <div className="expense">
        <div className="amount">{renderMonoValue(Math.round(chartData.amount))}</div>
        <span className="percentage">({expensePercentage}%)</span>
      </div>
    </div>
  );
};


export default ReportChart;
