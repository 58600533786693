import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import SignedInLayout from '../../../layouts/SignedInLayout';

import { setOrderedFamilyMembers } from './../../../utils/tools';
import { Badge, Button, Form, Notification } from './../../../components/';
import ReportsDateSelect from './ReportsDateSelect';
import ReportsSimpleSelect from './ReportsSimpleSelect';
import ReportsMultiSelect from './ReportsMultiSelect';


class Reports extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      privacy: ['private', 'public'],
      types: ['expense', 'income'],
      possibleYears: [],
      possibleDates: {
        start: [],
        end: [],
      },
    };
  }

  componentDidMount() {
    this.setPossibleYears();
  }

  setPossibleYears = () => {
    const yearsAhead = 5;
    const possibleYears = [
      moment().subtract(1, 'y').format('YYYY'),
    ];
    for(let i = 0; i < yearsAhead; i++) {
      possibleYears.push(moment().add(i, 'y').format('YYYY'));
    }
    this.setState({
      possibleYears,
    });
  }

  handleAll = (name, list) => {
    const { filters, handleFormChange } = this.props;
    if(filters[name].length === list.length || filters[name].length + 1 === list.length) {
      handleFormChange(name, [], true);
    } else {
      handleFormChange(name, list, true);
    }
  }

  render() {
    const { user, layout, loading, filters, tagKeys, homeUsers, generateReport, handleFormChange, errors } = this.props;
    const { possibleYears, privacy, types } = this.state;
    const { t: translate } = this.context;

    const homeUsersFiltered = setOrderedFamilyMembers(user, homeUsers);

    return (
      <SignedInLayout
        page="Reports"
        currentPath="/reports"
        user={user}
        layout={layout}
        loading={loading}
      >
        <div className="Reports">
          <Form onSubmit={generateReport}>
            <h2 className="f24 w400 center">{translate('Create report')}</h2>

            <Form.Row>
              <label className="inline-block">{translate('Select source of data')}</label>
              <Badge
                inline
                label={translate('accounting')}
                active={filters.section === 'accounting'}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('section', 'accounting', true)}
              />
              <Badge
                inline
                label={translate('budget')}
                active={filters.section === 'budget'}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('section', 'budget', true)}
              />
            </Form.Row>

            <Form.Row>
              <ReportsSimpleSelect translate={translate} name={translate('of my')} keyParam="type" list={types} filters={filters} colors={['bg-red', 'bg-green']} handleFormChange={handleFormChange} />
              <ReportsSimpleSelect translate={translate} name={translate('that are')} keyParam="privacy" list={privacy} filters={filters} handleFormChange={handleFormChange} />
            </Form.Row>

            {filters.type.indexOf('income') > -1 && (
              <Form.Row>
                <ReportsMultiSelect withAll name={translate('coming from')} keyParam="incomeTags" filters={filters} list={tagKeys.income} handleFormChange={handleFormChange} />
              </Form.Row>
            )}

            {filters.type.indexOf('expense') > -1 && (
              <Form.Row>
                <ReportsMultiSelect withAll name={translate('category')} keyParam="expenseTags" filters={filters} list={tagKeys.expense} handleFormChange={handleFormChange} />
              </Form.Row>
            )}

            <Form.Row>
              <label className="block">{translate('for')}</label>
              <div className="inline-block m-t-5">
                <Badge label={translate('all')}
                  active={(filters.users.length === homeUsersFiltered.length + 1) && homeUsersFiltered.length > 0}
                  activeColor="bg-darkgrey"
                  onClick={() => this.handleAll('users', [...homeUsersFiltered, 'none'])}
                />
                {homeUsersFiltered && homeUsersFiltered.map((homeUser, idx) => {
                  return (
                    <Badge key={idx} label={`@${homeUser.nickname}` || `${homeUser.first_name} ${homeUser.last_name}`}
                      active={filters.users.indexOf(homeUser) > -1}
                      activeColor="bg-darkgrey"
                      onClick={() => handleFormChange('users', homeUser)}
                    />
                  );
                })}
              </div>
              <div>
                <Badge
                  inline
                  label=""
                  style={{ position: 'relative', top: 13 }}
                  className="empty"
                  active={(filters.users.indexOf('none') > -1)}
                  activeColor="bg-darkgrey"
                  onClick={() => handleFormChange('users', 'none')}
                />
                <span className="f15">{translate('include transactions that are not assigned to anybody')}</span>
              </div>
            </Form.Row>

            <Form.Row>
              <span className="block f16">{translate('during the period')}</span>
              <div className="periods">
                <ReportsDateSelect
                  keyParam="start"
                  filters={filters}
                  possibleYears={possibleYears}
                  handleFormChange={handleFormChange}
                  placeholder={translate('month, year')}
                  user={user}
                />
                <span className="separator">-</span>
                <ReportsDateSelect
                  keyParam="end"
                  filters={filters}
                  possibleYears={possibleYears}
                  handleFormChange={handleFormChange}
                  placeholder={translate('month, year')}
                  user={user}
                />
              </div>
            </Form.Row>

            {filters.section === 'accounting' && (<Form.Row>
              <label className="inline-block">{translate('Add summary of split requests')}</label>
              <Badge
                inline
                label={translate('for me')}
                active={filters.split_requests.indexOf('for-me') > -1}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('split_requests', 'for-me')}
              />
              <Badge
                inline
                label={translate('from me')}
                active={filters.split_requests.indexOf('from-me') > -1}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('split_requests', 'from-me')}
              />
            </Form.Row>)}

            <Form.Row>
              {filters.section === 'accounting' && (<label className="inline-block">{translate('Show history of transactions')}</label>)}
              {filters.section === 'budget' && (<label className="inline-block">{translate('Show expected transactions')}</label>)}
              <Badge
                inline
                label={translate('yes')}
                active={filters.history_enabled}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('history_enabled', true, true)}
              />
              <Badge
                inline
                label={translate('no')}
                active={!filters.history_enabled}
                activeColor="bg-darkgrey"
                onClick={() => handleFormChange('history_enabled', false, true)}
              />
            </Form.Row>

            <Form.Summary>
              {errors && (
                <Notification type="error" text={errors} />
              )}

              <Button
                type="submit"
                label={translate('Download PDF report')}
                align="center"
              />
            </Form.Summary>
          </Form>
        </div>
      </SignedInLayout>
    );
  }
}


Reports.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Reports;
