import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/nn';
import 'moment/locale/en-gb';
import { setLanguage } from 'redux-i18n';
import ipApi from 'ipapi.co';
import PropTypes from 'prop-types';

import { signupUser } from '../../../store/actions/auth';
import { cleanError } from '../../../store/actions/errors';
import { cleanOperation } from '../../../store/actions/operations';
import { removeWhitespace } from '../../../utils/string';
import SignUpView from '../SignUpView';

class SignUpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      email: '',
      password: '',
      passwordDefaultLength: 8,
      language: props.language,
      country: '',
    };
  }

  componentDidMount() {
    this.getUserLocationData();
  }

  componentWillUnmount() {
    this.props.cleanError('signup');
    this.props.cleanOperation('signup');
  }

  getUserLang = country => {
    const availableLanguagesMap = {
      GB: 'en',
      NO: 'nn',
      DK: 'da',
      SE: 'sv',
    };

    return availableLanguagesMap[country] || availableLanguagesMap.GB;
  }

  getUserLocationData = () => {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const paramsLang = urlParams.get('lang');

    // This package uses ipapi.co service with client api key (60 000 requests / month)
    ipApi.location(location => {
      const detectedLanguage = paramsLang || this.getUserLang(location.country);

      this.setState({ country: location.country });
      this.handleLanguageChange(detectedLanguage);
    }, '', process.env.REACT_APP_IPAPI_SECRET_KEY);
  }

  handleLanguageChange = language => {
    this.props.setLanguage(language);
    moment.locale(language);
    if(language === 'nn') {
      moment.updateLocale('nn', {
        weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
      });
    }
    this.setState({ language });
  }

  handleFieldChange = (name, forcedValue) => {
    if(forcedValue) {
      this.setState({ [name]: forcedValue });
      if(name === 'country') {
        const userLang = this.getUserLang(forcedValue);
        this.handleLanguageChange(userLang);
      }
    } else {
      return event => {
        let value = event.target.value;
        if(name === 'email') value = removeWhitespace(value);

        this.setState({ [name]: value });
      };
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const { country, firstName, email, password, language } = this.state;
    this.props.signupUser({ firstName, email, password, language, country });
    this.setState({
      password: '',
    });
  }

  render() {
    const { error, loading, isFormSubmited } = this.props;
    const { t: translate } = this.context;

    return (
      <SignUpView
        isFormSubmited={isFormSubmited}
        {...this.state}
        error={error}
        loading={loading}
        handleFieldChange={this.handleFieldChange}
        handleSubmit={this.handleSubmit}
        trialLength={translate('30 days')}
      />
    );
  }
}

SignUpContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ operations, errors, loading, i18nState }) => ({
  error: errors.signup,
  loading,
  isFormSubmited: !!operations.signup,
  language: i18nState.lang,
});

const mapDispatchToProps = {
  cleanError,
  cleanOperation,
  signupUser,
  setLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpContainer);
