import React from 'react';
import classnames from 'classnames';

const Button = ({ align, children, label, href, target, className, buttonClassName, ...other }) => {
  return (
    <div className={classnames('button-container', className, { [`button-container--align-${align}`]: align })}>
      {href ? (
        <a
          className={classnames('button', buttonClassName)}
          href={href}
          target={target}
          rel={target === '_blank' ? 'noopener' : undefined}
          {...other}
        >
          {label}
          {children}
        </a>
      ) : (
        <button
          className={classnames('button', buttonClassName)}
          {...other}
        >
          {label}
          {children}
        </button>
      )}
    </div>
  );
};


export default Button;
