export const translations = {
  'da': {
    'Access denied': 'Adgang nægtet',
    'You don\'t have permissions to view this page': 'Du har ikke tilladelse til at få adgang til denne side',
    'Return back home': 'Gå tilbage til startside',
    'Calendar': 'Kalender',
    'Budget': 'Budget',
    'Accounting': 'Regnskab',
    'Split Requests': 'Anmodninger om deling',
    'Reports': 'Rapporter',
    'Documents': 'Dokumenter',
    'My Home': 'Mit hjem',
    'Bonus code is ok! You will get {bonusTrialDaysAmount} days for free.': 'Bonuskoden er gyldig! Du får et {bonusTrialDaysAmount} dages gratis abonnement.',
    'Bonus code is ok! Your trial is extended to {bonusTrialEndDate}': 'Bonuskoden er gyldig! Den gratis periode er blevet forlænget til {bonusTrialEndDate}',
    'Do you have Bonus Code?': 'Har du en bonuskode?',
    'week': 'uge',
    'January': 'Januar',
    'February': 'Februar',
    'March': 'Marts',
    'April': 'April',
    'May': 'Maj',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'August',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'December',
    'Upload': 'Upload',
    'edit avatar': 'rediger avatar',
    'Click to upload files': 'Klik for at uploade filer',
    'all transactions': 'alle transaktioner',
    'income only': 'kun indtægter',
    'expenses only': 'kun udgifter',
    'Create subscription': 'Opret dit eget abonnement',
    'Your account has been blocked': 'Din konto er ikke længere aktiv',
    'You don\'t have access to all functionalities': 'Du har ikke adgang til alle funktionialiteter',
    'Get a subscription to use 2homes to its fullest!': 'Opret et abonnement for at bruge 2homes til fulde!',
    'Close': 'Luk',
    'Invitation has been sent!': 'Invitationen er sendt!',
    'Please ask the invitee to check for the invitation in their inbox.': 'Det kan være en god ide at fortælle din ekspartner at du har sendt dem en invitation til 2homes :)',
    'Update card details': 'Prøv igen',
    'There is a problem with payment for your subscription': 'Der var et problem med betalingen',
    'Update credit card details to solve the problem': 'Har du indtastet de korrekte kreditkortoplysninger?',
    'Subscribe': 'Abonner her',
    'You don\'t have permissions to invite {invitee}': 'Du har ikke adgang til at invitere folk til 2homes.',
    'To access all the features of 2homes you have need a subscription. It costs only {amount},- per month.': 'For at få adgang til alle funktioner i 2homes har du brug for et abonnement. Det koster kun {amount},- pr måned.',
    'Note: If you created your subscription via AppStore it won’t be cancelled. You can cancel it in AppStore.': 'Bemærk: Hvis du oprettede abonnementet via App Store, stoppes det ikke automatisk. Du skal også afslutte det i App Store.',
    'Cancel subscription': 'Annuller abonnementet',
    'Are you sure you want to cancel subscription?': 'Er du sikker på, at du vil afmelde dig?',
    'CANCEL SUBSCRIPTION': 'ANNULLER ABONNEMENTET',
    'Subscription created!': 'Betalingen var vellykket!',
    'You can use 2homes to its fullest now!': 'Du har nu fuld adgang til 2homes!',
    'Show walkthrough': 'Vis hjælpetekst',
    'Show notifications': 'Se beskeder',
    'Logout': 'Log ud',
    'Profile': 'Profil',
    'Error occured': 'Der opstod en fejl',
    'You have unsaved data in event modal window. Are you sure you want to close it?': 'Du har data, som ikke er gemt, i vinduet hændelsesmodal. Er du sikker på, at du vil lukke det?',
    'Yes, delete': 'Ja, slet',
    'Select date': 'Vælg dato',
    'Username is used for notifications and mentions. You can change it to how members of your family call you at home. Nickname should have between 2 and 10 characters.': 'Brugernavn bruges til meddelelser og omtaler. Du kan ændre det til, hvordan familiemedlemmer ringer til dig derhjemme. Kaldenavnet skal have mellem 2 og 10 tegn.',
    'Something went wrong. Check the card details and try again.': 'Noget gik galt. Kontroller kortoplysningerne, og prøv igjen.',
    'Please enter card details': 'Angiv kortoplysningerne',
    'Update credit card': 'Opdater dit kreditkort',
    'Register credit card': 'Indtast kreditkortoplysningerne',
    'Your card will be charged': 'Du vil blive opkrævet',
    'per month after the trial period.': 'per måned efter prøveperioden.',
    'every month': 'måned',
    'Submit': 'Indsend',
    'Cancel': 'Afbestille',
    'only current': 'kun aktuelle',
    'only upcoming': 'kun kommende',
    'all': 'alle',
    'Edit recurring event': 'Rediger tilbagevendende begivenhed',
    'for me': 'til mig',
    'from me': 'fra mig',
    '+ add new': '+ tilføj ny',
    'new category': 'ny kategori',
    'Hours': 'Timer',
    'Minutes': 'Minutter',
    'If you wish to use 2homes on your mobile, please download our app': 'For at bruge 2homes på din mobil, download appen her',
    'Download 2homes app': 'Download 2homes app',
    'If you have already installed the app, please click here to open': 'Hvis du allerede har installeret 2homes på din mobil, skal du klikke her for at åbne den',
    'Open 2homes app': 'Åpne 2homes app',
    'Back': 'Tilbage',
    'Next': 'Næste',
    'Skip': 'Spring over',
    'Finish': 'Afslut',
    'Could not activate your account.': 'Kunne ikke aktivere din konto.',
    'You accepted an invitation.': 'Du accepterede en invitation.',
    'We have just activated your account.': 'Vi har lige aktiveret din konto.',
    'Please': 'Venligst',
    'log in': 'log ind',
    'to enjoy using 2homes': 'for at bruge 2homes',
    'Please wait for account activation.': 'Vent på aktivering af kontoen.',
    'Activating account': 'Aktiverer konto',
    'Ready!': 'Klar!',
    'Already have an account?': 'Har du allerede en konto?',
    'Log in': 'Log ind',
    'Expenses and income': 'Udgifter og indtægter',
    '+ New scheduled transaction': '+ NY PLANLAGT TRANSAKTION',
    'Are you sure you want to delete data?': 'Er du sikker på, at du vil slette data?',
    'Choose transaction to delete': 'Vælg transaktion, der skal slettes',
    'Expenses': 'Udgifter',
    'Income': 'Indtægter',
    'Balance': 'Saldo',
    'Date': 'Dato',
    'Amount': 'Beløb',
    'By': 'Af',
    'Category': 'Kategori',
    'For': 'Til',
    'Visibility': 'Synlighed',
    'Notes': 'Noter',
    'Edit': 'Rediger',
    'Delete': 'Slet',
    'weekly': 'ugentlig',
    'biweekly': 'hver anden uge',
    'monthly': 'månedlig',
    'private': 'privat',
    'public': 'offentlig',
    'Add': 'Tilføj',
    'expense': 'udgift',
    'income': 'indtægt',
    'on': 'på',
    'from': 'fra',
    'Date of transaction': 'Transaktionsdato',
    'for': 'til',
    'total amount': 'beløb i alt',
    'Repeat': 'Gentag',
    'Starts:': 'Starter:',
    'Ends:': 'Slutter:',
    'Edit recurring transaction': 'Rediger tilbagevendende transaktion',
    '+ add a note': '+ tilføj en note',
    'Save': 'Gem',
    'Select transaction reason and enter amount to save it.': 'Vælg transaktionsårsag, og indtast beløb for at gemme den.',
    'Please invite people to your family to see the calendar': 'Inviter personer til din familie for at se kalenderen',
    'Are you sure you want to delete an event?': 'Er du sikker på, at du vil slette en begivenhed?',
    'Choose event to delete': 'Vælg begivenhed, du vil slette',
    'add event': 'tilføj begivenhed',
    'minutes': 'minutter',
    'hours': 'timer',
    'days': 'dage',
    'Reminder': 'Påmindelse',
    'before the event': 'inden begivenheden starter',
    '+ add reminder': '+ tilføj påmindelse',
    'Created by': 'Oprettet af',
    'Only creators can edit events': 'Kun skabere kan redigere begivenheder',
    'event': 'begivenhed',
    'childcare': 'børnepasning',
    'event name': 'navn på begivenhed',
    'date': 'dato',
    'Who also participates in the event?': 'Hvem deltager også i begivenheden?',
    'Caretaker': 'Opsynsperson',
    'download': 'download',
    'export to ICS': 'eksporter til ICS',
    'print': 'udskriv',
    'Selected calendar for': 'Valgt kalender for',
    'And': 'Og',
    'Copy link': 'Kopier link',
    'Download file': 'Download fil',
    'daily': 'dagligt',
    'Start date is the same as date of the event': 'Startdato er den samme som dato for begivenhed',
    'Week': 'Uge',
    'day': 'dag',
    'today': 'i dag',
    'all day': 'hele dagen',
    'Day': 'Dag',
    'Caring': 'Pleje',
    'Event': 'Begivenhed',
    '\'s calendar': '\'s kalender',
    '\'s calendar of ': '\'s kalender over ',
    'Download': 'Download',
    'Editing not allowed': 'Redigering er ikke tilladt',
    'Edit event': 'Redigér begivenhed',
    'Event contains notes': 'Begivenhed indeholder noter',
    'add childcare': 'tilføj børnepasning',
    'Childcare': 'Børnepasning',
    'Starts': 'Starter',
    'All day': 'Hele dagen',
    'Ends': 'Slutter',
    'Event end date has to be later than start date': 'Slutdato for begivenhed skal være senere end startdato',
    'Repeatable end date has to be later than start date': 'Gentagelig slutdato skal være senere end startdato',
    'Please select at least one child for childcare event.': 'Vælg mindst ét barn til børnepasningsbegivenhed.',
    'Profile information': 'Profiloplysninger',
    'first name': 'fornavn',
    'last name': 'efternavn',
    'nickname': 'kaldenavn',
    'color': 'farve',
    'gender': 'køn',
    'male': 'han',
    'female': 'hun',
    'date of birth': 'fødselsdag',
    'email': 'e-mail',
    'save changes': 'gem ændringer',
    'Your account has been deleted.': 'Din konto er blevet slettet.',
    'Confirmation email has been sent to your email account.': 'Bekræftelses-e-mail er sendt til din e-mailkonto.',
    'HOMEPAGE': 'HJEMMESIDE',
    'file': 'fil',
    'folder': 'mappe',
    'Are you sure you want to delete {confirmModalType}?': 'Er du sikker på, at du vil slette {confirmModalType}?',
    'Please create at least one folder to upload files.': 'Opret mindst én mappe for at uploade filer.',
    'File': 'Fil',
    'Name': 'Navn',
    'Author': 'Forfatter',
    '+ New folder': '+ Ny mappe',
    'My folders': 'Mine mapper',
    'Shared folders': 'Delte mapper',
    'Visible only for you': 'Kun synlig for dig',
    'Visible for everyone': 'Synlig for alle',
    'You don\'t have permissions to edit folder': 'Du har ikke tilladelse til at redigere mappen',
    'Folder is not empty or you don\'t have permissions to delete it': 'Mappen indeholder data, ellers har du ikke tilladelse til at slette dem',
    'Visible': 'Synlig',
    'Not visible': 'Ikke synlig',
    'folder name': 'mappenavn',
    'Folder': 'Mappe',
    'filename': 'filnavn',
    'Copied': 'Kopieret',
    'Copy the link to share the file': 'Kopiér linket for at dele filen',
    'You can send the copied link to anyone.': 'Du kan sende det kopierede link til alle.',
    'Every person with the link can download the file.': 'Enhver med linket kan downloade filen.',
    'File is too large. Max file size is': 'Filen er for stor. Max filstørrelse er',
    'Current relationship': 'Aktuelt forhold',
    'Parents': 'Forældre',
    'Kids': 'Børn',
    'Previous relationships': 'Tidligere forhold',
    'Are you sure you want to delete the invitation?': 'Er du sikker på, at du vil slette invitationen?',
    'Are you sure you want to change user visibility?': 'Er du sikker på, at du vil ændre brugersynlighed?',
    'Yes, change': 'Ja, skift',
    'Blocked user': 'Blokeret bruger',
    'me': 'mig',
    'Pending': 'Ubesvaret',
    'Hide': 'Skjul',
    'Show': 'Vis',
    'visible': 'synlig',
    'hidden': 'skjult',
    '+ Add family member': '+ Tilføj familiemedlem',
    'Caretakers': 'Opsynspersoner',
    'Create kid\'s profile': 'Opret børneprofil',
    'boy': 'dreng',
    'girl': 'pige',
    'Continue': 'Fortsæt',
    'current partner': 'nuværende partner',
    'previous partner': 'tidligere partner',
    'a caretaker': 'en opsynsperson',
    'Invite': 'Invitér',
    '+ add an invitation note': '+ tilføj en note til invitation',
    'Send invitation': 'Send invitation',
    'Add new kid': 'Tilføj nyt barn',
    'I´d like to invite you to join 2homes, an app that aims to simplify the lives of part-time parents.': 'Jeg vil gerne invitere dig til 2homes, en app\nder sigter mod at forenkle livet for os, der er\ndeltidsforældre.',
    'Are you sure you want to delete the file?': 'Er du sikker på, at du vil slette denne fil?',
    '+ Add new transaction': '+ Tilføj ny transaktion',
    'History of': 'Historie af',
    'Split with': 'Delt med',
    'Status {status}': 'Status {status}',
    'Split costs': 'Delte udgifter',
    'pending': 'ubehandlet',
    'Uploaded': 'Uploadet',
    'attachments': 'vedhæftede filer',
    '+ add new file': '+ tilføj ny fil',
    'Request': 'Anmodning',
    'to split 50/50': 'om at dele 50/50',
    'to pay the amount': 'om at betale beløbet',
    'date of transaction': 'transaktionsdato',
    'Select transaction reason, receiver and enter amount to save it.': 'Vælg transaktionsårsag, modtager, og indtast beløb for at gemme det.',
    'accepted / waiting for payment': 'accepteret/venter på betaling',
    'Split request': 'Anmodning om deling',
    'requested split costs with you': 'anmodede om at dele udgifter med dig',
    'On': 'Den',
    'Total amount': 'Beløb i alt',
    'Request for': 'Anmodning om',
    'Attachment': 'Vedhæftet fil',
    'Note': 'Note',
    'Accept': 'Accepter',
    'Reject': 'Afvis',
    'Status: ': 'Status: ',
    'Your response has been sent to requester.': 'Dit svar er sendt til afsenderen af anmodningen.',
    'Please complete your profile': 'Udfyld din profil',
    'name': 'navn',
    'surname': 'efternavn',
    'Continue >': 'Fortsæt >',
    'Hi': 'Hej',
    'Welcome to 2homes!': 'Velkommen til 2homes!',
    'The app that simplifies life for part-time parents.': 'Den app, der forenkler livet for deltidsforældre.',
    'Let us help you take the first steps.': 'Lad os hjælpe dig med at tage de første skridt.',
    'Let\'s start!': 'Lad os starte!',
    'Welcome': 'Velkommen tilbage',
    'The registration process is complete!': 'Registreringsprocessen er afsluttet!',
    'You can now use the 2homes app to its fullest!': 'Du kan nu bruge 2homes-appen til fulde!',
    'Explore 2homes app': 'Åpne 2homes app',
    'Files': 'Filer',
    'invite to 2homes kids': 'inviter til 2homes Kids',
    'Add first note-card': 'Tilføj første notekort',
    'Add note-card': 'Tilføj notekort',
    'Add new section': 'Tilføj nyt afsnit',
    'Are you sure you want to delete this information?': 'Er du sikker på, at du vil slette disse oplysninger?',
    '2homes kids invitation': 'Invitation til 2homes Kids',
    'Hi, You\'ve been invited to 2homes kids! Click on one of those links to download the app': 'Hej, du er inviteret til 2homes Kids! Klik på et af disse links for at downloade appen',
    'If you are using iOS (Apple)': 'Hvis du har iOS (Apple) telefon',
    'If you are using Android': 'Hvis du har Android telefon',
    'AFTER or if you ALREADY have 2homes Kids app installed on your phone, click on this link to login to your account': 'Når du har hentet, eller hvis du ALLEREDE har 2homes Kids appen installeret på din telefon, skal du klikke på dette link for at logge ind',
    'Invitation link copied to clipboard': 'Invitationslink kopieret til Udklipsholder',
    'Make sure that your kid has 2homes Kids app installed. You can find them in App Store and Google Play.': 'Sørg for, at dit barn har downloadet 2homes Kids, før du sender invitationen. Du kan finde appen i App Store og Google Play.',
    'You can now share your link in the app of your choice or use the shortcuts below.': 'Du kan nu dele dit link i din foretrukne app eller bruge genvejen nedenfor.',
    'Send via email': 'Send via e-mail',
    'Add new section for notes:': 'Tilfxxxxxxxøj nyt afsnit til noter:',
    'Change section name:': 'Skift afsnitsnavn:',
    'Add new note-card:': 'Tilføj nyt notekort:',
    'Change note-card:': 'Skift notekort:',
    'Change': 'Skift',
    'Create': 'Opret',
    'title': 'titel',
    'description': 'beskrivelse',
    'Ready': 'Klar',
    'Create your password': 'Lav et kodeord',
    'Set new password': 'Indtast den nye adgangskode',
    'Log in here': 'Log ind her',
    'with your new password': 'med din nye adgangskode',
    'Make sure that it contains at least 8 characters, including one capital letter and one number.': 'Sørg for, at den indeholder mindst 8 tegn, herunder et stort bogstav og et tal.',
    'Passwords must be the same and the password has to have at least 8 characters': 'Adgangskoderne skal være de samme og skal indeholde mindst 8 tegn',
    'Select country': 'Vælg land',
    'new password': 'ny adgangskode',
    'repeat password': 'gentag adgangskode',
    'I accept {Privacy Policy} and {Terms of Service}': 'Jeg accepterer {Privacy Policy} og {Terms of Service}',
    'Privacy Policy': 'Fortrolighedspolitik',
    'Terms of Service': 'Servicevilkår',
    'I agree that you may use the information I have provided for the purposes necessary for 2homes services.': 'Jeg accepterer, at du kan bruge de oplysninger, jeg har givet til det formål, der er nødvendigt til 2homes’ tjenester.',
    'We could not find a page you were looking for': 'Vi kunne ikke finde en side, du ledte efter',
    'Notifications': 'Notifikationer',
    'mark selected as read': 'marker valgt som læst',
    'mark all as read': 'marker alle som læst',
    'load more': 'indlæs flere',
    'deleted the account': 'slettede kontoen',
    'This action will affect your app in the following ways:': 'Denne handling vil påvirke din app på følgende måder:',
    'His/her events were deleted': 'Hans/hendes begivenheder blev slettet',
    'All of his/her sent invitations were cancelled': 'Alle hans/hendes sendte invitationer blev annulleret',
    'His/her documents shared with you were deleted': 'Hans/hendes dokumenter, der var delt med dig, blev slettet',
    'I UNDERSTAND': 'JEG FORSTÅR',
    'Type': 'Type',
    'From': 'Fra',
    'Message': 'Besked',
    'User': 'Bruger',
    'Password recovery': 'Nulstilling af adgangskode',
    'Don\'t have an account?': 'Har du ikke en konto?',
    'Create it here': 'Opret den her',
    'Please check your email for further instructions.': 'Tjek din e-mail for yderligere instruktioner.',
    'Let\’s make your new password more memorable this time:': 'Lad os gøre din nye adgangskode mere mindeværdig denne gang:',
    'Total expenses by category': 'Udgifter i alt efter kategori',
    'Total income by category': 'Indtægter i alt efter kategori',
    'Split requests': 'Anmodninger om deling',
    '(for me / from me)': '(til mig/fra mig)',
    'Split req. from me': 'Anm. om deling fra mig',
    'Split req. for me': 'Anm. om deling til mig',
    'Summaries': 'Resuméer',
    'History of transactions': 'Transaktionshistorik',
    'Expected transactions': 'Forventede transaktioner',
    'back to reports': 'tilbage til rapporter',
    'back to budget': 'tilbage til budget',
    'Accounting report': 'Regnskabsrapport',
    'Budget report': 'Budgetrapport',
    'General summaries': 'Generelle resuméer',
    'Expense': 'Udgift',
    'Details': 'Detaljer',
    'Split': 'Deling',
    'Total expenses by category:': 'Udgifter i alt efter kategori:',
    'Total income by category:': 'Indtægter i alt efter kategori:',
    'Create report': 'Opret rapport',
    'Select source of data': 'Vælg datakilde',
    'accounting': 'regnskab',
    'budget': 'budget',
    'of my': 'af mine',
    'that are': 'der er',
    'coming from': 'kommer fra',
    'category': 'kategori',
    'include transactions that are not assigned to anybody': 'medtag transaktioner, der ikke er tildelt til nogen',
    'during the period': 'i perioden',
    'month, year': 'måned, år',
    'Add summary of split requests': 'Tilføj oversigt over anmodninger om deling',
    'Show history of transactions': 'Vis transaktionshistorik',
    'Show expected transactions': 'Vis forventede transaktioner',
    'yes': 'ja',
    'no': 'nej',
    'Download PDF report': 'Download PDF-rapport',
    'Start date has to be earlier than end date': 'Startdatoen skal være tidligere end slutdatoen',
    'Please select at least one person': 'Vælg mindst én person',
    'Please select at least one income or expense category': 'Vælg mindst én indtægts- eller udgiftskategori',
    'Hi, ': 'Hej, ',
    ' has shared the following file:': ' har delt følgende fil:',
    'Click here to download': 'Klik her for at downloade',
    'If you still don\'t have account on 2homes, just go to': 'Hvis du stadig ikke har en 2homes-konto, skal du bare gå til',
    'and create a new one!': 'og oprette en ny!',
    'Someone': 'Nogen',
    'Welcome back!': 'Velkommen tilbage!',
    'password': 'adgangskode',
    'Forgot your password?': 'Glemt din adgangskode?',
    '3 months': '3 måneder',
    '30 days': '30 dages',
    'Create new account': 'Opret en ny konto',
    'On it\'s way...': 'På vej...',
    'Please check your inbox': 'Kontroller din indbakke',
    'to confirm your new account.': 'for at bekræfte din nye konto.',
    'A message should arrive within a couple of minutes.': 'En besked skal ankomme inden for et par minutter.',
    'Start your {trialLength} free trial period today': 'Start din {trialLength} gratis prøveperiode i dag',
    'First Name': 'Fornavn',
    'Email': 'E-mail',
    'Password': 'Adgangskode',
    'Already strong!': 'Allerede stærk!',
    'country': 'land',
    'Start my free trial': 'START GRATIS PRØVEPERIODE',
    'Change status to': 'Skift status til',
    'paid': 'betalt',
    'rejected': 'afvist',
    'For me': 'Til mig',
    'From me': 'Fra mig',
    'Paid': 'Betalt',
    'Rejected': 'Afvist',
    'Total': 'I alt',
    'Requested by': 'Anmodet af',
    'To': 'Til',
    'Status': 'Status',
    'Change password': 'Skift adgangskode',
    'Fill the form to change your password.': 'Udfyld formularen for at ændre din adgangskode.',
    'Enter old password': 'Indtast gammel adgangskode',
    'Enter new password': 'Indtast ny adgangskode',
    'Repeat new password': 'Gentag ny adgangskode',
    'New passwords must be the same': 'Nye adgangskoder skal være de samme',
    'Your password has been successfully changed': 'Din adgangskode er blevet ændret',
    'Please fill the form': 'Udfyld formularen',
    'Sorry to see you go…': 'Vi er kede af, at du forlader os…',
    'Deleting 2homes account': 'Sletter 2homes-konto',
    'Before you make a final decision, make sure you understand how account removal will result in the following actions:': 'Før du beslutter dig, skal du være klar over, at fjernelse af din konto medfører følgende:',
    'If you are sure, confirm by clicking the button below.': 'Hvis du er sikker, skal du bekræfte ved at klikke på knappen nedenfor.',
    'Your events will be deleted': 'Dine begivenheder vil blive slettet',
    'Your documents will be deleted': 'Dine dokumenter vil blive slettet',
    'Your private folders and their content will be deleted': 'Dine private mapper og deres indhold vil blive slettet',
    'All your transactions will be deleted': 'Alle dine transaktioner vil blive slettet',
    'All your sent invitations will be cancelled': 'Alle dine sendte invitationer vil blive annulleret',
    'Home without any active family members will be deleted': 'Startsiden uden aktive familiemedlemmer vil blive slettet',
    'Children account with no caretaker assigned will be deleted': 'Børnekonto uden tildelt opsynsperson vil blive slettet',
    'Your subscription will be canceled': 'Dit abonnement annulleres',
    'You will be logged out immediately.': 'Du vil blive logget ud med det samme.',
    'Remember that this action cannot be reversed!': 'Husk, at denne handling ikke kan tilbageføres!',
    'Note: If you created your subscription via AppStore it won’t be cancelled. Remember to also cancel it in AppStore after deleting your 2homes account': 'Bemærk: Hvis du oprettede abonnementet via App Store, stoppes det ikke automatisk. Husk også at afslutte det i App Store efter at have slettet din 2homes-konto.',
    'DELETE MY ACCOUNT': 'SLET MIN KONTO',
    'CANCEL': 'ANNULLER',
    'Subscription plan': 'Abonnementsplan',
    'You are currently free trial user': 'Du er i øjeblikket gratis prøvebruger',
    'You have cancelled subscription': 'Du har afmeldt',
    'You have access to all features and functionalities. Your trial period ends:': 'Du har adgang til alle menuer og funktionaliteter. Din prøveperiode slutter:',
    'You will lose access to all features and functionalities after payment period. Your payment period ends:': 'Du mister adgang til alle menuer og funktionaliteter, når abonnementet er udløbet. Perioden slutter:',
    'renew subscription': 'forny abonnement',
    'Subscription costs after trial:': 'Abonnementsafgift efter prøve:',
    'Subscription costs:': 'Abonnementspris:',
    'month': 'måned',
    'Payment method:': 'Betalingsmetode:',
    'edit': 'redigere',
    'Have a question? Contact as at': 'Har du spørgsmål? Kontakt os på',
    'My profile': 'Min profil',
    'language': 'sprog',
    'delete account': 'slet konto',
    'Something went wrong. Try again later.': 'Noget gik galt. Kontroller kortoplysningerne, og prøv igjen.',
    'The sections below show the members of your home. The first section shows your current and ex-partners. The second shows your children from current and previous relationships.': 'Disse lister viser medlemmer af dit hjem. De er inddelt i to afsnit. Den første viser nuværende og tidligere partnere, og det andet omfatter børn fra nuværende og tidligere forhold.',
    'Click here to add family members, including ex-partners, your current partner and kids.': 'Klik her for at tilføje familiemedlemmer, inklusive ekspartnere, din nuværende partner og børn.',
    'You can switch between the views by using these filters. You can show all home members, only visible or only hidden.': 'Du kan skifte mellem visningerne ved hjælp af disse filtre. Du kan vise alle hjemmets medlemmer, kun de synlige eller kun de skjulte.',
    'This shows your current relationship. Click the \'+\' button to add members to your home.': 'Dette er dit nuværende forhold. Klik knappen „+” til at tilføje et medlem til forholdet.',
    'This shows your previous relationships. Click the \'+\' button to add members to your home.': 'Dette viser dine tidligere forhold. Brug knappen „+” til at tilføje et medlem til forholdet.',
    'Click here to switch between day and week views. You can also quickly go to the current date by clicking the \'today\' button.': 'Klik her for at skifte mellem dag- og ugevisninger. Du kan også hurtigt gå til den aktuelle dato ved at klikke på knappen "i dag".',
    'Click here to change dates.': 'Klik her for at ændre dato.',
    'You can filter events by selecting and deselecting family members.': 'Du kan filtrere begivenheder ved at markere og fjerne markeringen af familiemedlemmer.',
    'You can create events by clicking on a calendar cell. In DAY view, you can Select the time range in a column to create the event. In WEEK view, you can create childcare or normal events by clicking the appropriate row in the day. Scroll down the calendar by first clicking in the column, between the navigation menu and the calendar.': 'Du kan oprette begivenheder ved at klikke på en kalendercelle. I DAGVISNING kan du vælge tidsintervallet i en kolonne for at oprette begivenheden. I UGEVISNING kan du oprette børnepasning eller normale begivenheder ved at klikke på den relevante række på dagen. Rul ned i kalenderen ved først at klikke i kolonnen mellem navigationsmenuen og kalenderen.',
    'We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Vi anmoder om, at oplysninger såsom medicinsk information, politisk opfattelse, race, seksuelle forhold og kriminelle handlinger / historie ikke deles.',
    'Click arrow buttons to switch the year. Click on month name to show its transactions.': 'Klik på piletasterne for at skifte år. Klik på månedens navn for at vise dens transaktioner.',
    'In this section you can see the summary of your income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.': 'I dette afsnit kan du se en oversigt over dine indtægter og udgifter. Venstre kolonne viser samlede data for hele året og højre kolonne kun data fra den valgte måned.',
    'Click here to add new file to pending transactions.': 'Klik her for at føje en ny fil til afventende transaktioner.',
    'Here you can see list of pending transactions. This is the list of files you uploaded for the selected month. You can edit each of them and add all required information, such as amount of money spent or the receiver of this cost/income. After editing, the record will be visible in a table with transactions, below pending files.': 'Her kan du se en liste over afventende transaktioner. Dette er listen over filer, du har uploadet for den valgte måned. Du kan redigere hver af dem og tilføje alle nødvendige oplysninger, såsom hvor mange penge der er brugt eller modtageren af denne udgift/indtægt. Efter redigering vil posten være synlig i en tabel med transaktioner, under afventende filer.',
    'You can add a new transaction by clicking this button. You will be able to attach a file to the new transaction after creating it.': 'Du kan tilføje en ny transaktion ved at klikke på denne knap. Du vil kunne vedhæfte en fil til den nye transaktion, når du har oprettet den.',
    'You can show only expenses or only income transactions by using these filters.': 'Du kan bruge disse filtre til kun at vise udgifter eller kun indtægtstransaktioner.',
    'Here you can see the history of your transactions. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor. You can attach a file to every existing transaction.': 'Her kan du se historikken for dine transaktioner. Denne liste indeholder poster med alle de oplysninger, der er givet (transaktionstype, beløb, modtager osv.). Du kan se indstillinger for redigering og sletning af posten, når du placerer markøren over den. Du kan vedhæfte en fil til alle eksisterende transaktioner.',
    'Click arrow buttons to switch the year. Click on month name to show expected costs or income.': 'Klik på piletasterne for at skifte år. Klik på månedens navn for at vise forventede omkostninger eller indtægter.',
    'In this section you can see the summary of your expected income and expenses. The left column shows aggregated data for the whole year and right column - only data from selected month.': 'I dette afsnit kan du se en oversigt over dine forventede indtægter og udgifter. Venstre kolonne viser samlede data for hele året og højre kolonne kun data fra den valgte måned.',
    'You can add new expected income and expenses by clicking this button.': 'Du kan tilføje nye forventede indtægter og udgifter ved at klikke på denne knap.',
    'You can show only expected expenses or expected income by using these filters.': 'Du kan bruge disse filtre til kun at vise forventede udgifter eller kun forventede indtægter.',
    'Here you can see all planned costs or income. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor.': 'Her kan du se alle planlagte udgifter eller indtægter. Denne liste indeholder poster med alle de oplysninger, der er givet (transaktionstype, beløb, modtager osv.). Du kan se indstillinger for redigering og sletning af posten, når du placerer markøren over den.',
    'Here you can see the list of all split requests sent to you. You can see an attached file by clicking on file icon (if there is one). You can also edit a split request by clicking edit icon. Editing means that you can change the status of the selected split request.': 'Her kan du se listen over alle anmodninger om deling, der er sendt til dig. Du kan se en vedhæftet fil ved at klikke på filikonet (hvis der er et). Du kan også redigere en anmodning om deling ved at klikke på ikonet Rediger. Redigering betyder, at du kan ændre statussen for den valgte anmodning om deling.',
    'Here you can see the list of all split requests created by you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.': 'Her kan du se listen over alle anmodninger om deling, der er oprettet af dig. Du kan se en vedhæftet fil ved at klikke på filikonet (hvis der er et). Du kan også redigere en anmodning om deling ved at klikke på Rediger-ikonet. Redigering betyder, at du kan ændre statussen for den valgte anmodning om deling.',
    'Here you can see the history of your split requests. This list contains only paid or rejected requests.': 'Her kan du se historikken for dine anmodninger om deling. Denne liste indeholder kun betalte eller afviste anmodninger.',
    'You can filter split requests history by type or see them all at once.': 'Du kan filtrere historikken for anmodninger om deling efter type eller se dem alle på én gang.',
    'In this section you can create a report. It contains data from the Accounting or Budget modules. It will be generated in pdf format, so it is easy to download and view later.': 'I dette afsnit kan du oprette en rapport. Den indeholder data fra regnskabs- eller budgetmodulet. Den genereres i PDF-format, så det er nemt at downloade og se den senere.',
    'If you want to store files, you have to create at least one folder. Click \'NEW FOLDER\' button to create one.': 'Hvis du vil gemme filer, skal du oprette mindst én mappe. Klik på knappen "NY MAPPE" for at oprette en.',
    'You can add files to this section by clicking this button. We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Du kan føje filer til dette afsnit ved at klikke på denne knap. Vi anmoder om, at oplysninger såsom medicinsk information, politisk opfattelse, race, seksuelle forhold og kriminelle handlinger / historie ikke deles.',
    'Here you can see the list of all files assigned to the selected child. They will be visible only to you.': 'Her kan du se listen over alle filer, der er tildelt det valgte barn. De vil kun være synlige for dig.',
    'You can add sections with notes by clicking this button.': 'Du kan tilføje afsnit med noter ved at klikke på denne knap.',
    'You can edit and delete a file by clicking the icon buttons. They are visible when you move the cursor over the element in the list.': 'Du kan redigere og slette en fil ved at klikke på ikonknapperne. De er synlige, når du placerer markøren over elementet på listen.',
    'You can edit or delete the existing transaction. You can also create split request from expenses.': 'Du kan redigere eller slette den eksisterende transaktion. Du kan også oprette en anmodning om deling ud fra udgifter.',
    'Here you can see the list of folders created by you and visible only to you. You can edit the folder by clicking the edit icon or, if the folder is empty, delete it by clicking the delete icon. A folder that is not empty cannot be deleted.': 'Her kan du se listen over mapper, der er oprettet af dig og kun synlige for dig. Du kan redigere mappen ved at klikke på ikonet Rediger, eller hvis mappen er tom, slette den ved at klikke på ikonet Slet. En mappe, der ikke er tom, kan ikke slettes.',
    'Here you can see the list of folders shared with you. They are created by your current partner and visible to both of you.': 'Her kan du se listen over mapper, der deles med dig. De er oprettet af din nuværende partner og er synlige for jer begge.',
    'By clicking this button you can add files to the selected folder.': 'Ved at klikke på denne knap kan du tilføje filer til den valgte mappe.',
    'Here you can see the list of files in a selected folder. You can edit or delete the existing transaction. You can also share the file by clicking on the share-icon button, which is visible after you move the cursor over the element in the list. Sharing a file means that a special link will be generated. You can copy and send the link to anyone you wish. Every person with the link can see and download the file.': 'Her kan du se listen over filer i en valgt mappe. Du kan redigere eller slette den eksisterende transaktion. Du kan også dele filen ved at klikke på ikonknappen Del, der er synlig, når du placerer markøren over elementet på listen. Deling af en fil betyder, at et særligt link vil blive genereret. Du kan kopiere og sende linket til alle, du ønsker. Hver person med linket kan se og downloade filen.',
    'You can edit and delete a file by clicking on icon buttons, which are visible after you move the cursor over the element in the list.': 'Du kan redigere og slette en fil ved at klikke på ikonknapper, som er synlige, når du placerer markøren over elementet på listen.',
    'After creating a section you can still edit it or remove it if it is no longer needed.': 'Når du har oprettet et afsnit, kan du stadig redigere eller fjerne det, hvis det ikke længere er nødvendigt.',
    'You can add notes to the section by clicking this button.': 'Du kan føje noter til afsnittet ved at klikke på denne knap.',
    'You can edit or remove all notes created previously.': 'Du kan redigere eller fjerne alle tidligere oprettede noter.',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'nn': {
    'Access denied': 'Ingen adgang',
    'You don\'t have permissions to view this page': 'Du mangler tillatelse til denne siden',
    'Return back home': 'Gå tilbake til forsiden',
    'Calendar': 'Kalender',
    'Budget': 'Budsjett',
    'Accounting': 'Regnskap',
    'Split Requests': 'Deling av kostnader',
    'Reports': 'Rapporter',
    'Documents': 'Dokumenter',
    'My Home': 'Mitt hjem',
    'Bonus code is ok! You will get {bonusTrialDaysAmount} days for free.': 'Bonuskoden er gyldig! Du får {bonusTrialDaysAmount} dager gratis abonnement.',
    'Bonus code is ok! Your trial is extended to {bonusTrialEndDate}': 'Bonuskoden er gyldig! Gratisperioden er utvidet til {bonusTrialEndDate}',
    'Do you have Bonus Code?': 'Har du bonuskode?',
    'week': 'uke',
    'January': 'Januar',
    'February': 'Februar',
    'March': 'Mars',
    'April': 'April',
    'May': 'Mai',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'August',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'Desember',
    'Upload': 'Lastet opp',
    'edit avatar': 'endre avatar',
    'Click to upload files': 'Klikk for å laste opp filer',
    'all transactions': 'alle transaksjoner',
    'income only': 'bare inntekter',
    'expenses only': 'bare kostnader',
    'Create subscription': 'Opprett eget abonnement',
    'Your account has been blocked': 'Kontoen din er ikke lenger aktiv',
    'You don\'t have access to all functionalities': 'Du har ikke tilgang til alle funksjoner',
    'Get a subscription to use 2homes to its fullest!': 'Skaff deg et abonnement for å få full adgang til 2homes!',
    'Close': 'Lukk',
    'Invitation has been sent!': 'Invitasjon har blitt sendt!',
    'Please ask the invitee to check for the invitation in their inbox.': 'Det kan være en god idé å si i fra til eks-partneren din at du har sendt de en invitasjon til 2homes.',
    'Update card details': 'Prøv på nytt',
    'There is a problem with payment for your subscription': 'Det oppstod et problem med betalingen',
    'Update credit card details to solve the problem': 'Har du tastet inn riktige kredittkortet opplysninger?',
    'Subscribe': 'Abonnere her',
    'You don\'t have permissions to invite {invitee}': 'Du har ikke tilgang til å invitere personer til 2homes.',
    'To access all the features of 2homes you have need a subscription. It costs only {amount},- per month.': 'For å få tilgang til alle funksjoner i 2homes trenger du et abonnement. Det koster kun {amount},- per måned.',
    'Note: If you created your subscription via AppStore it won’t be cancelled. You can cancel it in AppStore.': 'Merk: Hvis du opprettet abonnementet via App Store, blir det ikke automatisk stoppet. Du må også avslutte det i App Store.',
    'Cancel subscription': 'Avbryt abonnementet',
    'Are you sure you want to cancel subscription?': 'Er du sikker på at du vil avbryte abonnementet?',
    'CANCEL SUBSCRIPTION': 'AVBRYT ABONNEMENTET',
    'Subscription created!': 'Betalingen var vellykket!',
    'You can use 2homes to its fullest now!': 'Du har nå full adgang til 2homes!',
    'Show walkthrough': 'Vis hjelpetekst',
    'Show notifications': 'Vis meldinger',
    'Logout': 'Logg ut',
    'Profile': 'Profil',
    'Error occured': 'Det oppstod en feil',
    'You have unsaved data in event modal window. Are you sure you want to close it?': 'Du har data som ikke er lagret. Er du sikker på at du vil lukke vinduet?',
    'Yes, delete': 'Ja, slett',
    'Select date': 'Velg dato',
    'Username is used for notifications and mentions. You can change it to how members of your family call you at home. Nickname should have between 2 and 10 characters.': 'Brukernavn brukes for varslinger og omtaler. Du kan endre det til hva familiemedlemmene kaller deg hjemme. Kallenavn bør ha mellom 2 og 10 tegn.',
    'Something went wrong. Check the card details and try again.': 'Noe gikk galt. Sjekk kortopplysningene og prøv igjen.',
    'Please enter card details': 'Vennligst skriv inn kortopplysningene',
    'Update credit card': 'Oppdater kredittkortet',
    'Register credit card': 'Legg inn kredittkortdetaljene',
    'Your card will be charged': 'Kredittkortet vil bli belastet med',
    'per month after the trial period.': 'per måned etter prøveperioden.',
    'every month': 'pr mnd',
    'Submit': 'Send',
    'Cancel': 'Avbryt',
    'only current': 'bare denne',
    'only upcoming': 'kommende',
    'all': 'alle',
    'Edit recurring event': 'Endre repeterende hendelse',
    'for me': 'til meg',
    'from me': 'fra meg',
    '+ add new': '+ legg til ny',
    'new category': 'ny kategori',
    'Hours': 'Timer',
    'Minutes': 'Minutter',
    'If you wish to use 2homes on your mobile, please download our app': 'Last ned vår app for å bruke 2homes på mobilen',
    'Download 2homes app': 'Last ned 2homes app',
    'If you have already installed the app, please click here to open': 'Hvis du allerede har lastet ned appen, klikk her for å åpne den',
    'Open 2homes app': 'Åpne 2homes app',
    'Back': 'Tilbake',
    'Next': 'Neste',
    'Skip': 'Hopp over',
    'Finish': 'Avslutt',
    'Could not activate your account.': 'Kan ikke aktivere kontoen.',
    'You accepted an invitation.': 'Du aksepterte invitasjonen.',
    'We have just activated your account.': 'Vi har nettopp aktivert kontoen din.',
    'Please': 'Vennligst',
    'log in': 'logg inn',
    'to enjoy using 2homes': 'for å bruke 2homes',
    'Please wait for account activation.': 'Vennligst vent på kontoaktivering.',
    'Activating account': 'Aktivere konto',
    'Ready!': 'Klar!',
    'Already have an account?': 'Har du allerede opprettet en konto?',
    'Log in': 'Logg inn',
    'Expenses and income': 'Kostnader og inntekter',
    '+ New scheduled transaction': '+ NY PLANLAGT TRANSAKSJON',
    'Are you sure you want to delete data?': 'Er du sikker på at du vil slette data?',
    'Choose transaction to delete': 'Velg transaksjon som skal slettes',
    'Expenses': 'Kostnader',
    'Income': 'Inntekter',
    'Balance': 'Saldo',
    'Date': 'Dato',
    'Amount': 'Beløp',
    'By': 'Fra',
    'Category': 'Kategori',
    'For': 'Til',
    'Visibility': 'Synlighet',
    'Notes': 'Notater',
    'Edit': 'Endre',
    'Delete': 'Slette',
    'weekly': 'ukentlig',
    'biweekly': 'annenhver uke',
    'monthly': 'månedlig',
    'private': 'privat',
    'public': 'offentlig',
    'Add': 'Legg til',
    'expense': 'kostnader',
    'income': 'inntekter',
    'on': 'på',
    'from': 'fra',
    'Date of transaction': 'Dato for transaksjonen',
    'for': 'til',
    'total amount': 'totalt beløp',
    'Repeat': 'Gjenta',
    'Starts:': 'Starter:',
    'Ends:': 'Slutter:',
    'Edit recurring transaction': 'Endre repeterende transaksjon',
    '+ add a note': '+ legg til notat',
    'Save': 'Lagre',
    'Select transaction reason and enter amount to save it.': 'Velg og fyll inn alle felter før du lagrer.',
    'Please invite people to your family to see the calendar': 'Vennligst inviter andre til familien din for å se kalenderen',
    'Are you sure you want to delete an event?': 'Er du sikker på at du vil slette hendelsen?',
    'Choose event to delete': 'Velg hendelse som skal slettes',
    'add event': 'legg til hendelse',
    'minutes': 'minutter',
    'hours': 'timer',
    'days': 'dager',
    'Reminder': 'Påminnelse',
    'before the event': 'før hendelsen starter',
    '+ add reminder': '+ legg til påminnelse',
    'Created by': 'Opprettet av',
    'Only creators can edit events': 'Bare den som oppretter hendelsen har lov til å redigere',
    'event': 'hendelse',
    'childcare': 'samværstid',
    'event name': 'navn på hendelse',
    'date': 'dato',
    'Who also participates in the event?': 'Hvem deltar også i hendelsen ?',
    'Caretaker': 'Omsorgsperson',
    'download': 'last ned',
    'export to ICS': 'send til ICS',
    'print': 'skriv ut',
    'Selected calendar for': 'Velg kalenderen til',
    'And': 'Og',
    'Copy link': 'Kopier lenke',
    'Download file': 'Last ned fil',
    'daily': 'daglig',
    'Start date is the same as date of the event': 'Start dato er den samme som datoen for hendelsen',
    'Week': 'Uke',
    'day': 'dag',
    'today': 'i dag',
    'all day': 'hele dagen',
    'Day': 'Dag',
    'Caring': 'Omsorg',
    'Event': 'Hendelse',
    '\'s calendar': '\'s kalender',
    '\'s calendar of ': '\'s kalender ',
    'Download': 'Last ned',
    'Editing not allowed': 'Endring er ikke tillatt',
    'Edit event': 'Endre hendelse',
    'Event contains notes': 'Hendelse inneholder notater',
    'add childcare': 'legg til samværstid',
    'Childcare': 'Omsorg',
    'Starts': 'Starter',
    'All day': 'Hele dagen',
    'Ends': 'Slutter',
    'Event end date has to be later than start date': 'Sluttdato for hendelse må være senere enn startdatoen',
    'Repeatable end date has to be later than start date': 'Sluttdato for gjentagelse, må være senere enn startdatoen',
    'Please select at least one child for childcare event.': 'Vennligst velg minst ett barn for samværstid.',
    'Profile information': 'Profil informasjon',
    'first name': 'fornavn',
    'last name': 'etternavn',
    'nickname': 'kallenavn',
    'color': 'farge',
    'gender': 'kjønn',
    'male': 'mann',
    'female': 'kvinne',
    'date of birth': 'fødselsdato',
    'email': 'e-post',
    'save changes': 'lagre endringer',
    'Your account has been deleted.': 'Kontoen din er slettet.',
    'Confirmation email has been sent to your email account.': 'Bekreftelse har blitt sendt til din e-postkonto.',
    'HOMEPAGE': 'FORSIDE',
    'file': 'filen',
    'folder': 'mappe',
    'Are you sure you want to delete {confirmModalType}?': 'Er du sikker på at du vil slette {confirmModalType}?',
    'Please create at least one folder to upload files.': 'Opprett minst én mappe for å laste opp filer.',
    'File': 'Fil',
    'Name': 'Navn',
    'Author': 'Skrevet av',
    '+ New folder': '+ Ny mappe',
    'My folders': 'Mine mapper',
    'Shared folders': 'Delte mapper',
    'Visible only for you': 'Bare synlig for deg',
    'Visible for everyone': 'Synlig for alle',
    'You don\'t have permissions to edit folder': 'Du har ikke tillatelse til å redigere mappen',
    'Folder is not empty or you don\'t have permissions to delete it': 'Mappen inneholder data eller du har ikke tillatelse til å slette den',
    'Visible': 'Synlig',
    'Not visible': 'Ikke synlig',
    'folder name': 'mappenavn',
    'Folder': 'Mappe',
    'filename': 'filnavn',
    'Copied': 'Kopiert',
    'Copy the link to share the file': 'Kopier lenken for å dele filen',
    'You can send the copied link to anyone.': 'Du kan sende den kopierte lenken til hvem som helst.',
    'Every person with the link can download the file.': 'Alle som har mottatt lenken fra deg kan laste ned filen.',
    'File is too large. Max file size is': 'Filen er for stor. Maks filstørrelse er',
    'Current relationship': 'Nåværende partner',
    'Parents': 'Foreldre',
    'Kids': 'Barn',
    'Previous relationships': 'Tidligere partner(e)',
    'Are you sure you want to delete the invitation?': 'Er du sikker på at du vil slette invitasjonen?',
    'Are you sure you want to change user visibility?': 'Vil du endre statusen for synlighet til denne personen ?',
    'Yes, change': 'Ja, endre',
    'Blocked user': 'Blokkert bruker',
    'me': 'meg',
    'Pending': 'Ubesvart',
    'Hide': 'Gjemme seg',
    'Show': 'Vise',
    'visible': 'synlig',
    'hidden': 'skjult',
    '+ Add family member': '+ Legg til familiemedlem',
    'Caretakers': 'Omsorgspersoner',
    'Create kid\'s profile': 'Opprett barnets profil',
    'boy': 'gutt',
    'girl': 'jente',
    'Continue': 'Fortsett',
    'current partner': 'nåværende partner',
    'previous partner': 'tidligere partner',
    'a caretaker': 'en omsorgsperson',
    'Invite': 'Inviter',
    '+ add an invitation note': '+ legg ved en beskjed til invitasjonen',
    'Send invitation': 'Send invitasjon',
    'Add new kid': 'Legg til nytt barn',
    'I´d like to invite you to join 2homes, an app that aims to simplify the lives of part-time parents.': 'Jeg sender deg en invitasjon til å laste ned en app som er designet og utviklet for å gjøre hverdagen til deltidsforeldre bedre og enklere. Håper du aksepterer invitasjonen fra meg.',
    'Are you sure you want to delete the file?': 'Er du sikker på at du vil slette denne filen?',
    '+ Add new transaction': '+ Legg til ny transaksjon',
    'History of': 'Historikk',
    'Split with': 'Deles med',
    'Status {status}': 'Status {status}',
    'Split costs': 'Del kostnader',
    'pending': 'ubesvart',
    'Uploaded': 'Lastet opp',
    'attachments': 'vedlegg',
    '+ add new file': '+legg til ny fil',
    'Request': 'Forespørsel til',
    'to split 50/50': 'om å dele 50/50',
    'to pay the amount': 'om å betale beløpet',
    'date of transaction': 'dato for transaksjonen',
    'Select transaction reason, receiver and enter amount to save it.': 'Velg og fyll inn alle felter før du lagrer.',
    'accepted / waiting for payment': 'akseptert/venter på betaling',
    'Split request': 'Forespørsel om deling av kostnader',
    'requested split costs with you': 'spør om å dele kostnader med deg',
    'On': 'På',
    'Total amount': 'Totalt beløp',
    'Request for': 'Forespørsel om',
    'Attachment': 'Vedlegg',
    'Note': 'Notat',
    'Accept': 'Godta',
    'Reject': 'Avslå',
    'Status: ': 'Status: ',
    'Your response has been sent to requester.': 'Svaret ditt er sendt til den har forespurt deg.',
    'Please complete your profile': 'Vennligst fullfør din profil',
    'name': 'navn',
    'surname': 'etternavn',
    'Continue >': 'Fortsett >',
    'Hi': 'Hei',
    'Welcome to 2homes!': 'Velkommen til 2homes!',
    'The app that simplifies life for part-time parents.': 'Appen som skal gjøre hverdagen til deltidsforeldre bedre og enklere.',
    'Let us help you take the first steps.': 'La oss hjelpe deg med å ta de første stegene.',
    'Let\'s start!': 'La oss begynne!',
    'Welcome': 'Velkommen tilbake',
    'The registration process is complete!': 'Registreringsprosessen er fullført!',
    'You can now use the 2homes app to its fullest!': 'Du kan nå bruke 2homes appen til det fulle!',
    'Explore 2homes app': 'Åpne 2homes app',
    'Files': 'Filer',
    'invite to 2homes kids': 'invitere til 2homes kids',
    'Add first note-card': 'Legg til første notatkort',
    'Add note-card': 'Legg til notatkort',
    'Add new section': 'Legg til ny mappe',
    'Are you sure you want to delete this information?': 'Er du sikker på at du vil slette denne informasjonen?',
    '2homes kids invitation': '2homes kids invitasjon',
    'Hi, You\'ve been invited to 2homes kids! Click on one of those links to download the app': 'Hei, du er invitert til 2homes Kids! Klikk på en av disse lenkene for å laste ned appen',
    'If you are using iOS (Apple)': 'Hvis du har IOS (Apple) telefon',
    'If you are using Android': 'Hvis du har Android telefon',
    'AFTER or if you ALREADY have 2homes Kids app installed on your phone, click on this link to login to your account': 'ETTER nedlasting, eller hvis du ALLEREDE har 2homes Kids appen installert på telefonen din, klikker du på denne lenken for å logge deg på',
    'Invitation link copied to clipboard': 'Invitasjons lenken er kopiert til skrivebordet',
    'Make sure that your kid has 2homes Kids app installed. You can find them in App Store and Google Play.': 'Sørg for at barnet ditt har lastet ned 2homes Kids før du sender invitasjonen. Du finner appen i App Store og Google Play.',
    'You can now share your link in the app of your choice or use the shortcuts below.': 'Du kan nå dele lenken ved bruke snarvei til e-post, alternativt åpne og sende den via en annen valgfri app.',
    'Send via email': 'Send via e-post',
    'Add new section for notes:': 'Legg til ny mappe for notater:',
    'Change section name:': 'Endre mappe navn:',
    'Add new note-card:': 'Legg til nytt notatkort:',
    'Change note-card:': 'Endre notatkort:',
    'Change': 'Endre',
    'Create': 'Opprett',
    'title': 'tittel',
    'description': 'beskrivelse',
    'Ready': 'Klar',
    'Create your password': 'Lag passord',
    'Set new password': 'Lag nytt passord',
    'Log in here': 'Logg inn her',
    'with your new password': 'med ditt nye passord',
    'Make sure that it contains at least 8 characters, including one capital letter and one number.': 'Kontroller at den inneholder minst 8 symboler, én STOR bokstav og ett tall.',
    'Passwords must be the same and the password has to have at least 8 characters': 'Passord må være det samme, og må inneholde minst 8 tegn',
    'Select country': 'Velg land',
    'new password': 'nytt passord',
    'repeat password': 'gjenta passord',
    'I accept {Privacy Policy} and {Terms of Service}': 'Ved å opprette en ny konto samtykker du i 2homes {Terms of Service}',
    'Privacy Policy': '',
    'Terms of Service': 'vilkår',
    'I agree that you may use the information I have provided for the purposes necessary for 2homes services.': 'Jeg samtykker til at dere kan benytte de opplysningene jeg har gitt for det formålet som er nødvendig for 2homes sine tjenester.',
    'We could not find a page you were looking for': 'Vi kunne ikke finne siden du leter etter',
    'Notifications': 'Varslinger',
    'mark selected as read': 'merk som lest',
    'mark all as read': 'merk alle som lest',
    'load more': 'laste mer',
    'deleted the account': 'har slettet sin konto',
    'This action will affect your app in the following ways:': 'Denne handlingen har påvirket app’n din på følgende måter:',
    'His/her events were deleted': 'Alle hans/hennes hendelser ble slettet',
    'All of his/her sent invitations were cancelled': 'Alle hans/hennes sendte invitasjoner har blitt kansellert',
    'His/her documents shared with you were deleted': 'Alle hans/hennes dokumenter som var delt med deg ble slettet',
    'I UNDERSTAND': 'JEG FORSTÅR',
    'Type': 'Type',
    'From': 'Fra',
    'Message': 'Melding',
    'User': 'Bruker',
    'Password recovery': 'Gjenoppretting av passord',
    'Don\'t have an account?': 'Har du ikke opprettet en konto ennå ?',
    'Create it here': 'Opprett den her',
    'Please check your email for further instructions.': 'Vennligst sjekk e-posten din for ytterligere instruksjoner.',
    'Let\’s make your new password more memorable this time:': 'La oss gjøre det nye passordet lettere å huske denne gangen:',
    'Total expenses by category': 'Totale kostnader etter kategori',
    'Total income by category': 'Totale inntekter etter kategori',
    'Split requests': 'Forespurte delte kostnader',
    '(for me / from me)': '(til meg / fra meg)',
    'Split req. from me': 'Forespurt delt kostn. fra meg',
    'Split req. for me': 'Forespurt delt kostn. til meg',
    'Summaries': 'Sammendrag',
    'History of transactions': 'Transaksjonshistorikk',
    'Expected transactions': 'Forventede transaksjoner',
    'back to reports': 'tilbake til rapporter',
    'back to budget': 'tilbake til budsjett',
    'Accounting report': 'Regnskapsrapport',
    'Budget report': 'Budsjett rapport',
    'General summaries': 'Total sammendrag',
    'Expense': 'Kostnad',
    'Details': 'Detaljer',
    'Split': 'Dele',
    'Total expenses by category:': 'Totale kostnader etter kategori:',
    'Total income by category:': 'Totale inntekter etter kategori:',
    'Create report': 'Lag rapport',
    'Select source of data': 'Velg tallene fra',
    'accounting': 'regnskap',
    'budget': 'budsjett',
    'of my': 'som er mine',
    'that are': 'merket',
    'coming from': 'inntekter i katogori',
    'category': 'utgifter i kategori',
    'include transactions that are not assigned to anybody': 'inkluder transaksjoner som ikke er tilordnet noen personer',
    'during the period': 'for perioden',
    'month, year': 'måned, år',
    'Add summary of split requests': 'Vis totalsummen for forespørsler om deling av kostnader',
    'Show history of transactions': 'Vis transaksjonshistorikken',
    'Show expected transactions': 'Vis budsjetterte transaksjoner',
    'yes': 'ja',
    'no': 'nei',
    'Download PDF report': 'Last ned PDF rapport',
    'Start date has to be earlier than end date': 'Start datoen må være eldre enn sluttdatoen',
    'Please select at least one person': 'Velg minst én person',
    'Please select at least one income or expense category': 'Velg minst én inntekts-eller utgiftskategori',
    'Hi, ': 'Hei, ',
    ' has shared the following file:': ' har delt følgende fil:',
    'Click here to download': 'Klikk her for å laste ned',
    'If you still don\'t have account on 2homes, just go to': 'Hvis du fortsatt ikke har 2homes konto så bør du opprette den nå',
    'and create a new one!': 'og opprett en ny!',
    'Someone': 'Noen',
    'Welcome back!': 'Velkommen tilbake!',
    'password': 'passord',
    'Forgot your password?': 'Glemt passordet ditt ?',
    '3 months': '3 måneder',
    '30 days': '30 dagers',
    'Create new account': 'Opprett en  konto',
    'On it\'s way...': 'På vei....',
    'Please check your inbox': 'Vennligst sjekk e-posten din',
    'to confirm your new account.': 'og bekreft den nye kontoen.',
    'A message should arrive within a couple of minutes.': 'Melding skal komme innen et par minutter.',
    'Start your {trialLength} free trial period today': 'Start {trialLength} gratis prøveperiode i dag',
    'First Name': 'Fornavn',
    'Email': 'E-post',
    'Password': 'Passord',
    'Already strong!': 'Allerede sterk!',
    'country': 'land',
    'Start my free trial': 'START GRATIS PRØVEPERIODE',
    'Change status to': 'Endre status til',
    'paid': 'betalt',
    'rejected': 'avslått',
    'For me': 'Til meg',
    'From me': 'Fra meg',
    'Paid': 'Betalt',
    'Rejected': 'Avslått',
    'Total': 'Total',
    'Requested by': 'Forespurt av',
    'To': 'Til',
    'Status': 'Status',
    'Change password': 'Endre passord',
    'Fill the form to change your password.': 'Fyll ut skjemaet for å endre passordet.',
    'Enter old password': 'Skriv inn ditt gamle passord',
    'Enter new password': 'Skriv inn nytt passord',
    'Repeat new password': 'Gjenta nytt passord',
    'New passwords must be the same': 'Nye passord må være det samme',
    'Your password has been successfully changed': 'Passordet ditt er endret',
    'Please fill the form': 'Vennligst fyll ut skjemaet',
    'Sorry to see you go…': 'Beklager å se deg forlate oss…',
    'Deleting 2homes account': 'Slette 2homes-konto',
    'Before you make a final decision, make sure you understand how account removal will result in the following actions:': 'Før du tar den endelige avgjørelsen, må du vite hvilken konsekvenser sletting av kontoen din fører til:',
    'If you are sure, confirm by clicking the button below.': 'Hvis du er sikker, kan du bekrefte ved å klikke på knappen nedenfor.',
    'Your events will be deleted': 'Alle hendelsene dine vil bli slettet',
    'Your documents will be deleted': 'Alle dokumentene dine vil bli slettet',
    'Your private folders and their content will be deleted': 'Alle dine private mapper og innholdet i disse vil bli slettet',
    'All your transactions will be deleted': 'Alle transaksjonene dine vil bli slettet',
    'All your sent invitations will be cancelled': 'Alle dine sendte invitasjoner vil bli kansellert',
    'Home without any active family members will be deleted': 'Hjem uten aktive familiemedlemmer vil bli slettet',
    'Children account with no caretaker assigned will be deleted': 'Dine barns konti vil bli slettet hvis de ikke har andre registrerte omsorgspersoner i 2homes',
    'Your subscription will be canceled': 'Abonnementet ditt avbrytes',
    'You will be logged out immediately.': 'Du vil bli logget ut umiddelbart.',
    'Remember that this action cannot be reversed!': 'Husk at denne handlingen ikke kan angres!',
    'Note: If you created your subscription via AppStore it won’t be cancelled. Remember to also cancel it in AppStore after deleting your 2homes account': 'Merk: Hvis du opprettet abonnementet via App Store, blir det ikke automatisk stoppet. Husk også å avslutte det i App Store etter at du har slettet 2homes-kontoen',
    'DELETE MY ACCOUNT': 'SLETT KONTOEN MIN',
    'CANCEL': 'AVBRYT',
    'Subscription plan': 'Abonnementsplan',
    'You are currently free trial user': 'Du er for øyeblikket gratis prøvebruker',
    'You have cancelled subscription': 'Du har avsluttet abonnementet',
    'You have access to all features and functionalities. Your trial period ends:': 'Du har tilgang til alle menyer og funksjoner. Prøve perioden din avsluttes:',
    'You will lose access to all features and functionalities after payment period. Your payment period ends:': 'Du mister tilgangen til alle menyer og funksjoner etter at abonnementet har utløpt. Perioden avsluttes:',
    'renew subscription': 'forny abonnementet',
    'Subscription costs after trial:': 'Beløp etter prøve periode:',
    'Subscription costs:': 'Abonnementspris:',
    'month': 'måned',
    'Payment method:': 'Betalingsmåte:',
    'edit': 'endre',
    'Have a question? Contact as at': 'Har du spørsmål? Kontakt oss på',
    'My profile': 'Min profil',
    'language': 'språk',
    'delete account': 'slett konto',
    'Something went wrong. Try again later.': 'Noe gikk galt. Sjekk kortopplysningene og prøv igjen.',
    'The sections below show the members of your home. The first section shows your current and ex-partners. The second shows your children from current and previous relationships.': 'Denne listen viser medlemmene i hjemmet ditt. De er delt inn i to seksjoner. Den første viser din nåværende og tidligere partner(e). Den andre inkluderer barn fra ditt nåværende og eventuelt tidligere forhold.',
    'Click here to add family members, including ex-partners, your current partner and kids.': 'Klikk her for å legge til familiemedlemmer, inkludert din nåværende partner, tidligere partnere og barn fra disse forholdene.',
    'You can switch between the views by using these filters. You can show all home members, only visible or only hidden.': 'Her kan du veksle mellom forskjellige visninger av medlemmer i familienettverket ditt. Alternativene er å vise alle , bare de som er synlige eller bare de som er skjult.',
    'This shows your current relationship. Click the \'+\' button to add members to your home.': 'Dette er ditt nåværende forhold. Omsorgspersoner til venstre og barn i høyre kolonne. Bruk + knappen for å legge til personer som hører til dette forholdet.',
    'This shows your previous relationships. Click the \'+\' button to add members to your home.': 'Dette viser dine tidligere forhold. Klikk på + for å legge til medlemmer i hjemmet ditt.',
    'Click here to switch between day and week views. You can also quickly go to the current date by clicking the \'today\' button.': 'Klikk her for å bytte mellom dag-og ukes visninger. Du kan også raskt gå til dagens dato ved å klikke på “i dag” knappen.',
    'Click here to change dates.': 'Klikk her for å endre datoer.',
    'You can filter events by selecting and deselecting family members.': 'Du kan filtrere hendelser ved å velge eller oppheve merkingen av familiemedlemmer.',
    'You can create events by clicking on a calendar cell. In DAY view, you can Select the time range in a column to create the event. In WEEK view, you can create childcare or normal events by clicking the appropriate row in the day. Scroll down the calendar by first clicking in the column, between the navigation menu and the calendar.': 'Du kan opprette hendelser ved å klikke på kalender cellen. I DAG visning kan du velge tidsperiode i en kolonne for å opprette hendelsen. I UKE visning kan du opprette samværstid eller vanlige hendelser ved å klikke riktig rad på ønsket dag. For å kunne bla deg nedover kalenderen må du først å klikke i kolonnen, mellom navigeringsmenyen og kalenderen.',
    'We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Vi anmoder om at det ikke deles informasjon som sykdomsopplysninger, politisk ståsted, rase, seksuelle forhold og kriminelle handlinger/historikk.',
    'Click arrow buttons to switch the year. Click on month name to show its transactions.': 'Bruk piltastene <  > til å skifte år . Klikk på månedsetikettene for å vise transaksjonene og totalsum pr måned.',
    'In this section you can see the summary of your income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.': 'I denne seksjonen kan du se totalsummen av dine inntekter og utgifter. Venstre kolonne viser summen for hele året og høyre kolonne for fra valgt måned.',
    'Click here to add new file to pending transactions.': 'Klikk her for å legge til ny fil (kvittering, bilde etc) i ubehandlede transaksjoner.',
    'Here you can see list of pending transactions. This is the list of files you uploaded for the selected month. You can edit each of them and add all required information, such as amount of money spent or the receiver of this cost/income. After editing, the record will be visible in a table with transactions, below pending files.': 'Her kan du se en oversikt over transaksjoner som ikke er ferdigbehandlet. Dette er listen over filer du har lastet opp for valgt måned. Du kan redigere hver av dem og legge all nødvendig informasjon som f.eks beløp, hva som er kjøpt, til hvem person osv. Etter redigering vil posten være synlig i tabellen med transaksjoner.',
    'You can add a new transaction by clicking this button. You will be able to attach a file to the new transaction after creating it.': 'Du kan legge til en ny transaksjon ved å klikke på denne knappen. Du har også mulighet til å legge til en fil (kvittering, bilde etc). Det kan gjøres etter at du har opprettet den nye transaksjonen.',
    'You can show only expenses or only income transactions by using these filters.': 'Her kan du velge å vise alle transaksjonene dine.  Du har også muligheten til å filtrere slik at bare kostnadene eller bare inntektene vises.',
    'Here you can see the history of your transactions. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor. You can attach a file to every existing transaction.': 'Her vises historikken for transaksjonene dine. Denne listen inneholder poster med all informasjon som oppgis (transaksjonstype, beløp, mottaker osv.) for valgt måned. Du kan se alternativer for å endre og slette posten når du holder markøren over transaksjonen. Du har alltid mulighet legge til en fil (kvittering, bilde etc) til eksisterende transaksjon.',
    'Click arrow buttons to switch the year. Click on month name to show expected costs or income.': 'Bruk piltastene <  > til å skifte år . Klikk på månedsetikettene for å vise de budsjetterte transaksjonene og totalsum pr måned.',
    'In this section you can see the summary of your expected income and expenses. The left column shows aggregated data for the whole year and right column - only data from selected month.': 'I denne seksjonen kan du se totalsummen av dine budsjetterte kostnader og inntekter. Venstre kolonne viser summen for hele året og høyre kolonne for fra valgt måned.',
    'You can add new expected income and expenses by clicking this button.': 'Du kan legge til flere budsjetterte kostander og inntekter ved å klikke på denne knappen.',
    'You can show only expected expenses or expected income by using these filters.': 'Her kan du velge å vise alle de budsjetterte transaksjonene dine.  Du har også muligheten til å filtrere slik at bare kostnadene eller bare inntektene vises.',
    'Here you can see all planned costs or income. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor.': 'Her vises alle dine planlagte transaksjoner. Denne listen inneholder poster med all informasjon som oppgis (transaksjonstype, beløp, mottaker osv.) for valgt måned. Du kan se alternativer for å endre og slette posten når du holder markøren over den planlagte transaksjonen.',
    'Here you can see the list of all split requests sent to you. You can see an attached file by clicking on file icon (if there is one). You can also edit a split request by clicking edit icon. Editing means that you can change the status of the selected split request.': 'Dette er listen over alle forespørsler om deling av kostnader som er sendt TIL deg. Dette er mottatte forespørsler fra tidligere partner(e) om å dele barnerelaterte kostnader. Du kan se evt vedlagt fil ved å klikke på ikonet. Du kan også endre forespørselen ved å velge ikonet for dette. Med endre menes her at du kan forandre status på forespørselen.',
    'Here you can see the list of all split requests created by you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.': 'Dette er listen over alle forespørsler om deling av kostnader som er sendt FRA deg. Dette er sendte forespørsler til tidligere partner(e) om å dele barnerelaterte kostnader. Du kan se evt vedlagt fil ved å klikke på ikonet. Du kan også endre forespørselen ved å velge ikonet for dette. Endre menes her med at du kan forandre status på forespørselen.',
    'Here you can see the history of your split requests. This list contains only paid or rejected requests.': 'Her kan du se hele den historiske oversikten for BETALTE og AVSLÅTTE forespørsler om å dele kostnader med din tidligere partner(e).',
    'You can filter split requests history by type or see them all at once.': 'Du kan filtrere historikken over forespørsler om deling av kostander etter type (TIL meg eller FRA meg).  Evt se alle samtidig.',
    'In this section you can create a report. It contains data from the Accounting or Budget modules. It will be generated in pdf format, so it is easy to download and view later.': 'I denne delen kan du opprette en rapport. Den inneholder data fra regnskap eller budsjett modulen. Du har mulighet til å lage detaljerte rapporter med et unikt filtreringssystem. Etter at du har gjort dine valg, vil det bli generert en rapport i PDF-format. Rapporten kan du enkelt laste ned på PC’n og evt dele med andre.',
    'If you want to store files, you have to create at least one folder. Click \'NEW FOLDER\' button to create one.': 'Hvis du vil lagre filer, må du opprette minst én mappe. Klikk "NY MAPPE"-knappen for å opprette en.',
    'You can add files to this section by clicking this button. We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Du kan legge til nye filer i denne mappen ved å klikke på denne knappen. Vi anmoder om at det ikke deles informasjon som sykdomsopplysninger, politisk ståsted, rase, seksuelle forhold og kriminelle handlinger/historikk.',
    'Here you can see the list of all files assigned to the selected child. They will be visible only to you.': 'Her finner du listen over alle filer utfra valgt barn. De vil være synlig bare for deg.',
    'You can add sections with notes by clicking this button.': 'Du kan legge til mapper (inndelinger) med notater ved å klikke på denne knappen.',
    'You can edit and delete a file by clicking the icon buttons. They are visible when you move the cursor over the element in the list.': 'Du kan redigere og slette filen ved å klikke på ikon knapper. De er synlige når du flytter markøren over listen.',
    'You can edit or delete the existing transaction. You can also create split request from expenses.': 'Du kan endre eller slette den eksisterende transaksjonen. Du kan også opprette forespørsler om å dele kostnader fra kostnader.',
    'Here you can see the list of folders created by you and visible only to you. You can edit the folder by clicking the edit icon or, if the folder is empty, delete it by clicking the delete icon. A folder that is not empty cannot be deleted.': 'Her kan du listen over mapper som er opprettet av deg og synlig bare for deg. Du kan endre mappen ved å klikke på ikonet for dette, eller hvis mappen er tom, slette den ved å kikke på søppelkasse ikonet. Mapper som har innhold kan ikke slettes.',
    'Here you can see the list of folders shared with you. They are created by your current partner and visible to both of you.': 'Her kan se eventuelle delte mapper. De er laget av din nåværende partner og synlig for dere begge.',
    'By clicking this button you can add files to the selected folder.': 'Ved å klikke på denne knappen kan du legge til filer i den valgte mappen.',
    'Here you can see the list of files in a selected folder. You can edit or delete the existing transaction. You can also share the file by clicking on the share-icon button, which is visible after you move the cursor over the element in the list. Sharing a file means that a special link will be generated. You can copy and send the link to anyone you wish. Every person with the link can see and download the file.': 'Her kan du se listen av dokumenter i valgt mappe. Du kan endre eller slette dokumentet. I tillegg har du muligheten til å dele dokumentet med andre. Det gjøres ved å klikke på ikonet for deling. Den kommer frem ved å bevege markøren over dokumentet i listen. En lenke vil bli generert ved at du kopierer denne. Lenken kan du sende til hvem du vil og alle som mottar denne har mulighet til å laste den ned.',
    'You can edit and delete a file by clicking on icon buttons, which are visible after you move the cursor over the element in the list.': 'Du kan endre og slette filer ved å klikke på ikon knapper. De er synlige når du flytter markøren over listen.',
    'After creating a section you can still edit it or remove it if it is no longer needed.': 'Etter å ha opprettet mappen kan du fortsatt endre eller fjerne den når du ikke har behov for den lenger.',
    'You can add notes to the section by clicking this button.': 'Du kan legge til notater i mappen ved å klikke på denne knappen.',
    'You can edit or remove all notes created previously.': 'Du kan endre eller fjerne alle notater som tidligere er opprettet.',
  },
  'pl': {
    'Calendar': 'Kalendarz',
    'Budget': 'Budżet',
    'Accounting': 'Rachunkowość',
    'Split Requests': '',
    'Reports': 'Raporty',
    'Documents': 'Dokumenty',
    'My Home': 'Mój dom',
    'My files': 'Profil',
    'Drop file here': '',
    'Or': '',
    'Select File': 'Wybierz datę',
    'Web Address': '',
    'Public URL of an image file:': '',
    'Upload': '',
    'Please type a valid HTTP URL.': '',
    'Camera': '',
    'Make sure your browser allows camera capture, position yourself and click Capture:': '',
    'Capture': '',
    'Uploading': '',
    'Processing': '',
    'Try again': '',
    'OK': '',
    'Some of your images failed to upload.': '',
    'January': '',
    'February': '',
    'March': '',
    'April': '',
    'May': '',
    'June': '',
    'July': '',
    'August': '',
    'September': '',
    'October': '',
    'November': '',
    'December': '',
    'all transactions': 'Wszystkie Transakcje',
    'income only': 'Dochód',
    'expenses only': 'Wydatki',
    'Logout': 'Wyloguj',
    'Profile': 'Profil',
    'Error occured': 'Wystąpił błąd',
    'You have unsaved data in event modal window. Are you sure you want to close it?': 'Masz niezapisane dane w okienku dialogowym. Czy na pewno chcesz zamknąć?',
    'Yes, delete': '',
    'Select date': 'Wybierz datę',
    'Username is used for notifications and mentions. You can change it to how members of your family call you at home. Nickname should have between 2 and 10 characters.': '',
    'all': '',
    'for me': '',
    'from me': '',
    '+ add new': '+ dodaj nowy',
    'new category': 'Nowa kategoria',
    'Hours': '',
    'Minutes': '',
    'Back': '',
    'Next': '',
    'Skip': '',
    'Could not activate your account.': 'Nie można utworzyć konta',
    'You accepted an invitation.': '',
    'We have just activated your account.': 'Właśnie aktywowaliśmy Twoje konto.',
    'Please': '',
    'log in': '',
    'to enjoy using 2homes': '',
    'You accepted an invitation': '',
    'Please open your mobile application and enjoy using 2homes': '',
    'Please wait for account activation.': '',
    'Activating account': 'Aktywacja konta',
    'Ready!': 'Gotowe!',
    'Already have an account?': 'Masz już konto?',
    'Log in': 'Zaloguj',
    'Are you sure you want to delete data?': '',
    'Expenses and income': '',
    '+ Add new transaction': 'Wszystkie Transakcje',
    'Expenses': '',
    'Income': '',
    'Balance': '',
    'Date': '',
    'Amount': '',
    'By': '',
    'Category': 'Nowa kategoria',
    'For': '',
    'Visibility': '',
    'Notes': '',
    'Edit': '',
    'Delete': '',
    'private': '',
    'public': '',
    'Add': '',
    'expense': '',
    'income': '',
    'on': '',
    'from': '',
    'Date of transaction': 'Wszystkie Transakcje',
    'date of transaction': 'Wszystkie Transakcje',
    'for': '',
    'total amount': '',
    'repeats monthly': '',
    'Starts:': '',
    'Ends:': '',
    '+ add a note': '',
    'Save': '',
    'Select transaction reason and enter amount to save it.': '',
    'Start time cannot be later than end time.': '',
    'Are you sure you want to delete an event?': '',
    'Please invite people to your family to see the calendar': '',
    'Created by': '',
    'event': '',
    'childcare': '',
    'event name': '',
    'date': '',
    'Who also participates in the event?': '',
    'Caretaker': '',
    'Repeat': '',
    'download': '',
    'export to ICS': '',
    'print': '',
    'Selected calendar for': '',
    'And': '',
    'Copy link': '',
    'Download file': '',
    'Start date is the same as date of the event': '',
    'only current': '',
    'only upcoming': '',
    'week': '',
    'Week': '',
    'day': '',
    'today': '',
    'all day': '',
    'Day': '',
    'Caring': '',
    'Event': '',
    '\'s calendar': 'Kalendarz',
    '\'s calendar of ': 'Kalendarz',
    'Download': '',
    'Editing not allowed': '',
    'Edit event': '',
    'Event contains notes': '',
    'Childcare': '',
    'Starts': '',
    'All day': '',
    'Ends': '',
    'Event end date has to be later than start date': '',
    'Repeatable end date has to be later than start date': '',
    'Please select at least one child for childcare event.': '',
    'Profile information': '',
    'first name': '',
    'last name': '',
    'nickname': '',
    'color': 'kolor',
    'gender': 'płeć',
    'male': 'mężczyzna',
    'female': 'kobieta',
    'date of birth': '',
    'email': '',
    'save changes': '',
    'Are you sure you want to delete {confirmModalType}?': '',
    'Please create at least one folder to upload files.': '',
    'Click to upload files': '',
    'File': '',
    'Name': '',
    'Author': '',
    '+ New folder': '',
    'My folders': '',
    'Shared folders': '',
    'Visible': '',
    'Not visible': '',
    'folder name': '',
    'Folder': '',
    'filename': '',
    'Copied': '',
    'Copy the link to share the file': '',
    'You can send the copied link to anyone.': '',
    'Every person with the link can download the file.': '',
    'Parents': '',
    'Kids': '',
    'Do you want to add kid to this family?': '',
    'Do you want to add a second parent to this family?': '',
    'Yes': '',
    'Are you sure you want to delete the invitation?': '',
    'Are you sure you want to change user visibility?': '',
    'Yes, change': '',
    'me': 'mężczyzna',
    'pending...': '',
    'visible': '',
    'hidden': '',
    '+ Add family member': '',
    'Caretakers': '',
    'Create kid\'s profile': '',
    'edit avatar': '',
    'Continue': '',
    'current partner': '',
    'ex partner': '',
    'a caretaker': '',
    'Invite': '',
    '+ add an invitation note': '',
    'Send invitation': '',
    'You are adding current partner. It means that this partner is a parent to those children.': '',
    'Does this caretaker have already children in My Home view? Please select them.': '',
    'You are adding kid to your current family. It means that the following partner is a parent to this child.': '',
    'Does this kid have second parent in My Home view? Please select one.': '',
    'This caretaker does not have kids in My Home view yet': '',
    'This kid does not have second parent in My Home view yet': '',
    'Select member': 'Wybierz datę',
    'caretaker': '',
    'kid': '',
    'Select relationship': 'Wybierz datę',
    'Add new kid': '+ dodaj nowy',
    'Are you sure you want to delete the file?': '',
    'History of': '',
    'Split with': '',
    'Status {status}': '',
    'Split costs': '',
    'Pending': '',
    'Uploaded': '',
    'attachments': '',
    '+ add new file': '+ dodaj nowy',
    'Request': '',
    'to split 50/50': '',
    'to pay the amount': '',
    'Split request': '',
    'On': '',
    'Total amount': '',
    'Request for': '',
    'Attachment': '',
    'Note': '',
    'Accept': '',
    'Reject': '',
    'Status: ': '',
    'Your response has been sent to requester.': '',
    'Complete your profile': '',
    'name': '',
    'surname': '',
    'Continue >': '',
    'Hi': '',
    'Welcome to 2homes.': '',
    'Let us help you to take the first steps.': '',
    'Start by creating your family': '',
    'members profiles.': '',
    'Let\'s start!': '',
    'Two homes can be just as good as one': '',
    '2homes makes communications, logistics and accounting between divorced parents much easier. Children living in two homes will feel more secure when parents cooperates.': '',
    'Register account': '',
    'Log out': 'Zaloguj',
    'Go to App': '',
    'Files': '',
    'Add first note-card': '',
    'Add note-card': '',
    'Add new section': '',
    'Are you sure you want to delete this information?': '',
    'Add new section for notes:': '',
    'Change section name:': '',
    'Add new note-card:': '',
    'Change note-card:': 'Zmień hasło',
    'Change': '',
    'Create': 'Utwórz je tutaj',
    'title': '',
    'description': '',
    'Ready': '',
    'Set new password': '',
    'You can now': '',
    'with your new password.': '',
    'You can now open your mobile app and log in with your new password.': '',
    'Make sure that it contains at least 8 characters, including one capital letter and one number.': '',
    'Passwords must be the same and the password has to have at least 8 characters': '',
    'new password': 'nowe hasło',
    'repeat password': 'powtórz hasło',
    'We couldn\’t find a page you were looking for': '',
    'Return back home': '',
    'Notifications': '',
    'mark selected as read': '',
    'mark all as read': '',
    'load more': '',
    'Type': '',
    'From': '',
    'Message': '',
    'Password recovery': 'Odzyskanie hasła',
    'Don\'t have an account?': 'Nie masz jeszcze konta?',
    'Create it here': 'Utwórz je tutaj',
    'Please check your email for further instructions.': '',
    'Let\’s make your new password more memorable this time:': '',
    'Summaries': '',
    'History of transactions': 'Wszystkie Transakcje',
    'Expected transactions': 'Wszystkie Transakcje',
    'back to reports': '',
    'back to budget': '',
    'report': 'Raporty',
    'General summaries': '',
    'Expense': 'Wydatki',
    'Details': '',
    'Split': '',
    'Total expenses by category:': '',
    'Total income by category:': '',
    'Create report': 'Utwórz je tutaj',
    'Select source of data': 'Wybierz datę',
    'accounting': 'Rachunkowość',
    'budget': 'Budżet',
    'of my': '',
    'that are': '',
    'coming from': '',
    'category': 'Nowa kategoria',
    'include transactions that are not assigned to anybody': '',
    'month, year': '',
    'Add summary of split requests': '',
    'Show history of transactions': '',
    'Show expected transactions': 'Wszystkie Transakcje',
    'yes': '',
    'no': '',
    'Download PDF report': '',
    'Start date has to be earlier than end date': '',
    'Please select at least one person': '',
    'Please select at least one income or expense category': '',
    'Hi, ': '',
    ' has shared the following file:': '',
    'Click here to download': '',
    'If you still don\'t have account on 2homes, just go to': '',
    'and create a new one!': 'Przez stworzenie konta',
    'Someone': '',
    'Welcome back!': '',
    'password': '',
    'Forgot your password?': '',
    'Create new account': '',
    'On it\'s way...': 'W drodze…',
    'Please check your email': 'Sprawdź swój email',
    'and confirm your new account.': 'i potwierdź swoje nowe konto',
    'A message should arrive within couple of minutes.': '',
    'Start for free. The first month is on us.': 'Zacznij za darmo. Pierwszy miesiąc bierzemy na siebie.',
    'No credit card required.': 'Nie potrzeba karty kredytowej',
    'First Name': 'Imię',
    'Email': '',
    'Password': 'Hasło',
    'Already strong!': 'Hasło silne!',
    'language:': '',
    'Start my free trial': 'Zacznij mój okres próbny',
    'By creating new account': 'Przez stworzenie konta',
    'you agree to': 'zgadzasz się na',
    '2homes terms': 'warunki użytkowania 2homes',
    'Change status to': 'Zmień hasło',
    'pending': '',
    'accepted / waiting for payment': '',
    'paid': '',
    'rejected': '',
    'For me': '',
    'From me': '',
    'Paid': '',
    'Rejected': '',
    'Total': '',
    'Requested by': '',
    'To': '',
    'Status': '',
    'Change password': 'Zmień hasło',
    'Fill the form to change your password.': 'Wypełnij formularz, żeby zmienić hasła',
    'Enter old password': 'Podaj stare hasło',
    'Enter new password': 'Podaj nowe hasło',
    'Repeat new password': 'Powtórz nowe hasło',
    'New passwords must be the same': 'Nowe hasła muszą być takie same',
    'Your password has been successfully changed': 'Twoje hasło zostało zmienione',
    'Please fill the form': '',
    'My profile': 'Profil',
    'language': '',
    'Name and surname': 'Imię i nazwisko',
    'Nickname': '',
    'Gender': '',
    'Date of birth': '',
    'Color': '',
    'Those lists show members of your home. They are divided into two sections. First one shows your current and ex partners and the second one includes children from your current and previous relationships.': '',
    'Click this button to invite another member to your relationship, partner or child.': '',
    'You can switch between the views by using these filters. You can show all home members, only visible or only hidden.': '',
    'This is your current relationship. You can see caretakers on the left and children in right column. Use \'+\' button add member to the relationship.': '',
    'This is the list of your previous relationships. You can add partners and children to any of them. You can always create new relationship if you need.': '',
    'Click here to switch between day and week views. You can also quickly go to current date by clicking \'today\' button.': '',
    'Click here to open the month date picker to change date.': '',
    'You can filter events by selecting and deselecting family members.': '',
    'You can create events by clicking on calendar cell. In DAY view, you can select time range in a column to create the event. In WEEK view, you can create childcare or normal events by clicking proper row in the day. Scroll down the calendar by first clicking in the column, between the navigation menu and the calendar.': '',
    'Click arrow buttons to switch the year. Click month name to show its transactions.': '',
    'In this section you can see the summary of your income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.': '',
    'Click here to add new file to pending transactions.': '',
    'Here you can see list of pending transactions. This is the list of files you uploaded for selected month. You can edit each of them and add all needed information like amount of money spent or who is the receiver of this cost/income. After editing, the record will be visible in table with transactions, below pending files.': '',
    'You can add new transaction by clicking this button. You will be able to attach the file to new transaction after creating it.': '',
    'You can show only expenses or only income transactions by using these filters.': '',
    'Here you can see the history of your transactions. This list contains records with all information provided (transaction type, amount, receipient, etc.). You can see options for editing and deleting the record when you hover it with your cursor. You can attach the file to every existing transaction.': '',
    'Click arrow buttons to switch the year. Click month name to show expected costs or income.': '',
    'In this section you can see the summary of your expected income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.': '',
    'You can add new expected income and expenses by clicking this button.': '',
    'You can show only expected expenses or expected income by using these filters.': '',
    'Here you can see all planned costs or income. This list contains records with all information provided (transaction type, amount, receipient, etc.). You can see options for editing and deleting the record when you hover it with your cursor.': '',
    'Here you can see the list of all split requests sent to you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.': '',
    'Here you can see the list of all split requests created by you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.': '',
    'Here you can see the history of your split requests. This list contains only paid or rejected requests.': '',
    'You can filter split requests history by type or see them all at once.': '',
    'In this section you can create a report. It contains data from Accounting or Budget modules. It will be generated in pdf format, so it\'s easy to download it after.': '',
    'If you want to store files you have to create at least one folder. First, fill the input with folder name and then click \'NEW FOLDER\' button.': '',
    'Here you can see the list of all files assigned to selected child. They will be visible only for you.': '',
    'You can add files to this section by clicking this button.': '',
    'You can add sections with notes by clicking this button. Sections can contain any information you want, from medical ones to shoe sizes.': '',
    'You can edit and delete file by clicking icon buttons. They are visible when you move the cursor over the list\'s element.': '',
    'You can edit or delete the existing transaction. You can also create split request from expenses.': '',
    'Here you can see the list of folders created by you and visible only for you. You can edit the folder by clicking edit icon or, if the folder is empty, delete it by clicking delete icon. Not empty folder cannot be deleted.': '',
    'Here you can see the list of shared folders with you. They are created by your current partner and visible for both of you.': '',
    'By clicking this button you can add files to the selected folder.': '',
    'Here you can see the list of files in selected folder. You can edit or delete the existing transaction. You can also share the file by clicking share-icon button, which is visible after you move the cursor over the list\'s element. Sharing file means that the special link will be generated. You can copy and send the link to anyone you want. Every person with the link can see and download the file.': '',
    'You can edit and delete file by clicking icon buttons, which are visible after you move the cursor over the list\'s element.': '',
    'After creating section you can still edit it or remove when you don\'t need it anymore.': '',
    'You can add notes to the section by clicking this button.': '',
    'You can edit or remove all notes created previously.': '',
  },
  'sv': {
    'Access denied': 'Tillträde beviljas ej',
    'You don\'t have permissions to view this page': 'Du har inte behörighet att komma åt den här sidan',
    'Return back home': 'Gå tillbaka hem',
    'Calendar': 'Kalender',
    'Budget': 'Budget',
    'Accounting': 'Bokföring',
    'Split Requests': 'Delningsförfrågningar',
    'Reports': 'Rapporter',
    'Documents': 'Dokument',
    'My Home': 'Mitt hem',
    'Bonus code is ok! You will get {bonusTrialDaysAmount} days for free.': 'Bonuskoden är giltig! Du får {bonusTrialDaysAmount} dagars gratis prenumeration.',
    'Bonus code is ok! Your trial is extended to {bonusTrialEndDate}': 'Bonuskoden är giltig! Den fria perioden har förlängts till {bonusTrialEndDate}',
    'Do you have Bonus Code?': 'Har du en bonuskod?',
    'week': 'vecka',
    'January': 'Januari',
    'February': 'Februari',
    'March': 'Mars',
    'April': 'April',
    'May': 'Maj',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'Augusti',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'December',
    'Upload': 'Ladda upp',
    'edit avatar': 'redigera avatar',
    'Click to upload files': 'Klicka för att ladda upp filer',
    'all transactions': 'alla transaktioner',
    'income only': 'endast intäkter',
    'expenses only': 'endast utgifter',
    'Create subscription': 'Skapa din egen prenumeration',
    'Your account has been blocked': 'Ditt konto är inte längre aktivt',
    'You don\'t have access to all functionalities': 'Du har inte tillgång till alla funktioner',
    'Get a subscription to use 2homes to its fullest!': 'Skapa ett abonnemang för att använda 2homes till fullo!',
    'Close': 'Stäng',
    'Invitation has been sent!': 'Inbjudan har skickats!',
    'Please ask the invitee to check for the invitation in their inbox.': 'Det kan vara en bra idé att säga från til din expartner att du har skickat dem en inbjudan till 2homes :)',
    'Update card details': 'Försök igen',
    'There is a problem with payment for your subscription': 'Det uppstod ett problem med betalningen',
    'Update credit card details to solve the problem': 'Har du angett rätt kreditkortsinformation?',
    'Subscribe': 'Prenumerera här',
    'You don\'t have permissions to invite {invitee}': 'Du måste uppgradera för att bjuda in andra {invitee}',
    'To access all the features of 2homes you have need a subscription. It costs only {amount},- per month.': 'För att få tillgång till alla funktioner i 2homes behöver du ett abonnemang. Det kostar bara {amount},- per månad.',
    'Note: If you created your subscription via AppStore it won’t be cancelled. You can cancel it in AppStore.': 'Obs! Om du skapade prenumerationen via App Store stoppas den inte automatiskt. Du måste också avsluta det i App Store.',
    'Cancel subscription': 'Avbryt prenumerationen',
    'Are you sure you want to cancel subscription?': 'Är du säker på att du vill avsluta prenumerationen?',
    'CANCEL SUBSCRIPTION': 'AVBRYT PRENUMERATIONEN',
    'Subscription created!': 'Betalningen lyckades!',
    'You can use 2homes to its fullest now!': 'Du har nu full tillgång till 2homes!',
    'Show walkthrough': 'Visa hjälptext',
    'Show notifications': 'Visa meddelanden',
    'Logout': 'Logga ut',
    'Profile': 'Profil',
    'Error occured': 'Ett fel inträffade',
    'You have unsaved data in event modal window. Are you sure you want to close it?': 'Du har osparade data i händelsens modalfönster. Är du säker på att du vill stänga det?',
    'Yes, delete': 'Ja, radera',
    'Select date': 'Välj datum',
    'Username is used for notifications and mentions. You can change it to how members of your family call you at home. Nickname should have between 2 and 10 characters.': 'Användarnamnet används för meddelanden och omnämnanden. Du kan ändra det till det namn dina familjemedlemmar kallar dig hemma. Smeknamn ska innehålla mellan 2 och 10 tecken.',
    'Something went wrong. Check the card details and try again.': 'Något gick fel. Kontrollera kortinformationen och försök igjen.',
    'Please enter card details': 'Ange kortuppgifterna',
    'Update credit card': 'Uppdatera ditt kreditkort',
    'Register credit card': 'Ange kreditkortsuppgifterna',
    'Your card will be charged': 'Kreditkortet debiteras med',
    'per month after the trial period.': 'per månad efter testperioden.',
    'every month': 'per månad',
    'Submit': 'Skicka inn',
    'Cancel': 'Avbryt',
    'only current': 'endast nuvarande',
    'only upcoming': 'endast kommande',
    'all': 'alla',
    'Edit recurring event': 'Redigera återkommande händelse',
    'for me': 'till mig',
    'from me': 'från mig',
    '+ add new': '+ lägg till ny',
    'new category': 'ny kategori',
    'Hours': 'Timmar',
    'Minutes': 'Minuter',
    'If you wish to use 2homes on your mobile, please download our app': 'För att använda 2homes på din mobil, ladda ner appen här',
    'Download 2homes app': 'Ladda ner 2homes app',
    'If you have already installed the app, please click here to open': 'Om du redan har installerat 2homes på din mobil, klicka här för att öppna den',
    'Open 2homes app': 'Öppna 2homes app',
    'Back': 'Tillbaka',
    'Next': 'Nästa',
    'Skip': 'Hoppa över',
    'Finish': 'Avsluta',
    'Could not activate your account.': 'Kunde inte aktivera ditt konto.',
    'You accepted an invitation.': 'Du accepterade en inbjudan.',
    'We have just activated your account.': 'Vi har just aktiverat ditt konto.',
    'Please': 'Var vänlig',
    'log in': 'logga in',
    'to enjoy using 2homes': 'för att använda 2homes',
    'Please wait for account activation.': 'Vänta på kontoaktivering.',
    'Activating account': 'Aktiverar konto',
    'Ready!': 'Klart!',
    'Already have an account?': 'Har du redan ett konto?',
    'Log in': 'Logga in',
    'Expenses and income': 'Utgifter och intäkter',
    '+ New scheduled transaction': '+ NY PLANERAD TRANSAKTION',
    'Are you sure you want to delete data?': 'Är du säker på att du vill radera data?',
    'Choose transaction to delete': 'Välj transaktion att radera',
    'Expenses': 'Utgifter',
    'Income': 'Intäkter',
    'Balance': 'Saldo',
    'Date': 'Datum',
    'Amount': 'Belopp',
    'By': 'Av',
    'Category': 'Kategori',
    'For': 'För',
    'Visibility': 'Synlighet',
    'Notes': 'Anteckningar',
    'Edit': 'Redigera',
    'Delete': 'Radera',
    'weekly': 'veckovis',
    'biweekly': 'varannan vecka',
    'monthly': 'månatlig',
    'private': 'privat',
    'public': 'offentlig',
    'Add': 'Lägg till',
    'expense': 'utgift',
    'income': 'inkomst',
    'on': 'den',
    'from': 'från',
    'Date of transaction': 'Datum för transaktion',
    'for': 'för',
    'total amount': 'totalt belopp',
    'Repeat': 'Upprepa',
    'Starts:': 'Startar:',
    'Ends:': 'Slutar:',
    'Edit recurring transaction': 'Redigera återkommande transaktion',
    '+ add a note': '+ lägg till en anteckning',
    'Save': 'Spara',
    'Select transaction reason and enter amount to save it.': 'Välj skäl till transaktionen och ange beloppet för att spara den.',
    'Please invite people to your family to see the calendar': 'Bjud in människor till din familj för att se kalendern',
    'Are you sure you want to delete an event?': 'Är du säker på att du vill radera en händelse?',
    'Choose event to delete': 'Välj händelse att radera',
    'add event': 'lägg till händelse',
    'minutes': 'minuter',
    'hours': 'timmar',
    'days': 'dagar',
    'Reminder': 'Råminnelse',
    'before the event': 'innan händelsen startar',
    '+ add reminder': '+ lägg till påminnelse',
    'Created by': 'Skapad av',
    'Only creators can edit events': 'Endast skapare kan redigera händelser',
    'event': 'händelse',
    'childcare': 'barnomsorg',
    'event name': 'händelsenamn',
    'date': 'datum',
    'Who also participates in the event?': 'Vem mer deltar i händelsen?',
    'Caretaker': 'Omsorgsperson',
    'download': 'ladda ner',
    'export to ICS': 'exportera till ICS',
    'print': 'skriv ut',
    'Selected calendar for': 'Vald kalender för',
    'And': 'Och',
    'Copy link': 'Kopiera länk',
    'Download file': 'Ladda ner fil',
    'daily': 'dagligen',
    'Start date is the same as date of the event': 'Startdatumet är detsamma som datumet för händelsen',
    'Week': 'Vecka',
    'day': 'dag',
    'today': 'idag',
    'all day': 'hela dagen',
    'Day': 'Dag',
    'Caring': 'Omsorg',
    'Event': 'Händelse',
    '\'s calendar': 's kalender',
    '\'s calendar of ': 's kalender för ',
    'Download': 'Ladda ner',
    'Editing not allowed': 'Redigering ej tillåtet',
    'Edit event': 'Redigera händelse',
    'Event contains notes': 'Händelsen innehåller anteckningar',
    'add childcare': 'lägg till barnomsorg',
    'Childcare': 'Barnomsorg',
    'Starts': 'Startar',
    'All day': 'Hela dagen',
    'Ends': 'Slutar',
    'Event end date has to be later than start date': 'Händelsens slutdatum måste vara senare än startdatumet',
    'Repeatable end date has to be later than start date': 'Upprepbart slutdatum måste vara senare än startdatum',
    'Please select at least one child for childcare event.': 'Välj minst ett barn för en barnomsorgshändelse.',
    'Profile information': 'Profilinformation',
    'first name': 'förnamn',
    'last name': 'efternamn',
    'nickname': 'smeknamn',
    'color': 'färg',
    'gender': 'kön',
    'male': 'man',
    'female': 'kvinna',
    'date of birth': 'födelsedatum',
    'email': 'e-post',
    'save changes': 'spara ändringar',
    'Your account has been deleted.': 'Ditt konto har raderats.',
    'Confirmation email has been sent to your email account.': 'Bekräftelse har skickats till ditt e-postkonto.',
    'HOMEPAGE': 'HEMSIDA',
    'file': 'fil',
    'folder': 'mapp',
    'Are you sure you want to delete {confirmModalType}?': 'Är du säker på att du vill radera {confirmModalType}?',
    'Please create at least one folder to upload files.': 'Vänligen skapa minst en mapp för att ladda upp filer.',
    'File': 'Fil',
    'Name': 'Namn',
    'Author': 'Författare',
    '+ New folder': '+ Ny mapp',
    'My folders': 'Mina mappar',
    'Shared folders': 'Delade mappar',
    'Visible only for you': 'Endast synligt för dig',
    'Visible for everyone': 'Synlig för alla',
    'You don\'t have permissions to edit folder': 'Du har inte lov att redigera mappen',
    'Folder is not empty or you don\'t have permissions to delete it': 'Mappen innehåller data eller så har du inte lov att radera den',
    'Visible': 'Synlig',
    'Not visible': 'Inte synlig',
    'folder name': 'mappnamn',
    'Folder': 'Mapp',
    'filename': 'filnamn',
    'Copied': 'Kopierad',
    'Copy the link to share the file': 'Kopiera länken för att dela filen',
    'You can send the copied link to anyone.': 'Du kan skicka den kopierade länken till vem som helst.',
    'Every person with the link can download the file.': 'Alla personer som har länken kan ladda ner filen.',
    'File is too large. Max file size is': 'Filen är för stor. Max filstorlek är',
    'Current relationship': 'Nuvarande relation',
    'Parents': 'Föräldrar',
    'Kids': 'Barn',
    'Previous relationships': 'Tidigare relationer',
    'Are you sure you want to delete the invitation?': 'Är du säker på att du vill radera denna inbjudan?',
    'Are you sure you want to change user visibility?': 'Är du säker på att du vill ändra användarens synlighet?',
    'Yes, change': 'Ja, ändra',
    'Blocked user': 'Blockerad användare',
    'me': 'mig',
    'Pending': 'Obesvarade',
    'Hide': 'Dölj',
    'Show': 'Visa',
    'visible': 'synlig',
    'hidden': 'dold',
    '+ Add family member': '+ Lägg till familjemedlem',
    'Caretakers': 'Omsorgspersoner',
    'Create kid\'s profile': 'Skapa barnets profil',
    'boy': 'pojke',
    'girl': 'flicka',
    'Continue': 'Fortsätt',
    'current partner': 'nuvarande partner',
    'previous partner': 'tidigera partner',
    'a caretaker': 'en omsorgsperson',
    'Invite': 'Bjud in',
    '+ add an invitation note': '+ lägg till en anteckning till inbjudan',
    'Send invitation': 'Skicka inbjudan',
    'Add new kid': 'Lägg till nytt barn',
    'I´d like to invite you to join 2homes, an app that aims to simplify the lives of part-time parents.': 'Jag vil bjuda in dig till 2homes, en app som syftar till att förenkla livet för oss som är deltidsföräldrar.',
    'Are you sure you want to delete the file?': 'Är du säker på att du vill radera denna fil?',
    '+ Add new transaction': '+ Lägg till ny transaktion',
    'History of': 'Historik för',
    'Split with': 'Dela med',
    'Status {status}': 'Status {status}',
    'Split costs': 'Dela kostnader',
    'pending': 'obehandlad',
    'Uploaded': 'Uppladdad',
    'attachments': 'bilagor',
    '+ add new file': '+ lägg till ny fil',
    'Request': 'Förfrågan',
    'to split 50/50': 'för att dela 50/50',
    'to pay the amount': 'för att betala beloppet',
    'date of transaction': 'datum för transaktionen',
    'Select transaction reason, receiver and enter amount to save it.': 'Välj skälet till transaktionen samt ange mottagare och belopp för att spara den.',
    'accepted / waiting for payment': 'godkänd/väntar på betalning',
    'Split request': 'Delningsförfrågan',
    'requested split costs with you': 'kostnader som begärts att delas med dig',
    'On': 'På',
    'Total amount': 'Totalt belopp',
    'Request for': 'Begäran om',
    'Attachment': 'Bilaga',
    'Note': 'Anteckning',
    'Accept': 'Godkänn',
    'Reject': 'Avvisa',
    'Status: ': 'Status: ',
    'Your response has been sent to requester.': 'Din respons har skickats till förfrågare.',
    'Please complete your profile': 'Gör klart din profil',
    'name': 'namn',
    'surname': 'efternamn',
    'Continue >': 'Fortsätt >',
    'Hi': 'Hej',
    'Welcome to 2homes!': 'Välkommen till 2homes!',
    'The app that simplifies life for part-time parents.': 'Appen som förenklar livet för deltidsföräldrar.',
    'Let us help you take the first steps.': 'Låt oss hjälpa dig att komma igång.',
    'Let\'s start!': 'Då börjar vi!',
    'Welcome': 'Välkommen tillbaka',
    'The registration process is complete!': 'Registreringsprocessen har slutförts!',
    'You can now use the 2homes app to its fullest!': 'Du kan nu använda appen 2homes till fullo!',
    'Explore 2homes app': 'Öppna 2homes app',
    'Files': 'Filer',
    'invite to 2homes kids': 'bjud in till 2homes kids',
    'Add first note-card': 'Lägg till första anteckningslapp',
    'Add note-card': 'Lägg till anteckningslapp',
    'Add new section': 'Lägg till nytt avsnitt',
    'Are you sure you want to delete this information?': 'Är du säker på att du vill radera denna information?',
    '2homes kids invitation': 'Inbjudan till 2homes kids',
    'Hi, You\'ve been invited to 2homes kids! Click on one of those links to download the app': 'Hej, du är inbjuden till 2homes Kids! Klicka på en av dessa länkar för att ladda ner appen',
    'If you are using iOS (Apple)': 'Om du har iOS (Apple) telefon',
    'If you are using Android': 'Om du har Android telefon',
    'AFTER or if you ALREADY have 2homes Kids app installed on your phone, click on this link to login to your account': 'EFTER nedladdning, eller om du REDAN har 2homes Kids appen installerad på din telefon, klicka på denna länk för att logga in',
    'Invitation link copied to clipboard': 'Inbjudningslänk kopierad till urklipp',
    'Make sure that your kid has 2homes Kids app installed. You can find them in App Store and Google Play.': 'Se till att ditt barn har laddat ner 2homes Kids innan du skickar inbjudan. Du kan hitta appen i App Store och Google Play.',
    'You can now share your link in the app of your choice or use the shortcuts below.': 'Du kan nu dela länken i den app du föredrar eller använda genvägen nedan.',
    'Send via email': 'Skicka via e-post',
    'Add new section for notes:': 'Lägg till nytt avsnitt för anteckningar:',
    'Change section name:': 'Ändra avsnittets namn:',
    'Add new note-card:': 'Lägg till ny anteckningslapp:',
    'Change note-card:': 'Ändra anteckningslapp:',
    'Change': 'Ändra',
    'Create': 'Skapa',
    'title': 'titel',
    'description': 'beskrivning',
    'Ready': 'Klar',
    'Create your password': 'Skapa ett lösenord',
    'Set new password': 'Ange nytt lösenord',
    'Log in here': 'Logga in här',
    'with your new password': 'med ditt nya lösenord',
    'Make sure that it contains at least 8 characters, including one capital letter and one number.': 'Kontrollera att det innehåller minst 8 tecken, inklusive en stor bokstav och en siffra.',
    'Passwords must be the same and the password has to have at least 8 characters': 'Lösenorden måste vara identiska, och lösenordet måste innehålla minst 8 tecken',
    'Select country': 'Välj land',
    'new password': 'nytt lösenord',
    'repeat password': 'upprepa lösenord',
    'I accept {Privacy Policy} and {Terms of Service}': 'Jag accepterar {Privacy Policy} och {Terms of Service}',
    'Privacy Policy': 'Sekretesspolicy',
    'Terms of Service': 'Användarvillkor',
    'I agree that you may use the information I have provided for the purposes necessary for 2homes services.': 'Jag samtycker till att ni får använda den information jag har gett för det ändamål som är nödvändigt för 2homes tjänster.',
    'We could not find a page you were looking for': 'Vi kunde inte finna en sida du sökte efter',
    'Notifications': 'Meddelanden',
    'mark selected as read': 'markera valda som lästa',
    'mark all as read': 'markera alla som lästa',
    'load more': 'hämta mer',
    'deleted the account': 'raderade kontot',
    'This action will affect your app in the following ways:': 'Denna åtgärd påverkar din app på följande sätt:',
    'His/her events were deleted': 'Hans/hennes händelser har raderats',
    'All of his/her sent invitations were cancelled': 'Alla hans/hennes inbjudningar har annullerats',
    'His/her documents shared with you were deleted': 'De dokument han/hon har delat med dig har raderats',
    'I UNDERSTAND': 'JAG FÖRSTÅR',
    'Type': 'Typ',
    'From': 'Från',
    'Message': 'Meddelande',
    'User': 'Användare',
    'Password recovery': 'Återställning av lösenord',
    'Don\'t have an account?': 'Har du inget konto?',
    'Create it here': 'Skapa ett här',
    'Please check your email for further instructions.': 'Se din e-post för ytterligare anvisningar.',
    'Let\’s make your new password more memorable this time:': 'Se till att skapa ett lösenord som är lättare att komma ihåg den här gången:',
    'Total expenses by category': 'Totala utgifter per kategori',
    'Total income by category': 'Total inkomst per kategori',
    'Split requests': 'Delningsförfrågningar',
    '(for me / from me)': '(till mig/från mig)',
    'Split req. from me': 'Delningsförfr. från mig',
    'Split req. for me': 'Delningsförfr. till mig',
    'Summaries': 'Sammanfattningar',
    'History of transactions': 'Historik över transaktioner',
    'Expected transactions': 'Förväntade transaktioner',
    'back to reports': 'tillbaka till rapporter',
    'back to budget': 'tillbaka till budget',
    'Accounting report': 'Bokföringsrapport',
    'Budget report': 'Budgetrapport',
    'General summaries': 'Generella sammanfattningar',
    'Expense': 'Utgift',
    'Details': 'Uppgifter',
    'Split': 'Delad',
    'Total expenses by category:': 'Totala utgifter per kategori:',
    'Total income by category:': 'Total inkomst per kategori:',
    'Create report': 'Skapa rapport',
    'Select source of data': 'Välj datakälla',
    'accounting': 'bokföring',
    'budget': 'budget',
    'of my': 'för min',
    'that are': 'som är',
    'coming from': 'kommer från',
    'category': 'kategori',
    'include transactions that are not assigned to anybody': 'inkludera transaktioner som inte har tilldelats till någon',
    'during the period': 'under perioden',
    'month, year': 'månad, år',
    'Add summary of split requests': 'Lägg till summering av delningsförfrågningar',
    'Show history of transactions': 'Visa transaktionshistorik',
    'Show expected transactions': 'Visa förväntade transaktioner',
    'yes': 'ja',
    'no': 'nej',
    'Download PDF report': 'Ladda ner PDF-rapport',
    'Start date has to be earlier than end date': 'Startdatum måste vara tidigare än slutdatum',
    'Please select at least one person': 'Välj minst en person',
    'Please select at least one income or expense category': 'Välj åtminstone en inkomst- eller utgiftskategori',
    'Hi, ': 'Hej, ',
    ' has shared the following file:': ' har delat följande fil:',
    'Click here to download': 'Klicka här för att ladda ner',
    'If you still don\'t have account on 2homes, just go to': 'Om du fortfarande inte har något konto på 2homes går du bara till',
    'and create a new one!': 'och skapar ett nytt!',
    'Someone': 'Någon',
    'Welcome back!': 'Välkommen tillbaka!',
    'password': 'lösenord',
    'Forgot your password?': 'Har du glömt ditt lösenord?',
    '3 months': '3 månader',
    '30 days': '30 dagars',
    'Create new account': 'Skapa nytt konto',
    'On it\'s way...': 'På väg...',
    'Please check your inbox': 'Kontrollera inkorgen',
    'to confirm your new account.': 'för att bekräfta ditt nya konto.',
    'A message should arrive within a couple of minutes.': 'Ett meddelande bör komma inom några minuter.',
    'Start your {trialLength} free trial period today': 'Börja din {trialLength} gratis provperiod idag',
    'First Name': 'Förnamn',
    'Email': 'E-post',
    'Password': 'Lösenord',
    'Already strong!': 'Redan starkt!',
    'country': 'land',
    'Start my free trial': 'STARTA GRATIS PROVPERIOD',
    'Change status to': 'Ändra status till',
    'paid': 'betald',
    'rejected': 'avvisad',
    'For me': 'Till mig',
    'From me': 'Från mig',
    'Paid': 'Betald',
    'Rejected': 'Avvisad',
    'Total': 'Totalt',
    'Requested by': 'Begärd av',
    'To': 'Till',
    'Status': 'Status',
    'Change password': 'Ändra lösenord',
    'Fill the form to change your password.': 'Fyll i formuläret för att ändra ditt lösenord.',
    'Enter old password': 'Ange det gamla lösenordet',
    'Enter new password': 'Ange nytt lösenord',
    'Repeat new password': 'Upprepa nytt lösenord',
    'New passwords must be the same': 'De nya lösenorden måste vara identiska',
    'Your password has been successfully changed': 'Ditt lösenord har ändrats',
    'Please fill the form': 'Vänligen fyll i formuläret',
    'Sorry to see you go…': 'Synd att du lämnar oss…',
    'Deleting 2homes account': 'Raderar 2homes-kontot',
    'Before you make a final decision, make sure you understand how account removal will result in the following actions:': 'Innan du fattar ett slutligt beslut är det viktigt att du är införstådd med att borttagandet av kontot resulterar i följande åtgärder:',
    'If you are sure, confirm by clicking the button below.': 'Om du är säker bekräftar du genom att klicka på nedanstående knapp.',
    'Your events will be deleted': 'Dina händelser kommer att raderas',
    'Your documents will be deleted': 'Dina dokument kommer att raderas',
    'Your private folders and their content will be deleted': 'Dina privata mappar och deras innehåll kommer att raderas',
    'All your transactions will be deleted': 'Alla dina transaktioner kommer att raderas',
    'All your sent invitations will be cancelled': 'Alla dina skickade inbjudningar kommer att annulleras',
    'Home without any active family members will be deleted': 'Hem utan aktiva familjemedlemmar kommer att raderas',
    'Children account with no caretaker assigned will be deleted': 'Barnkonton utan utsedd omsorgsperson kommer att raderas',
    'Your subscription will be canceled': 'Din prenumeration kommer att avbrytas',
    'You will be logged out immediately.': 'Du kommer omedelbart att loggas ut.',
    'Remember that this action cannot be reversed!': 'Kom ihåg att denna åtgärd inte kan ångras!',
    'Note: If you created your subscription via AppStore it won’t be cancelled. Remember to also cancel it in AppStore after deleting your 2homes account': 'Obs! Om du skapade prenumerationen via App Store stoppas den inte automatiskt. Kom också ihåg att avsluta det i App Store efter att du har tagit bort ditt 2homes-konto.',
    'DELETE MY ACCOUNT': 'RADERA MITT KONTO',
    'CANCEL': 'AVBRYT',
    'Subscription plan': 'Prenumerationsplan',
    'You are currently free trial user': 'Du är för närvarande gratis provanvändare',
    'You have cancelled subscription': 'Du har avslutat prenumerationen',
    'You have access to all features and functionalities. Your trial period ends:': 'Du har tillgång till alla menyer och funktioner. Din provperiod slutar:',
    'You will lose access to all features and functionalities after payment period. Your payment period ends:': 'Du kommer att förlora åtkomst till alla menyer och funktioner efter att prenumerationen har gått ut. Perioden slutar:',
    'renew subscription': 'förnya prenumerationen',
    'Subscription costs after trial:': 'Prenumerationsbelopp efter prov:',
    'Subscription costs:': 'Prenumerationspris:',
    'month': 'månad',
    'Payment method:': 'Betalningsmetod:',
    'edit': 'redigera',
    'Have a question? Contact as at': 'Har du några frågor? Kontakta oss på',
    'My profile': 'Min profil',
    'language': 'språk',
    'delete account': 'radera konto',
    'Something went wrong. Try again later.': 'Något gick fel. Kontrollera kortinformationen och försök igjen.',
    'The sections below show the members of your home. The first section shows your current and ex-partners. The second shows your children from current and previous relationships.': 'Dessa listor visar dina familjemedlemmar. De är indelade i två delar. Den första visar din nuvarande och dina tidigare partner, och den andra inkluderar barn från din nuvarande och dina tidigare relationer.',
    'Click here to add family members, including ex-partners, your current partner and kids.': 'Klicka här för att lägga till familjemedlemmar, inklusive expartners, din nuvarande partner och barn.',
    'You can switch between the views by using these filters. You can show all home members, only visible or only hidden.': 'Du kan växla mellan vyerna genom att använda dessa filter. Du kan visa samtliga av hushållets medlemmar, endast synliga eller endast dolda.',
    'This shows your current relationship. Click the \'+\' button to add members to your home.': 'Detta är din nuvarande relation. \nKlicka ‚+’ knappen för att lägga \ntill en medlem till relationen.',
    'This shows your previous relationships. Click the \'+\' button to add members to your home.': 'Detta visar dina tidigare relationer. Klicka på ´ + ´-knappen för att lägga till medlemmar i ditt hem.',
    'Click here to switch between day and week views. You can also quickly go to the current date by clicking the \'today\' button.': 'Klicka här för att växla mellan dags- och veckovyer. Du kan också snabbt gå till dagens datum genom att klicka på "idag”-knappen.',
    'Click here to change dates.': 'Klicka här för att ändra datum.',
    'You can filter events by selecting and deselecting family members.': 'Du kan filtrera händelser genom att välja och välja bort familjemedlemmar.',
    'You can create events by clicking on a calendar cell. In DAY view, you can Select the time range in a column to create the event. In WEEK view, you can create childcare or normal events by clicking the appropriate row in the day. Scroll down the calendar by first clicking in the column, between the navigation menu and the calendar.': 'Du kan skapa händelser genom att klicka på en kalendercell. I DAGSvyn kan du välja tidsintervall i en kolumn för att skapa händelsen. I VECKOvyn kan du skapa barnomsorgshändelser eller vanliga händelser genom att klicka på lämplig rad på dagen. Skrolla ner i kalendern genom att först klicka i kolumnen, mellan navigationsmenyn och kalendern.',
    'We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Vi begär att information som medicinsk information, politisk åsikt, ras, sexuella relationer och kriminella handlingar / historia inte delas.',
    'Click arrow buttons to switch the year. Click on month name to show its transactions.': 'Klicka på pilknapparna för att byta år. Klicka på månadens namn för att visa dess transaktioner.',
    'In this section you can see the summary of your income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.': 'I det här avsnittet kan du se summeringen av dina inkomster och utgifter. Vänster kolumn visar sammantagna data för hela året, medan höger kolumn endast visar data från den valda månaden.',
    'Click here to add new file to pending transactions.': 'Klicka här för att lägga till en ny fil till obehandlade transaktioner.',
    'Here you can see list of pending transactions. This is the list of files you uploaded for the selected month. You can edit each of them and add all required information, such as amount of money spent or the receiver of this cost/income. After editing, the record will be visible in a table with transactions, below pending files.': 'Här kan du se listan över obehandlade transaktioner. Detta är listan över filer du laddat upp för den valda månaden. Du kan redigera var och en av dem och lägga till all nödvändig information, till exempel hur mycket pengar som spenderats eller mottagaren av denna kostnad/inkomst. Efter redigeringen kommer posten att synas i en tabell med transaktioner, under obehandlade filer.',
    'You can add a new transaction by clicking this button. You will be able to attach a file to the new transaction after creating it.': 'Du kan lägga till en ny transaktion genom att klicka på den här knappen. Du kan bifoga en fil till den nya transaktionen efter att du skapat den.',
    'You can show only expenses or only income transactions by using these filters.': 'Du kan visa endast utgifter eller endast inkomsttransaktioner med hjälp av dessa filter.',
    'Here you can see the history of your transactions. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor. You can attach a file to every existing transaction.': 'Här kan du se din transaktionshistorik. Denna lista innehåller poster med all tillhandahållen information (transaktionstyp, belopp, mottagare etc.). Du kan se alternativ för redigering och radering av posten när du håller muspekaren över den. Du kan bifoga en fil till varje befintlig transaktion.',
    'Click arrow buttons to switch the year. Click on month name to show expected costs or income.': 'Klicka på pilknapparna för att byta år. Klicka på månadens namn för att visa förväntade kostnader eller förväntad inkomst.',
    'In this section you can see the summary of your expected income and expenses. The left column shows aggregated data for the whole year and right column - only data from selected month.': 'I det här avsnittet kan du se summeringen av dina förväntade inkomster och utgifter. Den vänstra kolumnen visar sammantagna data för hela året, medan den högra kolumnen endast visar data från den valda månaden.',
    'You can add new expected income and expenses by clicking this button.': 'Du kan lägga till nya förväntade inkomster och utgifter genom att klicka på den här knappen.',
    'You can show only expected expenses or expected income by using these filters.': 'Du kan visa endast förväntade utgifter eller förväntad inkomst genom att använda dessa filter.',
    'Here you can see all planned costs or income. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor.': 'Här kan du se alla planerade kostnader eller inkomster. Denna lista innehåller poster med all tillhandahållen information (transaktionstyp, belopp, mottagare etc.). Du kan se alternativ för redigering och radering av posten när du håller muspekaren över den.',
    'Here you can see the list of all split requests sent to you. You can see an attached file by clicking on file icon (if there is one). You can also edit a split request by clicking edit icon. Editing means that you can change the status of the selected split request.': 'Här kan du se listan över alla delningsförfrågningar som skickats till dig. Du kan se en bifogad fil genom att klicka på filikonen (om det finns en). Du kan också redigera en delningsförfrågan genom att klicka på redigeringsikonen. Redigering innebär att du kan ändra den valda delningsförfrågans status.',
    'Here you can see the list of all split requests created by you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.': 'Här kan du se listan över alla delningsförfrågningar som skapats av dig. Du kan se en bifogad fil genom att klicka på filikonen om det finns en. Du kan också redigera en delningsförfrågan genom att klicka på redigeringsikonen. Redigering innebär att du kan ändra den valda delningsförfrågans status.',
    'Here you can see the history of your split requests. This list contains only paid or rejected requests.': 'Här kan du se historiken över dina delningsförfrågningar. Denna lista innehåller endast betalda eller avvisade förfrågningar.',
    'You can filter split requests history by type or see them all at once.': 'Du kan filtrera delningsförfrågningar per typ eller se alla samtidigt.',
    'In this section you can create a report. It contains data from the Accounting or Budget modules. It will be generated in pdf format, so it is easy to download and view later.': 'I det här avsnittet kan du skapa en rapport. Den innehåller data från bokförings- eller budgetmodulen. Den kommer att genereras i pdf-format, så att den lätt kan laddas ner och visas senare.',
    'If you want to store files, you have to create at least one folder. Click \'NEW FOLDER\' button to create one.': 'Om du vill lagra filer måste du skapa minst en mapp. Klicka på "NY MAPP" för att skapa en.',
    'You can add files to this section by clicking this button. We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.': 'Du kan lägga till filer till det här avsnittet genom att klicka på den här knappen. Vi begär att information som medicinsk information, politisk åsikt, ras, sexuella relationer och kriminella handlingar / historia inte delas.',
    'Here you can see the list of all files assigned to the selected child. They will be visible only to you.': 'Här kan du se listan över alla filer som tilldelats det valda barnet. De kommer endast att vara synliga för dig.',
    'You can add sections with notes by clicking this button.': 'Du kan lägga till avsnitt med anteckningar genom att klicka på den här knappen.',
    'You can edit and delete a file by clicking the icon buttons. They are visible when you move the cursor over the element in the list.': 'Du kan redigera och radera en fil genom att klicka på ikonknapparna. De är synliga när du flyttar markören över elementen i listan.',
    'You can edit or delete the existing transaction. You can also create split request from expenses.': 'Du kan redigera eller radera den befintliga transaktionen. Du kan också skapa en delningsförfrågan från utgifter.',
    'Here you can see the list of folders created by you and visible only to you. You can edit the folder by clicking the edit icon or, if the folder is empty, delete it by clicking the delete icon. A folder that is not empty cannot be deleted.': 'Här kan du se listan över mappar som skapats av dig och endast är synliga för dig. Du kan redigera mappen genom att klicka på redigeringsikonen eller, om mappen är tom, radera den genom att klicka på radera-ikonen. En mapp som inte är tom kan inte raderas.',
    'Here you can see the list of folders shared with you. They are created by your current partner and visible to both of you.': 'Här kan du se listan över mappar som delas med dig. De har skapats av din nuvarande partner och är synliga för er båda.',
    'By clicking this button you can add files to the selected folder.': 'Genom att klicka på den här knappen kan du lägga till filer i den valda mappen.',
    'Here you can see the list of files in a selected folder. You can edit or delete the existing transaction. You can also share the file by clicking on the share-icon button, which is visible after you move the cursor over the element in the list. Sharing a file means that a special link will be generated. You can copy and send the link to anyone you wish. Every person with the link can see and download the file.': 'Här kan du se listan över filer i en vald mapp. Du kan redigera eller radera den befintliga transaktionen. Du kan också dela filen genom att klicka på dela-ikonknappen som är synlig när du flyttar markören över elementet i listan. Att dela en fil innebär att en särskild länk kommer att genereras. Du kan kopiera och skicka länken till vem du vill. Alla personer som har länken kan se och ladda ner filen.',
    'You can edit and delete a file by clicking on icon buttons, which are visible after you move the cursor over the element in the list.': 'Du kan redigera och radera en fil genom att klicka på ikonknapparna, som är synliga när du flyttar markören över elementet i listan.',
    'After creating a section you can still edit it or remove it if it is no longer needed.': 'När du har skapat ett avsnitt kan du fortfarande redigera det eller ta bort det om det inte längre behövs.',
    'You can add notes to the section by clicking this button.': 'Du kan lägga till anteckningar i avsnittet genom att klicka på den här knappen.',
    'You can edit or remove all notes created previously.': 'Du kan redigera eller radera alla anteckningar som skapats tidigare.',
  },
};

export const FALLBACK_LANGUAGE = 'en';
