import React from 'react';
import PropTypes from 'prop-types';

import { NotificationsTableRow } from './NotificationsTableRow';
import { isEdge } from './../../../utils/browser';

export const NotificationsTable = ({ messages, homeUsers, ...props }, { t: translate }) => {
  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell check" />
          <div className="tcell medium center date">{translate('Date')}</div>
          <div className="tcell left p-r-15 type">{translate('Type')}</div>
          <div className="tcell author">{translate('From')}</div>
          <div className="tcell message">{translate('Message')}</div>
          <div className="tcell hidden-small-res">{translate('Note')}</div>
          <div className="tcell" />
        </div>
      </div>
      <div className="tbody">
        {messages && messages.map((row, idx) => {
          return (<NotificationsTableRow key={idx} row={row} homeUsers={homeUsers} {...props} />);
        })}
      </div>
    </div>
  );
};


NotificationsTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NotificationsTable;
