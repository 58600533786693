import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components';

import FoldersList from './FoldersList';


export const Folders = ({ folders, folderActive, ...props }, { t: translate }) => {
  return (
    <div className="folders-list">
      <div className="m-b-30">
        <Button
          label={translate('+ New folder')}
          align="center"
          style={{ letterSpacing: '1px', padding: '0px 20px' }}
          onClick={() => props.editFolder('folder', {})}
          data-e2e="documents-folder-add-button"
        />
      </div>

      {folders.my && folders.my.length > 0 && (
        <div className="folders-created">
          <h4 className="center">{translate('My folders')}</h4>
          <FoldersList
            type="my"
            folders={folders.my}
            folderActive={folderActive}
            {...props}
          />
        </div>
      )}

      {folders.shared && folders.shared.length > 0 && (
        <div className="folders-created" id="shared-folders">
          <h4 className="center">{translate('Shared folders')}</h4>
          <FoldersList
            type="shared"
            folders={folders.shared}
            folderActive={folderActive}
            {...props}
          />
        </div>
      )}
    </div>
  );
};


Folders.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Folders;
