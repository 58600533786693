import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Input } from './../../components';


class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    const split = this.props.value && this.props.value.split(':');

    this.state = {
      value: this.props.value || '00:00',
      active: {
        hour: split[0] || '00',
        minute: split[1] || '00',
      },
      hours: [
        ['00', '01', '02', '03', '04', '05'],
        ['06', '07', '08', '09', '10', '11'],
        ['12', '13', '14', '15', '16', '17'],
        ['18', '19', '20', '21', '22', '23'],
      ],
      minutes: [
        ['00', '05', '10'],
        ['15', '20', '25'],
        ['30', '35', '40'],
        ['45', '50', '55'],
      ],
      opened: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.open !== this.props.open || nextProps.value !== this.props.value) {
      const newSplit = nextProps.value && nextProps.value.split(':');
      this.setState({
        opened: nextProps.open,
        value: nextProps.value,
        active: {
          hour: newSplit[0] || '00',
          minute: newSplit[1] || '00',
        },
      });
    }
  }

  setActive = (type, value) => {
    const { active } = this.state;
    active[type] = value;

    const updatedValue = `${active.hour}:${active.minute}`;

    this.setState({
      active,
      value: updatedValue,
    }, () => {
      this.props.onChange(updatedValue);
    });
  }

  handleOpen = e => {
    const { disabled, onOpen } = this.props;
    if(e.target.className && e.target.className.indexOf('time-picker-input') > -1 && !disabled) {
      const { opened } = this.state;
      onOpen(opened);
    }
  }

  render() {
    const { value, active, hours, minutes, opened } = this.state;
    const { className, disabled, onChange, popperPlacement } = this.props;
    const { t: translate } = this.context;

    return (
      <div className={classnames('time-picker-container', { className })} onClick={this.handleOpen}>
        <Input type="text" name="time-picker" className="time-picker-input" value={value} onChange={() => onChange(value)} disabled={disabled} />

        {opened && (
          <div className={classnames('time-picker', { [popperPlacement]: popperPlacement })}>
            <div className="time-picker-content">
              <div className="hours">
                <label>{translate('Hours')}</label>
                {hours.map((hourRow, hrIdx) => (
                  <div key={hrIdx} className="tp-row">
                    {hourRow.map((hour, hIdx) => (
                      <div key={hIdx} className={`tp-cell ${active.hour === hour ? 'active' : ''}`} onClick={() => this.setActive('hour', hour)}>{hour}</div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="minutes">
                <label>{translate('Minutes')}</label>
                {minutes.map((minuteRow, mrIdx) => (
                  <div key={mrIdx} className="tp-row">
                    {minuteRow.map((minute, mIdx) => (
                      <div key={mIdx} className={`tp-cell ${active.minute === minute ? 'active' : ''}`} onClick={() => this.setActive('minute', minute)}>{minute}</div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}


TimePicker.contextTypes = {
  t: PropTypes.func,
};

export default TimePicker;
