import moment from 'moment';

import { DEFAULT_DATE_FORMAT, formatFinancialUrlDate } from '../../utils/date';
import { api, BASE_URL } from '../../utils/net';
import { setAmount } from '../../utils/tools';

import { fetchTagKeys } from './tags';
import {
  SET_FINANCIAL_FILTER_DATE,
  SET_FINANCIAL_ACTIVE_DATE,
  SET_FINANCIAL_DATA_SUCCESS,
  SAVE_FINANCIAL_PENDING_FILE_SUCCESS,
  SAVE_FINANCIAL_DATA_SUCCESS,
  DELETE_FINANCIAL_DATA_SUCCESS,
  SAVE_SPLIT_REQUEST_SUCCESS,
  SET_SPLIT_REQUEST_SUCCESS,
  CONFIRM_SPLIT_REQUEST_SUCCESS,
  SET_SPLIT_REQUESTS_SUCCESS,
  CHANGE_SPLIT_REQUESTS_SUCCESS,
  ADD_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function setFilterDate(date) {
  return {
    type: SET_FINANCIAL_FILTER_DATE,
    payload: date,
  };
}

export function setActiveDate(date) {
  return {
    type: SET_FINANCIAL_ACTIVE_DATE,
    payload: date,
  };
}

export function setFinancialDataSuccess(data) {
  return {
    type: SET_FINANCIAL_DATA_SUCCESS,
    payload: data,
  };
}

export function saveFinancialPendingFileSuccess(data) {
  return {
    type: SAVE_FINANCIAL_PENDING_FILE_SUCCESS,
    payload: data,
  };
}

export function saveFinancialDataSuccess(data) {
  return {
    type: SAVE_FINANCIAL_DATA_SUCCESS,
    payload: data,
  };
}

export function deleteFinancialPendingFileSuccess(file) {
  return {
    type: DELETE_FINANCIAL_DATA_SUCCESS,
    payload: file,
  };
}

export function saveSplitRequestSuccess(data) {
  return {
    type: SAVE_SPLIT_REQUEST_SUCCESS,
    payload: data,
  };
}

export function setSplitRequestSuccess(data) {
  return {
    type: SET_SPLIT_REQUEST_SUCCESS,
    payload: data,
  };
}

export function confirmSplitRequestSuccess() {
  return {
    type: CONFIRM_SPLIT_REQUEST_SUCCESS,
  };
}

export function setSplitRequestsSuccess(data) {
  return {
    type: SET_SPLIT_REQUESTS_SUCCESS,
    payload: data,
  };
}

export function changeSplitRequestStatusSuccess(splitRequest) {
  return {
    type: CHANGE_SPLIT_REQUESTS_SUCCESS,
    payload: splitRequest,
  };
}

export function addAttachmentSuccess(transactionId, file) {
  return {
    type: ADD_ATTACHMENT_SUCCESS,
    payload: { transactionId, file },
  };
}

export function removeAttachmentSuccess(transactionId, file) {
  return {
    type: REMOVE_ATTACHMENT_SUCCESS,
    payload: { transactionId, file },
  };
}


export const fetchFinancialData = (date, filter, reCall = false) => async dispatch => {
  dispatch(setActiveDate(date));
  dispatch(showLoader());

  try {
    const formattedDate = formatFinancialUrlDate(date);
    const { data } = await api.get(`${BASE_URL}accountings/${formattedDate}`);
    dispatch(setFinancialDataSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchFinancialData(date, filter, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('finances', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const deletePendingFile = (file, date) => async dispatch => {
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}transactions/${file.id}`);
    dispatch(deleteFinancialPendingFileSuccess(file));
    dispatch(hideLoader());
    dispatch(fetchTagKeys());
    dispatch(fetchFinancialData(date, 'all', false));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  }
};


export const savePendingFile = (date, file, fileType) => async dispatch => {
  dispatch(showLoader());

  const formattedDate = formatFinancialUrlDate(date);

  const formdata = new FormData();
  formdata.append('date', moment(date).format(DEFAULT_DATE_FORMAT));
  formdata.append('type', 'pending_transaction');
  formdata.append('filename', file.name);
  formdata.append('filetype', fileType);
  formdata.append('file', file);

  try {
    const response = await api.useFetch(
      `${BASE_URL}accountings/${formattedDate}/transactions`,
      'POST',
      formdata,
      { 'Content-Type': 'multipart/form-data' },
    );

    const data = await response.json();

    dispatch(saveFinancialPendingFileSuccess(data[0]));
    dispatch(hideLoader());
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  }
};


export const saveFinanceData = (data, date, method) => async dispatch => {
  dispatch(showLoader());

  try {
    const params = {
      type: data.type,
      tag_key: data.tag_key,
      amounts: setAmount(data.total_amount, data.user_ids.length),
      user_ids: data.user_ids,
      note: data.note,
      access: data.access,
      date: moment(data.transaction_date).format(DEFAULT_DATE_FORMAT),
    };

    let methodName = '';
    let url = '';
    if(method === 'add') {
      const formattedDate = formatFinancialUrlDate(date);
      methodName = 'post';
      url = `${BASE_URL}accountings/${formattedDate}/transactions`;
    } else {
      methodName = 'patch';
      url = `${BASE_URL}transactions/${data.id}`;
    }

    const response = await api[methodName](url, params);
    const newFinancialData = response.data;
    dispatch(saveFinancialDataSuccess(newFinancialData));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const saveSplitRequest = (data, date) => async dispatch => {
  dispatch(showLoader());

  try {
    const params = {
      split: setAmount(data.split_amount, 0, true),
      recipient_id: data.split_caretaker_id,
    };

    const url = `${BASE_URL}transactions/${data.id}/split`;
    await api.post(url, params);

    dispatch(saveSplitRequestSuccess(data));
    dispatch(hideLoader());
    dispatch(fetchFinancialData(date, 'all', false));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message.split[0]));
  }
};


export const fetchSplitRequestData = id => async dispatch => {
  dispatch(showLoader());

  try {
    const url = `${BASE_URL}transactions/${id}/show_expense`;
    const { data } = await api.get(url);
    dispatch(setSplitRequestSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const confirmSplitRequest = (id, confirmed) => async dispatch => {
  dispatch(showLoader());

  try {
    const url = `${BASE_URL}transactions/${confirmed ? 'accept' : 'reject'}/${id}`;
    await api.get(url);
    dispatch(confirmSplitRequestSuccess());
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const fetchSplitRequestsData = () => async dispatch => {
  dispatch(showLoader());

  try {
    const url = `${BASE_URL}split_requests`;
    const { data } = await api.get(url);
    dispatch(setSplitRequestsSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('finances', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const changeSplitRequestStatus = splitRequest => async dispatch => {
  dispatch(showLoader());

  try {
    const url = `${BASE_URL}split_requests/${splitRequest.id}`;
    await api.patch(url, {
      status: splitRequest.status,
    });
    dispatch(changeSplitRequestStatusSuccess(splitRequest));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during saving data');
    dispatch(setError('finances', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const addAttachment = (transactionId, file, fileType) => async dispatch => {
  const formdata = new FormData();
  formdata.append('filename', file.name);
  formdata.append('filetype', fileType);
  formdata.append('file', file);

  try {
    const response = await api.useFetch(
      `${BASE_URL}transactions/${transactionId}/file`,
      'POST',
      formdata,
      { 'Content-Type': 'multipart/form-data' },
    );

    const data = await response.json();

    dispatch(addAttachmentSuccess(data[0]));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during adding attachment');
    dispatch(setError('finances', message));
  }
};


export const removeAttachment = (transactionId, file) => async dispatch => {
  try {
    await api.delete(`${BASE_URL}transactions/${transactionId}/file`);
    dispatch(removeAttachmentSuccess(transactionId, file));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during removing attachment');
    dispatch(setError('finances', message));
  }
};
