/* eslint no-alert: 0, no-unused-vars: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';


const Modal = ({ children, small, ...props }, { t: translate }) => {
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      maxHeight: (window.innerHeight - 120 > 800) ? 800 : window.innerHeight - 120,
      width: small ? '' : '55vw',
      minWidth: '620px',
    },
  };

  const closeModalConfirm = () => {
    props.closeModal();

    // THIS WILL BE DISABLED SINCE WE HAVE NICER CONFIRM MODAL
    // if(props.dataChanged) {
    //     const confirmed = window.confirm(translate('You have unsaved data in event modal window. Are you sure you want to close it?'));
    //     if(confirmed) {
    //         props.closeModal();
    //     }
    // } else {
    //     props.closeModal();
    // }
  };

  return (
    <ReactModal
      shouldCloseOnOverlayClick
      isOpen={props.isOpen}
      contentLabel={`${props.label} Modal`}
      style={modalStyles}
      onRequestClose={closeModalConfirm}
      ariaHideApp={false}
    >
      <span className="pointer modal-close" onClick={closeModalConfirm}>
        <i className="zmdi zmdi-close" />
      </span>
      {children}
    </ReactModal>
  );
};


Modal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Modal;
