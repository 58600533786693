import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ alt, type, clickable }) => {
  return (
    <div className={`logo ${type}`}>
      {clickable ? (
        <Link to="/">
          <div className="logo-img" title={alt} />
        </Link>
      ) : (
        <a name="logo">
          <div className="logo-img" title={alt} />
        </a>
      )}
    </div>
  );
};


export default Logo;
