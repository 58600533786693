import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';

import 'cropperjs/dist/cropper.css';


class FileCropper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.image = null;
  }

  componentDidMount() {
    this.cropper = new Cropper(this.image);
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.src && prevProps.src !== this.props.src) {
      this.cropper.reset().clear().replace(this.props.src);
    }
  }

  componentWillUnmount() {
    if(this.image) {
      this.cropper.destroy();
      this.image = null;
      this.cropper = null;
    }
  }

  render() {
    const { handleUploadFile, src, uploadButtonLabel, uploadButtonStyle } = this.props;

    return (
      <div className="CropperModalContainer">
        <div className="ImageContainer" style={{ height: 500, width: 800 }}>
          <img
            ref={imgRef => { this.image = imgRef; }}
            src={src}
            style={{ display: 'none' }}
          />
        </div>

        <div className="ButtonContainer">
          <button
            type="button"
            onClick={() => handleUploadFile(this.cropper)} className="UploadButton"
            style={uploadButtonStyle}
          >
            {uploadButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}

FileCropper.propTypes = {
  handleUploadFile: PropTypes.func.isRequired,
  src: PropTypes.string,
  uploadButtonLabel: PropTypes.string,
  uploadButtonStyle: PropTypes.object,
};

export default FileCropper;
