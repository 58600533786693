import { setLanguage } from 'redux-i18n';
import moment from 'moment';
import 'moment/locale/nn';
import 'moment/locale/en-gb';
import { push } from 'connected-react-router';
import { isMobile } from 'src/utils/browser';
import { MODAL_NAMES } from 'src/utils/modals';
import { api, BASE_URL } from 'src/utils/net';
import { PERMISSION } from 'src/utils/permissions';
import * as URL from 'src/utils/urls';

import { showLoader, hideLoader } from './loading';
import { setOperationSuccess, cleanOperation } from './operations';
import {
  SIGNIN_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNOUT,
  SET_NEW_PASSWORD_SUCCESS,
  SET_RECOVER_PASSWORD_SUCCESS,
  SET_USER_FIRST_STEPS_NEEDED,
} from './../types';
import { setError } from './errors';
import { showModal } from './layout';


export function signinSuccess(user) {
  return {
    type: SIGNIN_SUCCESS,
    payload: user,
  };
}


export function signupSuccess() {
  return {
    type: SIGNUP_SUCCESS,
  };
}

export function signoutSuccess() {
  return {
    type: SIGNOUT,
  };
}


export function setNewPasswordSuccess() {
  return {
    type: SET_NEW_PASSWORD_SUCCESS,
  };
}


export function setRecoverPasswordSuccess() {
  return {
    type: SET_RECOVER_PASSWORD_SUCCESS,
  };
}

export function setUserFirstStepsNeeded() {
  return {
    type: SET_USER_FIRST_STEPS_NEEDED,
  };
}


export const signinUser = ({ email, password }) => async(dispatch, getState) => {
  dispatch(showLoader());

  try {
    const nextPath = api.getQueryParams('next');
    const client_token = api.getClientToken();
    const params = {
      email,
      password,
      client_token,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const { data } = await api.post(BASE_URL + 'authentication', params);

    if(typeof data === 'object' && typeof data.auth_token !== 'undefined') {
      const { user, auth_token, reset_token } = data;
      const hasDelayedPayment = user?.permission === PERMISSION.PAY_DELAYED;

      user.first_steps = !user.birthdate;
      user.avatar = user.file;

      if(user.first_steps) {
        dispatch(setUserFirstStepsNeeded());
      }
      dispatch(signinSuccess(user));

      const { i18nState } = getState();
      const language = user.language || (i18nState || {}).lang || 'en';

      if(language) {
        moment.locale(language);
        dispatch(setLanguage(language));

        if(language === 'nn') {
          moment.updateLocale('nn', {
            weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
            weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
          });
        }
      }

      api.setUser(user);
      api.setEmail(email);
      api.setAuthToken(auth_token);
      api.setResetToken(reset_token);

      dispatch(hideLoader());

      if(nextPath && nextPath !== '%2Fdelete-success') {
        const redirectionPath = decodeURIComponent(nextPath);
        dispatch(push(redirectionPath));
      } else {
        if(user.first_steps) {
          dispatch(push('/first-steps/1'));
        } else {
          const path = isMobile() ? '/first-steps/3' : '/home';
          dispatch(push(path));
        }
      }

      if(hasDelayedPayment) dispatch(showModal({ name: MODAL_NAMES.PAYMENT_PROBLEM_INFO }));
    } else {
      dispatch(setError('signin', 'Cannot sign in'));
    }
  } catch(error) {
    let message = 'Incorrect email / password';
    if(error.response && error.response.data) {
      message = error.response.data.errors;
    }
    dispatch(setError('signin', message));
  }
};


export const signupUser = ({ firstName, email, password, language, country }) => async dispatch => {
  dispatch(cleanOperation('signup'));
  dispatch(showLoader());

  try {
    const payload = {
      first_name: firstName,
      email,
      password,
      language,
      country_code: country,
      redirect_url: api.getAppHost() + 'activation',
    };

    const response = await api.post(BASE_URL + 'users', payload);
    if (typeof response.data === 'object') {
      dispatch(signupSuccess(payload));
      dispatch(setLanguage(language || 'en'));
      moment.locale(language || 'en');

      if(language === 'nn') {
        moment.updateLocale('nn', {
          weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
          weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
        });
      }

      dispatch(setOperationSuccess('signup'));
      dispatch(hideLoader());
    } else {
      dispatch(setError('signup', 'Cannot register user'));
    }
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Cannot register user', {
      422: 'User with given email address is already registered',
    });
    dispatch(setError('signup', message));
  }
};


export const signoutUser = isAccountDeleted => async dispatch => {
  const path = isAccountDeleted ? '/delete-success' : '/';
  api.logout();
  dispatch(push(path));
  dispatch(signoutSuccess());
};


export const setNewPassword = ({ newPassword }) => async dispatch => {
  dispatch(showLoader());

  try {
    const token = api.getQueryParams().token;
    const password = newPassword;
    await api.patch(BASE_URL + 'password_reset', { token, password });
    api.logout();
    dispatch(setNewPasswordSuccess());
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during setting new password. Please retype them.');
    dispatch(setError('newpassword', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const changePassword = ({ oldPassword, newPassword }) => async dispatch => {
  dispatch(showLoader());

  try {
    await api.post(BASE_URL + 'change_password', { oldPassword, newPassword });
    dispatch(setNewPasswordSuccess());
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during changing password. Please try again.');
    dispatch(setError('newpassword', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const recoverPassword = ({ email }) => async dispatch => {
  dispatch(showLoader());

  try {
    const redirectUrl = URL.getAppHost() + 'new-password';
    await api.post(BASE_URL + 'password_reset', { email, redirect_url: redirectUrl });
    api.logout();
    dispatch(setRecoverPasswordSuccess());
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during reseting password. Please try again.');
    dispatch(setError('passwordrecover', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const invitationConfirmation = (invitation_id, invitee_id, password, country_code, language) => async dispatch => {
  try {
    const token = api.getQueryParams().token;
    const client_token = api.getClientToken();
    await api.put(`${BASE_URL}invitation_confirmations/${invitation_id}`, {
      invitee_id,
      password,
      token,
      client_token,
      country_code,
      language,
    });
    api.logout();
  } catch(error) {
    const message = api.getRequestErrorMessage(error, 'Error during setting password. Please try again.');
    dispatch(setError('invitation', message));
  }
};
