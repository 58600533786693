import {
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NEW_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS_READ_SUCCESS, CLEAR_NEW_NOTIFICATIONS,
} from './../types';


const initialState = {
  countNew: 0,
  countTotal: 0,
  messages: [],
};


export function notificationsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      const { data, page } = action.payload;
      let updatedMessages = [];
      if(page === 1) {
        updatedMessages = [
          ...data.notifications,
        ];
      } else {
        updatedMessages = [
          ...state.messages,
          ...data.notifications,
        ];
      }

      return Object.assign({}, state, {
        countTotal: data.total_amount,
        messages: updatedMessages,
      });

    case FETCH_NEW_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        countNew: action.payload.count,
      });

    case SET_NOTIFICATIONS_READ_SUCCESS:
      const { messages } = state;
      const ids = action.payload.map(message => message.id);
      messages.map(message => {
        if(ids.indexOf(message.id) > -1) {
          message.status = 'read';
        }
        return message;
      });
      return Object.assign({}, state, {
        messages,
      });

    case CLEAR_NEW_NOTIFICATIONS:
      return Object.assign({}, state, {
        countNew: initialState.countNew,
      });

    default:
      return state;
  }
}
