export default (state = false, action) => {
  const newState = {
    LOCATION_CHANGE: true,
    LOCATION_CHANGE_NO_LOADER: false,
    LOADER_SHOW: true,
    LOADER_HIDE: false,

    ERROR: false,
  };
  return typeof newState[action.type] !== 'undefined' ? newState[action.type] : state;
};
