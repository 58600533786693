import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchCalendarEvents } from '../../../store/actions/calendar';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import CalendarPrint from '../components/print/CalendarPrint';

import * as pdfHelper from './../../../utils/pdf';
import * as DateHelper from './../../../utils/date';


class CalendarPrintContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: {},
      calendarUser: {},
      week: '',
      datesInWeek: [],
      loading: true,
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    const { calendar, match } = this.props;
    const { startDate } = match.params;

    this.prepareEventsDates();

    if(calendar.viewType === 'day') {
      await this.props.fetchCalendarEvents(startDate, 'week');
      this.prepareEventsDataForCalendar();
    } else {
      this.prepareEventsDataForCalendar();
    }
  }

  prepareEventsDates = () => {
    const { startDate } = this.props.match.params;
    const dateRanges = DateHelper.getDateRanges(startDate, 'week');

    const datesInWeek = [];
    for(let i = 0; i < 7; i++) {
      datesInWeek.push(moment(dateRanges.start_date).add(i, 'days').format('YYYY-MM-DD'));
    }

    const events = {};
    datesInWeek.forEach(day => {
      events[day] = {
        date: day,
        caring: [],
        details: [],
      };
    });

    let week = 'week ';
    week += moment(startDate).format('w, YYYY');
    const weekStart = moment(dateRanges.start_date).format('D MMM.');
    const weekEnd = moment(dateRanges.end_date).format('D MMM.');
    week += ` (${weekStart} - ${weekEnd})`;

    this.setState({
      events,
      week,
      datesInWeek,
    });
  }

  prepareEventsDataForCalendar = () => {
    const { calendar, match, homeUsers } = this.props;
    const { userId, action } = match.params;
    const userEvents = calendar.usersEvents.find(userEvent => userEvent.id === userId);

    if(userEvents) {
      const calendarUser = {
        firstName: userEvents.first_name,
        lastName: userEvents.last_name,
        id: userEvents.id,
      };

      const { events } = this.state;
      if(userEvents.calendar_events.length) {
        userEvents.calendar_events.map(userEvent => {
          const formattedStartTime = userEvent.start_time && userEvent.start_time.substr(0, 5);
          const formattedEndTime = userEvent.end_time && userEvent.end_time.substr(0, 5);
          const caretaker = userEvent.caretaker_ids.length > 0 && homeUsers[userEvent.caretaker_ids[0]];
          const kids = userEvent.kid_ids.map(kidId => homeUsers[kidId]);

          this.prepareEvent(caretaker, kids, userEvent, formattedStartTime, formattedEndTime);
        });
      }

      this.setState({
        events,
        calendarUser,
      });
    }

    setTimeout(() => {
      this.print(action);
    }, 1000);
  }

  prepareEvent = (caretaker, kids, userEvent, formattedStartTime, formattedEndTime) => {
    const { datesInWeek } = this.state;
    datesInWeek.forEach(day => {
      if(moment(day).isSameOrAfter(userEvent.start_date) && moment(day).isSameOrBefore(userEvent.end_date)) {
        if(userEvent.start_date === day && userEvent.end_date === day) {
          if(!userEvent.long_term) {
            this.setDetails(day, userEvent, formattedStartTime, formattedEndTime);
          } else {
            this.setCaring(day, caretaker, kids, formattedStartTime, formattedEndTime, false);
          }
        } else if(userEvent.start_date !== day && userEvent.end_date === day) {
          if(!userEvent.long_term) {
            this.setDetails(day, userEvent, '00:00', formattedEndTime);
          } else {
            this.setCaring(day, caretaker, kids, '...', formattedEndTime, false);
          }
        } else if(userEvent.start_date === day && userEvent.end_date !== day) {
          if(!userEvent.long_term) {
            this.setDetails(day, userEvent, formattedStartTime, '23:59');
          } else {
            this.setCaring(day, caretaker, kids, formattedStartTime, '...', false);
          }
        } else if(userEvent.start_date !== day && userEvent.end_date !== day) {
          if(!userEvent.long_term) {
            this.setDetails(day, userEvent, '00:00', '23:59');
          } else {
            this.setCaring(day, caretaker, kids, '...', '...', true);
          }
        }
      }
    });
  }

  setDetails = (date, userEvent, startTime, endTime) => {
    const { events } = this.state;
    const { homeUsers } = this.props;
    const details = {
      times: [startTime, endTime],
      title: userEvent.title,
      participants: userEvent.participant_ids.map(participant => homeUsers[participant]),
      notes: userEvent.note,
    };
    events[date].details.push(details);

    this.setState({
      events,
    });
  }

  setCaring = (date, caretaker, kids, startTime, endTime, allDay) => {
    const { events } = this.state;
    events[date].caring.push({
      caretaker,
      kids,
      allDay,
      startTime,
      endTime,
    });

    this.setState({
      events,
    });
  }

  sortEvents = eventsList => {
    if(eventsList.length > 0) {
      eventsList.forEach(eventDay => {
        eventDay.caring.sort((a, b) => {
          if(a.startTime === '...' || b.startTime === '...') return a.startTime > b.startTime ? 1 : -1;
          if(a.startTime !== '...' && b.startTime !== '...' && a.endTime !== '...' && b.endTime !== '...') return b.startTime < a.startTime ? -1 : 1;
          if(a.startTime !== '...' && b.startTime === '...') return b.startTime > a.startTime ? 1 : -1;
          return a.startTime < b.startTime ? -1 : 1;
        });
        eventDay.details.sort((a, b) => {
          return b.times[0] < a.times[0] ? 1 : (b.times[0] > a.times[0] ? -1 : 0);
        });
      });
    }
    return eventsList;
  }

  print = action => {
    if(action === 'download') {
      pdfHelper.print('page', '2homes_calendar').then(() => {
        this.setState({
          loading: false,
        });
      });
    } else {
      this.setState({
        loading: false,
      }, () => {
        window.print();
      });
    }
  }

  render() {
    const { calendarUser, events, week, loading } = this.state;
    const eventsList = this.sortEvents(Object.values(events));

    const pages = pdfHelper.preparePagesForPrinting('day-row', 225);

    return (
      <div>
        <div className="CalendarPrint Print" id="CalendarPrint">
          <CalendarPrint
            loading={loading}
            calendarUser={calendarUser}
            events={eventsList}
            week={week}
            pages={pages}
          />
        </div>

        <div className={`loading ${loading ? 'loading--visible' : ''}`}>
          <div className="loading__overlay">
            <div className="loader">
              <div className="b1">
                <span className="top left" />
                <span className="top right" />
                <span className="bottom left" />
                <span className="bottom right" />
              </div>
              <div className="b2">
                <span className="top left" />
                <span className="top right" />
                <span className="bottom left" />
                <span className="bottom right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


CalendarPrintContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.loading,
    calendar: state.calendar,
    homeUsers: state.homeUsers,
  };
};

const mapDispatchToProps = {
  fetchCalendarEvents,
  fetchHomesIfNeeded,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarPrintContainer);
