import React from 'react';
import  ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Loader, UploaderModal } from './components';

import './ReactFileUploader.scss';


const getFileType = fileName => {
  const splitedUrl = (fileName && fileName.split('.') || []);
  const fileType = splitedUrl.pop();

  return fileType;
};

class ReactFileUploader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileType: 'other',
      modalOpened: false,
      showLoader: false,
      url: '',
    };

    this.fileInput = null;
  }

  cropImage = cropper => {
    this.setState({ showLoader: true }, () => {
      const url = cropper.getCroppedCanvas().toDataURL();

      cropper.getCroppedCanvas().toBlob(blob => {
        const file = new File([blob], this.state.file.name, { type: this.state.file.type });

        this.setState({ file, url }, () => {
          this.handleUploadFile();
        });
      });
    });
  }

  openModal = () => { this.setState({ modalOpened: true }); }

  closeModal = () => {
    this.setState({ modalOpened: false, showLoader: false, url: '' }, () => {
      this.fileInput.value = '';
    });
  }

  handleChange = async event => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    const imageExtensions = ['jpg', 'jpeg', 'png'];

    const fileName = event.target.files[0].name;
    const fileType = getFileType(fileName);
    const isImage = imageExtensions.indexOf(fileType) >= 0;

    this.setState({
      file,
      fileType: isImage ? 'image' : 'other',
      url,
    }, () => {
      if(isImage) {
        this.openModal();
      } else {
        this.handleUploadFile();
      }
    });
  }

  handleUploadFile = () => {
    this.setState({ showLoader: true }, async() => {
      const { file, fileType } = this.state;

      await this.props.handleUploadFile(file, fileType);
      this.closeModal();
    });
  }

  renderLoader = () => {
    if(this.props.customLoader) { return this.props.customLoader; }

    return <Loader />;
  }

  render() {
    const { accept, children, label = 'Click to upload file', style = {}, uploadButtonLabel = 'Upload file' } = this.props;
    const { showLoader, url, modalOpened } = this.state;

    return (
      <div className="ReactFileUploader">
        {modalOpened && <div className="CropperModalBackground" onClick={this.closeModal} />}

        <label className={children ? 'Label' : 'Label Default'} style={style.inputLabelStyle}>
          {!children && label}
          {children}
          <input
            accept={accept}
            className="UploadInput"
            onChange={e => this.handleChange(e)}
            ref={ref => { this.fileInput = ref; }}
            type="file"
          />
        </label>

        {modalOpened && ReactDOM.createPortal((
          <UploaderModal
            closeModal={this.closeModal}
            handleUploadFile={this.cropImage}
            uploadButtonStyle={style.uploadButtonStyle}
            uploadButtonLabel={uploadButtonLabel}
            url={url}
          />
        ), document.querySelector('#uploader-modal'))}

        {showLoader && this.renderLoader()}
      </div>
    );
  }
}

ReactFileUploader.propTypes = {
  accept: PropTypes.string,
  customLoader: PropTypes.any,
  handleUploadFile: PropTypes.func.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  uploadButtonLabel: PropTypes.string,
};

export default ReactFileUploader;

