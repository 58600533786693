import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from '../../../components';


export const NotificationsModal = ({ modalOpened, toggleModal, userName }, { t: translate }) => {
  return (
    <Modal
      isOpen={modalOpened}
      label={'Notifications'}
      closeModal={() => toggleModal()}
    >
      <div className="NotificationsModal">
        <div className="LogoContainer m-t-30"><img className="Logo home-logo" src="/assets/homes_print2x.png" alt="" /></div>

        <h3 className="Title orange m-t-30">{`@${userName}`}</h3>
        <h3 className="Title">{translate('deleted the account')}</h3>

        <h4 className="InfoText">{translate('This action will affect your app in the following ways:')}</h4>

        <ul className="List">
          <li className="ListItem"><span className="black">{translate('His/her events were deleted')}</span></li>
          <li className="ListItem"><span className="black">{translate('All of his/her sent invitations were cancelled')}</span></li>
          <li className="ListItem"><span className="black">{translate('His/her documents shared with you were deleted')}</span></li>
        </ul>

        <Button onClick={() => toggleModal()} label={translate('I UNDERSTAND')} />
      </div>
    </Modal>
  );
};


NotificationsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NotificationsModal;
