import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { AuthForm, Button, Form, Input, Link, Inner, Notification } from '../../components';
import { isEdge } from '../../utils/browser';
import { getLanguages } from '../../utils/lang';
import { TWO_HOMES_APP_SCHEME } from '../../utils/net';

const NewPasswordView = ({
  country,
  invitation,
  newPassword,
  repeatedNewPassword,
  isSubmitted,
  error,
  loading,
  handleFieldChange,
  handleSubmit,
  validForm,
}, { t: translate }) => {
  const { push } = useHistory();
  const languages = getLanguages();
  const [isPrivacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [isDataConsentAccepted, setDataConsentAccepted] = useState(false);
  const areConsentsGiven = isPrivacyPolicyAccepted && isDataConsentAccepted;

  useEffect(() => {
    let timeout = null;
    if(isSubmitted) {
      timeout = setTimeout(() => {
        push('/sign-in');

        if(invitation) window.location.replace(TWO_HOMES_APP_SCHEME);
      }, 5000);
    }

    if(timeout) return () => clearTimeout(timeout);
  }, [isSubmitted]);

  return (
    <Inner>
      <AuthForm
        type={isSubmitted ? 'tada newPassword' : 'ellipsis newPassword'}
        title={isSubmitted ? translate('Ready') :
          invitation ? translate('Create your password') : translate('Set new password')}
      >
        {isSubmitted ? (
          <AuthForm.Paragraph>
            <p><Link to="/sign-in" style={{ textDecoration: 'underline' }}>{translate('Log in here')}</Link> {translate('with your new password')}</p>
          </AuthForm.Paragraph>
        ) : (
          <AuthForm.Paragraph>
            <p>{translate('Make sure that it contains at least 8 characters, including one capital letter and one number.')}</p>
          </AuthForm.Paragraph>
        )}

        {!isSubmitted ? (
          <Form
            onSubmit={handleSubmit}
            loading={loading}
          >
            {(!validForm() && newPassword !== '' && repeatedNewPassword !== '') ? (
              <Notification type="error" text={translate('Passwords must be the same and the password has to have at least 8 characters')} />
            ) : newPassword && validForm() && !country && invitation ? (
              <Notification type="error" text={translate('Select country')} />
            ) : (
              <Notification type="error" text={error} />
            )}
            <Form.Row>
              <input
                required
                autoComplete="off"
                type="password"
                value={newPassword || ''}
                onChange={handleFieldChange('newPassword')}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('new password')}</label>
            </Form.Row>
            <Form.Row>
              <input
                required
                autoComplete="off"
                type="password"
                value={repeatedNewPassword || ''}
                onChange={handleFieldChange('repeatedNewPassword')}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('repeat password')}</label>
            </Form.Row>
            {invitation ? (
              <>
                <Form.Row>
                  <Input
                    isSearchable
                    error={error}
                    name="country"
                    onChange={val => handleFieldChange('country', val)}
                    options={languages}
                    placeholder={translate('Select country')}
                    type="select"
                    value={country}
                  />
                </Form.Row>


                <Form.Row>
                  <input
                    required
                    className="pull-left"
                    onChange={() => setPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
                    type="checkbox"
                  />
                  <span className="f13 sign-up-checkbox">
                    {translate('I accept {Privacy Policy} and {Terms of Service}', {
                      ['Privacy Policy']: <Link href="https://2homes.com/privacy-policy/" target="_blank">{translate('Privacy Policy')}</Link>,
                      ['Terms of Service']: <Link href="https://2homes.com/terms-of-service/" target="_blank">{translate('Terms of Service')}</Link>,
                    })}
                  </span>
                </Form.Row>

                <Form.Row className="m-t-0">
                  <input
                    required
                    className="pull-left"
                    onChange={() => setDataConsentAccepted(!isDataConsentAccepted)}
                    type="checkbox"
                  />
                  <span className="f13 sign-up-checkbox">{translate('I agree that you may use the information I have provided for the purposes necessary for 2homes services.')}</span>
                </Form.Row>
              </>
            ) : null}
            <Form.Summary>
              <Button
                type="submit"
                label={translate('Continue')}
                align="center"
                disabled={invitation ? !validForm() || !areConsentsGiven || !country : !validForm()}
              />
            </Form.Summary>
          </Form>
        ) : (
          <span />
        )}
      </AuthForm>
    </Inner>
  );
};


NewPasswordView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NewPasswordView;
