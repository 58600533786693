import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Form, Badge, BadgesList, DatePicker, Modal, Notification, TagKeys } from '../../../../components';
import { isEdge } from '../../../../utils/browser';
import { setOrderedFamilyMembers, handleFormKeyPress } from '../../../../utils/tools';

import Attachment from './Attachment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';


export const FinanceModal = ({ loading, homeUsers, user, ...props }, { t: translate }) => {
  const formTouched = () => {
    return props.editFormData &&
      (props.editFormData.total_amount !== '' || props.editFormData.note !== '' || props.editFormData.tag_key !== '');
  };

  const validateForm = () => {
    return props.editFormData.tag_key !== '' && props.editFormData.total_amount > 0 && props.editFormData.user_ids.length >= 1;
  };

  const handleNumbers = event => {
    if(!event.target.validity.valid) {
      event.target.value = props.editFormData.total_amount;
    }
    props.handleFormChange('total_amount', event.target.value, true);
  };

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#finance-modal-form button[type="submit"]');
  };

  const homeUsersFiltered = setOrderedFamilyMembers(user, homeUsers);

  const transactionDate = props.editFormData.transaction_date && moment(props.editFormData.transaction_date);
  const attachmentsList = [];
  if(props.editFormData.filename) {
    attachmentsList.push({
      name: props.editFormData.filename,
      type: props.editFormData.filetype,
    });
  }

  return (
    <Modal
      isOpen={props.modalOpened}
      label="Finance"
      closeModal={() => props.closeModal(false)}
      dataChanged={formTouched()}
    >
      <div
        className="finance-modal"
        tabIndex="0"
        onKeyPress={handleKeyPress}
      >
        <div className="badges-section">
          <div className="title">{translate('Visibility')}</div>
          <Badge label={translate('private')}
            active={props.editFormData.access === 'private'}
            activeColor="bg-darkgrey"
            onClick={props.handleFormChange('access', 'private')}
          />
          <Badge label={translate('public')}
            active={props.editFormData.access === 'public'}
            activeColor="bg-darkgrey"
            onClick={props.handleFormChange('access', 'public')}
          />
        </div>

        <div className="badges-section">
          <div className="title">{translate('Add')}</div>
          <Badge label={translate('expense')}
            active={props.editFormData.type === 'expense'}
            activeColor="bg-red"
            onClick={props.handleFormChange('type', 'expense')}
          />
          <Badge label={translate('income')}
            active={props.editFormData.type === 'income'}
            activeColor="bg-green"
            onClick={props.handleFormChange('type', 'income')}
          />
        </div>

        <div className="badges-section">
          <div className="title">{translate('Date of transaction')}</div>
          <Form>
            <Form.Row>
              <DatePicker
                label={translate('date of transaction')}
                locale={user.language || 'en-gb'}
                maxDate={moment().add(1, 'year')}
                onChange={date => props.handleFormChange('transaction_date', date.format(DEFAULT_DATE_FORMAT), true)}
                selected={transactionDate}
              />
            </Form.Row>
          </Form>
        </div>

        <TagKeys
          type={props.editFormData.type}
          label={props.editFormData.type === 'expense' ? translate('on') : translate('from')}
          activeCategory={props.editFormData.tag_key}
          handleFormChange={props.handleFormChange}
        />

        {homeUsersFiltered.length > 0 && (
          <BadgesList
            label={translate('for')}
            list={homeUsersFiltered && Object.values(homeUsersFiltered)}
            activeBadges={props.editFormData.user_ids}
            handleFormChange={(key, val) => props.handleFormChange(key, val, true)}
            formKey="user_ids"
            activeColor="bg-main1"
            forceOne={props.editFormData.datatype === 'history'}
          />
        )}

        <Form id="finance-modal-form" onSubmit={props.saveFinance} loading={false}>
          <Form.Row>
            <input type="number"
              step="0.01"
              min="0"
              autoComplete="off"
              className="total-amount"
              onChange={handleNumbers}
              value={props.editFormData.total_amount || ''}
              data-e2e="accounting-total-amount-input"
            />
            <label className={isEdge ? 'edge' : ''}>{translate('total amount')}</label>
          </Form.Row>

          <Form.Row>
            <Attachment
              attachments={attachmentsList}
              addNewFile={props.addNewFile}
              setFileToDelete={props.setFileToDelete}
              showDeleteButton={props.editFormData.datatype !== 'pending'}
              transactionId={props.editFormData.id}
            />
          </Form.Row>

          <Form.Row>
            <div className="textarea-container">
              <textarea className="textarea-note" placeholder={translate('+ add a note')}
                onChange={props.handleFormChange('note')}
                value={props.editFormData.note || ''}
                data-e2e="accounting-notes-field"
              />
            </div>
          </Form.Row>

          {props.error && (
            <div className="error center">
              <Notification type="error" text={props.error} />
            </div>
          )}

          <Form.Summary>
            <Button
              type="submit"
              label={translate('Save')}
              align="center"
              disabled={loading || !validateForm()}
              data-e2e="accounting-save-button"
            />

            {!validateForm() ? (
              <div className="f12 m-t-0 error">
                {translate('Select transaction reason, receiver and enter amount to save it.')}
              </div>
            ) : null}
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


FinanceModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinanceModal;
