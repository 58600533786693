import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Link } from 'src/components';
import SignedInLayout from 'src/layouts/SignedInLayout';
import { MODAL_NAMES } from 'src/utils/modals';
import { PERMISSION } from 'src/utils/permissions';

import Relationship from './Relationship';


export const Families = ({
  homesUsersFamilies, homeUsers,
  layout, loading, showModal, user,
}, { t: translate }) => {
  const { push } = useHistory();
  const emptyHome = {
    secondParent: '',
    kids: [],
    homeId: undefined,
  };

  const redirectToForm = ({ home, homeType, invitee }) => {
    let path = '/';
    const canInviteKids = user.permission !== PERMISSION.INVITED_EX;
    const canInviteCaretakers = user.permission === PERMISSION.PAID || user.permission === PERMISSION.PAY_DELAYED;
    const shouldRedirectToInvitationForm = invitee === 'kid' ? canInviteKids : canInviteCaretakers;

    if(shouldRedirectToInvitationForm) {
      path += invitee === 'parent' ? 'home/' : 'kid/';
      path += `invite?${homeType}=`;
      path += home.homeId ?? undefined;

      push(path);
      return false;
    }
    showModal({ name: MODAL_NAMES.PERMISSIONS_INFO, data: { invitee } });
  };

  const renderViewSwitcher = () => {
    return (
      <div className="FamilyViewSwitcher m-t-30 right">
        <Link to="/home"><i className="zmdi zmdi-format-list-bulleted" /></Link>
        <Link to="/families"><i className="zmdi zmdi-accounts disabled" /></Link>
      </div>
    );
  };

  return (
    <SignedInLayout
      page="Family"
      currentPath="/families"
      user={user}
      loading={loading}
      layout={layout}
    >

      <div className="FamilyTree">
        <div className="family_container">
          {renderViewSwitcher()}

          <section className="MembersList" id="CurrentRelationship">
            <h2 className="m-b-10">{translate('Current relationship')}</h2>
            <div className="FamiliesMembersSectionTitles m-b-10">
              <h3>{translate('Parents')}</h3>
              <h3>{translate('Kids')}</h3>
            </div>

            <ul className="members_list">
              {homesUsersFamilies && homesUsersFamilies.lists && homesUsersFamilies.lists.current && (
                <li className="list_item">
                  <Relationship
                    user={user}
                    homeUsers={homeUsers}
                    home={homesUsersFamilies.lists.current}
                    homeType="current"
                    redirectToForm={redirectToForm}
                  />
                </li>
              )}
            </ul>
          </section>

          <section className="MembersList" id="ExRelationships">
            <h2 className="m-b-10">{translate('Previous relationships')}</h2>
            <div className="FamiliesMembersSectionTitles m-b-10">
              <h3>{translate('Parents')}</h3>
              <h3>{translate('Kids')}</h3>
            </div>

            {homeUsers && homesUsersFamilies && homesUsersFamilies.lists && (
              <ul className="members_list">
                {homesUsersFamilies.lists.ex && homesUsersFamilies.lists.ex.map(exHome => {
                  return (exHome.secondParent || exHome.kids.length > 0) && (
                    <li key={exHome.homeId} className="list_item">
                      <Relationship
                        user={user}
                        homeUsers={homeUsers}
                        home={exHome}
                        homeType="ex"
                        redirectToForm={redirectToForm}
                      />
                    </li>
                  );
                })}
                <li className="list_item">
                  <Relationship
                    user={user}
                    homeUsers={homeUsers}
                    home={emptyHome}
                    homeType="ex"
                    redirectToForm={redirectToForm}
                  />
                </li>
              </ul>
            )}
          </section>
        </div>
      </div>
    </SignedInLayout>
  );
};


Families.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Families;
