import React from 'react';
import PropTypes from 'prop-types';

import { Card, ModalConfirm } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';

import KidProfileModal from './modal/KidProfileModal';
import InviteModal from './modal/InviteModal';
import KidPersonalInfo from './KidPersonalInfo';
import KidFiles from './KidFiles';


export const KidProfile = ({ user, layout, loading, kidCurrent, ...props }, { t: translate }) => {
  const { id, profile = {}, whichFamily } = kidCurrent;
  return (
    <SignedInLayout
      page="KidProfile"
      currentPath={`/kid/${id}`}
      user={user}
      layout={layout}
      loading={loading}
    >

      <div className="kid-profile-info">
        <KidPersonalInfo
          editMode={props.editMode}
          currentKidData={props.currentKidData}
          handleFormChange={props.handleFormChange}
          handleAvatarChange={props.handleAvatarChange}
          saveData={props.saveData}
          error={props.error}
          loading={loading}
          user={user}
          openInviteModal={props.toggleInviteModal}
          copyPathToClipboard={props.copyPathToClipboard}
        />
        <input type="text" id="invitation-link" defaultValue={props.invitationURL} className="hidden-input" />

        {whichFamily !== 'partnerEx' && (
          <KidFiles
            isWalkthroughOpen={props.isWalkthroughOpen}
            kidCurrent={kidCurrent}
            saveFile={props.saveFile}
            editData={props.editData}
            setDataToDelete={props.setDataToDelete}
          />
        )}

        {whichFamily !== 'partnerEx' && profile.categories && profile.categories.map((category, catIdx) => (
          <Card
            key={catIdx}
            title={category.name}
            onEdit={() => props.editData('card', category)}
            onDelete={() => props.setDataToDelete('card', { id: category.id })}
            className={category.information && category.information.length === 0 ? 'no-leafs' : ''}
            isWalkthroughOpen={props.isWalkthroughOpen}
          >
            {category.information && category.information.map(leaf => (
              <Card.Leaf
                key={leaf.id}
                title={leaf.key}
                description={leaf.value}
                onEdit={() => props.editData('leaf', leaf, category.id)}
                onDelete={() => props.setDataToDelete('leaf', leaf, category.id)}
                isWalkthroughOpen={props.isWalkthroughOpen}
              />
            ))}

            <Card.Leaf.Empty
              title={category.information && category.information.length === 0 ?
                translate('Add first note-card') :
                translate('Add note-card')}
              icon="zmdi-plus"
              onClick={() => props.editData('leaf', {}, category.id)}
            />
          </Card>
        ))}

        {props.currentKidData.whichFamily !== 'partnerEx' && (
          <Card.Empty title={translate('Add new section')}
            icon="zmdi-plus"
            onClick={() => props.editData('card', {})}
          />
        )}
      </div>

      <ModalConfirm
        isOpen={props.confirmModalOpened}
        closeModal={() => props.closeModal(true)}
        question={translate('Are you sure you want to delete this information?')}
        deleteFunction={props.deleteData}
      />

      <KidProfileModal
        kid={kidCurrent}
        modalOpened={props.modalOpened}
        saveData={props.saveData}
        handleFormChange={props.handleFormChange}
        handleAvatarChange={props.handleAvatarChange}
        closeModal={props.closeModal}
        editFormData={props.editFormData}
        error={props.error}
        loading={loading}
      />

      <InviteModal
        closeModal={props.toggleInviteModal}
        kidEmail={props.currentKidData.email}
        loginToken={props.loginToken}
        modalOpened={props.inviteModalOpened}
        parentLanguage={props.parentLanguage}
      />

    </SignedInLayout>
  );
};


KidProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KidProfile;
