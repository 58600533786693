import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as file from './../../../utils/file';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from './../../../utils/date';
import { setNumberFormat } from './../../../utils/tools';
import { isEdge } from './../../../utils/browser';


export const SplitRequestsTable = ({ type, splitRequestsData, homeUsers, editSplit, editable, isWalkthroughOpen }, { t: translate }) => {
  const possibleStatus = {
    accepted: translate('accepted / waiting for payment'),
    pending: translate('pending'),
    rejected: translate('rejected'),
    paid: translate('paid'),
  };

  const tableRows = splitRequestsData && splitRequestsData.map((row, idx) => {
    if(row) {
      const fileType = file.getFileType(row.filetype);
      const userData = homeUsers && homeUsers[row.user_id];
      const recipientData = homeUsers && homeUsers[row.recipient_id];
      const ownerData = homeUsers && homeUsers[row.owner_id];

      const split_percentage = Math.round(row.split_amount * 100 / row.amount) || 0;

      const date = moment(row.date).format(DEFAULT_DISPLAYED_DATE_FORMAT);
      const colorClass = (row.status === 'paid' || row.status === 'accepted') ? 'success' : row.status === 'rejected' ? 'error' : 'warning';

      const status = possibleStatus[row.status];

      return (
        <div key={idx} className="trow">
          <div className="tcell right" style={{ minWidth: 115 }}>{date}</div>
          <div className="tcell right" style={{ minWidth: 80 }}>{setNumberFormat(row.amount, 2)}</div>
          <div className="tcell right w600" style={{ minWidth: 120 }}>
            <span>{setNumberFormat(row.split_amount, 2)}</span>
            <span className="inline-block f12 grey3 w400 p-l-5">({split_percentage}%)</span>
          </div>
          <div className="tcell" style={type === 'from-me' ? { color: '#fff', minWidth: 150 } : { minWidth: 150 }}>{ownerData && (`@${ownerData.nickname || ownerData.first_name.toLowerCase()}`)}</div>
          <div className="tcell" style={type === 'for-me' ? { color: '#fff', minWidth: 120 } : { minWidth: 120 }}>{recipientData && (`@${recipientData.nickname || recipientData.first_name.toLowerCase()}`)}</div>
          <div className="tcell" style={{ minWidth: 110 }}>{row.tag_key}</div>
          <div className="tcell" style={{ color: userData && userData.color, minWidth: 120 }}>{userData && (`@${userData.nickname || userData.first_name.toLowerCase()}`)}</div>
          <div className="tcell small center split-request-file" style={{ minWidth: 40 }}>
            {row.file_url ? (<a href={row.file_url} target="_blank"><i className={`zmdi zmdi-${fileType.icon || 'file'}`} /></a>) : (<span />)}
          </div>
          <div className={`tcell w500 wrap ${colorClass}`} style={{ minWidth: 100 }} title={status}>{status}</div>
          <div className="tcell split-notes" title={row.note} style={{ minWidth: 120 }}>
            <span>{row.note}</span>
          </div>
          <div className="tcell icons" style={{ minWidth: 90 }}>
            {editable && (
              <i className="zmdi zmdi-edit" style={{ visibility: isWalkthroughOpen ? 'visible' : 'hidden' }} onClick={() => editSplit(row)} />
            )}
          </div>
        </div>
      );
    }
  });

  return (
    <div className={`table split-requests-table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell center" style={{ minWidth: 115 }}>{translate('Date')}</div>
          <div className="tcell right" style={{ minWidth: 80 }}>{translate('Total')}</div>
          <div className="tcell center" style={{ minWidth: 120 }}>{translate('Split')}</div>
          <div className="tcell" style={type === 'from-me' ? { color: '#fff', minWidth: 150 } : { minWidth: 150 }}>{translate('Requested by')}</div>
          <div className="tcell" style={type === 'for-me' ? { color: '#fff', minWidth: 120 } : { minWidth: 120 }}>{translate('To')}</div>
          <div className="tcell" style={{ minWidth: 110 }}>{translate('On')}</div>
          <div className="tcell" style={{ minWidth: 120 }}>{translate('For')}</div>
          <div className="tcell" style={{ minWidth: 40 }}>{translate('File')}</div>
          <div className="tcell" style={{ minWidth: 100 }}>{translate('Status')}</div>
          <div className="tcell" style={{ minWidth: 120 }}>{translate('Notes')}</div>
          <div className="tcell" style={{ minWidth: 90 }} />
        </div>
      </div>
      <div className="tbody">
        {tableRows}
      </div>
    </div>
  );
};


SplitRequestsTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequestsTable;
