import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from '../utils/requireAuth';
import UnsupportedMobile from '../utils/withRedirectToMobile';
import PermissionsCheck from '../utils/withPermissions';
import { PERMISSION } from '../utils/permissions';

import Root from './Home';
import SignInRoute from './SignIn';
import { SignUpRoute, SignUp90DRoute } from './SignUp';
import SignOutRoute from './SignOut';
import ActivationRoute from './Activation';
import PasswordRecoverRoute from './PasswordRecover';
import NewPasswordRoute from './NewPassword';
import FirstSteps from './FirstSteps';
import { FamilyRoute, FamiliesRoute, InviteFamilyMemberRoute, InviteKidRoute } from './Family';
import BudgetRoute from './Budget';
import { CalendarRoute, CalendarPrintRoute, CalendarICSRoute } from './Calendar';
import NotificationsRoute from './Notifications';
import { AccountingRoute, SplitRequestDetailsRoute } from './Finances';
import SplitRequestsRoute from './SplitRequests';
import DocumentsRoute from './Documents';
import ShareFile from './ShareFile';
import { ReportsRoute, ReportsPrintRoute } from './Reports';
import NotFoundRoute from './NotFound';
import DeleteSuccessRoute from './DeleteSuccess';
import { ChangePasswordRoute, UserProfileRoute } from './UserProfile';
import KidProfileRoute from './KidProfile';
import CaretakerProfileRoute from './CaretakerProfile';
import KidsAppRedirect from './KidsAppRedirect';
import { SubscriptionRoute } from './Subscription';

const FirstStepsRoute = ProtectedRoute(PermissionsCheck(FirstSteps, [PERMISSION.INITIAL, PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT]));
const ShareFileRoute = PermissionsCheck(ShareFile, [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]);
const Home = ProtectedRoute(PermissionsCheck(UnsupportedMobile(FamilyRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED, PERMISSION.BLOCKED]));
const Families = ProtectedRoute(PermissionsCheck(UnsupportedMobile(FamiliesRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const InviteFamilyMember = ProtectedRoute(PermissionsCheck(UnsupportedMobile(InviteFamilyMemberRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const InviteKid = ProtectedRoute(PermissionsCheck(UnsupportedMobile(InviteKidRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Budget = ProtectedRoute(PermissionsCheck(UnsupportedMobile(BudgetRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Calendar = ProtectedRoute(PermissionsCheck(UnsupportedMobile(CalendarRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const CalendarPrint = ProtectedRoute(PermissionsCheck(CalendarPrintRoute, [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const CalendarICS = ProtectedRoute(PermissionsCheck(UnsupportedMobile(CalendarICSRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Notifications = ProtectedRoute(PermissionsCheck(UnsupportedMobile(NotificationsRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Accounting = ProtectedRoute(PermissionsCheck(UnsupportedMobile(AccountingRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const SplitRequestDetails = ProtectedRoute(PermissionsCheck(UnsupportedMobile(SplitRequestDetailsRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const SplitRequests = ProtectedRoute(PermissionsCheck(UnsupportedMobile(SplitRequestsRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Documents = ProtectedRoute(PermissionsCheck(UnsupportedMobile(DocumentsRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Reports = ProtectedRoute(PermissionsCheck(UnsupportedMobile(ReportsRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const ReportsPrint = ProtectedRoute(PermissionsCheck(UnsupportedMobile(ReportsPrintRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const KidProfile = ProtectedRoute(PermissionsCheck(UnsupportedMobile(KidProfileRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const CaretakerProfile = ProtectedRoute(PermissionsCheck(UnsupportedMobile(CaretakerProfileRoute), [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const UserProfile = ProtectedRoute(PermissionsCheck(UserProfileRoute, [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED, PERMISSION.BLOCKED]));
const ChangePassword = ProtectedRoute(PermissionsCheck(ChangePasswordRoute, [PERMISSION.INITIAL, PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED]));
const Subscription = ProtectedRoute(PermissionsCheck(SubscriptionRoute, [PERMISSION.PAID, PERMISSION.INVITED_EX, PERMISSION.INVITED_CURRENT, PERMISSION.PAY_DELAYED, PERMISSION.BLOCKED]));

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/sign-in" component={SignInRoute} />
    <Route path="/sign-up" component={SignUpRoute} />
    <Route path="/sign-up-90" component={SignUp90DRoute} />
    <Route path="/sign-out" component={SignOutRoute} />
    <Route path="/activation" component={ActivationRoute} />
    <Route path="/password-recover" component={PasswordRecoverRoute} />
    <Route path="/new-password" component={NewPasswordRoute} />
    <Route path="/first-steps/:step" component={FirstStepsRoute} />
    <Route exact path="/home" component={Home} />
    <Route path="/families" component={Families} />
    <Route path="/home/invite" component={InviteFamilyMember} />
    <Route path="/kid/invite" component={InviteKid} />
    <Route path="/budget" component={Budget} />
    <Route exact path="/calendar" component={Calendar} />
    <Route path="/calendar-print/:startDate/:userId/:action" component={CalendarPrint} />
    <Route path="/ics/:startDate/:userId" component={CalendarICS} />
    <Route path="/notifications" component={Notifications} />
    <Route path="/accounting" component={Accounting} />
    <Route path="/split-request" component={SplitRequestDetails} />
    <Route path="/split-requests" component={SplitRequests} />
    <Route path="/documents" component={Documents} />
    <Route path="/sharefile" component={ShareFileRoute} />
    <Route path="/reports" component={Reports} />
    <Route path="/reports-print" component={ReportsPrint} />
    <Route path="/delete-success" component={DeleteSuccessRoute} />
    <Route path="/user-profile" component={UserProfile} />
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/kid/:kidId" component={KidProfile} />
    <Route path="/caretaker/:caretakerId" component={CaretakerProfile} />
    <Route path="/2homeskids" component={KidsAppRedirect} />
    <Route path="/subscription" component={Subscription} />
    <Route path="/*" component={NotFoundRoute} />
  </Switch>
);

export default Routes;

