import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Badge } from './../../../../components';
import { isEdge } from './../../../../utils/browser';


class Caretakers extends React.PureComponent {
  componentDidMount() {
    this.caretakerClickHandler(this.props.caretaker.id);
  }

  caretakerClickHandler = caretakerId => {
    const { activeCaretaker, handleFormChange } = this.props;

    if(activeCaretaker === caretakerId) {
      handleFormChange('split_caretaker_id', null, true);
      handleFormChange('split_type', '', true);
    } else {
      handleFormChange('split_caretaker_id', caretakerId, true);
      handleFormChange('split_type', 'half', true);
    }
  };

  radioClickHandler = radioType => {
    const { split_amount, total_amount, handleFormChange } = this.props;

    handleFormChange('split_type', radioType, true);
    let splitAmount = split_amount;

    if(radioType === 'half') {
      splitAmount = total_amount ? (total_amount / 2) : 0;
    }
    handleFormChange('split_amount', splitAmount, true);
  };

  render() {
    const { caretaker = {}, activeCaretaker, split_amount, split_type, handleFormChange } = this.props;
    const { t: translate } = this.context;

    const nickname = caretaker.nickname ? caretaker.nickname : caretaker.first_name || caretaker.firstName;

    return (
      <div className="badges-section">
        <div className="title">{translate('Request')}</div>
        <Badge
          key={caretaker.id}
          label={`@${nickname}`}
          active={activeCaretaker === caretaker.id}
          bgColor={caretaker.color}
          onClick={() => this.caretakerClickHandler(caretaker.id)}
        />
        <div className="split-section">
          <div className="half pull-left">
            <input
              type="radio"
              name="split"
              checked={split_type === 'half'}
              onChange={() => this.radioClickHandler('half')}
              onClick={() => this.radioClickHandler('half')}
            />
            <span className="f12 m-t-5">{translate('to split 50/50')}</span>
          </div>
          <div className="half pull-right">
            <input
              type="radio"
              name="split"
              checked={split_type === 'amount'}
              onChange={() => this.radioClickHandler('amount')}
              onClick={() => this.radioClickHandler('amount')}
            />
            <div className="relative">
              <input
                type="text"
                autoComplete="off"
                className="split-amount"
                onFocus={() => this.radioClickHandler('amount')}
                onChange={handleFormChange('split_amount')}
                value={split_amount || ''}
              />
              <label className={classnames('amountLabel', { 'edge': isEdge })}>{translate('to pay the amount')}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Caretakers.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Caretakers;
