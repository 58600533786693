import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { Badge, Button, Form, Notification } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';


export const SplitRequest = ({ back, user, loading, layout, splitRequest, homeUsers, saveSplitRequest, submitted, error }, { t: translate }) => {
  const { push } = useHistory();

  const forUser = homeUsers && homeUsers[splitRequest.user_id];
  const owner = homeUsers && homeUsers[splitRequest.owner_id];
  const recipient = homeUsers && homeUsers[splitRequest.recipient_id];
  const status = splitRequest.status === 'accepted' ? translate('accepted / waiting for payment') : translate(splitRequest.status);

  const showButtons = ['accepted', 'paid', 'rejected'].indexOf(splitRequest.status) === -1;

  const closeModalConfirm = () => {
    push(back);
  };

  return (
    <SignedInLayout
      page="SplitRequests"
      currentPath="/split-requests"
      user={user}
      loading={loading}
      layout={layout}
    >

      <div className="SplitRequestForm">
        {!submitted && (
          <Form className="p-h-50">
            <span className="pointer modal-close" onClick={closeModalConfirm}>
              <i className="zmdi zmdi-close" />
            </span>
            <h2>{translate('Split request')}</h2>
            {owner && owner.id === user.id && (
              <div className="m-t-30">
              You requested split costs with <span className="w600">{recipient && (`${recipient.first_name} ${recipient.last_name}`)}</span>
              </div>
            )}
            {recipient && recipient.id === user.id && (
              <div className="m-t-30">
                <span className="w600">{owner && (`${owner.first_name} ${owner.last_name}`)}</span> {translate('requested split costs with you')}
              </div>
            )}
            <div className="p-h-50">
              <div className="m-t-30 left">
                <span className="m-r-10 f15 inline-block">{translate('On')}</span>
                <Badge
                  active
                  label={splitRequest.tag_key}
                  activeColor="bg-darkgrey"
                  onClick={() => false}
                />
              </div>
              <div className="m-t-30 left">
                <span className="m-r-10 f15 inline-block">{translate('For')}</span>
                <Badge
                  active
                  label={forUser && (`${forUser.first_name} ${forUser.last_name}`)}
                  activeColor="bg-orange"
                  onClick={() => false}
                />
              </div>
              <div className="m-t-30 left">
                <span className="m-r-10 f15 inline-block">{translate('Total amount')}</span>
                <span className="f18 w600">{splitRequest.amount}</span>
              </div>
              <div className="m-t-30 left">
                <span className="m-r-10 f15 inline-block">{translate('Request for')}</span>
                <span className="f18 w600">{splitRequest.split}</span>
              </div>

              {splitRequest.file && (
                <div className="m-t-30 left">
                  <span className="m-r-10 f15 inline-block">{translate('Attachment')}</span>
                  <span className="f18 w600"><a href={splitRequest.file} target="_blank">{splitRequest.filename}</a></span>
                </div>
              )}

              {splitRequest.note && (
                <div className="m-t-30 left">
                  <span className="m-r-10 f15 inline-block">{translate('Note')}</span>
                  <span className="f18 w500 block">{splitRequest.note}</span>
                </div>
              )}
            </div>

            <Notification
              type="error"
              text={error}
            />

            {showButtons ? (
              <Form.Summary>
                <div className="half pull-left p-t-20 p-b-30">
                  <Button
                    type="button"
                    label={translate('Accept')}
                    align="center"
                    onClick={() => saveSplitRequest(true)}
                  />
                </div>
                <div className="half pull-right p-t-20 p-b-30">
                  <Button
                    type="button"
                    label={translate('Reject')}
                    align="center"
                    style={{ backgroundColor: '#d7d9da' }}
                    onClick={() => saveSplitRequest(false)}
                  />
                </div>
                <div className="clear" />
              </Form.Summary>
            ) : (
              <Form.Summary>
                <div className="m-t-30 m-b-30 f20 center">{translate('Status: ')}
                  <span className={`m-t-30 m-b-30 f20 w600 center ${(splitRequest.status === 'paid' || splitRequest.status === 'accepted') ? 'success' : splitRequest.status === 'rejected' ? 'error' : ''}`}>{status}</span>
                </div>
              </Form.Summary>
            )}
          </Form>
        )}

        {submitted && (
          <div className="p-t-30 p-b-30 p-h-50">
            <h2>{translate('Split request')}</h2>
            <div className="p-t-20">{translate('Your response has been sent to requester.')}</div>
          </div>
        )}

      </div>
    </SignedInLayout>
  );
};


SplitRequest.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequest;
