import * as _ from 'lodash';
import tinycolor from 'tinycolor2';


export const getContentWidth = layout => {
  const asideCollapsedWidth = 72;
  const asideExpandedWidth = 168;
  return window.innerWidth - (layout.collapsed ? asideCollapsedWidth : asideExpandedWidth) - 17;
};


export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
  // http://kevin.vanzonneveld.net
  // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +     bugfix by: Michael White (http://getsprink.com)
  // +     bugfix by: Benjamin Lupton
  // +     bugfix by: Allan Jensen (http://www.winternet.no)
  // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +     bugfix by: Howard Yeend
  // +    revised by: Luke Smith (http://lucassmith.name)
  // +     bugfix by: Diogo Resende
  // +     bugfix by: Rival
  // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
  // +   improved by: davook
  // +   improved by: Brett Zamir (http://brett-zamir.me)
  // +      input by: Jay Klehr
  // +   improved by: Brett Zamir (http://brett-zamir.me)
  // +      input by: Amir Habibi (http://www.residence-mixte.com/)
  // +     bugfix by: Brett Zamir (http://brett-zamir.me)
  // +   improved by: Theriault
  // +   improved by: Drew Noakes
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
  const dec = (typeof dec_point === 'undefined') ? '.' : dec_point;

  const toFixedFix = () => {
    const k = Math.pow(10, prec);
    return Math.round(n * k) / k;
  };
  const s = (prec ? toFixedFix() : Math.round(n)).toString().split('.');

  if(s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

export const setNumberFormat = (number, decimals) => {
  return numberFormat(number, (decimals ? 2 : 0), ',', ' ');
};

export const setAmount = (value, split, forceString) => {
  if(split && split !== 0) {
    const values = [];
    for(let i = 0; i < split; i++) {
      const newValue = value / split;
      values.push(newValue);
    }
    return values;
  }
  const result = value && value.toString().replace(',', '.');
  if(forceString) {
    return result;
  }
  return [result];
};

export const cleanNickname = nickname => {
  return nickname.replace(/@/g, '').replace(/ /g, '');
};

export const convertHexToRgba = (hex, opacity) => {
  let c;
  if(hex && /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if(c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity})`;
  }
  return 'rgba(222, 222, 222, 1)';
};

export const convertRgbaToHex = rgba => {
  const parts = rgba.substring(rgba.indexOf('(')).split(',');
  const r = parseInt(parts[0].substring(1), 10);
  const g = parseInt(parts[1], 10);
  const b = parseInt(parts[2], 10);
  const a = parseFloat(parts[3].substring(0, parts[3].length - 1)).toFixed(2);

  return ('#' + r.toString(16) + g.toString(16) + b.toString(16) + (a * 255).toString(16).substring(0, 2));
};

export const lightenColor = (color, luminosity) => {
  if(!color) {
    return null;
  }
  let validColor = color.replace(/[^0-9a-f]/gi, '');
  if(validColor.length < 6) {
    validColor = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }
  const properLuminosity = luminosity || 0;
  // convert to decimal and change luminosity
  let newColor = '#';
  const black = 0;
  const white = 255;
  for (let i = 0; i < 3; i++) {
    let c = parseInt(validColor.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(black, c + (properLuminosity * white)), white)).toString(16);
    newColor += ('00' + c).substr(c.length);
  }
  return newColor;
};

export const invertColor = hex => {
  const { r, g, b } = getRGB(hex);
  return '#' + padZero(r) + padZero(g) + padZero(b);
};

export const padZero = (str, len) => {
  const properLen = len || 2;
  const zeros = new Array(properLen).join('0');
  return (zeros + str).slice(-properLen);
};

export const getFontColor = (bgColor, replacements) => {
  const tbgColor = tinycolor(bgColor);
  let color = '';
  if(tbgColor.getBrightness() > 120) {
    color = (replacements && replacements.black) || 'black';
  } else {
    color = (replacements && replacements.white) || 'white';
  }
  return color;
};

export const getRGB = color => {
  let hex = color;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hx to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16);
  const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16);
  const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

  return { r, g, b };
};


export const setOrderedFamilyMembers = (user, homeUsers) => {
  let homeUsersFiltered = [
    user,
  ];

  if(homeUsers) {
    const homeUsersSorted = Object.values(homeUsers).sort((a, b) => {
      return a.first_name > b.first_name ? 1 : -1;
    });

    const kidsWithEx = [];
    const kidsWithCurrent = [];
    const kidsPartnerEx = [];

    homeUsersSorted.forEach(homeUser => {
      const usr = homeUsers[homeUser.id];

      if(homeUser.id !== user.id && usr.familyStatus === 'kid' && usr.status !== 'pending' && usr.visibility) {
        if(usr.whichFamily === 'ex') {
          kidsWithEx.push(homeUser);
        }
        if(usr.whichFamily === 'current') {
          kidsWithCurrent.push(homeUser);
        }
        if(usr.whichFamily === 'partnerEx') {
          kidsPartnerEx.push(homeUser);
        }
      }
    });

    const kids = [
      ...kidsWithEx,
      ...kidsWithCurrent,
    ];

    homeUsersFiltered = [
      ...homeUsersFiltered,
      ...kids,
    ];

    const otherCaretakers = _.filter(homeUsers, usr => usr.familyStatus === 'current' && usr.status !== 'pending' && usr.visibility && usr.id !== user.id);

    homeUsersFiltered = [
      ...homeUsersFiltered,
      ...otherCaretakers,
      ...kidsPartnerEx,
    ];
  }

  return homeUsersFiltered;
};

export const handleFormKeyPress = (evt, selector) => {
  if(evt.key === 'Enter') {
    if(evt.target.nodeName !== 'TEXTAREA') {
      evt.preventDefault();
      document.querySelector(selector).click();
      return false;
    }
  }
};
