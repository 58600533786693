import { api, BASE_URL } from '../../utils/net';

import {
  SET_DOCUMENTS_SUCCESS,
  SAVE_DOCUMENTS_FILE_SUCCESS,
  UPDATE_DOCUMENTS_FILE_SUCCESS,
  DELETE_DOCUMENTS_FILE_SUCCESS,
  SET_FOLDERS_SUCCESS,
  ADD_NEW_FOLDER_SUCCESS,
  UPDATE_FOLDER_SUCCESS,
  DELETE_FOLDER_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function setDocumentsSuccess(data) {
  return {
    type: SET_DOCUMENTS_SUCCESS,
    payload: data,
  };
}

export function saveFileSuccess(data) {
  return {
    type: SAVE_DOCUMENTS_FILE_SUCCESS,
    payload: data,
  };
}

export function updateFileSuccess(data, previousFolderId) {
  return {
    type: UPDATE_DOCUMENTS_FILE_SUCCESS,
    payload: data,
    previousFolderId,
  };
}

export function deleteFileSuccess(data) {
  return {
    type: DELETE_DOCUMENTS_FILE_SUCCESS,
    payload: data,
  };
}

export function setFoldersSuccess(data) {
  return {
    type: SET_FOLDERS_SUCCESS,
    payload: data,
  };
}

export function addNewFolderSuccess(name) {
  return {
    type: ADD_NEW_FOLDER_SUCCESS,
    payload: name,
  };
}

export function updateFolderSuccess(data) {
  return {
    type: UPDATE_FOLDER_SUCCESS,
    payload: data,
  };
}

export function deleteFolderSuccess(data) {
  return {
    type: DELETE_FOLDER_SUCCESS,
    payload: data,
  };
}


export const fetchDocuments = (folderId, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}folders/${folderId}/documents`);
    dispatch(setDocumentsSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchDocuments(folderId, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('documents', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const updateFile = (folderId, filedata) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.patch(`${BASE_URL}documents/${filedata.id}`, {
      folder_id: filedata.folder_id,
      filename: filedata.filename,
      note: filedata.note,
    });
    dispatch(updateFileSuccess(data, folderId));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const saveFile = (folderId, file, fileType) => async dispatch => {
  dispatch(showLoader());

  const formdata = new FormData();
  formdata.append('file', file);
  formdata.append('filename', file.name);
  formdata.append('filetype', fileType);

  try {
    const response = await api.useFetch(
      `${BASE_URL}folders/${folderId}/documents`,
      'POST',
      formdata,
      { 'Content-Type': 'multipart/form-data' },
    );

    const data = await response.json();
    dispatch(fetchDocuments(folderId, true));
    dispatch(saveFileSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteFile = file => async dispatch => {
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}documents/${file.id}`);
    dispatch(deleteFileSuccess(file));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const fetchFoldersList = (reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}folders/`);
    dispatch(setFoldersSuccess(data));
    dispatch(hideLoader());
    data.my_folders.map(folder => {
      dispatch(fetchDocuments(folder.id));
    });
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchFoldersList(true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('documents', message));
    }
  }
};


export const addFolder = folder => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.post(`${BASE_URL}folders/`, {
      name: folder.name,
    });
    dispatch(addNewFolderSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const updateFolder = folder => async dispatch => {
  dispatch(showLoader());

  try {
    const { id, name, visible } = folder;
    const { data } = await api.patch(`${BASE_URL}folders/${folder.id}`, {
      id,
      name,
      visible: !!visible,
    });
    dispatch(updateFolderSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteFolder = folder => async dispatch => {
  dispatch(showLoader());

  try {
    await api.deleteWithParam(`${BASE_URL}folders/${folder.id}`, {
      id: folder.id,
    });
    dispatch(deleteFolderSuccess(folder));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('documents', message));
  } finally {
    dispatch(hideLoader());
  }
};

