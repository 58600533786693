import {
  SET_NAVIGATION_COLLAPSED,
  SET_NAVIGATION_EXPANDED,
  SET_LAYOUT_PRINTABLE,
  UNSET_LAYOUT_PRINTABLE,
} from './../types';


const DEFAULT_STATE = {
  collapsed: window.innerWidth < 1024,
  printable: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case SET_NAVIGATION_COLLAPSED:
      return Object.assign({}, state, {
        collapsed: true,
      });

    case SET_NAVIGATION_EXPANDED:
      return Object.assign({}, state, {
        collapsed: false,
      });

    case SET_LAYOUT_PRINTABLE:
      return Object.assign({}, state, {
        printable: true,
      });

    case UNSET_LAYOUT_PRINTABLE:
      return Object.assign({}, state, {
        printable: false,
      });

    default:
      return state;
  }
};
