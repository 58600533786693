export function getAppHost() {
  return document.location.protocol + '//' + document.location.host + '/';
}

export const getQueryParam = paramName => {
  const urlParams = new URLSearchParams(location.search);
  const param = urlParams.get(paramName);

  return param;
}
;
