import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../components';


const CalendarAlerts = ({
  alertsList = [], addNewAlert, disabled,
  removeAlert, updateAlert,
}, { t: translate }) => {
  const timeUnitsOptions = [
    { label: translate('minutes'), value: 'M' },
    { label: translate('hours'), value: 'H' },
    { label: translate('days'), value: 'D' },
  ];
  const showNewNotificationButton = alertsList.filter(el => el).length < 3 && !disabled;

  return (
    <div className="Alerts">
      <h4 className="Title">{translate('Reminder')}</h4>

      {alertsList.map((alert, idx) => alert && (
        <div className="AlertRow" key={idx}>
          <Input
            disabled={disabled}
            type="number"
            className="AlertInput"
            value={alert.amount}
            onChange={e => updateAlert(e, idx, 'amount')}
          />
          <Input
            withDefaultValue
            disabled={disabled}
            type="select"
            mode="alertSelect"
            options={timeUnitsOptions}
            className="AlertInput"
            value={alert.timeUnit}
            onChange={val => updateAlert(val, idx)}
          />
          {translate('before the event')}
          {!disabled && (
            <div className="DeleteButton pointer" onClick={() => removeAlert(idx)}>
              <i className="zmdi zmdi-close" />
            </div>
          )}
        </div>
      ))}

      {showNewNotificationButton && (
        <span
          className="pointer color-grey f14 nowrap"
          onClick={addNewAlert}
        >
          {translate('+ add reminder')}
        </span>
      )}
    </div>
  );
};

CalendarAlerts.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarAlerts;
