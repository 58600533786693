import React from 'react';
import PropTypes from 'prop-types';

import { isEdge } from './../../../utils/browser';

export const FoldersList = ({ type, folders, folderActive, ...props }, { t: translate }) => {
  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="tbody">
        {folders && folders.map((folder, idx) => {
          const isFolderReadyToDelete = props.folderReadyToDelete(folder);
          const eyeIconTitle = !folder.visible ?
            translate('Visible only for you') :
            translate('Visible for everyone');

          return (
            <div key={idx} className={'trow' + (folderActive === folder.id ? ' active' : '')}>
              <div className="tcell small">
                <i
                  className={`zmdi zmdi-${!folder.visible ? 'eye-off' : 'eye'}`}
                  title={eyeIconTitle}
                />
              </div>
              <div className="tcell folder-name pointer"
                title={folder.name}
                onClick={() => props.setFolderActive(folder)}
              >
                <div className="label" data-e2e={`documents-list-folder-${folder.name}`}>{folder.name}</div>
              </div>

              <div className="tcell icons">
                {type === 'my' ? (
                  <i className="zmdi zmdi-edit"
                    style={{ visibility: 'hidden' }}
                    onClick={() => props.editFolder('folder', folder)}
                    data-e2e={`documents-folder-edit-${folder.name}`}
                    title={translate('Edit')}
                  />
                ) : (
                  <i className="zmdi zmdi-edit"
                    style={{ visibility: 'hidden', opacity: 0.1, cursor: 'not-allowed' }}
                    title={translate('You don\'t have permissions to edit folder')}
                  />
                )}
                {(isFolderReadyToDelete && type === 'my') ? (
                  <i className="zmdi zmdi-delete"
                    style={{ marginLeft: 10, visibility: 'hidden' }}
                    onClick={() => props.setToDelete('folder', folder)}
                    title={translate('Delete')}
                  />
                ) : (
                  <i className="zmdi zmdi-delete"
                    style={{ marginLeft: 10, visibility: 'hidden', opacity: 0.1, cursor: 'not-allowed' }}
                    title={translate('Folder is not empty or you don\'t have permissions to delete it')}
                  />
                )}
              </div>

            </div>
          );
        })}
      </div>
    </div>
  );
};

FoldersList.contextTypes = {
  t: PropTypes.func.isRequired,
};


export default FoldersList;
