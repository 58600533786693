import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import InfoModal from './InfoModal';


const BlockedAccountInfo = ({ name }, { t: translate }) => {
  const { push } = useHistory();

  return (
    <InfoModal
      buttonLabel={translate('Create subscription')}
      imgType="ups"
      name={name}
      onClose={() => push('/subscription')}
    >
      <h2 className="Title">{translate('Your account has been blocked')}</h2>

      <p className="InfoText">
        {translate('You don\'t have access to all functionalities')}
      </p>
      <p className="InfoText">
        {translate('Get a subscription to use 2homes to its fullest!')}
      </p>
    </InfoModal>
  );
};


BlockedAccountInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BlockedAccountInfo;
