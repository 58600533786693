import moment from 'moment';

import { DEFAULT_DATE_FORMAT, formatFinancialUrlDate } from '../../utils/date';
import { api, BASE_URL } from '../../utils/net';
import { setAmount } from '../../utils/tools';

import { showLoader, hideLoader } from './loading';
import { fetchTagKeys } from './tags';
import {
  SET_BUDGET_FILTER_DATE,
  SET_BUDGET_ACTIVE_DATE,
  SET_BUDGET_DATA_SUCCESS,
  SAVE_BUDGET_DATA_SUCCESS,
  DELETE_BUDGET_DATA_SUCCESS,
} from './../types';
import { setError } from './errors';


export function setFilterDate(date) {
  return {
    type: SET_BUDGET_FILTER_DATE,
    payload: date,
  };
}

export function setActiveDate(date) {
  return {
    type: SET_BUDGET_ACTIVE_DATE,
    payload: date,
  };
}

export function setBudgetDataSuccess(data) {
  return {
    type: SET_BUDGET_DATA_SUCCESS,
    payload: data,
  };
}

export function saveBudgetDataSuccess(data) {
  return {
    type: SAVE_BUDGET_DATA_SUCCESS,
    payload: data,
  };
}

export function deleteBudgetDataSuccess(data) {
  return {
    type: DELETE_BUDGET_DATA_SUCCESS,
    payload: data,
  };
}


export const fetchBudgetData = (date, filter, reCall = false) => async dispatch => {
  dispatch(setActiveDate(date));
  dispatch(showLoader());

  try {
    const formattedDate = formatFinancialUrlDate(date);
    const { data } = await api.get(`${BASE_URL}budgets/${formattedDate}`);
    dispatch(setBudgetDataSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchBudgetData(date, filter, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('budget', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const saveBudgetData = (data, date, method) => async dispatch => {
  dispatch(showLoader());

  const intervals = {
    weekly: 'P1W',
    biweekly: 'P2W',
    monthly: 'P1M',
  };

  try {
    const params = {
      type: data.type.indexOf('scheduled_') > -1 ? data.type : `scheduled_${data.type}`,
      tag_key: data.tag_key,
      note: data.note,
      repeatable: data.repeatable,
      access: data.access,
      date: moment(data.transaction_date).format(DEFAULT_DATE_FORMAT),
    };

    if(data.repeatable) {
      params.starts = moment(data.transaction_date).format(DEFAULT_DATE_FORMAT);
      params.ends = moment(data.end_date).format(DEFAULT_DATE_FORMAT);
      params.interval = intervals[data.interval];
      params.repeatable_type = data.repeatable_type;
    }

    let methodName = '';
    let url = '';
    if(method === 'add') {
      const formattedDate = formatFinancialUrlDate(date);
      methodName = 'post';

      params.user_ids = data.user_ids;
      params.amounts = setAmount(data.total_amount, data.user_ids.length);
      url = `${BASE_URL}budgets/${formattedDate}/scheduled_transactions`;
    } else {
      methodName = 'patch';

      params.user_id = data.user_ids[0];
      params.amount = setAmount(data.total_amount, 1)[0];
      url = `${BASE_URL}scheduled_transactions/${data.id}`;
    }

    const response = await api[methodName](url, params);
    const newBudgetData = response.data;
    dispatch(saveBudgetDataSuccess(newBudgetData));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('budget', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteBudgetData = (data, date) => async dispatch => {
  dispatch(showLoader());

  const params = {
    repeatable: data.repeatable,
  };

  if(data.repeatable) {
    params.repeatable_type = data.repeatable_type;
  }

  try {
    await api.deleteWithParam(`${BASE_URL}scheduled_transactions/${data.id}`, params);
    dispatch(deleteBudgetDataSuccess(data));
    dispatch(hideLoader());
    dispatch(fetchTagKeys());
    dispatch(fetchBudgetData(date, 'all', false));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('budget', message));
  }
};
