import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { Form, ModalConfirm, RepeatableEdit } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';
import { isEdge, isMac } from '../../../utils/browser';
import { getContentWidth } from '../../../utils/tools';

import CalendarNavigation from './navigation/CalendarNavigation';
import CalendarDatePicker from './navigation/CalendarDatePicker';
import CalendarModal from './modal/CalendarModal';
import CalendarHeader from './CalendarHeader';
import CalendarPrintActions from './print/CalendarPrintActions';
import CalendarInner from './CalendarInner';


export const Calendar = ({ user, layout, loading, calendar, caretakers, kids, setAlertsList, ...props }, { t: translate }) => {
  const filterEvents = events => {
    const filteredEvents = [];
    events && events.map(event => {
      if(event.caretaker_ids.length === 0) {
        filteredEvents.push(event);
      }
      props.filters.caretakers && props.filters.caretakers.forEach(caretakerId => {
        if(~event.caretaker_ids.indexOf(caretakerId) && filteredEvents.filter(evt => evt.id === event.id).length === 0) {
          filteredEvents.push(event);
        }
      });
    });
    return filteredEvents;
  };

  const addNewEvent = (type, start, end, personId) => {
    const slot = {
      start: start || calendar.calendarDate,
      end: end || calendar.calendarDate,
    };
    props.handleEventFormChange('type', type === 'longTerm' ? 'care' : 'event');
    if(personId && kids[personId]) {
      props.handleEventFormChange('kid_ids', [kids[personId].id]);
    }

    if(personId && caretakers[personId]) {
      props.handleEventFormChange('caretaker_ids', [caretakers[personId].id]);
    }
    props.handleSelectedSlot(slot, {});
  };

  const getCalendarPeople = () => {
    const caretakersForCalendar = {};
    Object.keys(caretakers).map(caretakerId => {
      if(caretakers[caretakerId] && caretakers[caretakerId].familyStatus !== 'ex') {
        calendar.usersEvents.forEach(userEvent => {
          if(userEvent.id === caretakerId && user.id !== caretakerId) {
            caretakersForCalendar[caretakerId] = caretakers[caretakerId];
          }
        });
      }
    });
    return Object.assign({}, {
      [user.id]: user,
    }, kids, caretakersForCalendar);
  };


  const calendarPeople = getCalendarPeople();

  const contentWidth = getContentWidth(layout);
  const leftColumnWidth = 86;
  let columnWidth = Math.ceil((contentWidth - leftColumnWidth) / Object.keys(calendarPeople).length);
  if(columnWidth < 280) {
    columnWidth = 280;
  }

  const rangeStartDate = props.printAction === 'export' ?
    moment(calendar.rangeStartDate).subtract(60, 'days').format('YYYY-MM-DD') :
    moment(calendar.rangeStartDate).format('YYYY-MM-DD');
  const isCalendarBiggerThenWindow = () => {
    const factor = Object.keys(calendarPeople).length * 280;
    return factor > getContentWidth(layout);
  };

  return (
    <SignedInLayout
      page="Calendar"
      currentPath="/calendar"
      user={user}
      loading={loading}
      layout={layout}
    >
      {props.datePickerOpened && (
        <CalendarDatePicker
          calendar={calendar}
          setCalendarDate={props.setCalendarDate}
          contentWidth={contentWidth}
          user={user}
        />
      )}

      <div className={`content-header ${calendar.viewType}`}>
        <CalendarNavigation user={user} calendar={calendar} caretakers={caretakers} contentWidth={contentWidth} {...props} />
        <CalendarPrintActions
          possibleActions={calendar.viewType === 'week' ? ['print', 'download', 'export'] : ['export']}
          calendarPeople={calendarPeople}
          rangeStartDate={rangeStartDate}
          printAction={props.printAction}
          showPrintModal={props.showPrintModal}
          closePrintModal={props.closePrintModal}
          printModalOpened={props.printModalOpened}
          handlePrintUserChange={props.handlePrintUserChange}
          printSelectedUser={props.printSelectedUser}
          setPrintAction={props.setPrintAction}
          user={user}
        />
      </div>

      <div className="calendar-content-container">
        <CalendarHeader user={user} layout={layout} calendar={calendar} columnWidth={columnWidth} calendarPeople={calendarPeople} />

        <div
          className={classnames('calendars', calendar.viewType, {
            'edge': isEdge,
            'mac': isMac && !isCalendarBiggerThenWindow(),
            'print': layout.printable,
          })}
        >
          {Object.keys(calendarPeople).length === 0 && (
            <div className="empty-view-message">{translate('Please invite people to your family to see the calendar')}</div>
          )}

          <CalendarInner
            user={user}
            calendar={calendar}
            caretakers={caretakers}
            calendarPeople={calendarPeople}
            columnWidth={columnWidth}
            handleSelectedSlot={props.handleSelectedSlot}
            filterEvents={filterEvents}
            filters={props.filters}
            editEvent={props.editEvent}
            addNewEvent={addNewEvent}
          />
        </div>
      </div>

      {props.confirmModalOpened && (
        <ModalConfirm
          isOpen={props.confirmModalOpened}
          closeModal={() => props.closeEventModal(true)}
          question={translate('Are you sure you want to delete an event?')}
          deleteFunction={props.deleteEvent}
          innerComponent={props.eventToDelete.repeatable && (
            <Form.Row>
              <RepeatableEdit
                editFormData={props.eventFormData}
                handleEventFormChange={props.handleEventFormChange}
                label={translate('Choose event to delete')}
              />
            </Form.Row>
          )}
        />
      )}

      {props.modalOpened && (
        <CalendarModal
          user={user}
          kids={kids}
          caretakers={caretakers}
          calendarPeople={calendarPeople}
          modalOpened={props.modalOpened}
          saveCalendarEvent={props.saveCalendarEvent}
          handleEventFormChange={props.handleEventFormChange}
          closeEventModal={props.closeEventModal}
          eventFormData={props.eventFormData}
          startTimePickerOpened={props.startTimePickerOpened}
          endTimePickerOpened={props.endTimePickerOpened}
          toggleTimePicker={props.toggleTimePicker}
          closeTimePickers={props.closeTimePickers}
          setEventToDelete={props.setEventToDelete}
          error={props.error}
          errorMessage={props.errorMessage}
          loading={loading}
          setAlertsList={setAlertsList}
        />
      )}
    </SignedInLayout>
  );
};

Calendar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Calendar;
