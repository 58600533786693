import React from 'react';

import SignedInLayout from '../../../layouts/SignedInLayout';

import InvitePartnerForm from './InvitePartnerForm';

const InviteFamilyMember = ({
  user, cancelForm, formData, error, loading, layout, validateForm, onFieldChange, onSubmit,
}) => {
  return (
    <SignedInLayout
      page="Family"
      currentPath="/home"
      user={user}
      loading={loading}
      layout={layout}
    >
      <InvitePartnerForm
        {...formData}
        loading={loading}
        error={error}
        validateForm={validateForm}
        cancelForm={cancelForm}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
      />
    </SignedInLayout>
  );
};


export default InviteFamilyMember;
