import React from 'react';
import classnames from 'classnames';

import Header from '../../components/Header';
import AsideMenu from '../../components/AsideMenu';

import Loading from './../../components/Loading';


export const SignedInLayout = ({ user, loading, page, currentPath, layout, children }) => {
  const pathName = currentPath.slice(1);
  let styles = 'signedin-layout';
  if(layout.collapsed) {
    styles += ' collapsed';
  }
  if(layout.printable) {
    styles += ' printable';
  }

  return (
    <div className={styles}>
      <Header pathName={pathName} user={user} type={layout.collapsed ? 'collapsed' : ''} dataE2E="dashboard-header" />
      <div className={classnames('signedin-layout__content', page)}>
        <AsideMenu
          currentPath={currentPath}
          user={user}
        />
        <Loading show={loading} style={{ overflow: 'auto' }}>
          {children}
        </Loading>
      </div>
    </div>
  );
};


export default SignedInLayout;
