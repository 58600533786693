import Card from './Card';
import EmptyCard from './EmptyCard';
import Leaf from './Leaf';
import EmptyLeaf from './EmptyLeaf';

Card.Empty = EmptyCard;
Card.Leaf = Leaf;
Card.Leaf.Empty = EmptyLeaf;

export default Card;
