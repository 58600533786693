import React from 'react';

import Button from './../Button';

const EmptyLeaf = ({ title, icon, onClick, style }) => {
  const label = (<span><i className={`zmdi ${icon}`} /> {title}</span>);

  return (
    <div className="card leaf empty">
      <Button label={label} onClick={onClick} style={style} align="center" />
    </div>
  );
};


export default EmptyLeaf;
