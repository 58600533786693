import {
  MODAL_OPEN,
  MODAL_HIDE,
  SET_NAVIGATION_COLLAPSED,
  SET_NAVIGATION_EXPANDED,
  SET_LAYOUT_PRINTABLE,
  UNSET_LAYOUT_PRINTABLE,
} from './../types';


export function collapseNavigation() {
  return {
    type: SET_NAVIGATION_COLLAPSED,
  };
}

export function expandNavigation() {
  return {
    type: SET_NAVIGATION_EXPANDED,
  };
}

export function setLayoutPrintable() {
  return {
    type: SET_LAYOUT_PRINTABLE,
  };
}

export function unsetLayoutPrintable() {
  return {
    type: UNSET_LAYOUT_PRINTABLE,
  };
}

export const showModal = ({
  name,
  data = {},
}) => ({
  type: MODAL_OPEN,
  payload: { name, data },
});

export const hideModal = () => ({
  type: MODAL_HIDE,
});
