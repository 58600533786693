import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from '../../components';
import { TWO_HOMES_KIDS_APP_SCHEME } from '../../utils/net';

class KidsAppRedirect extends React.PureComponent {
  componentDidMount() {
    const { history, location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const language = urlParams.get('language');

    if(token) {
      window.location.replace(`${TWO_HOMES_KIDS_APP_SCHEME}--/app2homes-kids?loginToken=${token}&language=${language}`);
    } else {
      history.push('/');
    }
  }

  render() {
    return (
      <Loading show />
    );
  }
}


KidsAppRedirect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KidsAppRedirect;
