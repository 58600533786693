/* eslint no-console: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
require('react-big-calendar/lib/css/react-big-calendar.css');

import { getCssPrefix, isEdge } from './../../../../utils/browser';
import { getFontColor } from './../../../../utils/tools';
import EventParticipants from './EventParticipants';


export const CalendarBig = ({ events, calendar, caretakers, calendarPeople, editEvent, handleSelectedSlot, setEventColor }, { t: translate }) => {
  const localizer = momentLocalizer(moment);

  const eventStyleGetter = event => {
    const style = {
      borderRadius: '0px',
      borderRight: '0px',
      borderLeft: '0px',
    };

    const calendarParams = {
      style,
    };

    calendarParams.className = '';
    if(event.allDayEvent) {
      calendarParams.className = 'all-day-event';
    }

    const eventType = event.longTerm ? 'longTerm' : 'normal';
    const color = setEventColor(eventType, event, event.person, event.creator_id, event.longTerm ? caretakers : calendarPeople);

    if(event.longTerm) {
      const prefix = getCssPrefix();
      event.background = color;
      calendarParams.style.backgroundImage = `${prefix}linear-gradient(left, ${color} 10px, transparent 0)`;
      calendarParams.style.backgroundColor = isEdge ? `${color}` : 'transparent';
      calendarParams.className = 'long-term';
    } else {
      event.background = color; // event.caretaker_ids.length === 1 ? caretakers[event.caretaker_ids[0]].color : caretakers[event.creator_id].color;
      calendarParams.style.backgroundImage = 'none';
      calendarParams.style.backgroundColor = color;
      calendarParams.className = `left${event.eventsInSameTime.length === 1 ? event.eventsInSameTime.length + 1 : event.eventsInSameTime.length}`;
    }
    return calendarParams;
  };

  const Event = ({ event }) => {
    const startDate = moment(event.start).format('HH:mm');
    const endDate = moment(event.end).format('HH:mm');
    const minutesDifference = moment(event.end).diff(moment(event.start), 'minutes');


    if(event.longTerm) {
      return (
        <span className="custom-long-term-event"
          style={{ background: event.background }}
          data-e2e={`calendar-long-term-container-${event.title}`}
        >
          <span className="event-title" style={{ color: getFontColor(event.background) }}>{event.title}</span>
          <span className="event-time" style={{ color: getFontColor(event.background) }}>{startDate} - {endDate}</span>
        </span>
      );
    }

    const eventStyles = {
      width: '100%',
      height: '100%',
      display: 'block',
    };

    const showParticipants = true; // minutesDifference >= 120;
    const showNote = false; // minutesDifference >= 180;

    return (
      <span className="custom-event"
        title={event.editing_locked ? translate('Editing not allowed') : translate('Edit event')}
        style={eventStyles}
        data-e2e={`calendar-event-container-${event.title}`}
      >
        {minutesDifference > 30 && (<span className="event-time f11" style={{ color: getFontColor(event.background, { black: '#2e383e' }) }}>{startDate} - {endDate}</span>)}
        <span className="event-title f14" style={{ color: getFontColor(event.background) }}>
          {minutesDifference <= 30 && (
            <span className="f11" style={{ color: getFontColor(event.background) }}>{startDate}, </span>
          )}
          {event.title}
        </span>
        {showParticipants && event.participant_ids.length && (
          <EventParticipants
            type="normal"
            calendarEvent={event}
            calendarPeople={calendarPeople}
            caretakers={caretakers}
            eventColor={event.background}
            mainClassNames={`event-note ${(!showNote && event.note !== '') ? '' : 'fullwidth'}`}
          />
        )}
        {showNote && event.note && (<span className="event-note" data-e2e={`calendar-event-container-${event.title}-note`}>{event.note}</span>)}
        {!showNote && event.note !== '' && minutesDifference > 30 && (
          <span className={`day-note-notificator ${getFontColor(event.background)}`} title={translate('Event contains notes')} />
        )}
      </span>
    );
  };

  return (
    <BigCalendar
      selectable
      popup
      events={events}
      culture="en-gb"
      defaultView={calendar.viewType}
      toolbar={false}
      date={calendar.calendarDate}
      onNavigate={() => console.log('navigate')}
      onSelectEvent={editEvent}
      onSelectSlot={handleSelectedSlot}
      eventPropGetter={eventStyleGetter}
      components={{
        event: Event,
      }}
      localizer={localizer}
    />
  );
};


CalendarBig.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarBig;
