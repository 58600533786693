import React from 'react';
import PropTypes from 'prop-types';

import { Form, Button, Modal } from './../../components';


const ModalConfirm = ({ ...props }, { t: translate }) => {
  return (
    <Modal
      small
      isOpen={props.isOpen}
      label={props.innerComponent ? 'Default Confirm Extended' : 'Default Confirm'}
      closeModal={() => props.closeModal(true)}
    >
      <div className={`default-confirm-modal ${props.additionalClassNames}`}>
        <Form onSubmit={props.deleteFunction} loading={false}>
          <Form.Row>
            <p className="center modal-question">{props.question}</p>
          </Form.Row>
          {props.innerComponent}
          <Form.Summary>
            <Button
              autoFocus
              type="submit"
              label={props.buttonLabel || translate('Yes, delete')}
              align="center"
              data-e2e={props.dataE2E}
            />
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


ModalConfirm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModalConfirm;
