import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '../../../components';

const ShareFile = ({ ...props }, { t: translate }) => (
  <div className="Share">
    <div><img className="logo" src="assets/logo2x.png" /></div>
    <h5>
      {translate('Hi, ')}
      <strong>{props.author}</strong>
      {translate(' has shared the following file:')}
    </h5>

    <div className="file-section">
      {props.filename && (
        <span className="block m-t-10 m-b-30 f14">{props.filename}</span>
      )}

      <div className="button-container">
        <Link target="_blank" href={props.fileurl} className="button p-h-10 p-v-10 f15">
          {translate('Click here to download')}
        </Link>
      </div>
    </div>

    <div className="login-info">
      <span className="block">{translate("If you still don't have account on 2homes, just go to")}</span>
      <Link to="/sign-in" style={{ marginRight: 5 }}>2homes.com</Link>
      {translate('and create a new one!')}
    </div>
  </div>
);


ShareFile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ShareFile;
