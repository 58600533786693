import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isEdge } from './../../../../utils/browser';


export const CalendarPrint = ({ calendarUser, events, week, pages }, { t: translate }) => {
  const participantsList = participants => {
    return (
      <ul className="participants">
        {participants && participants.map((participant, pidx) => {
          return participant && (
            <li key={pidx} style={{ color: participant.color }}>
              {`@${participant.nickname || participant.first_name.toLowerCase()}` }
            </li>
          );
        })}
      </ul>
    );
  };

  const eventChildCare = caring => {
    return (
      <div className="tcell caring">
        {caring && caring.map((car, cidx) => {
          return (
            <div key={cidx} className="m-b-20" style={{ color: car.caretaker.color }}>
              {car.allDay && (<span>{translate('all day')}</span>)}
              {!car.allDay && (<span>{car.startTime} - {car.endTime}</span>)}
              <span className="block">
                {car.caretaker && car.caretaker.id !== calendarUser.id && (
                  <span className="f14 m-r-10 block">{`@${car.caretaker.nickname || car.caretaker.first_name.toLowerCase()}`}</span>
                )}
                {car.kids.length > 0 && car.caretaker.id === calendarUser.id && car.kids.map((kid, idx) => (
                  <span className="f14 m-r-10 block" key={idx}>{`@${kid.nickname || kid.first_name.toLowerCase()}`}</span>
                ))}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const eventDetails = details => {
    return (
      <div className="tcell events">
        {details && details.map((detail, didx) => {
          return (
            <div key={didx} className="event">
              <div className="times">{detail.times[0]} - {detail.times[1]}</div>
              <div className="details">
                <span className="name">{detail.title}</span>
                {participantsList(detail.participants)}
              </div>
              <div className="notes">{detail.notes}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const eventsList = days => {
    return (
      <div className="tbody">
        {events && events.map((event, idx) => {
          if(days === undefined || (days && days.indexOf(idx) > -1) || days.length === 0) {
            const day = moment(event.date).format('ddd');
            const date = moment(event.date).format('D MMM.');
            return (
              <div key={idx} className="trow day-row">
                <div className="tcell day">{day}<br />{date}</div>
                {eventChildCare(event.caring)}
                {eventDetails(event.details)}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const tableHeader = idx => {
    return (
      <div className={`table-header idx${idx}`}>
        <div className="day">{translate('Day')}</div>
        <div className="caring">{translate('Caring')}</div>
        <div className="events">{translate('Event')}</div>
        <div className="notes">{translate('Notes')}</div>
      </div>
    );
  };

  return (
    <div className="CalendarPrint Print">
      <div className="container">
        {pages && Object.keys(pages).map((page, idx) => {
          return (
            <div key={idx} className="page">
              {idx === 0 && (
                <div>
                  <div className="logo print">
                    <img src="/assets/homes_print2x.png" width="48" height="44" />
                  </div>
                  <div className="title">{calendarUser.firstName}{translate("'s calendar")}</div>
                  <div className="week">{week}</div>
                </div>
              )}

              {idx > 0 && (
                <div className="page-header">
                  <div className="pull-left">{`${calendarUser.firstName}${translate("'s calendar of ")}${week}`}</div>
                </div>
              )}

              <div>
                {tableHeader(idx)}
                <div className={`table ${isEdge ? 'edge' : ''}`}>
                  {eventsList(pages[page].rows)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};


CalendarPrint.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarPrint;
