import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import {
  Avatar, AvatarPlaceholder, Badge, Button, Card,
  FileUploader, ColorPicker, DatePicker, Form,
  Input, NicknameField, Notification,
} from '../../../components';
import { prepareFileUrl } from '../../../utils/file';
import { DEFAULT_BIRTHDATE_FORMAT } from '../../../utils/date';


export const KidPersonalInfo = ({ editMode, currentKidData, handleFormChange, handleAvatarChange, saveData, loading, error, user, openInviteModal, copyPathToClipboard }, { t: translate }) => {
  const birthDate = currentKidData.birthdate && moment(currentKidData.birthdate) || moment();
  const datePickerMaxDate = moment().subtract(1, 'd');

  const avatarChange = fileResult => {
    currentKidData.file = fileResult.length && fileResult[0].url;
    handleAvatarChange(fileResult);
  };

  const avatar = { thumbnailUrl: prepareFileUrl(currentKidData.thumbnail_url), fileUrl: prepareFileUrl(currentKidData.file_url) };

  const editingBlocked = currentKidData.whichFamily === 'partnerEx';

  const handleOpenInvitationModal = () => {
    openInviteModal();
    copyPathToClipboard();
  };

  return (
    <Card
      className={classnames('kid-profile-container m-b-10', { edited: editMode })}
      deleteIcon="close"
      onDelete={() => window.history.back()}
      title={translate('Profile information')}
    >
      <div className="kid-profile-column">
        <div className="avatar" style={{ borderColor: currentKidData.color }}>
          {currentKidData.file_url ? (
            <Avatar avatar={avatar} />
          ) : (
            <AvatarPlaceholder user={currentKidData} />
          )}
        </div>
        {currentKidData.whichFamily !== 'partnerEx' ? (
          <FileUploader accept={'.jpg, .png'} handleUploadFile={avatarChange} translate={translate} />
        ) : null}
      </div>
      <div className="kid-profile-column">
        <Form loading={loading}>
          <Form.Row>
            <Input
              disabled={editingBlocked}
              type="text"
              name="first name"
              label={translate('name')}
              value={currentKidData.first_name || ''}
              onChange={handleFormChange('personal', 'first_name')}
              error={error}
            />
          </Form.Row>
          <Form.Row>
            <Input
              disabled={editingBlocked}
              type="text"
              name="last name"
              label={translate('surname')}
              value={currentKidData.last_name || ''}
              onChange={handleFormChange('personal', 'last_name')}
              error={error}
            />
          </Form.Row>
          <Form.Row>
            <NicknameField
              disabled={editingBlocked}
              name="nickname"
              label={translate('nickname')}
              value={currentKidData.nickname || currentKidData.first_name.toLowerCase() || ''}
              onChange={handleFormChange('personal', 'nickname')}
              error={error}
            />
          </Form.Row>
          <Form.Row>
            <div className="badges-section">
              <div className="title inline-block m-r-15">{translate('color')}:</div>
              <div className="color-picker-container">
                <ColorPicker
                  color={currentKidData.color || '#fff'}
                  onChange={color => handleFormChange('personal', 'color', color)}
                />
              </div>
            </div>
          </Form.Row>
        </Form>
      </div>
      <div className="kid-profile-column">
        <Form>
          <Form.Row>
            <div className="badges-section">
              <div className="title inline-block m-r-15 f16">{translate('gender')}:</div>
              <Badge
                label={translate('boy')}
                active={currentKidData.gender === 'male'}
                activeColor="bg-darkgrey"
                onClick={() => !editingBlocked && handleFormChange('personal', 'gender', 'male')}
              />
              <Badge
                label={translate('girl')}
                active={currentKidData.gender === 'female'}
                activeColor="bg-darkgrey"
                onClick={() => !editingBlocked && handleFormChange('personal', 'gender', 'female')}
              />
            </div>
          </Form.Row>
          <Form.Row>
            <DatePicker
              dateFormat={DEFAULT_BIRTHDATE_FORMAT}
              disabled={editingBlocked}
              locale={user.language || 'en-gb'}
              maxDate={datePickerMaxDate}
              onChange={date => !editingBlocked && handleFormChange('personal', 'birthdate', date.format('YYYY-MM-DD'))}
              selected={birthDate}
            />
          </Form.Row>
          <Form.Row className="m-t-80">
            <Button
              align="right"
              buttonClassName="clean vertical-center-content"
              onClick={handleOpenInvitationModal}
              type="button"
            >
              <div className="kidsLogo" />
              {translate('invite to 2homes kids')}
            </Button>
          </Form.Row>
        </Form>
      </div>

      {editMode && (
        <div className="kid-profile-submit-changes">
          {error && (
            <Notification type="error" text={error} exclude={['first name', 'last name', 'nickname', 'email']} />
          )}
          <Button onClick={saveData} buttonClassName="clean small" label={translate('save changes')} />
        </div>
      )}
    </Card>
  );
};


KidPersonalInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KidPersonalInfo;
