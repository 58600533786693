import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from './../../../../components';


export const ModalDocument = ({ ...props }, { t: translate }) => {
  return (
    <div>
      <Form.Row>
        <Input
          type="text"
          name="filename"
          label={translate('title')}
          value={props.editFormData.title || ''}
          onChange={props.handleFormChange('document', 'title')}
          error={props.error}
        />
      </Form.Row>
      <Form.Row>
        <div className="textarea-container">
          <textarea className="textarea-note" placeholder={translate('description')}
            onChange={props.handleFormChange('document', 'description')}
            value={props.editFormData.description || ''}
          />
        </div>
      </Form.Row>
    </div>
  );
};


ModalDocument.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModalDocument;
