import React from 'react';
import PropTypes from 'prop-types';

import CalendarPrintModal from './../modal/CalendarPrintModal';


export const CalendarPrintActions = ({ user, calendarPeople, printSelectedUser, rangeStartDate, printAction, handlePrintUserChange, showPrintModal, closePrintModal, printModalOpened, possibleActions, setPrintAction }, { t: translate }) => {
  return (
    <div className="calendar-print-actions">
      <div onClick={showPrintModal} title={translate('Download')}>
        <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="S2---Calendar-corrections" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Print/pdf-icon-placement---2-Copy" transform="translate(-908.000000, -26.000000)">
              <g id="pdf" transform="translate(907.000000, 26.000000)">
                <rect id="Rectangle-path" x="0" y="0" width="20" height="20" />
                <path d="M6,18 L9,18 L9,20 L6,20 C4.67391755,20 3.40214799,19.4732158 2.46446609,18.5355339 C1.5267842,17.597852 1,16.3260824 1,15 L1,5 C1,2.23857625 3.23857625,5.07265313e-16 6,0 L14,0 C16.7614237,1.69088438e-16 19,2.23857625 19,5 L19,8 L17,8 L17,5 C17,3.34314575 15.6568542,2 14,2 L6,2 C4.34314575,2 3,3.34314575 3,5 L3,15 C3,16.6568542 4.34314575,18 6,18 Z M19.75,14.45 C19.3599625,14.0622764 18.7300375,14.0622764 18.34,14.45 L18.34,14.45 L16,16.65 L16,11 C16,10.4477153 15.5522847,10 15,10 C14.4477153,10 14,10.4477153 14,11 L14,16.59 L11.71,14.3 C11.4656101,14.0146238 11.0818816,13.8903181 10.716581,13.9781899 C10.3512805,14.0660617 10.0660617,14.3512805 9.97818987,14.716581 C9.89031806,15.0818816 10.0146238,15.4656101 10.3,15.71 L14.3,19.71 C14.6888435,20.0911429 15.3111565,20.0911429 15.7,19.71 L19.7,15.85 C20.0867519,15.4666244 20.0956427,14.8442669 19.72,14.45 L19.75,14.45 Z" id="Shape" fill="#231F20" fillRule="nonzero" />
              </g>
            </g>
          </g>
        </svg>
      </div>

      <CalendarPrintModal
        possibleActions={possibleActions}
        user={user}
        printAction={printAction}
        calendarPeople={calendarPeople}
        rangeStartDate={rangeStartDate}
        handlePrintUserChange={handlePrintUserChange}
        modalOpened={printModalOpened}
        closePrintModal={closePrintModal}
        printSelectedUser={printSelectedUser}
        setPrintAction={setPrintAction}
      />
    </div>
  );
};


CalendarPrintActions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarPrintActions;
