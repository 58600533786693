import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { Walkthrough } from '../../../components';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded, setCurrentKid, updateCustomProfile } from '../../../store/actions/homes';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import {
  fetchKidsProfile, updateKidsProfile, saveKidsProfileCard, updateKidsProfileCard,
  deleteKidsProfileCard, saveKidsProfileLeaf, updateKidsProfileLeaf, deleteKidsProfileLeaf,
  fetchKidsDocuments, addKidsDocument, updateKidsDocument, deleteKidsProfileDocument,
} from '../../../store/actions/kidprofile';
import { prepareFileUrl } from '../../../utils/file';
import { cleanNickname } from '../../../utils/tools';
import { getAdditionalStepsData, showInitialWalkthrough } from '../../../utils/walkthrough';
import { api, APP_BASE_URL, BASE_URL } from '../../../utils/net';
import KidProfile from '../components/KidProfile';


const DEFAULT_FORM_DATA = {
  type: 'personal', // personal, card, leaf
  parent_id: '',
  id: '',
  title: '',
  description: '',
};


class KidProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      modalOpened: false,
      confirmModalOpened: false,
      currentKidData: {
        id: '',
        first_name: '',
        last_name: '',
        nickname: '',
        gender: '',
        color: '',
        file: '',
        avatar: {
          fullpath: '',
          fullname: '',
        },
        birthdate: '',
        email: '',
        file_url: prepareFileUrl(props.kidCurrent.file_url),
      },
      editFormData: _.cloneDeep(DEFAULT_FORM_DATA),
      inviteModalOpened: false,
      inviteToken: '',
      parentLanguage: '',
      invitationURL: '',
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    this.setCurrentKid();
    showInitialWalkthrough('kidId', this.props.showWalkthrough);
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.params.kidId !== prevProps.match.params.kidId) {
      this.setCurrentKid();
    }
  }

  componentWillUnmount() {
    this.props.cleanError('kid_profile');
  }

  setCurrentKid = () => {
    const kidId = this.props.match.params.kidId;
    const kid = Object.assign({}, this.props.homeUsers[kidId], {
      id: kidId,
    });

    if(!kid.first_name) {
      this.props.history.push('/404');
    } else {
      this.props.setCurrentKid(kid);
      if(kid.whichFamily !== 'partnerEx') {
        this.props.fetchKidsProfile(kid);
        this.props.fetchKidsDocuments(kid);
      }

      const currentKidData = Object.assign({}, this.state.currentKidData, kid);

      this.setState({ currentKidData }, () => {
        this.createInvitationURL();
      });
    }
  }

  handleFormChange = (type, name, forcedValue) => {
    const { editFormData, currentKidData } = this.state;
    let { editMode } = this.state;
    if(forcedValue) {
      if(type === 'personal') {
        currentKidData[name] = forcedValue;
        editMode = true;
      } else {
        editFormData[name] = forcedValue;
        editMode = false;
      }
      this.setState({
        editFormData,
        currentKidData,
        editMode,
      });
    } else {
      return event => {
        if(type === 'personal') {
          currentKidData[name] = event.target.value;
          editMode = true;
        } else {
          editFormData[name] = event.target.value;
          editMode = false;
        }
        this.setState({
          editFormData,
          currentKidData,
          editMode,
        });
      };
    }
  }

  handleAvatarChange = file => {
    this.handleFormChange('personal', 'file', file);
    this.handleFormChange('personal', 'file_url', URL.createObjectURL(file));
  }

  showModal = confirm => {
    this.props.cleanError('kid_profile');
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: true,
    });
  }

  closeModal = confirm => {
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: false,
      editFormData: _.cloneDeep(DEFAULT_FORM_DATA),
    });
  }

  editData = (type, data, parentId) => {
    if(type === 'card') {
      data.title = data.name;
    } else if(type === 'leaf') {
      data.title = data.key;
      data.description = data.value;
    } else if(type === 'document') {
      data.title = data.filename;
      data.description = data.note;
    }

    const { editFormData } = this.state;
    this.setState({
      editFormData: Object.assign({}, editFormData, data, {
        type,
        parent_id: parentId || '',
      }),
    }, () => {
      this.showModal();
    });
  }

  setDataToDelete = (type, data, parentId) => {
    const parent_id = parentId || this.state.editFormData.parent_id;
    this.setState({
      editFormData: Object.assign({}, data, {
        type,
        parent_id,
      }),
    }, () => {
      this.showModal(true);
    });
  }

  deleteData = event => {
    event.preventDefault();
    const { kidCurrent } = this.props;
    const { editFormData } = this.state;
    if(editFormData.type === 'card') {
      this.props.deleteKidsProfileCard(kidCurrent, editFormData.id).then(() => {
        this.closeModal(true);
      });
    } else if(editFormData.type === 'leaf') {
      this.props.deleteKidsProfileLeaf(editFormData).then(() => {
        this.closeModal(true);
      });
    } else if(editFormData.type === 'document') {
      this.props.deleteKidsProfileDocument(kidCurrent, editFormData.id).then(() => {
        this.closeModal(true);
      });
    }
  }

  saveData = event => {
    event.preventDefault();
    this.props.cleanError('kid_profile');
    const { editFormData, currentKidData: { whichFamily } } = this.state;
    if(editFormData.type === 'card') {
      this.saveCard();
    } else if(editFormData.type === 'personal' && whichFamily !== 'partnerEx') {
      this.saveProfile();
    }else if(editFormData.type === 'personal' && whichFamily === 'partnerEx') {
      this.saveCustomProfile();
    }  else if(editFormData.type === 'document') {
      this.saveFile();
    } else {
      this.saveLeaf();
    }
  }

  saveCustomProfile = () => {
    const { id, color } = this.state.currentKidData;
    this.props.updateCustomProfile({ color }, id).then(() => {
      if(!this.props.error || this.props.error === null) {
        this.setState({
          editMode: false,
        }, () => {
          this.setCurrentKid();
          this.props.history.push('/home');
        });
      }
    });
  }

  saveProfile = () => {
    const { id, first_name, last_name, birthdate, gender, color, file, file_url, email } = this.state.currentKidData;
    let { nickname } = this.state.currentKidData;
    nickname = nickname === '' ? first_name.toLowerCase() : cleanNickname(nickname);
    this.props.updateKidsProfile({ first_name, last_name, birthdate, nickname, gender, color, file, file_url, email }, id).then(() => {
      if(!this.props.error || this.props.error === null) {
        this.setState({
          editMode: false,
        }, () => {
          this.setCurrentKid();
        });
      }
    });
  }

  saveCard = () => {
    const { kidCurrent } = this.props;
    const { editFormData } = this.state;
    if(!_.isEmpty(editFormData.id)) {
      this.props.updateKidsProfileCard(kidCurrent, editFormData).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.clearForm();
        }
      });
    } else {
      this.props.saveKidsProfileCard(kidCurrent, editFormData.title).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.clearForm();
        }
      });
    }
  }

  saveLeaf = () => {
    const { editFormData } = this.state;
    if(!_.isEmpty(editFormData.id)) {
      this.props.updateKidsProfileLeaf(editFormData).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.clearForm();
        }
      });
    } else {
      this.props.saveKidsProfileLeaf(editFormData).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.clearForm();
        }
      });
    }
  }

  saveFile = (file, fileType) => {
    const { kidCurrent } = this.props;
    const { editFormData } = this.state;
    if(file) {
      this.props.addKidsDocument(kidCurrent, file, fileType).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.clearForm();
        }
      });
    } else {
      const fileToEdit = {
        filename: editFormData.title,
        note: editFormData.description,
      };
      this.props.updateKidsDocument(kidCurrent, fileToEdit, editFormData.id).then(() => {
        if(!this.props.error || this.props.error === null) {
          this.closeModal();
        }
      });
    }
  }

  clearForm = () => {
    this.setState({
      editFormData: _.cloneDeep(DEFAULT_FORM_DATA),
    });
    this.closeModal();
  }

  generateAdditionalSteps = () => {
    const { kidCurrent: { documents = [], profile = {} } } = this.props;
    const { t } = this.context;
    const additionalSteps = [];
    const steps = getAdditionalStepsData('/kidId', t);

    if(documents.length) {
      additionalSteps.push(steps.files);
    }

    if(profile.categories && profile.categories.length) {
      additionalSteps.push(...steps.notes);
    }

    if(profile.categories && profile.categories.length && profile.categories[0].information.length) {
      additionalSteps.push(steps.leaf);
    }

    return additionalSteps;
  }

  createInvitationURL = async() => {
    let loginToken = '';
    let invitationURL = '';
    let parentLanguage = '';

    try {
      const { data } = await api.post(`${BASE_URL}kid_entry_tokens?id=${this.state.currentKidData.id}`);

      loginToken = data.token;
      parentLanguage = data.language;
      invitationURL = `${APP_BASE_URL}2homeskids?token=${loginToken}&language=${parentLanguage}`;
      this.setState({ loginToken, parentLanguage, invitationURL });
    } catch (e) {
      console.log({ e });
    }
  }

  copyPathToClipboard = () => {
    const textArea = document.getElementById('invitation-link');
    textArea.select();

    document.execCommand('copy');
  }

  toggleInviteModal = () => {
    this.setState({ inviteModalOpened: !this.state.inviteModalOpened });
  }

  render() {
    const additionalSteps = this.generateAdditionalSteps();

    return (
      <div>
        <Walkthrough
          additionalSteps={additionalSteps}
          currentPath={'/kidId'}
          stepsPlacement={{ 2: 'top-start' }}
        />

        <KidProfile
          {...this.props}
          {...this.state}
          closeModal={this.closeModal}
          copyPathToClipboard={this.copyPathToClipboard}
          handleAvatarChange={this.handleAvatarChange}
          handleFormChange={this.handleFormChange}
          editData={this.editData}
          setDataToDelete={this.setDataToDelete}
          deleteData={this.deleteData}
          saveData={this.saveData}
          saveFile={this.saveFile}
          toggleInviteModal={this.toggleInviteModal}
        />
      </div>
    );
  }
}


KidProfileContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    homeUsers: state.homeUsers,
    kidCurrent: state.kidCurrent,
    error: state.errors.kid_profile,
    isWalkthroughOpen: state.walkthrough.isOpen,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  setCurrentKid,
  fetchKidsProfile,
  updateKidsProfile,
  updateCustomProfile,
  saveKidsProfileCard,
  updateKidsProfileCard,
  deleteKidsProfileCard,
  saveKidsProfileLeaf,
  updateKidsProfileLeaf,
  deleteKidsProfileLeaf,
  fetchKidsDocuments,
  addKidsDocument,
  updateKidsDocument,
  deleteKidsProfileDocument,
  cleanError,
  showWalkthrough,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KidProfileContainer);
