import React from 'react';
import * as _ from 'lodash';

import { Badge } from './../../components';


export const BadgesList = ({ list, label, activeBadges, formKey, activeColor, handleFormChange, forceOne, inline, disabledBadges, editingLocked }) => {
  const handleChange = element => {
    let newList = [];
    if(activeBadges.indexOf(element) > -1) {
      newList = _.without(activeBadges, element);
    } else {
      activeBadges.push(element);
      newList = _.cloneDeep(activeBadges);
    }
    if(forceOne) {
      newList = [element];
    }

    if(typeof handleFormChange === 'function') {
      handleFormChange(formKey, newList);
    }
    return false;
  };

  return (
    <div className="badges-section" style={inline ? { 'display': 'inline' } : {}}>
      {label && (<div className="title">{label}</div>)}
      {list && list.map(element => {
        if(!editingLocked) {
          return (
            <Badge
              key={element.id}
              label={element.nickname ? `@${element.nickname}` : `@${element.first_name.toLowerCase()}`}
              active={activeBadges.indexOf(element.id) > -1}
              bgColor={element.color}
              activeColor={activeColor || element.color}
              disabled={disabledBadges && disabledBadges.indexOf(element.id) > -1}
              onClick={() => handleChange(element.id)}
            />
          );
        } else if(activeBadges.indexOf(element.id) > -1) {
          return (
            <Badge
              active
              key={element.id}
              label={element.nickname ? `@${element.nickname}` : `@${element.first_name.toLowerCase()}`}
              bgColor={element.color}
              activeColor={activeColor || element.color}
              disabled={false}
            />
          );
        }
      })}
    </div>
  );
};

export default BadgesList;
