import React from 'react';


const EmptyCard = ({ title, icon, onClick }) => {
  return (
    <div className="card empty pointer" onClick={onClick}>
      <i className={`zmdi ${icon}`} />
      <span className="p-l-10 f16">{title}</span>
    </div>
  );
};


export default EmptyCard;
