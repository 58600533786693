import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isEdge } from '../../../utils/browser';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from '../../../utils/date';
import * as file from '../../../utils/file';


export const FinancesPendingTable = ({ pendingData, user, homeUsers, caretakers, editPendingFile, setFileToDelete, showIcon }, { t: translate }) => {
  const editFile = data => {
    data.type = 'expense';
    editPendingFile(data, 'pending');
  };

  const getAuthor = row => {
    let author;
    let authorColor;
    if(!row.owner_id || user.id === row.owner_id) {
      author = `@${user.nickname}` || user.first_name.toLowerCase();
      authorColor = caretakers[user.id] && caretakers[user.id].color;
    } else {
      author = homeUsers && Object.keys(homeUsers).length > 0 && homeUsers[row.owner_id] && (homeUsers[row.owner_id].nickname || homeUsers[row.owner_id].first_name.toLowerCase());
      authorColor = caretakers && row.owner_id && caretakers[row.owner_id] && caretakers[row.owner_id].color;
    }

    return {
      author,
      authorColor,
    };
  };


  const tableRows = pendingData.map((row, idx) => {
    const fileType = file.getFileType(row.filetype);

    const author = getAuthor(row);

    const date = moment(row.updated_at).format(DEFAULT_DISPLAYED_DATE_FORMAT);
    return (
      <div key={idx} className="trow">
        <div className="tcell medium right">{date}</div>
        <div className="tcell small center"><i className={`zmdi zmdi-${fileType.icon || 'file'}`} /></div>
        <div className="tcell half">
          <a href={file.prepareFileUrl(row.file_url)} target="_blank">{row.filename}</a>
        </div>
        <div className="tcell" style={{ color: author.authorColor }}>{author.author}</div>
        <div className="tcell icons">
          <i className="zmdi zmdi-square-right" style={{ visibility: showIcon ? 'visible' : 'hidden' }} onClick={() => editFile(row)} />
          <i className="zmdi zmdi-delete" style={{ marginLeft: 10, visibility: showIcon ? 'visible' : 'hidden' }} onClick={() => setFileToDelete(row)} />
        </div>
      </div>
    );
  });

  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell medium right">{translate('Uploaded')}</div>
          <div className="tcell">{translate('File')}</div>
          <div className="tcell half">{translate('Name')}</div>
          <div className="tcell">{translate('Author')}</div>
          <div className="tcell" />
        </div>
      </div>
      <div className="tbody">
        {tableRows}
      </div>
    </div>
  );
};


FinancesPendingTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesPendingTable;
