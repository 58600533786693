import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import ReportGeneralSummaries from './ReportGeneralSummaries';
import ReportGeneral from './ReportGeneral';
import ReportUser from './ReportUser';
import ReportTransactions from './ReportTransactions';


const ReportPrint = ({ reports, homeUsers, generalIdxOnPage, summariesPerPage, loading }, { t: translate }) => {
  const { push } = useHistory();

  const sliceChartData = (arr, size) => (
    arr.reduce((acc, item, index) => {
      if (index % size === 0) acc.push(arr.slice(index, index + size));
      return acc;
    }, [])
  );

  const renderSummaryPages = () => {
    const pages = [];
    const MAX_SUMMARY_CHARTS_PER_PAGE = {
      first: 13,
      rest: 16,
    };
    for(let i = 0; i < Object.keys(summariesPerPage).length; i++) {
      const splittedSummaries = reports.summaries.reduce((summaries, summary) => {
        const firstPageExpanses = summary.expenses.tag_keys.slice(0, MAX_SUMMARY_CHARTS_PER_PAGE.first);
        const firstPageIncomes = summary.incomes.tag_keys.slice(0, MAX_SUMMARY_CHARTS_PER_PAGE.first);
        const restExpanses = summary.expenses.tag_keys.slice(MAX_SUMMARY_CHARTS_PER_PAGE.first, summary.expenses.tag_keys.length);
        const restIncomes = summary.incomes.tag_keys.slice(MAX_SUMMARY_CHARTS_PER_PAGE.first, summary.incomes.tag_keys.length);
        const chunkedExpenses = [ firstPageExpanses, ...sliceChartData(restExpanses, MAX_SUMMARY_CHARTS_PER_PAGE.rest) ];
        const chunkedIncomes = [ firstPageIncomes, ...sliceChartData(restIncomes, MAX_SUMMARY_CHARTS_PER_PAGE.rest) ];
        let splittedSummary = [ summary ];

        splittedSummary =
          (chunkedExpenses.length >= chunkedIncomes.length ? chunkedExpenses : chunkedIncomes).map((chunk, idx) => ({
            ...summary,
            hasHeader: idx === 0,
            expenses: {
              ...summary.expenses,
              tag_keys: chunkedExpenses[idx],
            },
            incomes: {
              ...summary.incomes,
              tag_keys: chunkedIncomes[idx],
            },
          }));

        summaries.push(...splittedSummary);

        return summaries;
      }, []);

      const usersSummaries = splittedSummaries.slice(i * summariesPerPage[i], i * summariesPerPage[i] + summariesPerPage[i]);
      pages.push(
        <div key={`kids${i}`} className="page">
          <div className="title">{translate('Summaries')}</div>
          {usersSummaries.map((userData, idx) => {
            return (
              <ReportUser
                key={idx}
                homeUsers={homeUsers}
                userData={userData}
              />
            );
          })}
        </div>,
      );
    }
    return pages;
  };

  const transactionsPerPage = 13;
  const transactionsPages = Math.ceil(reports.transactions.length / transactionsPerPage);
  const renderTransactionsPages = () => {
    const pages = [];
    for(let i = 0; i < transactionsPages; i++) {
      const transactions = reports.transactions.slice(i * transactionsPerPage, i * transactionsPerPage + transactionsPerPage);
      pages.push(
        <div key={`transactions${i}`} className="page">
          <div className="title">{reports.section === 'accounting' ? translate('History of transactions') : translate('Expected transactions')}</div>
          <ReportTransactions transactions={transactions} homeUsers={homeUsers} />
        </div>,
      );
    }
    return pages;
  };

  const renderBackLink = () => {
    let linkBack = '/reports';
    let linkText = translate('back to reports');
    if(window.location.search === '?budget') {
      linkBack = '/budget';
      linkText = translate('back to budget');
    }

    return (
      <div className="m-10 w600 f16 pointer back-to-reports" onClick={() => { push(linkBack); }}>
        <i className="zmdi zmdi-chevron-left" style={{ position: 'relative', top: 2, left: -2 }} /> {linkText}
      </div>
    );
  };

  const titles = {
    accounting: translate('Accounting report'),
    budget: translate('Budget report'),
  };

  return (
    <div>
      {renderBackLink()}

      <div className="ReportPrint Print" id="ReportPrint">
        <div className="page">
          <div className="report-header">
            <div className="logo print">
              <img src="/assets/homes_print2x.png" width="48" height="44" />
            </div>
            <div className="title">{titles[reports.section]}</div>
            <div className="week">{reports.period}</div>
          </div>
          <ReportGeneralSummaries generalData={reports.general} splitsData={reports.splits} />

          <div className="title">{translate('General summaries')}</div>
          <ReportGeneral
            generalData={reports.general}
            generalIdxOnPage={generalIdxOnPage[0]}
            pageNr={0}
          />
        </div>

        {generalIdxOnPage.length > 1 && generalIdxOnPage.map((page, idx) => {
          if(idx > 0) {
            return (
              <div key={idx} className="page">
                <ReportGeneral
                  generalData={reports.general}
                  generalIdxOnPage={generalIdxOnPage[idx]}
                  pageNr={idx}
                />
              </div>
            );
          }
        })}

        {reports.summaries && renderSummaryPages()}
        {reports.transactions && reports.historyEnabled && renderTransactionsPages()}
      </div>

      <div className={`loading ${loading ? 'loading--visible' : ''}`}>
        <div className="loading__overlay">
          <div className="loader">
            <div className="b1">
              <span className="top left" />
              <span className="top right" />
              <span className="bottom left" />
              <span className="bottom right" />
            </div>
            <div className="b2">
              <span className="top left" />
              <span className="top right" />
              <span className="bottom left" />
              <span className="bottom right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


ReportPrint.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportPrint;
