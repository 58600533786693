import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Input } from 'src/components';
import useStore from 'src/hooks/useStore';
import { cleanError } from 'src/store/actions/errors';
import { verifyBonusCode } from 'src/store/actions/subscription';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from 'src/utils/date';


const BonusCode = ({ bonusCode, setBonusCode = () => {} }, { t: translate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useStore();
  const error = state.errors.bonusCode;

  let timeout = null;

  const showSuccessInfo = state.user?.bonusCode && !error;
  const bonusTrialEndDate = moment(state.user?.bonusCode?.trial_ends_on).format(DEFAULT_DISPLAYED_DATE_FORMAT);
  const bonusTrialDaysAmount = state.user?.bonusCode?.trial_days;
  const successInfo = showSuccessInfo ?
    bonusTrialDaysAmount ?
      translate('Bonus code is ok! You will get {bonusTrialDaysAmount} days for free.', { bonusTrialDaysAmount }) :
      translate('Bonus code is ok! Your trial is extended to {bonusTrialEndDate}', { bonusTrialEndDate }) :
    '';

  useEffect(() => {
    return () => dispatch(cleanError('bonusCode'));
  }, []);

  useEffect(() => {
    if(bonusCode) {
      timeout = setTimeout(() => {
        dispatch(verifyBonusCode(bonusCode));
      }, 1500);
    }
    return () => clearTimeout(timeout);
  }, [bonusCode]);

  return (
    <div className="BonusCode">
      <header className="flex space-between" onClick={() => setIsOpen(!isOpen)}>
        <p>{translate('Do you have Bonus Code?')}</p>
        <i className={classnames('Icon zmdi zmdi-chevron-left', { Rotated: isOpen })} />
      </header>

      {isOpen && (
        <Input
          className="BonusCodeInput"
          type="text"
          name="bonus"
          placeholder="xx-xx-xx"
          value={bonusCode}
          onChange={evt => setBonusCode(evt.target.value)}
          error={error}
          hint={successInfo}
          withHintQuestionMark={false}
        />
      )}
    </div>
  );
};

BonusCode.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusCode;
