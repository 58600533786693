import * as _ from 'lodash';

import {
  SET_HOMES_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  UPDATE_CUSTOM_PROFILE_SUCCESS,
} from './../types';


// const ME_COLOR = '#F5754E';
const CARETAKERS_COLORS = [
  '#927B51',
  '#BAA378',
  '#C0A172',
  '#BAA378',
  '#453823',
  '#382E1C',
  '#2C2416',
];

export function caretakersReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      const caretakersArray = [];
      const homeCaretakerMapper = {};

      const homeMapperAddId = (caretakerId, homeId) => {
        if(!homeCaretakerMapper[caretakerId]) {
          homeCaretakerMapper[caretakerId] = [ homeId ];
        } else {
          homeCaretakerMapper[caretakerId].push(homeId);
        }
      };

      if(!_.isNil(action.payload.my_home)) {
        caretakersArray.push([
          ...action.payload.my_home.caretaker_ids,
        ]);
        action.payload.my_home.caretaker_ids.forEach(cId => {
          homeMapperAddId(cId, action.payload.my_home.id);
        });
      }

      const otherHomes = ['my_ex_homes', 'partner_ex_homes'];
      otherHomes.forEach(otherHome => {
        if(action.payload[otherHome].length > 0) {
          caretakersArray.push([
            ...action.payload[otherHome].map(home => home.caretaker_ids),
          ]);
          action.payload[otherHome].forEach(exHome => {
            exHome.caretaker_ids.forEach(cId => {
              homeMapperAddId(cId, exHome.id);
            });
          });
        }
      });

      const caretakersList = _.uniq(
        _.flattenDeep(caretakersArray).sort(),
      );

      let amIMe;
      const caretakersObj = {};
      caretakersList.map((caretaker, idx) => {
        amIMe = action.currentUser.id === caretaker;
        if(action.payload.users[caretaker] && action.payload.users[caretaker].status !== 'pending') {
          caretakersObj[caretaker] = {
            id: caretaker,
            ...action.payload.users[caretaker],
            first_name: action.payload.users[caretaker].first_name,
            color: amIMe ? action.currentUser.color : (action.payload.users[caretaker].color || CARETAKERS_COLORS[idx]),
            current: !amIMe && action.payload.my_home && action.payload.my_home.caretaker_ids.indexOf(caretaker) > -1,
            ex: !amIMe && _.filter(action.payload.my_ex_homes, exHome => {
              return !amIMe && exHome.caretaker_ids.indexOf(caretaker) > -1;
            }).length > 0,
            homeIds: homeCaretakerMapper[caretaker],
          };
        }
      });
      return Object.assign({}, caretakersObj);

    case PROFILE_UPDATE_SUCCESS:
      const updatedCaretaker = Object.assign({}, state[action.payload.id], {
        ...action.payload,
        avatar: action.payload.file,
      });
      return Object.assign({}, state, {
        [action.payload.id]: updatedCaretaker,
      });

    case UPDATE_CUSTOM_PROFILE_SUCCESS:
      const updatedCaretakerCustom = Object.assign({}, state[action.payload.id], {
        ...action.payload,
        avatar: action.payload.file,
      });
      return Object.assign({}, state, {
        [action.payload.id]: updatedCaretakerCustom,
      });

    default:
      return state;
  }
}
