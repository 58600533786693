import React from 'react';
import PropTypes from 'prop-types';

import { SummaryBox } from './../../../components';


export const SplitRequestsSummary = ({ summaryData }, { t: translate }) => {
  const forMePending = (summaryData.for_me && summaryData.for_me.pending) || 0;
  const forMePaid = (summaryData.for_me && summaryData.for_me.paid) || 0;
  const forMeRejected = (summaryData.for_me && summaryData.for_me.rejected * -1) || 0;
  const fromMePending = (summaryData.from_me && summaryData.from_me.pending) || 0;
  const fromMePaid = (summaryData.from_me && summaryData.from_me.paid) || 0;
  const fromMeRejected = (summaryData.from_me && summaryData.from_me.rejected * -1) || 0;
  return (
    <div className="summaries" data-e2e="split-requests-summaries-box">
      <div className="summary">
        <label className="f14">{translate('for me')}</label>
        <SummaryBox
          name={translate('Pending')}
          value={forMePending}
          dotClassName="bg-warning"
          currency="NOK"
        />
        <SummaryBox
          name={translate('Paid')}
          value={forMePaid}
          dotClassName="bg-success"
          currency="NOK"
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Rejected')}
          value={forMeRejected}
          dotClassName="bg-error"
          currency="NOK"
        />
      </div>
      <div className="summary">
        <label className="f14">{translate('from me')}</label>
        <SummaryBox
          name={translate('Pending')}
          value={fromMePending}
          dotClassName="bg-warning"
          currency="NOK"
        />
        <SummaryBox
          name={translate('Paid')}
          value={fromMePaid}
          dotClassName="bg-success"
          currency="NOK"
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Rejected')}
          value={fromMeRejected}
          dotClassName="bg-error"
          currency="NOK"
        />
      </div>
    </div>
  );
};


SplitRequestsSummary.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequestsSummary;
