import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Walkthrough } from 'src/components';
import useStore from 'src/hooks/useStore';
import { fetchHomes } from 'src/store/actions/homes';
import { showModal } from 'src/store/actions/layout';
import { showWalkthrough } from 'src/store/actions/walkthrough';

import Families from '../components/Families';
import { showInitialWalkthrough } from '../../../utils/walkthrough';


const FamiliesContainer = () => {
  const [state, dispatch] = useStore();
  const { location }  = useHistory();
  const { homeUsers, homesUsersFamilies, layout, loading, user } = state;

  useEffect(() => {
    dispatch(fetchHomes());

    showInitialWalkthrough('families', () => dispatch(showWalkthrough('families')));
  }, []);

  return (
    <div>
      <Walkthrough
        currentPath={location.pathname}
        stepsPlacement={{ 1: 'top-start' }}
      />

      <Families
        homeUsers={homeUsers}
        homesUsersFamilies={homesUsersFamilies}
        layout={layout}
        loading={loading}
        showModal={payload => dispatch(showModal(payload))}
        user={user}
      />
    </div>
  );
};

export default FamiliesContainer;
