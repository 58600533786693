import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';

import { Button, Form, Badge, BadgesList, DatePicker, Modal, Notification, RepeatableEdit, TagKeys } from '../../../../components';
import { isEdge } from '../../../../utils/browser';
import { generateValidDates } from '../../../../utils/date';
import { setOrderedFamilyMembers, handleFormKeyPress } from '../../../../utils/tools';


const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';


export const BudgetModal = ({ loading, homeUsers, user, ...props }, { t: translate }) => {
  const startDate = props.editFormData.start_date && moment(props.editFormData.start_date);
  const endDate = props.editFormData.end_date && moment(props.editFormData.end_date);
  const badges = [
    { value: 'weekly', label: translate('weekly') },
    { value: 'biweekly', label: translate('biweekly') },
    { value: 'monthly', label: translate('monthly') },
  ];

  const homeUsersFiltered = setOrderedFamilyMembers(user, homeUsers);

  const formTouched = () => {
    return props.editFormData &&
            (props.editFormData.total_amount !== '' || props.editFormData.note !== '' || props.editFormData.tag_key !== '');
  };

  const validateForm = () => {
    return props.editFormData.tag_key !== '' && props.editFormData.total_amount !== '';
  };

  const handleNumbers = event => {
    if(!event.target.validity.valid) {
      event.target.value = props.editFormData.total_amount;
    }
    props.handleFormChange('total_amount', event.target.value, true);
  };

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#budget-modal-form button[type="submit"]');
  };

  if(!props.editFormData.type || props.editFormData.type === '') {
    props.editFormData.type = 'expense';
  }

  const fixedTypeName = props.editFormData.type.replace(/scheduled_/g, '');

  const transactionDate = props.editFormData.transaction_date && moment(props.editFormData.transaction_date);

  return (
    <Modal
      isOpen={props.modalOpened}
      label="Budget"
      closeModal={() => props.closeModal(false)}
      dataChanged={formTouched()}
    >

      <div
        className="finance-modal"
        tabIndex="0"
        onKeyPress={handleKeyPress}
      >
        <div className="badges-section">
          <div className="title">{translate('Visibility')}</div>
          <Badge label={translate('private')}
            active={props.editFormData.access === 'private'}
            activeColor="bg-darkgrey"
            onClick={props.handleFormChange('access', 'private')}
          />
          <Badge label={translate('public')}
            active={props.editFormData.access === 'public'}
            activeColor="bg-darkgrey"
            onClick={props.handleFormChange('access', 'public')}
          />
        </div>

        <div className="badges-section">
          <div className="title">{translate('Add')}</div>
          <Badge label={translate('expense')}
            active={fixedTypeName === 'expense'}
            activeColor="bg-red"
            onClick={props.handleFormChange('type', 'expense')}
          />
          <Badge label={translate('income')}
            active={fixedTypeName === 'income'}
            activeColor="bg-green"
            onClick={props.handleFormChange('type', 'income')}
          />
        </div>
        <TagKeys type={fixedTypeName} label={fixedTypeName === 'expense' ? translate('on') : translate('from')} activeCategory={props.editFormData.tag_key} handleFormChange={props.handleFormChange} />

        <div className="badges-section">
          <div className="title">{translate('Date of transaction')}</div>
          <Form>
            <Form.Row>
              <DatePicker
                locale={user.language || 'en-gb'}
                maxDate={moment().add(1, 'year')}
                onChange={date => props.handleFormChange('transaction_date', date.format(DEFAULT_DATE_FORMAT), true)}
                selected={transactionDate}
              />
            </Form.Row>
          </Form>
        </div>

        {homeUsersFiltered.length > 0 && (
          <BadgesList
            label={translate('for')}
            list={homeUsersFiltered && Object.values(homeUsersFiltered)}
            activeBadges={props.editFormData.user_ids}
            handleFormChange={(key, val) => props.handleFormChange(key, val, true)}
            formKey="user_ids"
            activeColor="bg-main1"
            forceOne={!_.isNil(props.editFormData.id)}
          />
        )}

        <Form id="budget-modal-form" onSubmit={props.saveBudget} loading={false}>
          <Form.Row>
            <input
              type="number"
              step="0.01"
              min="0"
              autoComplete="off"
              className="total-amount"
              onChange={handleNumbers}
              value={props.editFormData.total_amount || ''}
              data-e2e="budget-total-amount-input"
            />
            <label className={isEdge ? 'edge' : ''}>{translate('total amount')}</label>
          </Form.Row>
          {!props.editFormData.id && (
            <Form.Row>
              <input
                className="pull-left"
                type="checkbox"
                onChange={() => props.handleFormChange('repeatable', !props.editFormData.repeatable, true)}
              />
              <span className="f13 p-l-5">{translate('Repeat')}</span>
            </Form.Row>
          )}
          {!props.editFormData.id && props.editFormData.repeatable && (
            <Form.Row>
              <div className="badges-section m-b-20">
                {badges.map(badge => (
                  <Badge
                    active={props.editFormData.interval === badge.value}
                    activeColor="bg-darkgrey"
                    key={badge.value}
                    label={translate(badge.label)}
                    onClick={props.handleFormChange('interval', badge.value)}
                  />
                ))}
              </div>
              <div className="flex space-between">
                <div className="pull-left inline date-time-section">
                  <label className="lightgrey">{translate('Starts:')}</label>
                  {startDate && (
                    <DatePicker
                      disabled
                      locale={user.language || 'en-gb'}
                      maxDate={moment().add(1, 'year')}
                      selected={transactionDate}
                    />
                  )}
                </div>
                <div className="pull-left inline date-time-section">
                  <label className="lightgrey">{translate('Ends:')}</label>
                  {startDate && (
                    <DatePicker
                      includeDates={generateValidDates(props.editFormData.interval, transactionDate)}
                      locale={user.language || 'en-gb'}
                      maxDate={moment().add(1, 'year')}
                      onChange={date => props.handleFormChange('end_date', date.format(DEFAULT_DATE_FORMAT), true)}
                      popperPlacement={'top-end'}
                      selected={endDate}
                    />
                  )}
                </div>
              </div>
            </Form.Row>
          )}

          {props.editFormData.repeatable && props.editFormData.id && (
            <Form.Row>
              <RepeatableEdit
                editFormData={props.editFormData}
                handleEventFormChange={props.handleFormChange}
                label={translate('Edit recurring transaction')}
                type="budget"
              />
            </Form.Row>
          )}

          <div className="clear">
            <Form.Row>
              <div className="clear m-t-30 textarea-container" style={{ top: 20, marginBottom: 20 }}>
                <textarea className="textarea-note" placeholder={translate('+ add a note')}
                  onChange={props.handleFormChange('note')}
                  value={props.editFormData.note || ''}
                  data-e2e="budget-notes-field"
                />
              </div>
            </Form.Row>
          </div>

          {props.error && (
            <div className="error center">
              <Notification type="error" text={props.error} />
            </div>
          )}

          <Form.Summary>
            <Button
              type="submit"
              label={translate('Save')}
              align="center"
              disabled={loading || !validateForm()}
              data-e2e="budget-save-button"
            />

            {!validateForm() ? (
              <div className="f12 m-t-0 error">
                {translate('Select transaction reason and enter amount to save it.')}
              </div>
            ) : null}
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


BudgetModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BudgetModal;
