import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from './InfoModal';


const SubscriptionCancelInfo = ({ name }, { t: translate }) => (
  <InfoModal
    buttonLabel={translate('Close')}
    imgType="forgot"
    name={name}
  >
    <h3 className="Title">
      {translate('Note: If you created your subscription via AppStore it won’t be cancelled. You can cancel it in AppStore.')}
    </h3>
  </InfoModal>
);


SubscriptionCancelInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SubscriptionCancelInfo;
