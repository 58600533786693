import moment from 'moment';

import {
  SET_REPORTS_SUCCESS,
  FETCH_REPORT_DATA_SUCCESS,
} from './../types';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from './../../utils/date';


const initialState = {
  general: {},
  summaries: [],
  transactions: [],
  splits: {},
  period: '',
  section: 'accounting',
  historyEnabled: true,
};


const setGeneral = actionGeneral => {
  actionGeneral.expenses.total = 0;
  actionGeneral.expenses.tag_keys.map(expense => {
    actionGeneral.expenses.total += expense.amount;
  });

  actionGeneral.incomes.total = 0;
  actionGeneral.incomes.tag_keys.map(income => {
    actionGeneral.incomes.total += income.amount;
  });
  return actionGeneral;
};

const setSummaries = actionSummaries => {
  actionSummaries.map(summary => {
    summary.expenses.total = 0;
    summary.expenses.tag_keys.map(expense => {
      summary.expenses.total += expense.amount;
    });

    summary.incomes.total = 0;
    summary.incomes.tag_keys.map(income => {
      summary.incomes.total += income.amount;
    });
  });
  return actionSummaries;
};

const setTransactions = actionTransactions => {
  const transactions = [];
  actionTransactions.map(transaction => {
    const transactionObject = {
      date: moment(transaction.date).format(DEFAULT_DISPLAYED_DATE_FORMAT),
      expense: (transaction.type === 'Expense' || transaction.type === 'ScheduledExpense') ? transaction.amount : 0,
      income: (transaction.type === 'Income' || transaction.type === 'ScheduledIncome') ? transaction.amount : 0,
      details: {
        title: transaction.tag_key,
        user_id: transaction.user_id,
        note: transaction.note,
      },
      recipient_id: transaction.recipient_id,
      status: transaction.status,
      split_amount: transaction.split_amount,
      type: (transaction.type === 'Expense' || transaction.type === 'Income') ? 'accounting' : 'budget',
    };
    transactions.push(transactionObject);
  });
  return transactions;
};


export function reportsReducer(state = initialState, action) {
  switch(action.type) {
    case SET_REPORTS_SUCCESS:
      return [
        ...action.payload,
      ];

    case FETCH_REPORT_DATA_SUCCESS:
      const { data, filters } = action.payload;
      const general = setGeneral(data.general_summary);
      const splits = data.split_summary;
      const period = data.period;
      const summaries = setSummaries(data.users_summaries);
      const transactions = setTransactions(data.transactions);
      return Object.assign({}, state, {
        general,
        splits,
        period,
        summaries,
        transactions,
        section: filters.section,
        historyEnabled: filters.history_enabled,
      });

    default:
      return state;
  }
}
