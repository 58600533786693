import React from 'react';

import SignedInLayout from '../../../layouts/SignedInLayout';

import InviteKidForm from './InviteKidForm';

const InviteKid = ({
  user, cancelForm, formData, error, loading, layout, onFieldChange, onAvatarChange, onSubmit,
}) => {
  return (
    <SignedInLayout
      page="Family"
      currentPath="/home"
      user={user}
      loading={loading}
      layout={layout}
    >
      <InviteKidForm
        {...formData}
        loading={loading}
        error={error}
        cancelForm={cancelForm}
        onFieldChange={onFieldChange}
        onAvatarChange={onAvatarChange}
        onSubmit={onSubmit}
        user={user}
      />
    </SignedInLayout>
  );
};


export default InviteKid;
