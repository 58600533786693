import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { isEdge } from './../../../../utils/browser';
import { convertHexToRgba, getFontColor, setOrderedFamilyMembers } from './../../../../utils/tools';
import EventParticipants from './EventParticipants';


export const CalendarWeekEvents = ({ type, day, user, calendarPeople, caretakers, columnWidth, weekEvents, editEvent, addNewEvent, setEventColor }, { t: translate }) => {
  const dayName = moment(day).format('ddd');
  const date = moment(day).format('D MMM');
  const dayNo = parseInt(moment(day).format('E'), 10);

  const eventClicked = e => {
    if(e.target && e.target.className.indexOf('tcell-inside') > -1) {
      e.preventDefault();
      const clickedDay = e.target.getAttribute('data-day');
      const clickedType = e.target.getAttribute('data-type');
      const clickedPerson = e.target.getAttribute('data-personid');
      addNewEvent(clickedType, clickedDay, clickedDay, clickedPerson);
    }
  };

  const sortEvents = (events = []) => {
    const allDayEvents = [];
    const startEvents = [];
    const endEvents = [];
    const normalEvents = [];

    events.forEach(event => {
      if(event.start_date !== day && event.end_date !== day) {
        allDayEvents.push(event);
      }
      if(event.start_date === day && event.end_date !== day) {
        startEvents.push(event);
      }
      if(event.start_date !== day && event.end_date === day) {
        endEvents.push(event);
      }
      if(event.start_date === day && event.end_date === day) {
        normalEvents.push(event);
      }
    });
    return [
      ...allDayEvents,
      ...endEvents,
      ...startEvents,
      ...normalEvents,
    ];
  };

  const calendarPeopleSorted = setOrderedFamilyMembers(user, calendarPeople);

  const renderAddButton = () => (
    <div className="tcell-inside-add-event">
      + {type === 'normal' ? translate('add event') : translate('add childcare')}
    </div>
  );

  return (
    <div className="trow week-view week-event">

      {type === 'longTerm' && (
        <div className="tcell relative date-header center">
          <span className="day-name">{dayName}</span>
          <span className="day-name">{date}</span>
        </div>
      )}
      {type === 'normal' && (
        <div className="tcell relative date-header center">&nbsp;</div>
      )}

      {calendarPeopleSorted && calendarPeopleSorted.map((person, idxPerson) => {
        const styles = { width: isEdge ? columnWidth - 2 : columnWidth };
        const tCellInsideStyles = { width: (columnWidth - 21) };

        const calendarEvents = sortEvents(weekEvents[day][type][person.id]);
        if(calendarEvents && calendarEvents.length > 0) {
          return (
            <div key={idxPerson} className="tcell relative" style={styles}>
              <div className={classnames('tcell-inside', { 'no-border-top': dayNo === 1 })} style={tCellInsideStyles} data-day={day} data-type={type} data-personid={person.id} onClick={eventClicked}>
                {calendarEvents.map((calendarEvent, idxEvent) => {
                  const startDateTime = calendarEvent.displayed_start_date ?
                    `${calendarEvent.displayed_start_date} ${calendarEvent.displayed_start_time}` :
                    `${calendarEvent.start_date} ${calendarEvent.start_time}`;
                  const endDateTime = calendarEvent.displayed_end_date ?
                    `${calendarEvent.displayed_end_date} ${calendarEvent.displayed_end_time}` :
                    `${calendarEvent.end_date} ${calendarEvent.end_time}`;
                  const startTime = moment(startDateTime).format('HH:mm');
                  const endTime = moment(endDateTime).format('HH:mm');

                  const eventColor = setEventColor(type, calendarEvent, person, calendarEvent.creator_id, caretakers);

                  const eventStyles = {
                    border: 'none',
                    borderLeft: type === 'longTerm' ? `4px solid ${eventColor}` : 'none',
                    backgroundColor: type === 'normal' ? eventColor && (convertHexToRgba(eventColor, 1) || 'rgba(222, 222, 222, 1)') : 'none',
                  };

                  const bubbleStyle = {};
                  if(isEdge) {
                    bubbleStyle.borderTop = 'none';
                    bubbleStyle.borderBottom = 'none';
                  }

                  return (
                    <div key={idxEvent}
                      title={calendarEvent.editing_locked ? translate('Editing not allowed') : translate('Edit event')}
                      className={`person pointer ${type.toLowerCase()} relative`}
                      onClick={() => editEvent(calendarEvent, calendarEvent.kid)}
                      style={eventStyles}
                    >

                      {calendarEvent.note !== '' && (
                        <div className={`week-note-notificator ${type === 'normal' && getFontColor(eventColor)}`} title={translate('Event contains notes')} />
                      )}

                      <div className={`details ${calendarEvent.editing_locked ? '' : 'pointer'}`}>
                        {type === 'longTerm' && (<span className="lowercase black f14 w400">{translate('Childcare')}</span>)}

                        {type === 'normal' && (
                          <div className="times f12 inline-block">
                            <span style={{ color: getFontColor(eventColor) }}>{startTime} - {endTime}</span>
                          </div>
                        )}
                        {type === 'normal' && (<div className="title" style={{ color: getFontColor(eventColor) }}>{calendarEvent.title || ''}</div>)}
                        {type === 'normal' && calendarEvent.participant_ids.length && (
                          <EventParticipants type={type} calendarEvent={calendarEvent} calendarPeople={calendarPeople} person={person} caretakers={caretakers} eventColor={eventColor} mainClassNames="block m-t-5" />
                        )}

                        {type === 'longTerm' && (
                          <div className="times inline-block p-l-5 grey f14">
                            {calendarEvent.start_date === day && calendarEvent.end_date !== day && (
                              <span><span className="grey">{translate('Starts')}:</span> <span className="f12 grey">{startTime}</span></span>
                            )}
                            {calendarEvent.start_date !== day && calendarEvent.end_date !== day && (
                              <span className="grey">{translate('All day')}</span>
                            )}
                            {calendarEvent.start_date !== day && calendarEvent.end_date === day && (
                              <span><span className="grey">{translate('Ends')}:</span> <span className="f12 grey">{endTime}</span></span>
                            )}
                            {calendarEvent.start_date === day && calendarEvent.end_date === day && (
                              <span className="f12 grey">{startTime} - {endTime}</span>
                            )}
                          </div>
                        )}

                        {type === 'longTerm' && (
                          <EventParticipants type={type} calendarEvent={calendarEvent} calendarPeople={calendarPeople} person={person} caretakers={caretakers} mainClassNames="block" />
                        )}
                      </div>
                    </div>
                  );
                })}
                {renderAddButton()}
              </div>
            </div>
          );
        }

        return (
          <div key={idxPerson} className="tcell person relative" style={styles}>
            <div className={`tcell-inside ${dayNo === 1 ? 'no-border-top' : ''}`} style={tCellInsideStyles} onClick={() => addNewEvent(type, day, day, person.id)}>
              <div className={`person pointer ${type.toLowerCase()} relative`}>
                {renderAddButton()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};


CalendarWeekEvents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarWeekEvents;
