import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import {
  Avatar, AvatarPlaceholder, Badge, Button,
  Card, ColorPicker,
  DatePicker, Input, Form,
  LanguageSelect, NicknameField, Notification, FileUploader,
} from 'src/components';
import { DEFAULT_BIRTHDATE_FORMAT } from 'src/utils/date';
import { getCountryLabel } from 'src/utils/lang';

import SubscriptionPlan from './SubscriptionPlan';


const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const UserProfileEdit = ({ history, loading, profile, error, ...props }, { t: translate }) => {
  const { push } = useHistory();
  const datePickerMaxDate = moment().subtract(1, 'd');

  const { birthDate: userBirthDate, editMode, subscription } = profile;
  const birthDate = (userBirthDate && moment(userBirthDate)) || moment();
  const hasSubscription = subscription.id && subscription.status !== 'canceled';

  return (
    <div>
      <Card
        className={classnames('kid-profile-container m-b-10', { edited: editMode })}
        deleteIcon="close"
        onDelete={() => push('/home')}
        title={translate('My profile')}
      >
        <div className="kid-profile-column">
          <div className="avatar" style={{ borderColor: profile.color }}>
            {(profile.avatar && profile.avatar.fileUrl !== '') ? (
              <Avatar avatar={profile.avatar} />
            ) : (
              <AvatarPlaceholder user={profile} />
            )}
          </div>
          <FileUploader accept={'.jpg, .png'} handleUploadFile={props.onAvatarChange} translate={translate} />
        </div>
        <div className="kid-profile-column">
          <Form loading={loading}>
            <Form.Row>
              <Input
                type="text"
                name="first name"
                label={translate('name')}
                value={profile.firstName}
                onChange={props.onFieldChange('firstName')}
                error={error}
              />
            </Form.Row>
            <Form.Row>
              <Input
                type="text"
                name="last name"
                label={translate('surname')}
                value={profile.lastName}
                onChange={props.onFieldChange('lastName')}
                error={error}
              />
            </Form.Row>
            <Form.Row>
              <NicknameField
                name="nickname"
                label={translate('nickname')}
                value={profile.username || profile.firstName}
                onChange={props.onFieldChange('username')}
                error={error}
              />
            </Form.Row>
            <Form.Row>
              <Input
                disabled
                type="text"
                name="country"
                value={getCountryLabel(profile.country)}
              />
            </Form.Row>
            <Form.Row>
              <div className="badges-section">
                <div className="title inline-block m-r-15">{translate('color')}:</div>
                <div className="color-picker-container">
                  <ColorPicker
                    color={profile.color}
                    onChange={color => props.onFieldChange('color', color)}
                  />
                </div>
              </div>
            </Form.Row>
          </Form>
        </div>
        <div className="kid-profile-column">
          <Form>
            <Form.Row>
              <div className="badges-section">
                <div className="title inline-block m-r-15">{translate('gender')}:</div>
                <Badge
                  label={translate('male')}
                  active={profile.gender === 'male'}
                  activeColor="bg-darkgrey"
                  onClick={() => props.onFieldChange('gender', 'male')}
                />
                <Badge
                  label={translate('female')}
                  active={profile.gender === 'female'}
                  activeColor="bg-darkgrey"
                  onClick={() => props.onFieldChange('gender', 'female')}
                />
              </div>
            </Form.Row>
            <Form.Row>
              <DatePicker
                dateFormat={DEFAULT_BIRTHDATE_FORMAT}
                locale={profile.language || 'en-gb'}
                onChange={date => props.onFieldChange('birthDate', date.format(DEFAULT_DATE_FORMAT))}
                maxDate={datePickerMaxDate}
                selected={birthDate}
              />
            </Form.Row>
            <Form.Row>
              <Input
                type="email"
                name="email"
                label={translate('email')}
                value={profile.email}
                onChange={props.onFieldChange('email')}
                error={error}
              />
            </Form.Row>
            <Form.Row>
              <LanguageSelect
                label={`${translate('language')}:`}
                language={profile.language}
                changeLanguage={props.changeLanguage}
              />
            </Form.Row>
          </Form>
        </div>

        {editMode && (
          <div className="kid-profile-submit-changes">
            {error && (
              <Notification type="error" text={error} exclude={['first name', 'last name', 'nickname', 'email']} />
            )}
            <Button onClick={props.onSubmitProfile} buttonClassName="clean small" label={translate('save changes')} />
          </div>
        )}
      </Card>

      <Button
        align="right"
        type="button"
        onClick={props.toggleConfirmationModal}
        buttonClassName="link delete m-r-15"
        label={translate('delete account')}
      />

      {hasSubscription ? (
        <SubscriptionPlan
          subscription={subscription}
          showModal={props.showModal}
          renewSubscription={props.renewSubscription}
        />
      ) : (
        <Button
          align="center"
          type="button"
          onClick={() => history.push('subscription')}
          buttonClassName="m-t-15"
          label={translate('Create subscription')}
        />
      )}
    </div>
  );
};


UserProfileEdit.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default UserProfileEdit;
