import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { fetchReportData } from '../../../store/actions/reports';
import ReportPrint from '../components/print/ReportPrint';

import * as pdfHelper from './../../../utils/pdf';


const SUMMARY_PARAMS = {
  basicHeight: 140,
  chartTitleHeight: 48,
  chartHeight: 55,
  pageTitle: 120,
};

const PAGE_HEIGHT = 969;

const MAX_GENERAL_CHARTS_PER_PAGE = {
  first: 9,
  rest: 17,
};

// const MAX_SUMMARY_CHARTS_PER_PAGE = 10;


class ReportsPrintContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generalIdxOnPage: [{
        expenses: [],
        incomes: [],
      }],
      summariesPerPage: { 0: 1 },  // pageIdx: countSummaries
      loading: true,
    };
  }

  componentDidMount() {
    const { history, reports } = this.props;
    if(reports.summaries && reports.summaries.length > 0) {
      this.fitSummariesToPage();
      this.fitGeneralToPage();
    } else {
      history.push('/reports');
    }
  }

  fitSummariesToPage = () => {
    const { reports } = this.props;
    const { summariesPerPage } = this.state;
    const pageSpace = PAGE_HEIGHT - SUMMARY_PARAMS.pageTitle;

    let numberOfSummariesPerPage = 0;
    let aggregatedComponentsHeight = 0;
    let page = 0;
    const tempSummariesPerPage = {};
    reports.summaries.forEach((summary, idx) => {
      tempSummariesPerPage[idx] = 1; // TEMP
      const expenseTagKeysCount = summary.expenses.tag_keys.length;
      const incomeTagKeysCount = summary.incomes.tag_keys.length;
      let componentHeight = SUMMARY_PARAMS.basicHeight;
      if(expenseTagKeysCount > 0) {
        componentHeight += SUMMARY_PARAMS.chartTitleHeight;
      }
      for(let i = 0; i < expenseTagKeysCount; i++) {
        componentHeight += SUMMARY_PARAMS.chartHeight;
      }
      if(incomeTagKeysCount > 0) {
        componentHeight += SUMMARY_PARAMS.chartTitleHeight;
      }
      for(let i = 0; i < incomeTagKeysCount; i++) {
        componentHeight += SUMMARY_PARAMS.chartHeight;
      }

      if((aggregatedComponentsHeight + componentHeight) < pageSpace) {
        aggregatedComponentsHeight += componentHeight;
        numberOfSummariesPerPage++;
        summariesPerPage[page] = numberOfSummariesPerPage;
      } else {
        summariesPerPage[page] = numberOfSummariesPerPage;
        page++;
        aggregatedComponentsHeight = 0;
        numberOfSummariesPerPage = 0;
      }
    });

    this.setState({
      summariesPerPage: tempSummariesPerPage,
    });
  }

  fitGeneralToPage = () => {
    const { reports } = this.props;
    const { generalIdxOnPage } = this.state;

    if(reports && reports.general) {
      const countExpenses = reports.general.expenses.tag_keys.length;
      const countIncomes = reports.general.incomes.tag_keys.length;
      let leftExpenses = countExpenses;

      for(let pageNr = 0; pageNr < 100; pageNr++) {
        const key = pageNr === 0 ? 'first' : 'rest';
        const usedExpenses = generalIdxOnPage.reduce((total, current) => total + current.expenses.length, 0);
        const usedIncomes = generalIdxOnPage.reduce((total, current) => total + current.incomes.length, 0);
        leftExpenses = countExpenses - usedExpenses;
        let spaceLeft = leftExpenses > MAX_GENERAL_CHARTS_PER_PAGE[key] ?
          MAX_GENERAL_CHARTS_PER_PAGE[key] :
          MAX_GENERAL_CHARTS_PER_PAGE[key] - usedExpenses - usedIncomes;


        if((countExpenses > usedExpenses || countIncomes > usedIncomes) && spaceLeft > 0) {
          generalIdxOnPage[pageNr] = {
            expenses: [],
            incomes: [],
          };

          for(let i = usedExpenses; i < countExpenses; i++) {
            if(spaceLeft > 0) {
              generalIdxOnPage[pageNr].expenses.push(i);
              spaceLeft--;
            }
          }

          for(let i = usedIncomes; i < countIncomes; i++) {
            if(spaceLeft > 0) {
              generalIdxOnPage[pageNr].incomes.push(i);
              spaceLeft--;
            }
          }
        }
      }

      this.setState({
        generalIdxOnPage: generalIdxOnPage,
      }, () => {
        setTimeout(() => {
          this.print();
        }, 1000);
      });
    }
  }

  print = () => {
    pdfHelper.print('page', '2homes_report').then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { generalIdxOnPage, summariesPerPage, loading } = this.state;
    const { reports, homeUsers } = this.props;

    return (
      <ReportPrint
        summariesPerPage={summariesPerPage}
        generalIdxOnPage={generalIdxOnPage}
        reports={reports}
        homeUsers={homeUsers}
        loading={loading}
        print={this.print}
      />
    );
  }
}


ReportsPrintContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    homeUsers: state.homeUsers,
    reports: state.reports,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  fetchReportData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsPrintContainer);
