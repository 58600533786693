import React from 'react';
import { SliderPicker } from 'react-color';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      color: this.props.color || '#fff',
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.color !== this.props.color) {
      this.setState({
        color: nextProps.color,
      });
    }
  }

  togglePicker = () => {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
    });
  }

  handleChangeComplete = color => {
    this.setState({
      color: color.hex,
    }, () => {
      this.props.onChange(color.hex);
    });
  }

  render() {
    const { opened, color } = this.state;

    return (
      <div className="ColorPicker">
        <div className="color-picker-preview pointer" style={{ backgroundColor: color }} onClick={this.togglePicker} />

        {opened && (
          <SliderPicker
            width="100%"
            color={color}
            onChangeComplete={this.handleChangeComplete}
          />
        )}
      </div>
    );
  }
}


export default ColorPicker;
