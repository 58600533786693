import React from 'react';

import { AvatarPlaceholder } from '../../../components';
import { getOS, isEdge } from '../../../utils/browser';
import { prepareFileUrl } from '../../../utils/file';
import { setOrderedFamilyMembers, getContentWidth } from '../../../utils/tools';


export const CalendarHeader = ({ user, layout, calendar, columnWidth, calendarPeople }) => {
  const isCalendarBiggerThenWindow = () => {
    const factor = Object.keys(calendarPeople).length * 280;
    return factor > getContentWidth(layout);
  };
  const scrollBarWidthWeekEdge = isCalendarBiggerThenWindow() ? 0 : 14;
  const scrollBarWidthDayEdge = isCalendarBiggerThenWindow() ? 8 : 13;

  const scrollBarWidth = isEdge ? (calendar.viewType === 'day' ? scrollBarWidthDayEdge : scrollBarWidthWeekEdge) : 0;
  const styles = getOS() === 'Windows' ? { width: `calc(100% - ${scrollBarWidth}px)` } : {};

  const UserHeader = ({ usr }) => {
    return (
      <div className="kid-header">
        <div className="kid-avatar">
          {usr.thumbnail_url ? (<img src={prepareFileUrl(usr.thumbnail_url)} style={{ borderColor: usr.color }} alt="" />) : (<AvatarPlaceholder user={usr} />)}
        </div>
        <div className="kid-names">
          <span className="kid-name">{usr.first_name} {usr.last_name}</span>
          <span className="kid-nickname" style={{ color: usr.color }}>{`@${usr.nickname && usr.nickname.toLowerCase()}` || `@${usr.first_name && usr.first_name.toLowerCase()}`}</span>
        </div>
      </div>
    );
  };

  const calendarPeopleSorted = setOrderedFamilyMembers(user, calendarPeople);

  return (
    <div className="calendar-headers" style={styles}>
      {calendarPeopleSorted && calendarPeopleSorted.map((usr, idx) => {
        const width = `${columnWidth}px`;
        return (
          <div className={`calendar-kid-header ${calendar.viewType}`} key={idx} style={{ width: width }}>
            <UserHeader usr={calendarPeople[usr.id]} />
          </div>
        );
      })}
    </div>
  );
};


export default CalendarHeader;
