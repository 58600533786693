import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import without from 'lodash/without';
import classnames from 'classnames';
import {
  Badge, BadgesList, Button, DatePicker, Input, Form,
  Modal, Notification, RepeatableEdit, TimePicker,
} from 'src/components';
import { handleFormKeyPress, setOrderedFamilyMembers } from 'src/utils/tools';

import CalendarAlerts from './CalendarAlerts';
import CalendarRepeatable from './CalendarRepeatable';


const DEFAULT_ALERT_DATA = { amount: 10, timeUnit: 'M' };

export const CalendarModal = ({
  caretakers, loading, kids,
  setAlertsList, user, ...props
}, { t: translate }) => {
  const { eventFormData: {
    alertsList,
    caretaker_ids,
    creator_id,
    editing_locked,
    end_date,
    end_time,
    id,
    kid_ids,
    note,
    originalRepeatable,
    repeatable,
    start_date,
    start_time,
    user_id,
    title,
    type,
  } } = props;

  const isCreator = creator_id === user.id;

  const addNewAlert = () => {
    const updatedAlertsList = [...alertsList];
    updatedAlertsList.push(Object.create(DEFAULT_ALERT_DATA));

    setAlertsList(updatedAlertsList);
  };

  const updateAlert = (event, idx, type) => {
    const updatedAlertsList = alertsList;
    const newValue = alertsList[idx];

    if(type === 'amount') {
      newValue.amount = event.target.value;
    } else {
      newValue.timeUnit = event;
    }
    updatedAlertsList[idx] = newValue;

    setAlertsList([...updatedAlertsList]);
  };

  const removeAlert = idx => {
    const list = alertsList.map((el, i) => {
      if(i !== idx) return el;
    });

    setAlertsList([...list]);
  };

  const startDateTime = start_date && moment(`${start_date} ${start_time}`);
  const endDateTime = end_date && moment(`${end_date} ${end_time}`);

  const handleCaretakerOnKidsList = () => {
    let caretakerIds = caretaker_ids;
    if(caretakerIds.indexOf(user.id) > -1) {
      caretakerIds = without(caretaker_ids, user.id);
    } else {
      caretakerIds.push(user.id);
    }
    props.handleEventFormChange('caretaker_ids', caretakerIds);
  };

  const formTouched = () => {
    if(props.modalOpened) {
      return type === 'event' && title !== '' && !editing_locked;
    }
    return false;
  };

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#calendar-modal-form button[type="submit"]');
  };

  const sortByNickname = users => {
    return users && Object.values(users).sort((a, b) => {
      return a.nickname > b.nickname ? 1 : -1;
    });
  };

  const getVisibleUsers = users => users.filter(usr => usr.visibility);

  const author = caretakers[creator_id] &&
    (caretakers[creator_id].nickname || caretakers[creator_id].first_name.toLowerCase());

  const sortedParticipants = [];
  if(type === 'care') {
    sortedParticipants.push(caretakers[user.id]);
  }

  const sortedCaretakers = getVisibleUsers(sortByNickname(caretakers));
  sortedCaretakers && sortedCaretakers.map(caretaker => {
    if(caretaker.id !== user.id) {
      sortedParticipants.push(caretakers[caretaker.id]);
    }
  });

  const orderCalendarPeople = setOrderedFamilyMembers(user, props.calendarPeople);
  const kidsFromCalendarPeople = orderCalendarPeople.filter(p => p.familyStatus === 'kid');
  const sortedKids = getVisibleUsers(kidsFromCalendarPeople);

  const personISCaretaker = user_id && caretakers[user_id];
  const showDeleteButton = personISCaretaker ? !caretakers[user_id].current : (kids[user_id] && kids[user_id].whichFamily !== 'partnerEx');

  const showUsersBadge = !editing_locked ? type === 'event' : caretaker_ids.indexOf(user.id) > -1;

  return (
    <Modal
      isOpen={props.modalOpened}
      label={editing_locked ? 'Calendar Disabled' : 'Calendar'}
      closeModal={() => props.closeEventModal()}
      dataChanged={formTouched()}
    >

      {start_date && (
        <div
          className={classnames('calendar-modal', { disabled: editing_locked })}
          tabIndex="0"
          onClick={e => props.closeTimePickers(e)}
          onKeyPress={handleKeyPress}
        >
          {id && showDeleteButton && (
            <span
              className="cancel error right f12 pointer"
              onClick={() => props.setEventToDelete(props.eventFormData)}
            >
              {translate('Delete')}
            </span>
          )}
          {id && author && (
            <div className="lightgrey f12">
              {translate('Created by')} <span style={{ fontWeight: 600, color: caretakers[creator_id].color }}>{author}</span>
              {editing_locked && (
                <span className="infoText block m-t-10">{translate('Only creators can edit events')}</span>
              )}
            </div>
          )}

          <Form
            className={editing_locked && 'disabledCalendarForm'}
            id="calendar-modal-form"
            loading={false}
            onSubmit={props.saveCalendarEvent}
          >
            <div>
              {!editing_locked && (
                <div className="badges-section">
                  <div className="title">{translate('Add')}</div>
                  <Badge
                    label={translate('event')}
                    active={type === 'event' || !type}
                    activeColor="bg-darkgrey"
                    onClick={() => !editing_locked && props.handleEventFormChange('type', 'event')}
                  />
                  <Badge
                    label={translate('childcare')}
                    active={type === 'care'}
                    activeColor="bg-darkgrey"
                    onClick={() => !editing_locked && props.handleEventFormChange('type', 'care')}
                  />
                </div>
              )}

              {!editing_locked && (
                <div className="badges-section">
                  <div className="title">{translate('Visibility')}</div>
                  <Badge
                    label={translate('private')}
                    active={props.eventFormData.private}
                    activeColor="bg-darkgrey"
                    onClick={() => !editing_locked && props.handleEventFormChange('private', true)}
                  />
                  <Badge
                    label={translate('public')}
                    active={!props.eventFormData.private}
                    activeColor="bg-darkgrey"
                    onClick={() => !editing_locked && props.handleEventFormChange('private', false)}
                  />
                </div>
              )}

              <div className="badges-section">
                <div className="title">{translate('For')}</div>
                {showUsersBadge && (
                  <Badge
                    label={`@${user.nickname}`}
                    active={caretaker_ids.indexOf(user.id) > -1}
                    activeColor={caretakers[user.id].color}
                    bgColor={caretakers[user.id].color}
                    onClick={() => !editing_locked && handleCaretakerOnKidsList()}
                  />
                )}

                <BadgesList
                  inline
                  label=""
                  list={sortedKids}
                  activeBadges={kid_ids}
                  handleFormChange={!editing_locked && props.handleEventFormChange}
                  formKey="kid_ids"
                  activeColor="bg-main1"
                  editingLocked={editing_locked}
                />
              </div>

              {type !== 'care' && (
                <Form.Row>
                  <Input
                    type="text"
                    name="title"
                    label={translate('event name')}
                    className="event-name"
                    onChange={event => props.handleEventFormChange('title', event.target.value)}
                    value={title || ''}
                    disabled={editing_locked}
                    error={props.error}
                  />
                </Form.Row>
              )}
              <Form.Row>
                <div className="pull-left date-time-section">
                  <label className="lightgrey">{translate('Starts:')}</label>
                  <div>
                    {startDateTime && (
                      <div className="flex">
                        <TimePicker
                          className="time-input left"
                          disabled={editing_locked}
                          onChange={time => props.handleEventFormChange('start_time', time)}
                          onOpen={open => props.toggleTimePicker(open, 'start')}
                          open={props.startTimePickerOpened}
                          value={moment(startDateTime).format('HH:mm')}
                        />
                        <DatePicker
                          disabled={editing_locked}
                          label={translate('date')}
                          locale={user.language || 'en-gb'}
                          maxDate={moment().add(1, 'year')}
                          onChange={date => props.handleEventFormChange('start_date', date.format('YYYY-MM-DD'))}
                          selected={startDateTime}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="pull-left divider center">-</div>
                <div className="pull-right date-time-section">
                  <label className="lightgrey">{translate('Ends:')}</label>
                  <div className="right">
                    {endDateTime && (
                      <div className="flex">
                        <TimePicker
                          className="time-input right"
                          disabled={editing_locked}
                          onChange={time => props.handleEventFormChange('end_time', time)}
                          onOpen={open => props.toggleTimePicker(open, 'end')}
                          open={props.endTimePickerOpened}
                          popperPlacement="top-end"
                          value={moment(endDateTime).format('HH:mm')}
                        />
                        <DatePicker
                          disabled={editing_locked}
                          label={translate('date')}
                          locale={user.language || 'en-gb'}
                          maxDate={moment().add(3, 'year')}
                          onChange={date => props.handleEventFormChange('end_date', date.format('YYYY-MM-DD'))}
                          selected={endDateTime}
                          popperPlacement="top-end"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="clear" />
              </Form.Row>

              <Form.Row>
                <CalendarAlerts
                  addNewAlert={addNewAlert}
                  alertsList={alertsList}
                  disabled={id && !isCreator}
                  removeAlert={removeAlert}
                  updateAlert={updateAlert}
                />
              </Form.Row>

              {sortedParticipants.length > 0 && (
                <Form.Row>
                  <div className="participants">
                    <BadgesList
                      label={type === 'event' ? translate('Who also participates in the event?') : translate('Caretaker')}
                      list={sortedParticipants}
                      activeBadges={caretaker_ids}
                      handleFormChange={!editing_locked && props.handleEventFormChange}
                      formKey="caretaker_ids"
                      forceOne={type === 'care'}
                      editingLocked={editing_locked}
                    />
                  </div>
                </Form.Row>
              )}

              {!id && (
                <Form.Row>
                  <div className="repeatable">
                    {!originalRepeatable && (
                      <input
                        type="checkbox"
                        className="inline-block"
                        disabled={editing_locked}
                        value={repeatable}
                        onChange={e => props.handleEventFormChange('repeatable', e.target.checked)}
                      />
                    )}
                    <span className="inline-block">{translate('Repeat')}</span>
                  </div>
                </Form.Row>
              )}

              {(repeatable && !id) && (
                <CalendarRepeatable user={user} {...props} />
              )}

              {originalRepeatable && (
                <Form.Row>
                  <RepeatableEdit editFormData={props.eventFormData} {...props} />
                </Form.Row>
              )}
            </div>

            {(!editing_locked || note) && (
              <Form.Row>
                <div className="textarea-container m-t-30">
                  <textarea
                    className="textarea-note" placeholder={translate('+ add a note')}
                    onChange={event => props.handleEventFormChange('note', event.target.value)}
                    value={note || ''}
                    disabled={editing_locked}
                  />
                </div>
              </Form.Row>
            )}

            <Form.Summary>
              <Notification type="error" text={props.error} exclude={['title']} />
              {props.errorMessage && (<div className="error center f13">{props.errorMessage}</div>)}

              {!editing_locked && (
                <Button
                  type="submit"
                  label={translate('Save')}
                  align="center"
                  disabled={loading}
                />
              )}
            </Form.Summary>
          </Form>
        </div>
      )}
    </Modal>
  );
};


CalendarModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarModal;
