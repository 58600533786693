import { isEdge } from '../../utils/browser';
import { api, BASE_URL } from '../../utils/net';

import {
  SET_REPORTS_SUCCESS,
  GENERATE_REPORT_SUCCESS,
  FETCH_REPORT_DATA_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function setReportsSuccess(reports) {
  return {
    type: SET_REPORTS_SUCCESS,
    payload: reports,
  };
}

export function generateReportSuccess(data) {
  return {
    type: GENERATE_REPORT_SUCCESS,
    payload: data,
  };
}

export function fetchReportDataSuccess(data, filters) {
  return {
    type: FETCH_REPORT_DATA_SUCCESS,
    payload: {
      data,
      filters,
    },
  };
}


export const generateReport = filters => async dispatch => {
  dispatch(showLoader());

  try {
    const start = filters.start_date.split('-');
    const end = filters.end_date.split('-');
    const url = `${BASE_URL}reports/${start[1]}-${start[0]}:${end[1]}-${end[0]}.pdf`;
    const users = [];
    filters.for.forEach(user_id => {
      if(user_id !== 'none') {
        users.push(user_id);
      }
    });

    const { data } = await api.get(url, {
      user_ids: users,
      privacy: filters.privacy,
      tags: filters.tags,
      type: filters.type,
      split_requests: filters.split_requests,
      notes: filters.note,
      not_assigned: filters.for.indexOf('none') > -1,
    }, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    dispatch(generateReportSuccess(data));
    if(isEdge) {
      window.navigator.msSaveBlob(file, 'report.pdf');
    }
    return fileURL;
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('reports', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const fetchReportData = filters => async dispatch => {
  dispatch(showLoader());

  try {
    const url = `${BASE_URL}reports/${filters.start_date}:${filters.end_date}.pdf`;
    const users = [];
    filters.for.forEach(user_id => {
      if(user_id !== 'none') {
        users.push(user_id);
      }
    });

    const { data } = await api.get(url, {
      user_ids: users,
      privacy: filters.privacy,
      tags: filters.tags,
      type: filters.type,
      split_requests: filters.split_requests,
      notes: filters.note,
      not_assigned: filters.for.indexOf('none') > -1,
      section: filters.section,
    });
    dispatch(fetchReportDataSuccess(data, filters));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('reports', message));
  } finally {
    dispatch(hideLoader());
  }
};
