import React from 'react';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import PropTypes from 'prop-types';

import { getWalkthroughSteps } from '../../utils/walkthrough';
import { changeWalkthroughStep } from '../../store/actions/walkthrough';

import WALKTHROUGH_STYLES from './WalkthroughStyles';


const Walkthrough = ({ additionalSteps = [], changeWalkthroughStep: joyrideCallback, currentPath, stepsPlacement = [], walkthrough }, { t: translate }) => {
  const { isOpen, pathName, stepIndex } = walkthrough;
  const path = currentPath.slice(1, currentPath.length);
  const shouldWalkthroughRun = isOpen && (path === pathName || path === 'kidId');

  const mainContainer = document.querySelector('.loading') || {};
  const rightProperty = - (mainContainer.offsetWidth + mainContainer.offsetLeft - window.innerWidth);

  const renderStep = (target, stepContent, idx) => {
    const isPlacementDetermined = Object.keys(stepsPlacement).includes(idx.toString());

    return {
      content: (<h2>{stepContent}</h2>),
      target,
      disableBeacon: true,
      styles: { options: { zIndex: 10000 } },
      locale: {
        back: translate('Back'),
        next: translate('Next'),
        skip: translate('Skip'),
        last: translate('Finish'),
      },
      placement: isPlacementDetermined ? stepsPlacement[idx] : 'bottom-start',
    };
  };

  const renderSteps = () => {
    const STEPS = { ...getWalkthroughSteps(path, translate) };
    let walkthroughSteps = [];
    if(STEPS) {
      Object.keys(STEPS).forEach((target, idx) => {
        const stepContent = STEPS[target];
        walkthroughSteps.push(renderStep(target, stepContent, idx));
      });
    }

    if(additionalSteps) {
      walkthroughSteps = [
        ...walkthroughSteps,
        ...additionalSteps,
      ];
    }
    return walkthroughSteps;
  };

  return (
    <Joyride
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      callback={joyrideCallback}
      run={shouldWalkthroughRun}
      scrollOffset={150}
      spotlightPadding={0}
      stepIndex={stepIndex}
      steps={renderSteps()}
      styles={{ ...WALKTHROUGH_STYLES, overlay: { right: rightProperty } }}
    />
  );
};

Walkthrough.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  return {
    walkthrough: state.walkthrough,
  };
};

const mapDispatchToProps = {
  changeWalkthroughStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Walkthrough);
