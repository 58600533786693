import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded, updateCustomProfile } from '../../../store/actions/homes';
import CaretakerProfile from '../components/CaretakerProfile';


const DEFAULT_FORM_DATA = {
  color: '',
};


class CaretakerProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      caretaker: {},
      editFormData: _.cloneDeep(DEFAULT_FORM_DATA),
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    const caretakerId = this.props.match.params.caretakerId;
    const caretaker = this.props.homeUsers && this.props.homeUsers[caretakerId];

    if(!caretaker) {
      this.props.history.push('/404');
    } else {
      this.setState({
        caretaker,
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanError('homes');
  }

  handleFormChange = (name, value) => {
    const { editFormData } = this.state;
    let { editMode } = this.state;
    if(value) {
      editFormData[name] = value;
      editMode = true;
      this.setState({
        editFormData,
        editMode,
      });
    }
  }

  saveCaretaker = () => {
    const { caretaker, editFormData } = this.state;
    const { color } = editFormData;
    this.props.updateCustomProfile({ color }, caretaker.id).then(() => {
      if(!this.props.error || this.props.error === null) {
        this.setState({
          editMode: false,
          caretaker: Object.assign({}, caretaker, {
            color,
          }),
        }, () => {
          this.props.history.push('/home');
        });
      }
    });
  }

  render() {
    return (
      <CaretakerProfile
        {...this.props}
        {...this.state}
        handleFormChange={this.handleFormChange}
        saveCaretaker={this.saveCaretaker}
        error={this.props.error}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    homeUsers: state.homeUsers,
    error: state.errors.homes,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  updateCustomProfile,
  cleanError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaretakerProfileContainer);
