import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Notification, Form } from 'src/components';

const InvitePartnerForm = ({
  first_name, email, note, onFieldChange, onSubmit, loading, cancelForm, error,
}, { t: translate }) => {
  const partner = window.location.search.indexOf('?current') > -1 ?
    translate('current partner') :
    window.location.search.indexOf('?ex') > -1 ?
      translate('previous partner') :
      translate('a caretaker');

  return (
    <div className="InvitePartnerForm CompleteYourProfile">
      <Form
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="pull-right invite-close pointer" onClick={() => cancelForm()}><i className="zmdi zmdi-close" /></div>
        <h2>{translate('Invite')} {partner}</h2>

        <Form.Row>
          <Input
            type="text"
            name="first name"
            label={translate('first name')}
            value={first_name}
            onChange={onFieldChange('first_name')}
          />
        </Form.Row>
        <Form.Row>
          <Input
            type="email"
            name="email"
            label={translate('email')}
            value={email}
            onChange={onFieldChange('email')}
          />
        </Form.Row>

        <div className="center p-t-10 p-b-10">
          <Notification type="error" text={error} />
        </div>

        <Form.Row>
          <div className="textarea-container">
            <textarea className="textarea-note" placeholder={translate('+ add an invitation note')}
              onChange={onFieldChange('note')}
              value={note || ''}
            />
          </div>
        </Form.Row>

        <Form.Summary>
          <Button
            type="submit"
            label={translate('Send invitation')}
            align="center"
          />
        </Form.Summary>
      </Form>
    </div>
  );
};


InvitePartnerForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InvitePartnerForm;
