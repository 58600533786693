import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const PAGE_HEIGHT_PX = 842;
export const PAGE_WIDTH_PX = 595;

function setpixelated(context) {
  context.imageSmoothingEnabled = false; /* standard */
  context.mozImageSmoothingEnabled = false; /* Firefox */
  context.oImageSmoothingEnabled = false; /* Opera */
  context.webkitImageSmoothingEnabled = false; /* Safari */
  context.msImageSmoothingEnabled = false; /* IE */
}


export const preparePagesForPrinting = (className, headerHeight) => {
  const daysRows = document.getElementsByClassName(className);
  const heights = [];
  for(let i = 0; i < daysRows.length; i++) {
    const dayRow = daysRows.item(i);
    heights.push(dayRow.offsetHeight);
  }

  const pageHeight = PAGE_HEIGHT_PX;
  const pageWidth = PAGE_WIDTH_PX;
  const defaultMargins = 5;
  const scaleFactor = pageWidth / 820;
  const scaledHeaderHeight = headerHeight * scaleFactor;

  let aggregateHeights = 0;
  const pages = {};
  let pageNr = 0;
  pages[pageNr] = {
    rows: [],
    margin: 0,
  };

  heights.forEach((height, idx) => {
    const scaledHeight = height * scaleFactor;
    if(idx === 0) {
      aggregateHeights = scaledHeaderHeight;
    }
    if(aggregateHeights + scaledHeight <= pageHeight) {
      aggregateHeights += scaledHeight;
      pages[pageNr].rows.push(idx);
    } else {
      pageNr++;
      pages[pageNr] = {
        rows: [idx],
        margin: pageHeight - aggregateHeights + defaultMargins,
      };
      aggregateHeights = defaultMargins;
    }
  });
  return pages;
};


export const htmlToPdf = (elementId, headerText, filename) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const source = document.getElementById(elementId);

  source.style.transform = source.style.webkitTransform = 'scale(2)';
  source.style.transformOrigin = source.style.webkitTransformOrigin = '0 0';
  html2canvas(source, {
    width: source.offsetWidth * 2,
    height: source.offsetHeight * 2,
  })
    .then(canvas => {
      // source.style.transform = source.style.webkitTransform = 'scale(1)';
      setpixelated(canvas.getContext('2d'));
      const imgData = canvas.toDataURL('image/jpeg', 1);

      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const pageHeight = doc.internal.pageSize.height;
      let position = 10;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - position;
      while(heightLeft >= 0) {
        position = heightLeft - imgHeight + 30;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const pageCount = doc.internal.getNumberOfPages();
      for(let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(100);
        if(i !== pageCount - 1) {
          doc.text(10, 10, headerText);
        }
        doc.text(10, 290, 'generated via 2homes.com');
        doc.text(200, 290, 'p.' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount);
      }
      doc.save(filename);
    });
};


export const print = (className, filename) => {
  const promise = new Promise(resolve => {
    const doc = new jsPDF('p', 'mm', 'a4', true);
    const sources = document.getElementsByClassName(className);

    const renderedPages = [];
    let renderedPagesCounter = 0;
    for(let i = 0; i < sources.length; i++) {
      const source = sources.item(i);

      // source.style.transform = source.style.webkitTransform = 'scale(1.2)';
      // source.style.transformOrigin = source.style.webkitTransformOrigin = '0 0';

      html2canvas(source, {
        width: source.offsetWidth * 1,
        height: source.offsetHeight * 1,
        onrendered: canvas => {
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          ctx.mozImageSmoothingEnabled = false;
          ctx.oImageSmoothingEnabled = false;
          ctx.webkitImageSmoothingEnabled = false;
          ctx.msImageSmoothingEnabled = false;
        },
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png', 1);
        const imgWidth = 210;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const position = 0;

        renderedPages[i] = {
          imgData,
          position,
          imgWidth,
          imgHeight,
        };
        renderedPagesCounter++;

        source.style.transform = source.style.webkitTransform = 'scale(1)';
        if(renderedPagesCounter === sources.length) {
          processPrinting(doc, renderedPages, filename).then(() => {
            return resolve();
          });
        }
      });
    }
  });
  return promise;
};

export const processPrinting = (doc, renderedPages, filename) => {
  const printed = new Promise((resolve, reject) => {
    if(renderedPages.length && typeof renderedPages[0] === 'object') {
      renderedPages.forEach((page, idx) => {
        if(idx > 0) {
          doc.addPage();
        }
        // const moveTop = idx === 0 ? 10 : 35;
        doc.addImage(page.imgData, 'PNG', 0, 0, page.imgWidth, page.imgHeight, `hash${idx}`, 'FAST');
      });

      const pageCount = doc.internal.getNumberOfPages();
      for(let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(100);
        doc.text(20, 290, 'generated via 2homes.com');
        doc.text(190, 290, 'p.' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount);
      }

      doc.save(`${filename}.pdf`);
      resolve('saved');
    } else {
      reject('no pages rendered');
    }
  });
  return printed;
};
