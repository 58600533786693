import axios from 'axios';
import { v4 } from 'uuid';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_BASE_URL = `${window.location.origin}/`;
export const TWO_HOMES_KIDS_APP_SCHEME = process.env.REACT_APP_KIDS_APP_SCHEME;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const GOOGLE_STORE_KIDS_URL = 'http://bit.ly/2homes-kids-android'; // 'https://play.google.com/store/apps/details?id=com.atoma.a2homeskids&hl=en';
export const APPLE_STORE_KIDS_URL = 'http://bit.ly/2homes-kids-ios'; // 'https://apps.apple.com/us/app/2homes-kids/id1468876886?l=en&ls=1';
export const GOOGLE_STORE_URL = 'https://play.google.com/store/apps/details?id=com.atoma.a2homes&hl=en';
export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/2homes/id1464494872?l=en&ls=1';

export const TWO_HOMES_APP_SCHEME = 'app2homes://';
export const TWO_HOMES_DEV_APP_SCHEME = process.env.REACT_APP_DEV_APP_SCHEME; // replace IP with you local

const STORAGE_AUTH_TOKEN_KEY = '2homes-auth-token';
const STORAGE_RESET_TOKEN_KEY = '2homes-reset_token';
const STORAGE_CLIENT_TOKEN = '2homes-client-token';
const STORAGE_EMAIL_KEY = '2homes-email';
export const STORAGE_USER_KEY = '2homes-user';
export const STORAGE_WALKTHROUGH_KEY = `2homes-walkthrough-${process.env.NODE_ENV}`;

class API {
  constructor(history = null) {
    this.history = history;
    this.authToken = localStorage.getItem(STORAGE_AUTH_TOKEN_KEY);
    this.resetToken = localStorage.getItem(STORAGE_RESET_TOKEN_KEY);
    this.client_token = localStorage.getItem(STORAGE_CLIENT_TOKEN);
    if (!this.client_token) {
      this.client_token = this.generateClientTokent();
    }
    const user = localStorage.getItem(STORAGE_USER_KEY);
    if (user) {
      this.user = JSON.parse(user);
    } else {
      this.user = null;
    }
    this.email = localStorage.getItem(STORAGE_EMAIL_KEY);
  }

  isLoggedIn() {
    return !!this.authToken;
  }

  setAuthToken(token) {
    this.authToken = token;
    localStorage.setItem(STORAGE_AUTH_TOKEN_KEY, token);
  }

  getAuthToken() {
    return this.authToken;
  }

  removeAuthToken() {
    this.authToken = null;
    localStorage.removeItem(STORAGE_AUTH_TOKEN_KEY);
  }

  setResetToken(token) {
    this.resetToken = token;
    localStorage.setItem(STORAGE_RESET_TOKEN_KEY, token);
  }

  removeResetToken() {
    this.resetToken = null;
    localStorage.removeItem(STORAGE_RESET_TOKEN_KEY);
  }

  setUser(user) {
    this.user = user;
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem(STORAGE_USER_KEY);
  }

  setEmail(email) {
    this.email = email;
    localStorage.setItem(STORAGE_EMAIL_KEY, email);
  }

  getClientToken() {
    return this.client_token;
  }

  generateClientTokent() {
    const uuid = v4();
    localStorage.setItem(STORAGE_CLIENT_TOKEN, uuid);
    return uuid;
  }

  logout() {
    this.removeAuthToken();
    this.removeResetToken();
    this.removeUser();
  }

  addTokenToConfig(config = {}) {
    const token = this.authToken;
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = 'Token ' + token;
    }
    return config;
  }

  getRequestErrorMessage(error = {}, defaultMessage = '', additionalMessages = {}) {
    let message = defaultMessage;
    if(error.response) {
      if(error.response && error.response.status === 401) {
        window.location.href = `/sign-in?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      }
      if(error.response.data) {
        message = error.response.data.errors || error.response.data.error;
      } else if(typeof additionalMessages[error.response.status] !== 'undefined') {
        message = additionalMessages[error.response.status];
      }
    }
    return message;
  }

  reAuthorizeUser() {
    const params = {
      client_token: this.client_token,
      reset_token: this.resetToken,
    };
    return axios.post(`${BASE_URL}reset_authentication`, params)
      .then(response => {
        this.setAuthToken(response.data.auth_token);
        this.setResetToken(response.data.reset_token);
      }).catch(() => {
        window.location.href = `/sign-in?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      });
  }

  post(url, params = {}, config = {}) {
    const configToken = this.addTokenToConfig(config);
    return axios.post(url, params, configToken);
  }

  put(url, params = {}, config = {}) {
    const configToken = this.addTokenToConfig(config);
    return axios.put(url, params, configToken);
  }

  patch(url, params = {}, config = {}) {
    const configToken = this.addTokenToConfig(config);
    return axios.patch(url, params, configToken);
  }

  get(url, params = {}, config = {}) {
    const configToken = this.addTokenToConfig(config);
    configToken.params = params;
    return axios.get(url, configToken);
  }

  delete(url, config = {}) {
    const configToken = this.addTokenToConfig(config);
    return axios.delete(url, configToken);
  }

  deleteWithParam(url, params) {
    const token = this.authToken;
    return axios({
      method: 'delete',
      url,
      data: null,
      headers: {
        'Authorization': 'Token ' + token,
      },
      params,
    });
  }

  fake(url, params, data = {}, error = null) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (error === null) {
          resolve({ data });
        } else {
          reject({
            error,
          });
        }
      }, 500);
    });
  }

  getAppHost() {
    const { protocol, host } = document.location;
    return protocol + '//' + host + '/';
  }

  getQueryParams(key = null) {
    const hash = {};
    document.location.search
      .replace('?', '')
      .split('&')
      .map(t => t.split('='))
      .forEach(arr => {
        hash[arr[0]] = arr[1];
      });
    if (key) {
      return hash[key] || null;
    }
    return hash;
  }

  useFetch = async(url, method, body, headers, config = {}) => {
    const configToken = await this.addTokenToConfig(config);
    const options = {
      method,
      body,
      headers: {
        Accept: 'application/json',
        ...headers,
      },
      ...configToken,
    };
    return fetch(url, options);
  }
}

export const api = new API();
