import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SummaryBox } from '../../../../components';
import { setNumberFormat } from '../../../../utils/tools';


const ReportGeneralSummaries = ({ generalData, splitsData }, { t: translate }) => {
  const balance = generalData.incomes && generalData.expenses && (generalData.incomes.total - generalData.expenses.total);

  let valueSplit = '';
  if(splitsData) {
    if(splitsData.for_me !== null) {
      valueSplit += `${setNumberFormat(splitsData.for_me || 0, 2)}`;
    }
    if(splitsData.for_me !== null && splitsData.from_me !== null) {
      valueSplit += ' / ';
    }
    if(splitsData.from_me !== null) {
      valueSplit += `${setNumberFormat(splitsData.from_me || 0, 2)}`;
    }
  }

  const renderFullLabel = () => (
    <Fragment>
      {translate('Split requests')}
      <span className="block">{translate('(for me / from me)')}</span>
    </Fragment>
  );

  const noSplitsForMeLabel = translate('Split req. from me');
  const noSplitsFromMeLabel = translate('Split req. for me');
  const splitsSummaryLabel = splitsData.for_me !== null && splitsData.from_me !== null ?
    renderFullLabel() :
    splitsData.for_me === null ? noSplitsForMeLabel : noSplitsFromMeLabel;

  return (
    <div className="report-general-summary">
      <div className="summary">
        <SummaryBox
          plusMinusIndicator
          name={translate('Expenses')}
          value={(generalData.expenses && (generalData.expenses.total * -1)) || 0}
          possibleClasses={['success', 'error']}
          currency="NOK"
          decimalPlaces={0}
        />
      </div>
      <div className="summary">
        <SummaryBox
          plusMinusIndicator
          name={translate('income')}
          value={(generalData.incomes && generalData.incomes.total) || 0}
          possibleClasses={['success', 'error']}
          currency="NOK"
          decimalPlaces={0}
        />
      </div>
      <div className="summary">
        <SummaryBox
          plusMinusIndicator
          name={translate('Balance')}
          value={balance || 0}
          possibleClasses={['success', 'error']}
          currency="NOK"
          decimalPlaces={0}
        />
      </div>
      {valueSplit && (<div className="summary" style={{ width: '35%' }}>
        <SummaryBox
          name={splitsSummaryLabel}
          value={(splitsData && splitsData.for_me) || 0}
          valueStr={valueSplit}
          currency="NOK"
        />
      </div>)}
    </div>
  );
};


ReportGeneralSummaries.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportGeneralSummaries;
