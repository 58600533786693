import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { cleanError } from '../../../store/actions/errors';
import { addKid } from '../../../store/actions/homes';
import { hideLoader } from '../../../store/actions/loading';
import { cleanNickname } from '../../../utils/tools';
import { DEFAULT_DATE_FORMAT } from '../../../utils/date';
import InviteKid from '../components/InviteKid';

class InviteKidContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      home_type: 'current',
      home_id: '',
      first_name: '',
      last_name: '',
      email: '',
      nickname: '',
      gender: '',
      note: '',
      birthdate: moment().subtract(1, 'd').format(DEFAULT_DATE_FORMAT),
      avatar: {
        fileUrl: '',
        thumbnailUrl: '',
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const current = urlParams.get('current');
    const ex = urlParams.get('ex');

    if(current) {
      this.setState({
        home_type: 'current',
        home_id: current !== 'undefined' ? current : '',
      });
    }

    if(ex) {
      this.setState({
        home_type: 'ex',
        home_id: ex !== 'undefined' ? ex : '',
      });
    }

    this.props.hideLoader();
  }

  componentWillUnmount() {
    this.props.cleanError('homes');
  }

  handleFieldChange = (name, forcedValue, flat) => {
    if(flat) {
      this.setState({ [name]: forcedValue });
    } else {
      return event => {
        let value = event.target.value;
        if (event.target.tagName.toUpperCase() === 'A') {
          value = true;
        }
        this.setState({ [name]: value });
      };
    }
  }

  cancelForm = () => {
    this.props.history.push('/home');
  }

  handleAvatarChange = file => {
    this.setState({
      avatar: { thumbnailUrl: URL.createObjectURL(file) },
      avatarFile: file,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.cleanError('homes');
    const { first_name, last_name, email, birthdate, note, avatarFile, gender, home_type, home_id } = this.state;
    let { nickname } = this.state;
    nickname = nickname === '' ? first_name.toLowerCase().replace(/ /g, '').substr(0, 10) : cleanNickname(nickname);
    const kidToAdd = { first_name, last_name, nickname, birthdate, note, file: avatarFile, gender, home_type, home_id };
    if(email && email !== '') {
      kidToAdd.email = email;
    }
    this.props.addKid(kidToAdd);
  }

  render() {
    return (
      <InviteKid
        {...this.props}
        formData={{ ...this.state }}
        isNoteVisible={this.state.isNoteVisible}
        onFieldChange={this.handleFieldChange}
        onAvatarChange={this.handleAvatarChange}
        cancelForm={this.cancelForm}
        onSubmit={this.handleSubmit}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    error: state.errors.homes,
    layout: state.layout,
    loading: state.loading,
  };
};

const mapDispatchToProps = {
  addKid,
  hideLoader,
  cleanError,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteKidContainer);
