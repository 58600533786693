import React from 'react';
import * as _ from 'lodash';

import Badge from './../../../../components/Badge';
import { isEdge } from './../../../../utils/browser';


export const CalendarNavigationCaretakers = ({ user, caretakers, filters, setFilters }) => {
  let orderedCaretakers = [user];
  if(caretakers) {
    orderedCaretakers.push(
      Object.values(caretakers).filter(caretaker => caretaker.familyStatus === 'current' && caretaker.visibility && caretaker.id !== user.id),
      Object.values(caretakers).filter(caretaker => caretaker.familyStatus === 'ex' && caretaker.visibility && caretaker.id !== user.id),
    );
  }
  orderedCaretakers = _.flattenDeep(orderedCaretakers);

  return (
    <div className={`caretakers-filter pull-right ${isEdge ? 'edge' : ''}`}>
      {orderedCaretakers && Object.values(orderedCaretakers).map((caretaker, idx) => {
        return (
          <Badge key={idx}
            label={caretaker.nickname ? `@${caretaker.nickname}` : `@${caretaker.first_name.toLowerCase()}`}
            active={filters.caretakers.indexOf(caretaker.id) > -1}
            bgColor={caretaker.color}
            onClick={() => setFilters([caretaker.id])}
          />
        );
      })}
    </div>
  );
};


export default CalendarNavigationCaretakers;
