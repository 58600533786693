import React from 'react';
import PropTypes from 'prop-types';

import { FileUploader, Notification } from '../../../components';
import { sortByDate } from '../../../utils/date';

import FinancesPendingTable from './FinancesPendingTable';


class FinancesPending extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingTableParams: {
        opened: true,
        page: 1,
      },
    };
  }

  togglePendingTable = () => {
    const { pendingTableParams } = this.state;
    pendingTableParams.opened = !pendingTableParams.opened;
    this.setState({
      pendingTableParams,
    }, () => {
      setTimeout(() => {
        document.querySelector('.finances-pending').style.marginTop = 'initial';
      }, 350);
    });
  }

  render() {
    const { pendingTableParams } = this.state;
    const { finance, homeUsers, isWalkthroughOpen, user, saveFile, error } = this.props;
    const { t: translate } = this.context;

    const pendingData = sortByDate(finance.pendingData, 'desc', 'created_at');

    return (
      <div className="clear" style={{ marginTop: isWalkthroughOpen ? 0 : 240 }}>
        <div className="section-title center">
          <div>{translate('pending')} ({finance.pendingData.length})</div>
          {pendingTableParams.opened && (<div className="chevron chevron-up" onClick={this.togglePendingTable} />)}
          {!pendingTableParams.opened && (<div className="chevron chevron-down" onClick={this.togglePendingTable} />)}
        </div>

        <FileUploader handleUploadFile={saveFile} translate={translate} type="dropzone" />

        <div className="finances-pending" style={{ maxHeight: isWalkthroughOpen ? 85 : 'initial' }} rel={pendingTableParams.opened ? 'opened' : 'closed'}>
          {error ? (
            <div className="notification-container">
              <Notification
                type="error"
                text={error}
              />
            </div>
          ) : null}
          <FinancesPendingTable showIcon={isWalkthroughOpen} pendingData={pendingData} homeUsers={homeUsers} user={user} {...this.props} />
        </div>
      </div>
    );
  }
}


FinancesPending.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesPending;
