import React from 'react';
import PropTypes from 'prop-types';

import ReportChart from './ReportChart';

const ReportGeneral = ({ generalData, generalIdxOnPage }, { t: translate }) => {
  return (
    <div className="report-general report-container report-summary">
      {generalData.expenses && generalData.expenses.total > 0 && (
        <div className="categories">
          {generalIdxOnPage.expenses.length > 0 && (<p>{`${translate('Total expenses by category')}:`}</p>)}
          {generalData.expenses.tag_keys.map((tagKey, idx) => {
            if(generalIdxOnPage.expenses.indexOf(idx) > -1) {
              return (
                <ReportChart
                  key={idx}
                  chartData={tagKey}
                  total={generalData.expenses.total}
                />
              );
            }
          })}
        </div>
      )}

      {generalData.incomes && generalData.incomes.total > 0 && (
        <div className="categories">
          {generalIdxOnPage.incomes.length > 0 && (<p>{`${translate('Total income by category')}:`}</p>)}
          {generalData.incomes.tag_keys.map((tagKey, idx) => {
            if(generalIdxOnPage.incomes.indexOf(idx) > -1) {
              return (
                <ReportChart
                  key={idx}
                  chartData={tagKey}
                  total={generalData.incomes.total}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};


ReportGeneral.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportGeneral;
