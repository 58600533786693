import React from 'react';
import PropTypes from 'prop-types';

import { getFontColor } from './../../../../utils/tools';


export const EventParticipants = ({ type, calendarEvent, calendarPeople, person, caretakers, mainClassNames, eventColor }) => {
  const filteredParticipants = () => {
    let participants = [];
    calendarEvent && calendarEvent.participant_ids.map(participant => {
      if(type === 'longTerm' && (
        (calendarPeople[participant] && person.familyStatus !== calendarPeople[participant].familyStatus) ||
        (caretakers[participant] && person.id !== calendarEvent.caretaker_ids[0] && person.familyStatus !== caretakers[participant].familyStatus)
      )) {
        participants.push(participant);
      } else if(type === 'normal') {
        participants.push(participant);
      }
    });

    participants.sort((a, b) => {
      if(calendarPeople[a] && calendarPeople[b]) {
        return calendarPeople[a].first_name > calendarPeople[b].first_name ? 1 : -1;
      }
    });

    const currentUser = participants.filter(participant => caretakers[participant] && caretakers[participant].current === false && caretakers[participant].familyStatus === 'current');
    const partner = participants.filter(participant => caretakers[participant] && caretakers[participant].current === true);
    const partnerEx = participants.filter(participant => caretakers[participant] && caretakers[participant].familyStatus === 'ex');

    const ownKids = participants.filter(participant => calendarPeople[participant] && calendarPeople[participant].familyStatus === 'kid' && calendarPeople[participant].whichFamily === 'ex');
    const currentKids = participants.filter(participant => calendarPeople[participant] && calendarPeople[participant].familyStatus === 'kid' && calendarPeople[participant].whichFamily === 'current');
    const pertnerExKids = participants.filter(participant => calendarPeople[participant] && calendarPeople[participant].familyStatus === 'kid' && calendarPeople[participant].whichFamily === 'partnerEx');

    participants = [
      ...currentUser,
      ...ownKids,
      ...currentKids,
      ...partner,
      ...pertnerExKids,
      ...partnerEx,
    ];

    return participants;
  };

  const participants = filteredParticipants();

  return (
    <span className={mainClassNames} data-e2e={`calendar-event-container-${calendarEvent.title}-participants`}>
      <span className="participants flex flex-wrap">{participants && participants.map((participant, idx) => {
        const name = (calendarPeople[participant] && calendarPeople[participant].nickname && `@${calendarPeople[participant].nickname.toLowerCase()}`) ||
          (calendarPeople[participant] && calendarPeople[participant].first_name && calendarPeople[participant].first_name.toLowerCase()) ||
          (caretakers[participant] && caretakers[participant].nickname && `@${caretakers[participant].nickname.toLowerCase()}`) ||
          (caretakers[participant] && caretakers[participant].first_name && caretakers[participant].first_name.toLowerCase());

        return (
          <span className="f11 lh22" key={idx}>
            <span className="m-r-5 w400 black lowercase" style={eventColor && { color: getFontColor(eventColor) }}>{name}</span>
          </span>
        );
      })}</span>
    </span>
  );
};


EventParticipants.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EventParticipants;
