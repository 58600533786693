import { ERROR, CLEAN_ERROR } from './../types';


export default (state = {}, action) => {
  if (action.type === ERROR) {
    return {
      ...state,
      [action.payload.type]: action.payload.message,
    };
  } else if (action.type === CLEAN_ERROR) {
    return {
      ...state,
      [action.payload]: null,
    };
  }
  return state;
};
