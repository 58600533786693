import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from './InfoModal';


const SubscriptionCreateSuccess = ({ name }, { t: translate }) => (
  <InfoModal imgType="tada" name={name}>
    <h2 className="Title">{translate('Subscription created!')}</h2>

    <h3 className="InfoText">
      {translate('You can use 2homes to its fullest now!')}
    </h3>
  </InfoModal>
);


SubscriptionCreateSuccess.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SubscriptionCreateSuccess;
