import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DatePicker } from '../../../components';


const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const ReportsDateSelect = ({ keyParam, filters, placeholder, handleFormChange, user }) => {
  const selected = (filters && keyParam && moment(filters[keyParam])) || moment();

  return (
    <div className="half inline date-time-section">
      {filters[keyParam] && (
        <DatePicker
          label={placeholder}
          locale={user.language || 'en-gb'}
          onChange={date => handleFormChange(keyParam, date.format(DEFAULT_DATE_FORMAT), true)}
          selected={selected}
        />
      )}
    </div>
  );
};


ReportsDateSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportsDateSelect;
