import React from 'react';
import PropTypes from 'prop-types';


const GettingStarted = ({ firstName, history }, { t: translate }) => (
  <div className="GettingStarted CompleteYourProfile">
    <section>
      <h2>{translate('Hi')} <br /> {firstName}!</h2>
      <p>
        {translate('Welcome to 2homes!')}
        <br />
        {translate('The app that simplifies life for part-time parents.')}
        <br />
        {translate('Let us help you take the first steps.')}
      </p>
      <p className="button-container">
        <button
          type="button"
          className="button"
          onClick={() => history.push('/first-steps/2')}
        >
          {translate("Let's start!")}
        </button>
      </p>
    </section>
  </div>
);


GettingStarted.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GettingStarted;
