import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { Button, Form, Modal, Notification } from '../../../../components';
import { handleFormKeyPress } from '../../../../utils/tools';

import Caretakers from './Caretakers';


export const FinanceModalSplit = ({ user, caretakers, ...props }, { t: translate }) => {
  const exCaretakers = _.filter(caretakers, ['ex', true]);

  const formTouched = () => {
    return props.editFormData &&
      (props.editFormData.split_caretaker_id !== '' || props.editFormData.split_type !== '');
  };

  const validForm = () => {
    return !_.isEmpty(props.editFormData.split_caretaker_id) && !_.isEmpty(props.editFormData.split_type);
  };

  const handleKeyPress = e => {
    handleFormKeyPress(e, '#finance-split-modal-form button[type="submit"]');
  };

  const recipient = (props.editFormData.recipient || {});
  const recipientSecondParent = (recipient.parents || []).find(parent => caretakers[parent.id].familyStatus === 'ex');

  return (
    <Modal
      small
      isOpen={props.modalOpened}
      label="Finance-Split"
      closeModal={() => props.closeModal(false)}
      dataChanged={formTouched()}
    >

      <div
        tabIndex="0"
        onKeyPress={handleKeyPress}
        className="finance-modal"
      >
        <Form id="finance-split-modal-form" onSubmit={props.saveSplitRequest} loading={false}>
          {props.editFormData.type === 'expense' && Object.keys(exCaretakers).length > 0 && (
            <Form.Row>
              <Caretakers
                user={user}
                caretaker={recipientSecondParent}
                activeCaretaker={props.editFormData.split_caretaker_id}
                handleFormChange={props.handleFormChange}
                split_amount={props.editFormData.split_amount}
                split_type={props.editFormData.split_type}
                total_amount={props.editFormData.total_amount}
              />
            </Form.Row>
          )}

          {props.error && (
            <div className="error center">
              <Notification type="error" text={props.error} />
            </div>
          )}

          <Form.Summary>
            <Button
              type="submit"
              label={translate('Save')}
              align="center"
              disabled={!validForm()}
              data-e2e="accounting-save-button"
            />
          </Form.Summary>
        </Form>
      </div>
    </Modal>
  );
};


FinanceModalSplit.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinanceModalSplit;
