import { push } from 'connected-react-router';

import { api, BASE_URL } from '../../utils/net';

import { signoutUser } from './auth';
import {
  PROFILE_UPDATE_SUCCESS,
  UNSET_USER_FIRST_STEPS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function profileUpdateSuccess(profile) {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload: profile,
  };
}

export function unsetUserFirstSteps() {
  return {
    type: UNSET_USER_FIRST_STEPS,
  };
}


export const deleteUserAccount = () => async dispatch => {
  const defaultErrorMessage = 'Cannot delete user\'s profile';

  try {
    await api.delete(`${BASE_URL}me`);
    dispatch(signoutUser(true));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, defaultErrorMessage);
    dispatch(setError('delete_account', message));
  }
};

export const getUserProfile = () => async dispatch => {
  const defaultErrorMessage = 'Cannot fetch user\'s profile';

  try {
    const { data } = await api.get(`${BASE_URL}me`);

    api.setUser(data);
    dispatch(profileUpdateSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, defaultErrorMessage);
    dispatch(setError('first_steps', message));
  } finally {
    dispatch(hideLoader());
  }
};

export const updateUserProfile = ({ firstName, username, language, lastName, birthDate, gender, avatarFile, color, email }, redirect, reCall = false) => async dispatch => {
  dispatch(showLoader());
  const defaultErrorMessage = 'Cannot update user profile';
  const defaultColor = gender === 'male' ? '#81bbbd' : '#f5664e';

  try {
    let profile = {};

    if(avatarFile) {
      const formdata = new FormData();
      formdata.append('first_name', firstName);
      formdata.append('language', language);
      formdata.append('last_name', lastName || '-');
      formdata.append('birthdate', birthDate);
      formdata.append('nickname', username);
      formdata.append('gender', gender);
      formdata.append('color', color || defaultColor);
      formdata.append('email', email);
      formdata.append('file', avatarFile);
      formdata.append('filetype', 'image');
      formdata.append('filename', avatarFile.name);

      const response = await api.useFetch(
        `${BASE_URL}me`,
        'PATCH',
        formdata,
        { 'Content-Type': 'multipart/form-data' },
      );

      profile = await response.json();
    } else {
      const userData = {
        first_name: firstName,
        last_name: lastName,
        language,
        birthdate: birthDate,
        nickname: username,
        gender: gender,
        color: color,
        email: email,
      };

      const { data } = await api.patch(`${BASE_URL}me`, userData);
      profile = { ...data };
    }

    if(profile.errors) {
      throw new Error(profile.errors);
    } else {
      profile.first_steps = false;
      profile.avatar = profile.file;
      await api.setUser(profile);

      dispatch(profileUpdateSuccess(profile));
      dispatch(hideLoader());
      if(redirect) {
        const url = '/first-steps/3';
        dispatch(push(url));
      }
    }
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(updateUserProfile({ firstName, lastName, birthDate }, true));
        dispatch(unsetUserFirstSteps());
      });
    } else {
      const message = api.getRequestErrorMessage(error, defaultErrorMessage);
      dispatch(setError('first_steps', message));
    }
  }
};
