import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { showWalkthrough } from 'src/store/actions/walkthrough';


const Help = ({ pathName, showWalkthrough: showHelp }, { t: translate }) => {
  const handleOnClick = () => {
    window.scrollTo(0, 0);
    showHelp(pathName);
  };

  const showInitialWalkthrough = window.location.search && window.location.search.indexOf('?walkthrough') > -1;

  if(showInitialWalkthrough) {
    handleOnClick();
  }

  return (
    <div className="Help">
      <button type="button" className="HelpButton" onClick={handleOnClick}>
        <i
          className={classnames('zmdi zmdi-help', {
            highlighted: showInitialWalkthrough,
          })}
          title={translate('Show walkthrough')}
        />
      </button>
    </div>
  );
};

Help.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
  showWalkthrough,
};

export default connect(
  null,
  mapDispatchToProps,
)(Help);
