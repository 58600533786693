import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import SignedInLayout from '../../../layouts/SignedInLayout';

import { ModalConfirm } from './../../../components';
import FinanceModal from './modal/FinanceModal';
import FinanceModalSplit from './modal/FinanceModalSplit';
import FinancesDateFilter from './FinancesDateFilter';
import FinancesSummary from './FinancesSummary';
import FinancesPending from './FinancesPending';
import FinancesHistory from './FinancesHistory';


export const Finances = ({ user, loading, layout, finance, caretakers, ...props }, { t: translate }) => {
  const activeDateMoment = moment(finance.activeDate);
  const activeMonth = activeDateMoment.format('MMMM');
  const activeYear = activeDateMoment.format('YYYY');

  return (
    <SignedInLayout
      page="Accounting"
      currentPath="/accounting"
      user={user}
      loading={loading}
      layout={layout}
    >
      {props.modalType === 'normal' && (
        <FinanceModal
          homeUsers={props.homeUsers}
          user={user}
          modalOpened={props.modalOpened}
          handleFormChange={props.handleFormChange}
          closeModal={props.closeModal}
          editFormData={props.editFormData}
          saveFinance={props.saveFinance}
          addNewFile={props.addNewFile}
          setFileToDelete={props.setFileToDelete}
          error={props.error}
          loading={loading}
        />
      )}

      {props.modalType === 'split' && (
        <FinanceModalSplit
          user={user}
          caretakers={caretakers}
          modalOpened={props.modalOpened}
          handleFormChange={props.handleFormChange}
          closeModal={props.closeModal}
          editFormData={props.editFormData}
          saveSplitRequest={props.saveSplitRequest}
          error={props.error}
        />
      )}

      <ModalConfirm
        isOpen={props.confirmModalOpened}
        closeModal={() => props.closeModal(true)}
        question={translate('Are you sure you want to delete the file?')}
        deleteFunction={props.deleteFile}
        dataE2E="accounting-delete-modal-confirm"
      />

      <div className="content-header content-summaries summaries-fixed" style={{ position: props.isWalkthroughOpen ? 'initial' : 'fixed' }}>
        <FinancesDateFilter finance={finance} setDate={props.setDate} />
        <FinancesSummary summaryData={finance.summaryData} activeMonth={activeMonth} activeYear={activeYear} />
      </div>

      <FinancesPending
        finance={finance}
        isWalkthroughOpen={props.isWalkthroughOpen}
        user={user}
        homeUsers={props.homeUsers}
        caretakers={caretakers}
        saveFile={props.saveFile}
        editPendingFile={props.editFile}
        setFileToDelete={props.setFileToDelete}
        error={props.error}
      />

      <FinancesHistory
        finance={finance}
        homeUsers={props.homeUsers}
        homesUsersFamilies={props.homesUsersFamilies}
        isWalkthroughOpen={props.isWalkthroughOpen}
        user={user}
        caretakers={caretakers}
        filterActive={props.filterActive}
        setHistoryFilter={props.setHistoryFilter}
        setModalType={props.setModalType}
        editFile={props.editFile}
        setFileToDelete={props.setFileToDelete}
        kids={props.kids}
      />
    </SignedInLayout>
  );
};


Finances.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Finances;
