import Form from './Form';
import FormRow from './FormRow';
import FormSummary from './FormSummary';
import FormTitle from './FormTitle';

Form.Row = FormRow;
Form.Summary = FormSummary;
Form.Title = FormTitle;

export default Form;
