import React from 'react';
import moment from 'moment';

import { DateSelector } from './../../../components';


export const FinancesDateFilter = ({ finance, setDate }) => {
  const selectedYear = finance.filterDate.getFullYear();
  const selectedMonth = finance.filterDate.getMonth() + 1;

  return (
    <DateSelector
      selectedYear={selectedYear}
      selectedMonth={selectedMonth}
      activeDate={moment(finance.activeDate).toDate()}
      setDate={setDate}
    />
  );
};


export default FinancesDateFilter;
