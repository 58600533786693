import { api, BASE_URL } from '../../utils/net';

import {
  SET_KID_PROFILE_SUCCESS,
  UPDATE_KID_PROFILE_SUCCESS,
  ADD_KID_CARD_SUCCESS,
  UPDATE_KID_CARD_SUCCESS,
  DELETE_KID_CARD_SUCCESS,
  ADD_KID_LEAF_SUCCESS,
  UPDATE_KID_LEAF_SUCCESS,
  DELETE_KID_LEAF_SUCCESS,
  SET_KID_DOCUMENTS_SUCCESS,
  ADD_KID_DOCUMENTS_SUCCESS,
  UPDATE_KID_DOCUMENTS_SUCCESS,
  DELETE_KID_DOCUMENTS_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function setKidProfileSuccess(profile) {
  return {
    type: SET_KID_PROFILE_SUCCESS,
    payload: profile,
  };
}

export function updateKidProfileSuccess(profile) {
  return {
    type: UPDATE_KID_PROFILE_SUCCESS,
    payload: profile,
  };
}

export function addKidProfileCardSuccess(card) {
  return {
    type: ADD_KID_CARD_SUCCESS,
    payload: card,
  };
}

export function updateKidProfileCardSuccess(card) {
  return {
    type: UPDATE_KID_CARD_SUCCESS,
    payload: card,
  };
}

export function deleteKidProfileCardSuccess(cardId) {
  return {
    type: DELETE_KID_CARD_SUCCESS,
    payload: cardId,
  };
}

export function addKidProfileLeafSuccess(cardId, leaf) {
  return {
    type: ADD_KID_LEAF_SUCCESS,
    cardId,
    leaf,
  };
}

export function updateKidProfileLeafSuccess(cardId, leaf) {
  return {
    type: UPDATE_KID_LEAF_SUCCESS,
    cardId,
    leaf,
  };
}

export function deleteKidProfileLeafSuccess(cardId, leafId) {
  return {
    type: DELETE_KID_LEAF_SUCCESS,
    cardId,
    leafId,
  };
}

export function setKidDocumentsSuccess(payload) {
  return {
    type: SET_KID_DOCUMENTS_SUCCESS,
    payload,
  };
}

export function addKidDocumentsSuccess(payload) {
  return {
    type: ADD_KID_DOCUMENTS_SUCCESS,
    payload,
  };
}

export function updateKidDocumentsSuccess(payload) {
  return {
    type: UPDATE_KID_DOCUMENTS_SUCCESS,
    payload,
  };
}

export function deleteKidsProfileDocumentSuccess(payload) {
  return {
    type: DELETE_KID_DOCUMENTS_SUCCESS,
    payload,
  };
}


export const fetchKidsProfile = (kid, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}kids/${kid.id}/information_categories/`);
    dispatch(setKidProfileSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchKidsProfile(kid, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('kid_profile', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const updateKidsProfile = (kidData, kidId) => async dispatch => {
  dispatch(showLoader());

  try {
    const { first_name, last_name, birthdate, nickname, gender, color, file, email } = kidData;
    let kidProfile = {};

    if(file && file !== '') {
      const formdata = new FormData();
      formdata.append('first_name', first_name);
      formdata.append('last_name', last_name);
      formdata.append('birthdate', birthdate);
      formdata.append('nickname', nickname);
      formdata.append('gender', gender);
      formdata.append('color', color);
      email && formdata.append('email', email || null);
      formdata.append('file', file);
      formdata.append('filetype', 'image');
      formdata.append('filename', file.name);

      const response = await api.useFetch(
        `${BASE_URL}kids/${kidId}`,
        'PATCH',
        formdata,
        { 'Content-Type': 'multipart/form-data' },
      );

      kidProfile = await response.json();
    } else {
      const params = {
        first_name: kidData.first_name,
        last_name: kidData.last_name,
        birthdate: kidData.birthdate,
        nickname: kidData.nickname,
        gender: kidData.gender,
        color: kidData.color,
        email: kidData.email,
      };

      const { data } = await api.patch(`${BASE_URL}kids/${kidId}`, params);
      kidProfile = { ...data };
    }

    dispatch(updateKidProfileSuccess(kidProfile));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


/* DOCUMENTS */
export const fetchKidsDocuments = (kid, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}kids/${kid.id}/documents/`);
    dispatch(setKidDocumentsSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchKidsDocuments(kid, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('kid_profile', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const addKidsDocument = (kid, file, fileType) => async(dispatch, getState) => {
  dispatch(showLoader());
  const { user } = getState();

  try {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('filetype', fileType);
    formdata.append('filename', file.name);
    formdata.append('note', '');
    formdata.append('owner_id', user.id);

    const response = await api.useFetch(
      `${BASE_URL}kids/${kid.id}/documents/`,
      'POST',
      formdata,
      { 'Content-Type': 'multipart/form-data' },
    );

    const data = await response.json();
    dispatch(addKidDocumentsSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const updateKidsDocument = (kid, file, fileId) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.patch(`${BASE_URL}kids/${kid.id}/documents/${fileId}`, file);
    dispatch(updateKidDocumentsSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteKidsProfileDocument = (kid, documentId) => async dispatch => {
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}kids/${kid.id}/documents/${documentId}`);
    dispatch(deleteKidsProfileDocumentSuccess(documentId));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


/* CARDS */
export const saveKidsProfileCard = (kid, name) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.post(`${BASE_URL}kids/${kid.id}/information_categories/`, {
      name,
    });
    dispatch(addKidProfileCardSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const updateKidsProfileCard = (kid, card) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.patch(`${BASE_URL}kids/${kid.id}/information_categories/${card.id}`, {
      name: card.title,
    });
    dispatch(updateKidProfileCardSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteKidsProfileCard = (kid, cardId) => async dispatch => {
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}kids/${kid.id}/information_categories/${cardId}`);
    dispatch(deleteKidProfileCardSuccess(cardId));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


/* LEAFS */
export const saveKidsProfileLeaf = leaf => async dispatch => {
  dispatch(showLoader());

  try {
    const { parent_id, title, description } = leaf;
    const { data } = await api.post(`${BASE_URL}kids/information_categories/${parent_id}/information/`, {
      key: title,
      value: description,
    });
    dispatch(addKidProfileLeafSuccess(parent_id, data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const updateKidsProfileLeaf = leaf => async dispatch => {
  dispatch(showLoader());

  try {
    const { parent_id, id, title, description } = leaf;
    const { data } = await api.put(`${BASE_URL}kids/information_categories/${parent_id}/information/${id}`, {
      key: title,
      value: description,
    });
    dispatch(updateKidProfileLeafSuccess(parent_id, data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteKidsProfileLeaf = leaf => async dispatch => {
  dispatch(showLoader());

  try {
    const { parent_id, id } = leaf;
    await api.delete(`${BASE_URL}kids/information_categories/${parent_id}/information/${id}`);
    dispatch(deleteKidProfileLeafSuccess(parent_id, id));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('kid_profile', message));
  } finally {
    dispatch(hideLoader());
  }
};
