import React from 'react';

import CalendarInnerDay from './CalendarInnerDay';
import CalendarInnerWeek from './CalendarInnerWeek';


export const CalendarInner = ({ ...props }) => {
  const setEventColor = (eventType, calendarEvent, person, creatorId, caretakers) => {
    let color = person && person.color || '#E59907';
    if(eventType === 'longTerm') {
      const caretaker = caretakers[calendarEvent.caretaker_ids[0]] || caretakers[calendarEvent.creator_id] || {};
      color = caretaker.color;
    }
    return color;
  };

  return props.calendar.viewType === 'day' ? (
    <CalendarInnerDay setEventColor={setEventColor} {...props} />
  ) : (
    <CalendarInnerWeek setEventColor={setEventColor} {...props} />
  );
};


export default CalendarInner;
