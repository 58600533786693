import * as _ from 'lodash';
import moment from 'moment';
import { startOfDay, startOfWeek } from 'date-fns';

import {
  SET_CALENDAR_EVENT_SUCCESS,
  SET_CALENDAR_VIEW,
  SET_CALENDAR_DATE,
  ADD_CALENDAR_EVENT_SUCCESS,
  UPDATE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT_SUCCESS,
  GENERATE_ICS_SUCCESS,
} from './../types';


const DEFAULT_STATE = {
  viewType: 'day',
  calendarDate: new Date(),
  rangeStartDate: moment(),
  usersEvents: [],
  ics: {
    url: '',
    content: '',
  },
};


export function calendarReducer(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case SET_CALENDAR_VIEW:
      return Object.assign({}, state, {
        viewType: action.payload,
        rangeStartDate: action.payload === 'week' ? startOfWeek(state.calendarDate, { weekStartsOn: 1 }) : startOfDay(state.calendarDate),
      });

    case SET_CALENDAR_DATE:
      return Object.assign({}, state, {
        calendarDate: action.payload,
        rangeStartDate: state.viewType === 'week' ? startOfWeek(action.payload, { weekStartsOn: 1 }) : startOfDay(action.payload),
      });

    case SET_CALENDAR_EVENT_SUCCESS:
      return Object.assign({}, state, {
        usersEvents: [
          ...action.payload,
        ],
      });

    case ADD_CALENDAR_EVENT_SUCCESS:
      let usersEvents = state.usersEvents;
      usersEvents.map(userEvent => {
        if(userEvent.id === action.payload.kid_id) {
          userEvent.calendar_events.push(action.payload);
        }
      });
      return Object.assign({}, state, {
        usersEvents,
      });

    case UPDATE_CALENDAR_EVENT_SUCCESS:
      const updatedUsersEvents = [];
      state.usersEvents.map(userEvent => {
        const newEvents = [];
        userEvent.calendar_events.map(event => {
          if(event.id === action.eventId) {
            const newEvent = _.cloneDeep(action.event);
            newEvent.long_term = action.event.type === 'care';
            newEvents.push(_.cloneDeep(newEvent));
          } else {
            newEvents.push(event);
          }
        });
        userEvent.calendar_events = newEvents;
        updatedUsersEvents.push(userEvent);
      });
      return Object.assign({}, state, {
        usersEvents: updatedUsersEvents,
      });

    case DELETE_CALENDAR_EVENT_SUCCESS:
      usersEvents = state.usersEvents;
      usersEvents.map(userEvent => {
        userEvent.calendar_events = _.filter(userEvent.calendar_events, e => e.id !== action.eventId);
      });
      return Object.assign({}, state, {
        usersEvents,
      });

    case GENERATE_ICS_SUCCESS:
      return Object.assign({}, state, {
        ics: action.payload,
      });

    default:
      return state;
  }
}
