import React from 'react';
import classnames from 'classnames';

const languageOptions = [
  { flagCode: 'GB', name: 'English', value: 'en' },
  { flagCode: 'NO', name: 'Norwegian', value: 'nn' },
  { flagCode: 'DK', label: 'Danish', value: 'da' },
  { flagCode: 'SE', label: 'Swedish', value: 'sv' },
];

class LanguageSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.language !== this.props.language) this.setState({ language: this.props.language });
  }

  handleChangeLanguage = language => {
    this.setState({
      language,
    }, () => {
      this.props.changeLanguage(language);
    });
  }

  render() {
    const { label } = this.props;
    const { language } = this.state;

    return (
      <div className="language-select">
        {label ? (
          <label className="m-r-15">{label}</label>
        ) : null}

        <div>
          {languageOptions.map(lang => (
            <div
              key={lang.flagCode}
              className={classnames('flag inline-block m-r-5', lang.flagCode, {
                'active': language === lang.value,
              })}
              onClick={() => this.handleChangeLanguage(lang.value)}
              title={lang.name}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default LanguageSelect;

