import React from 'react';
import { connect } from 'react-redux';

import { signoutUser } from '../../../store/actions/auth';

class SignOutContainer extends React.Component {
  componentDidMount() {
    this.props.signoutUser();
  }

  render() {
    return null;
  }
}


const mapDispatchToProps = {
  signoutUser,
};

export default connect(null, mapDispatchToProps)(SignOutContainer);
