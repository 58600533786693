import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';

import { getDaysList } from './../../../../utils/date';
import { isEdge } from './../../../../utils/browser';
import CalendarWeekEvents from './CalendarWeekEvents';


const setWeekEvents = (weekDates, events, filters, calendarPeople) => {
  const weekEvents = {};
  _.forEach(weekDates, day => {
    weekEvents[day] = {
      normal: {},
      longTerm: {},
    };
    events.map(userEvent => {
      weekEvents[day].longTerm[userEvent.id] = [];
      weekEvents[day].normal[userEvent.id] = [];

      userEvent.calendar_events.map(event => {
        if(filters && (_.intersection(filters.caretakers, event.caretaker_ids).length > 0 || event.caretaker_ids.length === 0) && (
          moment(event.start_date).isSame(day, 'day') ||
          moment(event.end_date).isSame(day, 'day') ||
          moment(day).isBetween(`${event.start_date} ${event.start_time}`, `${event.end_date} ${event.end_time}`, 'day', '[]')
        )) {
          event.kid = calendarPeople[userEvent.id];
          if(event.longTerm) {
            weekEvents[day].longTerm[userEvent.id].push(event);
          } else {
            const evt = _.cloneDeep(event);
            if(moment(event.start_date).isBefore(day)) {
              evt.displayed_start_date = event.end_date;
              evt.displayed_start_time = '00:00:00';
            }
            if(moment(event.end_date).isAfter(day)) {
              evt.displayed_end_date = event.start_date;
              evt.displayed_end_time = '23:59:59';
            }
            weekEvents[day].normal[userEvent.id].push(evt);
          }
        }
      });
    });
  });

  return weekEvents;
};


export const CalendarWeek = ({ user, calendar, caretakers, filters, editEvent, addNewEvent, setEventColor, calendarPeople, columnWidth }) => {
  const weekDates = getDaysList(calendar.rangeStartDate, 7);

  calendar.usersEvents.forEach(userEvent => {
    const longTermEvents = [];
    const normalEvents = [];
    userEvent.calendar_events.forEach(event => {
      event.long_term ? longTermEvents.push(event) : normalEvents.push(event);
    });
    const sortedLongTermEvents = _.sortBy(longTermEvents, ['end_date', 'end_time', 'start_date', 'start_time']);
    const sortedNormalEvents = _.sortBy(normalEvents, ['start_date', 'start_time']);
    userEvent.calendar_events = [
      ...sortedLongTermEvents,
      ...sortedNormalEvents,
    ];
  });

  const weekEvents = setWeekEvents(weekDates, calendar.usersEvents, filters, calendarPeople);

  return (
    <div className="calendar-week-container">
      {weekDates && weekDates.map((day, idx) => {
        const dayName = moment(day).format('ddd  D MMM');
        return (
          <div className="calendar-week-container-row" key={idx}>
            <div className="date-header-row">{dayName}</div>
            <div className={`table calendar-week ${isEdge ? 'edge' : ''}`}>
              <div className="thead">
                <CalendarWeekEvents
                  type="longTerm"
                  day={day}
                  user={user}
                  caretakers={caretakers}
                  calendarPeople={calendarPeople}
                  columnWidth={columnWidth}
                  weekEvents={weekEvents}
                  editEvent={editEvent}
                  addNewEvent={addNewEvent}
                  setEventColor={setEventColor}
                />
              </div>
              <div className="tbody">
                <CalendarWeekEvents
                  type="normal"
                  day={day}
                  user={user}
                  caretakers={caretakers}
                  calendarPeople={calendarPeople}
                  columnWidth={columnWidth}
                  weekEvents={weekEvents}
                  editEvent={editEvent}
                  addNewEvent={addNewEvent}
                  setEventColor={setEventColor}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};


export default CalendarWeek;
