import * as TYPES from './../types';


const INITIAL_STATE = { isOpen: false, pathName: '', stepIndex: 0 };

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.WALKTHROUGH_SHOW:
      return { ...state, isOpen: true, pathName: action.payload };

    case TYPES.WALKTHROUGH_HIDE:
      return { isOpen: false, pathName: '', stepIndex: 0 };

    case TYPES.WALKTHROUGH_UPDATE_STEP_INDEX:
      return { ...state, stepIndex: action.payload };

    default:
      return state;
  }
};
