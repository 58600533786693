import React from 'react';
import classnames from 'classnames';

import { Loading } from '../';

const Form = ({ className, children, loading, style, ...other }) => (
  <div className={classnames('form', className)} style={style}>
    <Loading show={loading}>
      <form {...other}>
        {children}
      </form>
    </Loading>
  </div>
);

export default Form;
