import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../Button';
import Logo from '../Logo';
import { getOS } from '../../utils/browser';
import { APPLE_STORE_URL, GOOGLE_STORE_URL, TWO_HOMES_APP_SCHEME } from '../../utils/net';

import './_UnsupportedMobile.scss';

const UnsupportedMobile = (props, { t: translate }) => {
  const { language } = useSelector(({ user }) => user);
  const os = getOS();
  // path for running in expo
  // const appRedirectPath = `${TWO_HOMES_DEV_APP_SCHEME}--/app2homes?redirectPath=${'Login'}&language=${language}`;
  const appRedirectPath = `${TWO_HOMES_APP_SCHEME}--/app2homes?redirectPath=${'Login'}&language=${language}`;
  const storeRedirectPath = typeof os === 'string' && os === 'Android' ? GOOGLE_STORE_URL : APPLE_STORE_URL;

  return (
    <div className="unsupported-mobile-container vertical-center-content flex-column">
      <Logo alt="2homes" type="standard-big" />

      <p className="text description">
        {translate('If you wish to use 2homes on your mobile, please download our app')}
      </p>
      <Button className="link-button-container m-b-20" buttonClassName="link-button" href={storeRedirectPath} type="button" label={translate('Download 2homes app')} />

      <p className="text description">
        {translate('If you have already installed the app, please click here to open')}
      </p>
      <Button className="link-button-container m-b-20" buttonClassName="link-button" href={appRedirectPath} label={translate('Open 2homes app')} />
    </div>
  );
};

UnsupportedMobile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default UnsupportedMobile;
