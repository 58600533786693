import React, { Fragment } from 'react';
import { useHistory } from 'react-router';

import Logo from '../Logo';

import SearchForm from './SearchForm';
import MessagesNotificator from './MessagesNotificator';
import UserDropdown from './UserDropdown';
import Help from './Help';


const Header = ({ pathName, user, type }) => {
  const { location } = useHistory();
  const isFirstStepsRoute = location?.pathname.split('/').indexOf('first-steps') > -1;

  return (
    <div className="Header">
      <Logo alt="2homes" type={type} clickable={user.subscription} />
      <SearchForm />
      {!isFirstStepsRoute ? (
        <Fragment>
          <MessagesNotificator />
          <Help pathName={pathName} />
        </Fragment>
      ) : null}
      <UserDropdown isFirstStepsRoute={isFirstStepsRoute} user={user} />
    </div>
  );
};

export default Header;
