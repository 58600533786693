import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/nn';
import 'moment/locale/en-gb';
import { setLanguage } from 'redux-i18n';

import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { showModal } from '../../../store/actions/layout';
import { renewSubscription } from '../../../store/actions/subscription';
import { deleteUserAccount, getUserProfile, updateUserProfile } from '../../../store/actions/user';
import { prepareFileUrl } from '../../../utils/file';
import { removeWhitespace } from '../../../utils/string';
import { cleanNickname } from '../../../utils/tools';
import UserProfile from '../components/UserProfile';

class UserProfileContainer extends React.PureComponent {
  constructor(props) {
    super(props);


    this.state = {
      card: null,
      confirmationModalOpened: false,
      editView: false,
      editMode: false,
      firstName: '',
      lastName: '',
      birthDate: '',
      username: '',
      gender: '',
      email: '',
      id: '',
      color: '',
      language: 'en',
      avatar: {
        thumbnailUrl: '',
        fileUrl: '',
      },
      step: 1,
      subscription: props.user.subscription ?? {},
      country: 'NO',
    };
  }

  componentDidMount() {
    this.props.fetchHomesIfNeeded();
    this.updateUserData();
    this.props.getUserProfile();
  }

  componentDidUpdate(nextProps) {
    const updateCondition = (this.props.user?.subscription?.status !== nextProps.user?.subscription?.status) ||
      (this.props.user?.card?.last4 !== nextProps.user?.card?.last4);

    if(updateCondition) {
      this.updateUserData();
    }
  }

  componentWillUnmount() {
    this.props.cleanError('user_profile');
  }

  updateUserData = () => {
    const { user  } = this.props;

    this.setState({
      firstName: user.first_name,
      lastName: user.last_name,
      birthDate: user.birthdate,
      username: user.nickname,
      gender: user.gender,
      email: user.email,
      id: user.id,
      color: user.color,
      language: user.language || 'en',
      avatar: {
        thumbnailUrl: prepareFileUrl(user.thumbnail_url) || '',
        fileUrl: prepareFileUrl(user.file_url) || '',
      },
      subscription: user.subscription ?? {},
      country: user.country_code,
      card: user.card,
    });
  }

  switchEditView = () => {
    const { editView, avatar } = this.state;
    avatar.fullpath = avatar.original;

    let newState = {
      avatar,
      editView: !editView,
    };

    if(editView) {
      const { first_name, last_name, birthdate, nickname, gender, email, id, color, language } = this.props.user;
      newState = Object.assign({}, newState, {
        firstName: first_name,
        lastName: last_name,
        birthDate: birthdate,
        username: nickname,
        gender,
        email,
        id,
        color,
        language,
      });
    }
    this.setState(newState);
  }

  handleChangeLanguage = language => {
    this.props.setLanguage(language);
    this.handleFieldChange('language', language);
    moment.locale(language);

    if(language === 'nn') {
      moment.updateLocale('nn', {
        weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
      });
    }
  }

  handleFieldChange = (name, forcedValue) => {
    if(forcedValue) {
      this.setState({
        [name]: forcedValue,
        editMode: true,
      });
    } else {
      return event => {
        let value = event.target.value;
        if(name === 'email') value = removeWhitespace(value);

        this.setState({
          [name]: value,
          editMode: true,
        });
      };
    }
  }

  handleAvatarChange = file => {
    this.setState({
      avatar: { ...this.state.avatar, fileUrl: URL.createObjectURL(file) },
      avatarFile: file,
      editMode: true,
    });
  }

  handleSubmitProfile = event => {
    event.preventDefault();
    this.props.cleanError('user_profile');
    const { firstName, lastName, birthDate, gender, avatarFile, color, email, language } = this.state;
    let { username } = this.state;
    username = username === '' ? firstName.toLowerCase() : cleanNickname(username);
    this.props.updateUserProfile({ firstName, lastName, birthDate, username, gender, avatarFile, color, email, language }, false).then(() => {
      const { error } = this.props;
      if(!error || error === null) {
        this.props.history.push('/home');
      }
    });
  }

  toggleConfirmationModal = () => {
    this.props.cleanError('delete_account');
    this.setState({ step: 1, confirmationModalOpened: !this.state.confirmationModalOpened });
  }

  setStep = direction => {
    const newStep = direction === 'next' ? 2 : 1;

    this.setState({ step: newStep });
  }

  handleDeleteUserAccount = () => {
    this.props.deleteUserAccount();
  }

  render() {
    return (
      <UserProfile
        {...this.props}
        profile={this.state}
        onFieldChange={this.handleFieldChange}
        onAvatarChange={this.handleAvatarChange}
        onSubmitProfile={this.handleSubmitProfile}
        switchEditView={this.switchEditView}
        changeLanguage={this.handleChangeLanguage}
        handleDeleteUserAccount={this.handleDeleteUserAccount}
        setStep={this.setStep}
        step={this.state.step}
        toggleConfirmationModal={this.toggleConfirmationModal}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    error: state.errors.first_steps,
    deleteError: state.errors.delete_account,
  };
};

const mapDispatchToProps = {
  updateUserProfile,
  fetchHomesIfNeeded,
  setLanguage,
  cleanError,
  deleteUserAccount,
  showModal,
  getUserProfile,
  renewSubscription,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileContainer);
