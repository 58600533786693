import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { cleanError } from '../../../store/actions/errors';
import { fetchNewNotifications, fetchNotifications, setNotificationsRead } from '../../../store/actions/notifications';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import Notifications from '../components/Notifications';


class NotificationsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationModalOpened: false,
      page: 1,
      perPage: 10,
      selectedMessages: [],
      deletedUserName: '',
    };
  }

  async componentDidMount() {
    const { page, perPage } = this.state;
    await this.props.fetchHomesIfNeeded();
    this.props.fetchNotifications(page, perPage);
  }

  loadMore = () => {
    const { perPage } = this.state;
    let { page } = this.state;
    page++;

    this.setState({
      page,
    }, () => {
      this.props.fetchNotifications(page, perPage);
    });
  }

  handleSelectedMessage = notificationId => {
    let { selectedMessages } = this.state;
    if(selectedMessages.indexOf(notificationId) > -1) {
      const index = selectedMessages.findIndex(idx => idx === notificationId);
      selectedMessages = [
        ...selectedMessages.slice(0, index),
        ...selectedMessages.slice(index + 1),
      ];
    } else {
      selectedMessages.push(notificationId);
    }

    this.setState({
      selectedMessages,
    });
  }

  handleRedirection = message => {
    if(message.action && message.action === 'destroy_user') {
      this.toggleNotificationsModal(message);
      this.props.setNotificationsRead([message.id]);
    } else {
      const redirectPath = message.notifiable_type.toLowerCase() === 'calendarevent' ?
        `/calendar?start-date=${moment(message.notifiable_start_date).format('YYYY-MM-DD')}` :
        `/split-request?id=${message.notifiable_id}&back=notifications`;

      this.props.setNotificationsRead([message.id]);
      this.props.history.push(redirectPath);
    }
  }

  toggleNotificationsModal = message => {
    const deletedUserName = this.state.notificationModalOpened ? '' : message.message.split(' ')[0];
    this.setState({ deletedUserName, notificationModalOpened: !this.state.notificationModalOpened });
  }

  setNotificationsRead = all => {
    let messagesToUpdate = [];
    if(all) {
      this.props.notifications.messages.map(message => {
        messagesToUpdate.push(message.id);
      });
    } else {
      const { selectedMessages } = this.state;
      messagesToUpdate = [
        ...selectedMessages,
      ];
    }

    this.setState({
      selectedMessages: [],
    });

    this.props.setNotificationsRead(messagesToUpdate).then(() => {
      this.props.fetchNewNotifications();
    });
  }

  render() {
    return (
      <Notifications
        {...this.props}
        {...this.state}
        handleSelectedMessage={this.handleSelectedMessage}
        setNotificationsRead={this.setNotificationsRead}
        handleRedirection={this.handleRedirection}
        loadMore={this.loadMore}
        toggleNotificationsModal={this.toggleNotificationsModal}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    notifications: state.notifications,
    homeUsers: state.homeUsers,
    error: state.errors.notifications,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  fetchNewNotifications,
  fetchNotifications,
  setNotificationsRead,
  cleanError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsContainer);
