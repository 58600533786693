import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLanguage } from 'redux-i18n';
import moment from 'moment';
import base64 from 'base-64';

import { prepareFileUrl } from '../../../utils/file';
import ShareFile from '../components/ShareFile';


class ShareFileContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      fileurl: '',
      author: '',
      filename: '',
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    const { t: translate } = this.context;
    const urlParams = new URLSearchParams(location.search);
    const lang = urlParams.get('lang');
    const file = urlParams.get('file');
    const filename = urlParams.get('filename');
    const author = urlParams.get('author');

    if(lang) {
      this.props.setLanguage(lang);
      moment.locale(lang);

      if(lang === 'nn') {
        moment.updateLocale('nn', {
          weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
          weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
        });
      }
    }

    if(file) {
      this.setState({
        fileurl: prepareFileUrl(base64.decode(file)),
        author: author || translate('Someone'),
        filename: filename || '',
      });
    } else {
      history.push('/');
    }
  }

  render() {
    return (
      <ShareFile {...this.state} />
    );
  }
}


const mapDispatchToProps = {
  setLanguage,
};


ShareFileContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


export default connect(
  null,
  mapDispatchToProps,
)(ShareFileContainer);
