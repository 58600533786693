import { LOCATION_CHANGE, LOCATION_CHANGE_NO_LOADER } from './../types';


const initialState = null;
export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return action.payload;
    }

    case LOCATION_CHANGE_NO_LOADER: {
      return action.payload;
    }

    default:
      return state;
  }
}
