import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import InfoModal from './InfoModal';


const PaymentProblemInfo = ({ name }, { t: translate }) => {
  const { push } = useHistory();

  return (
    <InfoModal
      buttonLabel={translate('Update card details')}
      imgType="ups"
      name={name}
      onClose={() => push('/subscription?mode=edit')}
    >
      <h2 className="Title">{translate('There is a problem with payment for your subscription')}</h2>

      <h3 className="InfoText">
        {translate('Update credit card details to solve the problem')}
      </h3>
    </InfoModal>
  );
};


PaymentProblemInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaymentProblemInfo;
