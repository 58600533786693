import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CalendarNavigationCaretakers from './NavigationCaretakers';
import CalendarNavigationView from './NavigationView';


export const CalendarNavigation = ({ user, calendar, caretakers, ...props }, { t: translate }) => {
  const calendarDateMoment = moment(calendar.calendarDate);
  const dayMonthSelected = calendarDateMoment.format('ddd, DD MMMM');
  const monthSelected = calendarDateMoment.format('MMMM');
  const weekSelected = calendarDateMoment.format('W');
  const yearSelected = calendarDateMoment.format('YYYY');

  return (
    <div className="calendar-header">
      <CalendarNavigationView viewType={calendar.viewType} setCalendarView={props.setCalendarView} setCalendarDate={props.setCalendarDate} />

      <div className="date-container">
        <div className="date-selected" onClick={() => props.toggleDatePicker()} >
          {calendar.viewType === 'day' ? (
            <span>
              <span className="day selected" data-e2e="calendar-day-selected">{dayMonthSelected}, </span>
              <span className="week">{translate('week')} {weekSelected}, </span>
            </span>
          ) : (<span />)}
          {calendar.viewType === 'week' ? (<span className="week selected">{translate('Week')} {weekSelected}, </span>) : (<span />)}
          {calendar.viewType === 'month' ? (<span className="month selected">{monthSelected}, </span>) : (<span />)}
          <span className="year">{yearSelected}</span>
        </div>

        <div
          className={'chevron' + (props.datePickerOpened ? ' chevron-up' : ' chevron-down')}
          onClick={props.toggleDatePicker}
        />
      </div>

      <CalendarNavigationCaretakers user={user} caretakers={caretakers} filters={props.filters} setFilters={props.setFilters} />

    </div>
  );
};


CalendarNavigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarNavigation;
