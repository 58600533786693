import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';

import { Walkthrough } from '../../../components';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { fetchReportData } from '../../../store/actions/reports';
import { fetchTagKeysIfNeeded } from '../../../store/actions/tags';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { showInitialWalkthrough } from '../../../utils/walkthrough';
import Reports from '../components/Reports';


const DEFAULT_FILTERS = {
  start: new Date(),
  end: new Date(),
  type: ['income', 'expense'],
  privacy: [],
  incomeTags: [],
  expenseTags: [],
  users: [],
  split_requests: [],
  note: '',
  section: 'accounting',
  history_enabled: true,
};

class ReportsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: _.cloneDeep(DEFAULT_FILTERS),
      generated: '',
      errors: [],
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    await this.props.fetchTagKeysIfNeeded();
    showInitialWalkthrough('reports', this.props.showWalkthrough);
  }

  componentWillUnmount() {
    this.props.cleanError('reports');
  }

  handleFormChange = (name, value, flat) => {
    const { filters } = this.state;
    if(!flat) {
      if(filters[name].indexOf(value) === -1) {
        filters[name].push(value);
      } else {
        filters[name] = _.without(filters[name], value);
      }
    } else {
      filters[name] = value;
    }
    this.setState({
      filters,
    });
  }

  generateReport = e => {
    e.preventDefault();
    this.setState({
      generated: '',
    });
    const { filters } = this.state;
    const { t: translate } = this.context;

    filters.start_date = moment(filters.start).format('YYYY-MM-DD');
    filters.end_date = moment(filters.end).format('YYYY-MM-DD');
    filters.for = _.map(filters.users, user => user.id || 'none');
    filters.tags = _.union(filters.incomeTags, filters.expenseTags);

    const err = [];
    if(moment(filters.start_date).isAfter(filters.end_date)) {
      err.push(translate('Start date has to be earlier than end date'));
    }
    if(filters.for.length === 0) {
      err.push(translate('Please select at least one person'));
    }
    if(filters.tags.length === 0) {
      err.push(translate('Please select at least one income or expense category'));
    }

    if(err.length === 0) {
      this.props.fetchReportData(filters).then(() => {
        this.props.history.push('/reports-print?reports');
      });
    } else {
      this.setState({
        errors: err,
      });
    }
  }

  render() {
    const { filters, generated, errors } = this.state;

    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          stepsPlacement={{ 0: 'top-start' }}
        />

        <Reports
          {...this.props}
          errors={errors}
          filters={filters}
          generated={generated}
          handleFormChange={this.handleFormChange}
          generateReport={this.generateReport}
        />
      </div>
    );
  }
}


ReportsContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    homeUsers: state.homeUsers,
    tagKeys: state.tagKeys,
    error: state.errors.reports,
  };
};

const mapDispatchToProps = {
  fetchHomesIfNeeded,
  fetchTagKeysIfNeeded,
  fetchReportData,
  cleanError,
  showWalkthrough,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsContainer);
