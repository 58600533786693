import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Button } from '../../../components';

const DeleteSuccess = (props, { t: translate }) => {
  const { push } = useHistory();

  return (
    <div className="DeleteSuccess">
      <div className="container">
        <div className="LogoContainer"><img className="Logo home-logo" src="/assets/homes_print2x.png" alt="" /></div>

        <h1 className="title">{translate(`${'Bye bye'}...`)}</h1>

        <h2 className="info-text">
          {translate('Your account has been deleted.')}
        </h2>
        <h2 className="info-text">
          {translate('Confirmation email has been sent to your email account.')}
        </h2>

        <Button
          align="center"
          buttonClassName="AddNewButton m-t-50 m-b-30"
          label={translate('HOMEPAGE')}
          onClick={() => push('/')}
        />
      </div>
    </div>
  );
};


DeleteSuccess.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DeleteSuccess;
