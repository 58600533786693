import React from 'react';
import PropTypes from 'prop-types';

import { SummaryBox } from './../../../components';


export const FinancesSummary = ({ summaryData, activeMonth, activeYear }, { t: translate }) => {
  const monthlyExpenses = summaryData.monthly_expenses * -1 || 0;
  const yearlyExpenses = summaryData.yearly_expenses * -1 || 0;
  return (
    <div className="summaries" data-e2e="accounting-summaries-box">
      <div className="summary">
        <label className="f14">{activeYear}</label>
        <SummaryBox
          plusMinusIndicator
          name={translate('Expenses')}
          value={yearlyExpenses}
          currency="NOK"
          decimalPlaces={0}
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Income')}
          value={summaryData.yearly_income || 0}
          currency="NOK"
          decimalPlaces={0}
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Balance')}
          value={summaryData.yearly_balance || 0}
          possibleClasses={['success', 'error']}
          currency="NOK"
          decimalPlaces={0}
        />
      </div>
      <div className="summary">
        <label className="f14">{activeMonth}</label>
        <SummaryBox
          plusMinusIndicator
          name={translate('Expenses')}
          value={monthlyExpenses}
          currency="NOK"
          decimalPlaces={0}
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Income')}
          value={summaryData.monthly_income || 0}
          currency="NOK"
          decimalPlaces={0}
        />
        <SummaryBox
          plusMinusIndicator
          name={translate('Balance')}
          value={summaryData.monthly_balance || 0}
          possibleClasses={['success', 'error']}
          currency="NOK"
          decimalPlaces={0}
        />
      </div>
    </div>
  );
};


FinancesSummary.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancesSummary;
