import { api, BASE_URL } from './../../utils/net';
import {
  SET_TAG_KEYS_SUCCESS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function setTagKeysSuccess(data) {
  return {
    type: SET_TAG_KEYS_SUCCESS,
    payload: data,
  };
}


export const fetchTagKeys = (reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}tag_keys/`);
    dispatch(setTagKeysSuccess(data));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchTagKeys(true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('finances', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const fetchTagKeysIfNeeded = (tagToCheck, tagTypeToCheck) => (dispatch, getState) => {
  const fixedType = tagTypeToCheck && tagTypeToCheck.replace(/scheduled_/g, '');
  const { tagKeys } = getState();
  if(!tagKeys || tagKeys.income.length === 0 || tagKeys.expense.length === 0) {
    return dispatch(fetchTagKeys());
  }
  if(tagToCheck && tagTypeToCheck && tagKeys[fixedType] && tagKeys[fixedType].indexOf(tagToCheck) === -1) {
    return dispatch(fetchTagKeys());
  }
  return new Promise(resolve => resolve());
};
