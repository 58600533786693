import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { collapseNavigation, expandNavigation } from '../../store/actions/layout';

import AsideMenuLink from './AsideMenuLink';
import BudgetIcon from './assets/budget-i.svg';
import BudgetIconActive from './assets/budget-a.svg';
import AccountingIcon from './assets/accounting-i.svg';
import AccountingActive from './assets/accounting-a.svg';
import CalendarIcon from './assets/calendar-i.svg';
import CalendarIconActive from './assets/calendar-a.svg';
import SplitRequestsIcon from './assets/split-i.svg';
import SplitRequestsIconActive from './assets/split-a.svg';
import ReportsIcon from './assets/reports-i.svg';
import ReportsIconActive from './assets/reports-a.svg';
import DocumentsIcon from './assets/documents-i.svg';
import DocumentsIconActive from './assets/documents-a.svg';
import HomeIcon from './assets/my-home-i.svg';
import HomeIconActive from './assets/my-home-a.svg';

class AsideMenu extends React.Component {
  constructor(props) {
    super(props);
  }
  /*
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: require('./assets/dashboard-i.svg'),
        iconActive: require('./assets/dashboard-a.svg'),
        enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
        visible: true
    },
    */

  getMenuItems = () => {
    const { user, homesUsersFamilies } = this.props;
    const { t: translate } = this.context;

    const shouldShowSplitRequests = homesUsersFamilies && homesUsersFamilies.exFamily &&
      homesUsersFamilies.exFamily.caretakers > 0 && homesUsersFamilies.exFamily.kids > 0;

    const menuItems = [{
      path: '/calendar',
      label: translate('Calendar'),
      icon: CalendarIcon,
      iconActive: CalendarIconActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: true,
    }, {
      path: '/budget',
      label: translate('Budget'),
      icon: BudgetIcon,
      iconActive: BudgetIconActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: true,
    }, {
      path: '/accounting',
      label: translate('Accounting'),
      icon: AccountingIcon,
      iconActive: AccountingActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: true,
    }, {
      path: '/split-requests',
      label: translate('Split Requests'),
      icon: SplitRequestsIcon,
      iconActive: SplitRequestsIconActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: shouldShowSplitRequests,
    }, {
      path: '/reports',
      label: translate('Reports'),
      icon: ReportsIcon,
      iconActive: ReportsIconActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: true,
    }, {
      path: '/documents',
      label: translate('Documents'),
      icon: DocumentsIcon,
      iconActive: DocumentsIconActive,
      enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
      visible: true,
    }, {
      path: '/home',
      label: translate('My Home'),
      icon: HomeIcon,
      iconActive: HomeIconActive,
      enabled: !user.first_steps,
      visible: true,
    }];
    return menuItems;
  }

  getKidsMenuItems = () => {
    const { user, kids, homesUsersFamilies } = this.props;

    const menuItems = [];
    kids && Object.values(kids).map(kid => {
      if(kid.visibility) {
        menuItems.push({
          path: `/kid/${kid.id}`,
          label: kid.first_name,
          color: kid.color || '#E59907',
          icon: require('./assets/icon.png'),
          iconActive: require('./assets/icon.png'),
          enabled: !user.first_steps && homesUsersFamilies.haveIFamilies,
          visible: true,
        });
      }
    });
    return menuItems;
  }

  handleCollapseNavigation = () => {
    if(this.props.layout.collapsed) {
      this.props.expandNavigation();
    } else {
      this.props.collapseNavigation();
    }
  }

  render() {
    const { currentPath, layout } = this.props;
    const menuItems = this.getMenuItems();
    const kidsMenuItems = this.getKidsMenuItems();

    return (
      <aside className={layout.collapsed ? 'collapsed' : ''}>
        <ul>
          {menuItems.map((item, idx) => (
            (item.visible && (
              <AsideMenuLink
                key={`${item.path}-key`}
                enabled={item.enabled}
                active={item.path.indexOf(currentPath) === 0 || (item.path === '/home' && currentPath.indexOf('/caretaker/') > -1)}
                className={`main-menu ${idx === (menuItems.length - 1) ? 'last' : ''}`}
                {...item}
              />
            ))
          ))}
          {kidsMenuItems.map((kidItem, idx) => (
            <AsideMenuLink
              enabled
              key={`${kidItem.path}-key`}
              active={kidItem.path.indexOf(currentPath) === 0}
              className={`kid-menu ${idx === 0 ? 'first' : ''}`}
              {...kidItem}
            />
          ))}
        </ul>
        <div className="collapse" onClick={this.handleCollapseNavigation} />
      </aside>
    );
  }
}

AsideMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.user,
  layout: state.layout,
  kids: state.kids,
  homesUsersFamilies: state.homesUsersFamilies,
});

const mapDispatchToProps = {
  collapseNavigation,
  expandNavigation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsideMenu);
