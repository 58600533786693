import * as _ from 'lodash';

import {
  SET_HOMES_SUCCESS,
  SET_CURRENT_KID,
  SET_KID_PROFILE_SUCCESS,
  UPDATE_KID_PROFILE_SUCCESS,
  UPDATE_CUSTOM_PROFILE_SUCCESS,
  ADD_KID_CARD_SUCCESS,
  UPDATE_KID_CARD_SUCCESS,
  DELETE_KID_CARD_SUCCESS,
  ADD_KID_LEAF_SUCCESS,
  UPDATE_KID_LEAF_SUCCESS,
  DELETE_KID_LEAF_SUCCESS,
  SET_KID_DOCUMENTS_SUCCESS,
  ADD_KID_DOCUMENTS_SUCCESS,
  UPDATE_KID_DOCUMENTS_SUCCESS,
  DELETE_KID_DOCUMENTS_SUCCESS,
} from './../types';


const sortByName = kids => {
  return kids && kids.sort((a, b) => {
    return a.first_name > b.first_name ? 1 : -1;
  });
};

const isKidVisibleAndActive = (action, kidId) => {
  const { payload: { users } } = action;
  return users[kidId] && users[kidId].status !== 'pending' && users[kidId].visibility;
};

const getCaretakersList = (caretakersIds, users) => {
  const list = [];
  caretakersIds.forEach(cId => {
    if(users[cId]) {
      list.push({
        id: users[cId].id,
        firstName: users[cId].first_name,
        lastName: users[cId].last_name,
        nickname: users[cId].nickname,
        color: users[cId].color || '#E59907',
      });
    }
  });
  return list;
};

const getKidParents = (action, kidId) => {
  const { payload: { my_home, my_ex_homes, partner_ex_homes, users } } = action;

  let parents = [];

  if(my_home && my_home.kid_ids.indexOf(kidId) > -1) {
    parents = getCaretakersList(my_home.caretaker_ids, users);
  } else {
    const exHome = my_ex_homes.find(ex => ex.kid_ids.indexOf(kidId) > -1);
    const partnerExHome = partner_ex_homes.find(pex => pex.kid_ids.indexOf(kidId) > -1);

    if(exHome) {
      parents = getCaretakersList(exHome.caretaker_ids.slice(0, 2), users);
    }

    if(partnerExHome) {
      parents = getCaretakersList(partnerExHome.caretaker_ids, users);
    }
  }

  return parents;
};

const getKidsFromHome = (action, home) => {
  const { payload: { users } } = action;
  const list = [];

  home.kid_ids.forEach(kidId => {
    if(isKidVisibleAndActive(action, kidId)) {
      list.push({
        ...users[kidId],
        homeId: home.id,
        parents: getKidParents(action, kidId),
      });
    }
  });

  return list;
};


export function kidsReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      let kidsEx = [];
      if(action.payload.my_ex_homes.length > 0) {
        action.payload.my_ex_homes.forEach(home => {
          kidsEx = [
            ...kidsEx,
            ...getKidsFromHome(action, home),
          ];
        });
      }

      const kidsCurrent = [];
      if(!_.isNil(action.payload.my_home)) {
        action.payload.my_home.kid_ids.forEach(kidId => {
          if(isKidVisibleAndActive(action, kidId)) {
            kidsCurrent.push({
              ...action.payload.users[kidId],
              homeId: action.payload.my_home.id,
              parents: getKidParents(action, kidId),
            });
          }
        });
      }

      let kidsPartnerEx = [];
      if(action.payload.partner_ex_homes && action.payload.partner_ex_homes.length > 0) {
        action.payload.partner_ex_homes.forEach(home => {
          kidsPartnerEx = [
            ...kidsPartnerEx,
            ...getKidsFromHome(action, home),
          ];
        });
      }

      const kidsList = [
        ...sortByName(kidsEx),
        ...sortByName(kidsCurrent),
        ...sortByName(kidsPartnerEx),
      ];

      const kidsObj = {};
      kidsList.forEach(kid => {
        kidsObj[kid.id] = { ...kid };
      });
      return Object.assign({}, kidsObj);

    case UPDATE_KID_PROFILE_SUCCESS:
      const updatedKid = Object.assign({}, state[action.payload.id], {
        ...action.payload,
        avatar: action.payload.file,
      });
      return Object.assign({}, state, {
        [action.payload.id]: updatedKid,
      });

    case UPDATE_CUSTOM_PROFILE_SUCCESS:
      const updatedKidCustom = Object.assign({}, state[action.payload.id], {
        ...action.payload,
        avatar: action.payload.file,
      });

      return Object.assign({}, state, {
        [action.payload.id]: updatedKidCustom,
      });

    default:
      return state;
  }
}


export function kidCurrentReducer(state = {}, action) {
  switch(action.type) {
    case SET_HOMES_SUCCESS:
      const firstKidId = !_.isNil(action.payload.my_home) ? action.payload.my_home.kid_ids[0] : null;
      if(!_.isNil(firstKidId)) {
        return Object.assign({}, action.payload.users[firstKidId], {
          id: firstKidId,
        });
      }
      return state;

    case SET_CURRENT_KID:
      return Object.assign({}, action.payload);

    case SET_KID_PROFILE_SUCCESS:
      const categories = [];
      action.payload.map(category => {
        const information = [];
        category.information.map(info => {
          information.push(Object.assign({}, info, {
            title: info.key,
            description: info.value,
          }));
        });
        category.information = information;
        categories.push(category);
      });
      return Object.assign({}, state, {
        profile: {
          categories,
        },
      });

    case UPDATE_KID_PROFILE_SUCCESS:
      return Object.assign({}, state, action.payload);

    case UPDATE_CUSTOM_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        color: action.payload.color,
        visibility: action.payload.visibility,
      });

    case ADD_KID_CARD_SUCCESS:
      return Object.assign({}, state, {
        profile: {
          categories: [
            ...state.profile.categories,
            action.payload,
          ],
        },
      });

    case UPDATE_KID_CARD_SUCCESS:
      state.profile.categories.forEach(category => {
        if(category.id === action.payload.id) {
          category.name = category.title = action.payload.name;
        }
      });
      return state;

    case DELETE_KID_CARD_SUCCESS:
      return Object.assign({}, state, {
        profile: {
          categories: state.profile.categories.filter(category => category.id !== action.payload),
        },
      });

    case ADD_KID_LEAF_SUCCESS:
      state.profile.categories.map(category => {
        if(action.cardId === category.id) {
          category.information.push(action.leaf);
        }
      });
      return state;

    case UPDATE_KID_LEAF_SUCCESS:
      state.profile.categories.map(category => {
        if(category.id === action.cardId) {
          category.information.forEach(info => {
            if(info.id === action.leaf.id) {
              info.key = info.title = action.leaf.key;
              info.value = info.description = action.leaf.value;
            }
          });
        }
        return category;
      });
      return state;

    case DELETE_KID_LEAF_SUCCESS:
      state.profile.categories.map(category => {
        if(action.cardId === category.id) {
          category.information = category.information.filter(info => info.id !== action.leafId);
        }
      });
      return state;

    case SET_KID_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        documents: action.payload,
      });

    case ADD_KID_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        documents: [
          ...state.documents,
          action.payload,
        ],
      });

    case UPDATE_KID_DOCUMENTS_SUCCESS:
      state.documents.map(document => {
        if(action.payload.id === document.id) {
          document.filename = action.payload.filename;
          document.note = action.payload.note;
        }
      });
      return state;

    case DELETE_KID_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        documents: state.documents.filter(document => document.id !== action.payload),
      });

    default:
      return state;
  }
}
