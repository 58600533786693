import { push } from 'connected-react-router';
import { getTranslateFunction } from 'redux-i18n';
import { MODAL_NAMES } from 'src/utils/modals';
import { api, BASE_URL } from 'src/utils/net';
import { PERMISSION } from 'src/utils/permissions';
import { FALLBACK_LANGUAGE, translations } from 'src/translations';

import { cleanError, setError } from './errors';
import { showModal } from './layout';
import { hideLoader, showLoader } from './loading';
import { getUserProfile, profileUpdateSuccess } from './user';
import { hideModal } from './layout';

export const createSubscription = (paymentId, bonusCode, hasInitialPermission) => async(dispatch, getState) => {
  const { i18nState, user } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);
  const redirectRoute = hasInitialPermission ? '/first-steps/4' : '/user-profile';
  dispatch(showLoader());

  try {
    const { data } = await api.post(`${BASE_URL}subscriptions`, {
      bonus_code_name: bonusCode,
      payment_method_id: paymentId,
      provider: 'stripe',
    });

    const updatedUserData = { ...user, permission: PERMISSION.PAID, subscription: data };

    dispatch(profileUpdateSuccess(updatedUserData));
    await api.setUser(updatedUserData);
    dispatch(push(redirectRoute));
    if(!hasInitialPermission) dispatch(showModal({ name: MODAL_NAMES.SUBSCRIPTION_CREATE_SUCCESS }));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Check the card details and try again.'));
    dispatch(setError('subscription', message));
  } finally {
    dispatch(hideLoader());
  }
};

export const createSubscriptionCustomer = () => async(dispatch, getState) => {
  const { i18nState, user } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);
  dispatch(showLoader());

  try {
    const { data } = await api.post(`${BASE_URL}client_secrets`);
    const updatedUserData = { ...user, subscription: { ...user.subscription, customerSecret: data.client_secret } };
    dispatch(profileUpdateSuccess(updatedUserData));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Check the card details and try again.'));
    dispatch(setError('subscription', message));
  } finally {
    dispatch(hideLoader());
  }
};

export const cancelSubscription = subscriptionId => async(dispatch, getState) => {
  const { i18nState } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}subscriptions/${subscriptionId}`);
    dispatch(getUserProfile());

    dispatch(hideModal());
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Try again later.'));
    dispatch(setError('subscription', message));
  } finally {
    dispatch(hideLoader());
  }
};

export const updateCreditCardData = paymentId => async(dispatch, getState) => {
  const { i18nState, user } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);
  const subscriptionId = user.subscription?.id;
  dispatch(showLoader());

  try {
    const { data } = await api.patch(`${BASE_URL}subscriptions/${subscriptionId}`, { payment_method_id: paymentId });
    const updatedUserData = { ...user, subscription: data };

    dispatch(profileUpdateSuccess(updatedUserData));
    api.setUser(updatedUserData);
    dispatch(push('/user-profile'));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Check the card details and try again.'));
    dispatch(setError('subscription', message));
  } finally {
    setTimeout(() => {
      dispatch(hideLoader());
    }, 3000);
  }
};

export const verifyBonusCode = name => async(dispatch, getState) => {
  const { i18nState, user } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);

  try {
    dispatch(cleanError('bonusCode'));

    const { data } = await api.post(`${BASE_URL}bonus_codes/verification`, { name });
    const updatedUserData = { ...user, bonusCode: data };

    dispatch(profileUpdateSuccess(updatedUserData));
    await api.setUser(updatedUserData);
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Try again later.'));
    dispatch(setError('bonusCode', message));
  }
};

export const renewSubscription = () => async(dispatch, getState) => {
  const { i18nState, user } = getState();
  const translate = getTranslateFunction(translations, i18nState.lang, FALLBACK_LANGUAGE);

  try {
    dispatch(showLoader());

    const { data } = await api.post(`${BASE_URL}subscriptions`, { provider: 'stripe' });
    const updatedUserData = { ...user, subscription: data };

    dispatch(profileUpdateSuccess(updatedUserData));
    api.setUser(updatedUserData);
  } catch(error) {
    const message = api.getRequestErrorMessage(error, translate('Something went wrong. Try again later.'));
    dispatch(setError('subscription', message));
  } finally {
    dispatch(hideLoader());
  }
};
