import { push } from 'connected-react-router';
import { MODAL_NAMES } from 'src/utils/modals';
import { api, BASE_URL } from 'src/utils/net';

import {
  SET_HOMES_SUCCESS,
  ADD_KID_SUCCESS,
  INVITE_PARTNER_SUCCESS,
  SET_CURRENT_KID,
  SET_PARTNER_DATA_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_CUSTOM_PROFILE_SUCCESS,
} from './../types';
import { setError } from './errors';
import { showModal } from './layout';
import { showLoader, hideLoader } from './loading';
import { signinSuccess } from './auth';


export function setHomesSuccess(homes, user) {
  return {
    type: SET_HOMES_SUCCESS,
    payload: homes,
    currentUser: user,
  };
}

export function addKidSuccess(payload) {
  return {
    type: ADD_KID_SUCCESS,
    payload,
  };
}

export function setCurrentKid(kid) {
  return {
    type: SET_CURRENT_KID,
    payload: kid,
  };
}

export function invitePartnerSuccess(partner) {
  return {
    type: INVITE_PARTNER_SUCCESS,
    payload: partner,
  };
}

export function setPartnerDataSuccess(partner) {
  return {
    type: SET_PARTNER_DATA_SUCCESS,
    payload: partner,
  };
}

export function deleteUserSuccess() {
  return {
    type: DELETE_USER_SUCCESS,
  };
}

export function updateCustomProfileSuccess(profile) {
  return {
    type: UPDATE_CUSTOM_PROFILE_SUCCESS,
    payload: profile,
  };
}


export const fetchHomes = (reCall = false) => async(dispatch, getState) => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}homes/family_tree/`);
    const currentUserId = getState().user.id;
    const user = { ...getState().user, ...data.users[currentUserId] };

    user.current = false;
    user.visibility = true;

    if(data.users && Object.keys(data.users).length > 0) {
      Object.keys(data.users).map(homeUser => {
        data.users[homeUser].avatar = data.users[homeUser].file;
        if(user.id === homeUser) {
          user.avatar = data.users[homeUser].file;
        }
      });
    }
    dispatch(signinSuccess(user));
    dispatch(setHomesSuccess(data, user));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchHomes(true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('homes', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const fetchHomesIfNeeded = () => (dispatch, getState) => {
  const { homeUsers } = getState();
  if(!homeUsers || Object.keys(homeUsers).length === 0) {
    return dispatch(fetchHomes());
  }
};


export const addKid = (data, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const kid = {
      ...data,
      nickname: data.nickname || data.first_name.toLowerCase().replace(/ /g, '').substr(0, 10),
    };

    await api.post(`${BASE_URL}kid_invitations`, kid);

    dispatch(fetchHomes());
    dispatch(hideLoader());
    dispatch(push('/home'));
    if(data.email) dispatch(showModal({ name: MODAL_NAMES.INVITATION_SUCCESS }));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(addKid(data, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('homes', message));
    }
  }
};


export const invitePartner = (data, reCall = false) => async dispatch => {
  const defaultMessage = 'Cannot invite partner to this family';
  dispatch(showLoader());

  try {
    const response = await api.post(`${BASE_URL}caretaker_invitations`, data);
    if (typeof response.data === 'object') {
      const person = response.data;
      person.avatar = person.file;
      dispatch(invitePartnerSuccess(person));
      dispatch(hideLoader());
      dispatch(push('/home'));
      dispatch(showModal({ name: MODAL_NAMES.INVITATION_SUCCESS }));
    } else {
      dispatch(setError('family', defaultMessage));
    }
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(invitePartner(data, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error, defaultMessage);
      dispatch(setError('homes', message));
    }
  }
};


export const deleteInvitation = id => async dispatch => {
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}invitations/${id}`);
    dispatch(setHomesSuccess());
    dispatch(hideLoader());
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('family', message));
  }
};


export const setPartnerData = (id, data) => async dispatch => {
  const defaultMessage = "Error during setting Partner's data";
  dispatch(showLoader());

  try {
    const response = api.patch(`${BASE_URL}caretakers/${id}`, data);
    const partner = response.data;
    dispatch(setPartnerDataSuccess(partner));
  } catch(error) {
    const message = api.getRequestErrorMessage(error, defaultMessage);
    dispatch(setError('homes', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const deleteUser = id => async dispatch => {
  const defaultMessage = 'Error during deleting user';
  dispatch(showLoader());

  try {
    await api.delete(`${BASE_URL}delete/${id}`);
    dispatch(deleteUserSuccess());
  } catch(error) {
    const message = api.getRequestErrorMessage(error, defaultMessage);
    dispatch(setError('homes', message));
  } finally {
    dispatch(hideLoader());
  }
};


export const updateCustomProfile = (payload, id) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.patch(`${BASE_URL}custom_profiles/${id}`, payload);
    dispatch(updateCustomProfileSuccess(data));
    dispatch(hideLoader());
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('family', message));
  }
};
