import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import moment from 'moment';

import { Button, Logo } from '../../../components';

const NotFound = (props, { t: translate }) => {
  const date = moment().format('YYYY');
  const { push } = useHistory();

  return (
    <div className="NotFound">
      <div className="container">
        <h1 className="title">{translate(`${'Sorry'}...`)}</h1>

        <Logo type="ups" />

        <p className="info-text">
          {translate('We could not find a page you were looking for')}
        </p>

        <Button
          align="center"
          buttonClassName="AddNewButton m-b-30"
          label={translate('Return back home')}
          onClick={() => push('/home')}
        />
      </div>

      <footer>
        <span className="footer-copy">
          {`© ${date} 2homes`}
        </span>
        <span className="footer-copy">|</span>
        <a className="footer-link" href="mailto:support@2homes.com">support@2homes.com</a>
      </footer>
    </div>
  );
};


NotFound.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NotFound;
