import React from 'react';
import PropTypes from 'prop-types';

import { setNumberFormat } from './../../../../utils/tools';

const ReportTransactions = ({ transactions, homeUsers }, { t: translate }) => {
  const transactionsType = (transactions && transactions[0] && transactions[0].type) || 'accounting';
  const maxNoteLength = transactionsType === 'accounting' ? 72 : 102;

  return (
    <div className="report-container report-transactions">
      <div className="table">
        <div className="thead">
          <div className="trow">
            <div className="tcell center" style={{ width: 80 }}>{translate('Date')}</div>
            <div className="tcell right" style={{ width: 90 }}>{translate('Expense')}</div>
            <div className="tcell right" style={{ width: 90, paddingRight: 10 }}>{translate('Income')}</div>
            <div className="tcell" style={{ width: 120 }}>{translate('Details')}</div>
            {transactionsType === 'accounting' && (<div className="tcell" style={{ width: 70 }}>{translate('Split')}</div>)}
            <div className="tcell" style={{ minWidth: 200 }}>{translate('Note')}</div>
          </div>
        </div>

        <div className="tbody">
          {transactions && transactions.map((transaction, idx) => {
            const recipient = transaction.details.user_id && homeUsers[transaction.details.user_id];
            const split = (transaction.recipient_id && homeUsers[transaction.recipient_id]) || {};
            let splitRecipient = '';
            let colorStatus = '';
            if(split && split.nickname) {
              splitRecipient = `@${split.nickname}`;
              colorStatus = (transaction.status === 'pending' || transaction.status === 'default') ? 'yellow' : (
                transaction.status === 'rejected' ? 'error' : 'success'
              );
            }
            const note = transaction.details.note && (
              transaction.details.note.length > maxNoteLength ? `${transaction.details.note.substr(0, maxNoteLength)}...` : transaction.details.note
            );

            return (
              <div key={idx} className="trow">
                <div className="tcell right f12" style={{ width: 80 }}>{transaction.date}</div>
                <div className="tcell right error m-r-15 f12 w600" style={{ width: 90 }}>{setNumberFormat(transaction.expense, 2)}</div>
                <div className="tcell right success m-r-15 f12 w600" style={{ width: 90, paddingRight: 10 }}>{setNumberFormat(transaction.income, 2)}</div>
                <div className="tcell f12" style={{ width: 120 }}>{transaction.details.title}<br />{recipient && (`@${recipient.nickname}` || recipient.first_name)}</div>
                {transactionsType === 'accounting' && (
                  <div className="tcell f12" style={{ width: 70 }}>
                    <div>{splitRecipient}</div>
                    {colorStatus && (
                      <div>
                        <span className="f12 m-r-5"><i className={`zmdi zmdi-circle f9 ${colorStatus}`} /></span>
                        <span className="inline-block darkgrey">{setNumberFormat(transaction.split_amount, 2)}</span>
                      </div>
                    )}
                  </div>
                )}
                <div className="tcell wrap f12" style={{ minWidth: 200 }}>{note}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};


ReportTransactions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportTransactions;
