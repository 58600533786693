import React from 'react';

import SignedInLayout from '../../../layouts/SignedInLayout';

import UserProfileEdit from './UserProfileEdit';
import ConfirmationModal from './ConfirmationModal';


export const UserProfile = ({ user, layout, loading, profile, ...props }) => {
  return (
    <SignedInLayout
      page="KidProfile"
      currentPath="/user_profile"
      user={user}
      layout={layout}
      loading={loading}
    >
      <div className="kid-profile-info">
        <UserProfileEdit profile={profile} {...props} />
      </div>

      <ConfirmationModal
        closeModal={props.toggleConfirmationModal}
        deleteError={props.deleteError}
        handleDeleteUserAccount={props.handleDeleteUserAccount}
        modalOpened={profile.confirmationModalOpened}
        setStep={props.setStep}
        step={props.step}
      />
    </SignedInLayout>
  );
};


export default UserProfile;
