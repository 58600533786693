import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Avatar, AvatarPlaceholder } from 'src/components';
import { prepareFileUrl } from 'src/utils/file';
import { PERMISSION } from 'src/utils/permissions';


const FamilyMember = (
  {
    first_name,
    last_name,
    nickname,
    file_url,
    id,
    homeUsers,
    kids,
    status,
    deleteInvitation,
    current,
    familyStatus,
    onClickEdit,
    onClickHide,
    permission,
    thumbnail_url,
    visibility,
    visibilityFilter,
  },
  { t: translate },
) => {
  const isPending = status === 'pending';
  const isHidden = !visibility;
  const style = {
    color: (homeUsers && homeUsers[id] && homeUsers[id].color) || '#e59907',
    outlineWidth: familyStatus === 'current' && !current && '1px',
  };
  const display = !isPending && 'none';

  let parents = '';
  kids && kids[id] && kids[id].parents && kids[id].parents.forEach((parent, idx) => {
    parents += `${idx !== 0 ? ' & ' : ''}${parent.firstName}`;
  });

  const avatar = { thumbnailUrl: prepareFileUrl(thumbnail_url), fileUrl: prepareFileUrl(file_url) };
  const isUser = familyStatus === 'current' && !current;
  const isBlocked = permission === PERMISSION.BLOCKED && !isUser;

  return (
    <div
      className={classnames('FamilyMember', {
        is_pending: status === 'pending',
        is_user: isUser,
        is_hidden: isHidden && visibilityFilter === 'all',
        is_blocked: isBlocked,
      })}
      style={style}
      title={isBlocked ? translate('Blocked user') : null}
    >
      <div className="avatar_container flex">
        <div className="avatar">
          {(avatar && avatar.thumbnailUrl) ? (
            <Avatar avatar={avatar} />
          ) : (
            <AvatarPlaceholder user={homeUsers && homeUsers[id]} />
          )}
        </div>
        <div>
          <h3 className="member-name black f16 w400" title={`${first_name} ${last_name}`}>
            {first_name} {last_name}
          </h3>
          {nickname && !isPending && (
            <h4 className="nickname w400" style={style}>{`@${nickname.toLowerCase()}` || '@nickname'}</h4>
          )}
        </div>
      </div>
      <div className="status_container flex">
        <div className="label f13">
          {!isPending
            ? familyStatus === 'current' && !current
              ? translate('me')
              : familyStatus === 'kid' ? parents : translate(familyStatus + ' partner')
            : `${translate('Pending').toLowerCase()}...`}
        </div>
        <div className="remove flex">
          {!isPending && !isHidden && (
            <i className="zmdi zmdi-edit" title={translate('Edit')} onClick={onClickEdit} />
          )}

          {!isPending && !(familyStatus === 'current' && !current) && (
            <i
              className={`zmdi zmdi-${visibility ? 'eye-off' : 'eye'}`}
              title={visibility ? translate('Hide') : translate('Show')}
              onClick={() => onClickHide(id, !isHidden)}
            />
          )}

          {isPending && !(familyStatus === 'current' && !current) && (
            <i
              style={{ display: display }}
              className="zmdi zmdi-delete p-l-10"
              title={translate('Delete')}
              onClick={isPending && (() => deleteInvitation(id))}
            />
          )}
        </div>
      </div>
    </div>
  );
};


FamilyMember.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FamilyMember;
