import { OPERATION_SUCCESS, CLEAN_OPERATION } from './../types';


export const setOperationSuccess = type => {
  return {
    type: OPERATION_SUCCESS,
    payload: type,
  };
};

export const cleanOperation = type => {
  return {
    type: CLEAN_OPERATION,
    payload: type,
  };
};
