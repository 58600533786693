import React from 'react';
import { connect } from 'react-redux';
import { Walkthrough } from 'src/components';
import { fetchHomes, updateCustomProfile, deleteInvitation } from 'src/store/actions/homes';
import { showModal } from 'src/store/actions/layout';
import { showWalkthrough } from 'src/store/actions/walkthrough';
import { MODAL_NAMES } from 'src/utils/modals';
import { PERMISSION } from 'src/utils/permissions';
import { showInitialWalkthrough } from 'src/utils/walkthrough';

import Family from '../components/Family';


class FamilyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: true,
      toHide: {},
      toDelete: '',
      confirmDeleteModalOpened: false,
      confirmHideModalOpened: false,
      visibilityFilter: 'visible',
    };
  }

  async componentDidMount() {
    await this.props.fetchHomes();

    showInitialWalkthrough('home', this.props.showWalkthrough);

    this.checkAccountStatus();
  }

  checkAccountStatus = () => {
    const hasBlockedAccount = this.props.user?.permission === PERMISSION.BLOCKED;
    if(hasBlockedAccount) this.props.showModal({ name: MODAL_NAMES.BLOCKED_ACCOUNT_INFO });
  }

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({
      editMode: !editMode,
    });
  }

  setVisibilityFilter = filter => {
    this.setState({
      visibilityFilter: filter,
    });
  }

  showModal = type => {
    this.setState({
      [`confirm${type}ModalOpened`]: true,
    });
  }

  closeModal = () => {
    this.setState({
      confirmDeleteModalOpened: false,
      confirmHideModalOpened: false,
      toDelete: '',
      toHide: {},
    });
  }

  setUserVisibility = (userId, hide) => {
    this.setState({
      toHide: {
        userId,
        hide,
      },
    }, () => {
      this.showModal('Hide');
    });
  }

  setUserVisibilityConfirmed = e => {
    e.preventDefault();
    const { toHide } = this.state;

    this.props.updateCustomProfile({
      visibility: (!toHide.hide).toString(),
    }, toHide.userId).then(() => {
      this.closeModal();
    });
  }

  deleteInvitation = userId => {
    this.setState({
      toDelete: userId,
    }, () => {
      this.showModal('Delete');
    });
  }

  deleteInvitationConfirmed = e => {
    e.preventDefault();
    const { toDelete } = this.state;
    this.props.deleteInvitation(toDelete).then(() => {
      this.props.fetchHomes();
      this.closeModal();
    });
  }

  render() {
    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          stepsPlacement={{ 0: 'top-start' }}
        />

        <Family
          {...this.props}
          {...this.state}
          toggleEditMode={this.toggleEditMode}
          deleteInvitation={this.deleteInvitation}
          deleteInvitationConfirmed={this.deleteInvitationConfirmed}
          setUserVisibility={this.setUserVisibility}
          setUserVisibilityConfirmed={this.setUserVisibilityConfirmed}
          closeModal={this.closeModal}
          setVisibilityFilter={this.setVisibilityFilter}
        />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    home: state.home,
    homesEx: state.homesEx,
    homeUsers: state.homeUsers,
    family: state.current_family_id ? state.families.find(elt => elt.id === state.current_family_id) : {},
    partner: state.partner,
    caretakers: state.caretakers,
    kids: state.kids,
    loading: state.loading,
  };
};

const mapDispatchToProps = {
  fetchHomes,
  updateCustomProfile,
  deleteInvitation,
  showWalkthrough,
  showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FamilyContainer);
