import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Card, FileUploader } from '../../../components';
import { isEdge } from '../../../utils/browser';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from '../../../utils/date';
import * as file from '../../../utils/file';


export const KidFiles = ({ kidCurrent, ...props }, { t: translate }) => {
  const tableRows = kidCurrent.documents && kidCurrent.documents.map((row, idx) => {
    const fileType = file.getFileType(row.filetype);

    const date = moment(row.updated_at).format(DEFAULT_DISPLAYED_DATE_FORMAT);

    return (
      <div key={idx} className="trow">
        <div className="tcell">{date}</div>
        <div className="tcell small center"><i className={`zmdi zmdi-${fileType.icon || 'file'}`} /></div>
        <div className="tcell">
          <a href={file.prepareFileUrl(row.file_url)} target="_blank">{row.filename}</a>
        </div>
        <div className="tcell">{row.note}</div>
        <div className="tcell icons">
          <i className="zmdi zmdi-edit" style={{ visibility: !props.isWalkthroughOpen && 'hidden' }} onClick={() => props.editData('document', row)} />
          <i className="zmdi zmdi-delete" style={{ marginLeft: 10, visibility: !props.isWalkthroughOpen && 'hidden' }} onClick={() => props.setDataToDelete('document', row)} />
        </div>
      </div>
    );
  });

  return (
    <div className="kids-files-container">
      <Card title={translate('Files')}>
        <div className="dropzone-container">
          <FileUploader noMargin handleUploadFile={props.saveFile} translate={translate} type="dropzone" />
        </div>

        <div className={`table ${isEdge ? 'edge' : ''}`} style={{ marginTop: 22, width: '100%' }}>
          <div className="thead">
            <div className="trow">
              <div className="tcell">{translate('Date')}</div>
              <div className="tcell">{translate('File')}</div>
              <div className="tcell">{translate('Name')}</div>
              <div className="tcell">{translate('Notes')}</div>
              <div className="tcell" />
            </div>
          </div>
          <div className="tbody">
            {tableRows}
          </div>
        </div>
      </Card>
    </div>
  );
};


KidFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KidFiles;
