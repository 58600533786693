import React from 'react';
import classnames from 'classnames';


const FormRow = ({ children, className }) => (
  <div className={classnames('form__row relative', className)}>
    {children}
  </div>
);


export default FormRow;
