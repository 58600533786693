import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as file from './../../../utils/file';
import { isEdge } from './../../../utils/browser';
import { DEFAULT_DISPLAYED_DATE_FORMAT } from './../../../utils/date';


export const FilesList = ({ files, user, homeUsers, editFile, createShareLink, setFileToDelete, isWalkthroughOpen }, { t: translate }) => {
  const tableRows = files && files.map(row => {
    const fileType = file.getFileType(row.filetype);
    const date = moment(row.updated_at).format(DEFAULT_DISPLAYED_DATE_FORMAT);

    let author;
    if(user.id === row.owner_id) {
      author = `@${user.nickname}` || user.first_name.toLowerCase();
    } else {
      author = homeUsers && Object.keys(homeUsers).length > 0 && homeUsers[row.owner_id] && (homeUsers[row.owner_id].nickname || homeUsers[row.owner_id].first_name.toLowerCase());
    }

    const canIEditOrDelete = user.id === row.owner_id;

    return (
      <div key={row.id} className="trow" data-e2e={`documents-files-row-${row.filename}`}>
        <div className="tcell">{date}</div>
        <div className="tcell small center"><i className={`zmdi zmdi-${fileType.icon || 'file'}`} /></div>
        <div className="tcell">
          <a href={file.prepareFileUrl(row.file_url)} target="_blank">{row.filename}</a>
        </div>
        <div className="tcell">{author}</div>
        <div className="tcell">{row.note}</div>
        <div className="tcell icons">
          {canIEditOrDelete && (
            <i className="zmdi zmdi-link"
              style={{ visibility: 'hidden' }}
              onClick={() => createShareLink(row)}
              data-e2e={`documents-files-row-share-${row.filename}`}
            />
          )}
          {canIEditOrDelete && (
            <i className="zmdi zmdi-edit"
              style={{ marginLeft: 10, visibility: isWalkthroughOpen ? 'visible' : 'hidden' }}
              onClick={() => editFile('file', row)}
              data-e2e={`documents-files-row-edit-${row.filename}`}
            />
          )}
          {canIEditOrDelete && (
            <i className="zmdi zmdi-delete"
              style={{ marginLeft: 10, visibility: isWalkthroughOpen ? 'visible' : 'hidden' }}
              onClick={() => setFileToDelete('file', row)}
              data-e2e={`documents-files-row-delete-${row.filename}`}
            />
          )}
        </div>
      </div>
    );
  });


  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell">{translate('Date')}</div>
          <div className="tcell">{translate('File')}</div>
          <div className="tcell">{translate('Name')}</div>
          <div className="tcell">{translate('Author')}</div>
          <div className="tcell">{translate('Notes')}</div>
          <div className="tcell" />
        </div>
      </div>
      <div className="tbody">
        {tableRows}
      </div>
    </div>
  );
};


FilesList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FilesList;
