import React from 'react';

import { STORAGE_WALKTHROUGH_KEY, STORAGE_USER_KEY } from './net';

export const getWalkthroughSteps = (currentPath, translate) => {
  const steps = {
    home: {
      '.members-container': translate('The sections below show the members of your home. The first section shows your current and ex-partners. The second shows your children from current and previous relationships.'),
      '.AddNewButton': translate('Click here to add family members, including ex-partners, your current partner and kids.'),
      '.visibility-filters': translate('You can switch between the views by using these filters. You can show all home members, only visible or only hidden.'),
    },
    families: {
      '#CurrentRelationship': translate("This shows your current relationship. Click the '+' button to add members to your home."),
      '#ExRelationships': translate("This shows your previous relationships. Click the '+' button to add members to your home."),
    },
    calendar: {
      '.badges': translate("Click here to switch between day and week views. You can also quickly go to the current date by clicking the 'today' button."),
      '.date-container': translate('Click here to change dates.'),
      '.caretakers-filter': translate('You can filter events by selecting and deselecting family members.'),
      '.calendar-content-container': translate('You can create events by clicking on a calendar cell. In DAY view, you can Select the time range in a column to create the event. In WEEK view, you can create childcare or normal events by clicking the appropriate row in the day. Scroll down the calendar by first clicking in the column, between the navigation menu and the calendar.'),
    },
    'accounting': {
      '.ReactFileUploader': translate('We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.'),
      '.date-filter': translate('Click arrow buttons to switch the year. Click on month name to show its transactions.'),
      '.summaries': translate('In this section you can see the summary of your income and expenses. Left column shows aggregated data for the whole year and right column - only data from selected month.'),
      '.DropzonePlaceholder': translate('Click here to add new file to pending transactions.'),
      '.finances-pending': translate('Here you can see list of pending transactions. This is the list of files you uploaded for the selected month. You can edit each of them and add all required information, such as amount of money spent or the receiver of this cost/income. After editing, the record will be visible in a table with transactions, below pending files.'),
      '.button': translate('You can add a new transaction by clicking this button. You will be able to attach a file to the new transaction after creating it.'),
      '.history-filters': translate('You can show only expenses or only income transactions by using these filters.'),
      '.finances-history': translate('Here you can see the history of your transactions. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor. You can attach a file to every existing transaction.'),
    },
    budget: {
      '.date-filter': translate('Click arrow buttons to switch the year. Click on month name to show expected costs or income.'),
      '.summaries': translate('In this section you can see the summary of your expected income and expenses. The left column shows aggregated data for the whole year and right column - only data from selected month.'),
      '.button': translate('You can add new expected income and expenses by clicking this button.'),
      '.history-filters-container': translate('You can show only expected expenses or expected income by using these filters.'),
      '.finances-history': translate('Here you can see all planned costs or income. This list contains records with all information provided (transaction type, amount, recipient, etc.). You can see options for editing and deleting the record when you hover over it with your cursor.'),
    },
    'split-requests': {
      '.finances-history': translate('Here you can see the list of all split requests sent to you. You can see an attached file by clicking on file icon (if there is one). You can also edit a split request by clicking edit icon. Editing means that you can change the status of the selected split request.'),
      '.finances-history.from-me': translate('Here you can see the list of all split requests created by you. You can see attached file by clicking on file icon if there is one. You can also edit split request by clicking edit icon. Editing means that you can change the status of selected split request.'),
      '.finances-history.history': translate('Here you can see the history of your split requests. This list contains only paid or rejected requests.'),
      '.history-filters': translate('You can filter split requests history by type or see them all at once.'),
    },
    reports: {
      '.form': translate('In this section you can create a report. It contains data from the Accounting or Budget modules. It will be generated in pdf format, so it is easy to download and view later.'),
    },
    documents: {
      '.button-container': translate('We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.'),
      '.button': translate("If you want to store files, you have to create at least one folder. Click 'NEW FOLDER' button to create one."),
    },
    kidId: {
      '.DropzonePlaceholder': translate('You can add files to this section by clicking this button. We request that no information such as health information, political views, race, sexual orientation and criminal acts/history should be shared.'),
      '.table': translate('Here you can see the list of all files assigned to the selected child. They will be visible only to you.'),
      '.card.empty.pointer': translate('You can add sections with notes by clicking this button.'),
    },
  };

  return currentPath && steps[currentPath] ? steps[currentPath] : null;
};

export const getAdditionalStepsData = (currentPath, translate) => {
  const path = currentPath.slice(1, currentPath.length);

  const stepsData = {
    accounting: {
      pending:
      {
        content: <h2>{translate('You can edit and delete a file by clicking the icon buttons. They are visible when you move the cursor over the element in the list.')}</h2>,
        target: '.tbody .trow',
        placement: 'bottom-start',
      },
      history:
      {
        content: <h2>{translate('You can edit or delete the existing transaction. You can also create split request from expenses.')}</h2>,
        target: '.finances-history .tbody .trow',
        placement: 'top-start',
      },
    },
    documents: {
      my: {
        content: <h2>{translate('Here you can see the list of folders created by you and visible only to you. You can edit the folder by clicking the edit icon or, if the folder is empty, delete it by clicking the delete icon. A folder that is not empty cannot be deleted.')}</h2>,
        target: '.folders-created',
        placement: 'bottom-start',
      },
      shared: {
        content: <h2>{translate('Here you can see the list of folders shared with you. They are created by your current partner and visible to both of you.')}</h2>,
        target: '#shared-folders',
        placement: 'top-start',
      },
      files: [
        {
          content: <h2>{translate('By clicking this button you can add files to the selected folder.')}</h2>,
          target: '.DropzonePlaceholder',
          placement: 'bottom-start',
        },
        {
          content: <h2>{translate('Here you can see the list of files in a selected folder. You can edit or delete the existing transaction. You can also share the file by clicking on the share-icon button, which is visible after you move the cursor over the element in the list. Sharing a file means that a special link will be generated. You can copy and send the link to anyone you wish. Every person with the link can see and download the file.')}</h2>,
          target: '.files-list',
          placement: 'bottom-start',
        },
      ],
    },
    kidId: {
      files: {
        content: <h2>{translate('You can edit and delete a file by clicking on icon buttons, which are visible after you move the cursor over the element in the list.')}</h2>,
        target: '.tbody .trow',
        placement: 'top',
      },
      notes: [
        {
          content: <h2>{translate('After creating a section you can still edit it or remove it if it is no longer needed.')}</h2>,
          target: '.kids-files-container + .card',
          placement: 'top',
        },
        {
          content: <h2>{translate('You can add notes to the section by clicking this button.')}</h2>,
          target: document.querySelector('.card.no-leafs .button') ? '.card.no-leafs .button' : '.card.leaf.empty .button',
          placement: 'left-start',
        },
      ],
      leaf: {
        content: <h2>{translate('You can edit or remove all notes created previously.')}</h2>,
        target: '.card.leaf .content',
        placement: 'top-start',
      },
    },
  };

  return path && stepsData[path] ? stepsData[path] : {};
};

export const showInitialWalkthrough = (viewName, onShow) => {
  const stringifiedUser = localStorage.getItem(STORAGE_USER_KEY);
  const parsedUser = JSON.parse(stringifiedUser);
  const walkthroughLocalStorageKey = `${STORAGE_WALKTHROUGH_KEY}-${viewName}-${parsedUser.id}`;
  const shouldShowWalkthrough = !localStorage.getItem(walkthroughLocalStorageKey);

  if (shouldShowWalkthrough) {
    localStorage.setItem(walkthroughLocalStorageKey, 'true');

    onShow(viewName);
  }
};
