import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useStore from 'src/hooks/useStore';
import { STRIPE_API_KEY } from 'src/utils/net';
import { FALLBACK_LANGUAGE } from 'src/translations';

import CheckoutForm from './CheckoutForm';


const stripePromise = loadStripe(STRIPE_API_KEY);

export const Payment = () => {
  const [state] = useStore();

  const languageOptions = {
    nn: 'nb-NO',
    da: 'da',
    sv: 'sv',
  };

  return (
    <section className="CompleteYourProfile PaymentSection">
      <Elements
        options={{
          fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap' }],
          locale: languageOptions[state.i18nState.lang] || FALLBACK_LANGUAGE,
        }}
        stripe={stripePromise}
      >
        <CheckoutForm />
      </Elements>
    </section>
  );
};

export default Payment;
