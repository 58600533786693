import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from './InfoModal';


const InvitationSuccess = ({ name }, { t: translate }) => (
  <InfoModal imgType="tada" name={name}>
    <h2 className="Title">{translate('Invitation has been sent!')}</h2>

    <h3 className="InfoText">
      {translate('Please ask the invitee to check for the invitation in their inbox.')}
    </h3>
  </InfoModal>
);


InvitationSuccess.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InvitationSuccess;
