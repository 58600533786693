import React from 'react';


const AvatarPlaceholder = ({ user }) => {
  const firstName = user.first_name || user.firstName;
  const lastName = user.last_name || user.lastName;

  return (
    <div className="empty-image" style={{ color: user.color || '#e59907' }}>
      {firstName && firstName.slice(0, 1).toUpperCase()}{lastName && lastName.slice(0, 1).toUpperCase()}
    </div>
  );
};

export default AvatarPlaceholder;
