import React from 'react';
import PropTypes from 'prop-types';

import FileCropper from './../FileCropper';

import './UploaderModalStyles.scss';

const UploaderModal = ({ closeModal, handleUploadFile, uploadButtonLabel, uploadButtonStyle, url }) => (
  <div className="CropperModal" id="cropper-container">

    <div className="CloseIconContainer" onClick={closeModal}>
      <span className="CloseIcon" />
      <span className="CloseIcon RightIcon" />
    </div>

    <div style={{ padding: 50, paddingBottom: 30 }}>
      <FileCropper
        handleUploadFile={handleUploadFile}
        src={url}
        uploadButtonLabel={uploadButtonLabel}
        uploadButtonStyle={uploadButtonStyle}
      />
    </div>
  </div>
);

UploaderModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  uploadButtonLabel: PropTypes.string,
  uploadButtonStyle: PropTypes.object,
  url: PropTypes.string,
};


export default UploaderModal;
