import React from 'react';
import PropTypes from 'prop-types';

import SignedInLayout from '../../../layouts/SignedInLayout';

import { Badge } from './../../../components';
import NotificationsTable from './NotificationsTable';
import NotificationsModal from './NotificationsModal';


export const Notifications = ({ user, loading, layout, notifications, notificationModalOpened, toggleNotificationsModal, homeUsers, ...props }, { t: translate }) => {
  const showLoadMoreButton = notifications && notifications.messages && notifications.countTotal > 0 && notifications.messages.length < notifications.countTotal;

  return (
    <SignedInLayout
      page="Notifications"
      currentPath="/notifications"
      user={user}
      loading={loading}
      layout={layout}
    >
      <h2 className="f24 w400 p-t-30 center">{translate('Notifications')}</h2>

      <div className="notifications-container">
        <div className="notifications-actions top">
          <Badge
            label={translate('mark selected as read')}
            active={false}
            activeColor="bg-darkgrey"
            onClick={() => props.setNotificationsRead()}
            fontColor="#000"
          />

          <Badge
            label={translate('mark all as read')}
            active={false}
            activeColor="bg-darkgrey"
            onClick={() => props.setNotificationsRead(true)}
          />
        </div>

        <NotificationsTable
          selectedMessages={props.selectedMessages}
          messages={notifications.messages}
          homeUsers={homeUsers}
          handleSelectedMessage={props.handleSelectedMessage}
          handleRedirection={props.handleRedirection}
        />

        {showLoadMoreButton && (
          <div className="notifications-actions center bottom">
            <Badge
              active
              label={translate('load more')}
              activeColor="bg-darkgrey"
              onClick={() => props.loadMore()}
            />
          </div>
        )}
      </div>

      {notificationModalOpened && (
        <NotificationsModal
          modalOpened={notificationModalOpened}
          toggleModal={toggleNotificationsModal}
          userName={props.deletedUserName}
        />
      )}
    </SignedInLayout>
  );
};


Notifications.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Notifications;
