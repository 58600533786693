import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';

import { Walkthrough } from '../../../components';
import {
  setCalendarView, setCalendarDate, fetchCalendarEvents,
  addCalendarEvent, updateCalendarEvent, deleteCalendarEvent,
} from '../../../store/actions/calendar';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { showInitialWalkthrough } from '../../../utils/walkthrough';
import { setLayoutPrintable, unsetLayoutPrintable } from '../../../store/actions/layout';
import { DEFAULT_DISPLAYED_DATE_FORMAT, isAllDayEvent, isEventInStartDayPart, isEventInEndDayPart } from '../../../utils/date';
import Calendar from '../components/Calendar';


const timeRowHeight = 55;
const topBarHeight = 149;

const DEFAULT_ALERT_DATA = { amount: 10, timeUnit: 'M' };

const DEFAULT_EVENT_FORM = {
  type: 'event',
  private: true,
  title: '',
  start_date: '',
  start_time: '',
  end_date: '',
  end_time: '',
  note: '',
  caretaker_ids: [],
  kid_ids: [],
  user_id: '',
  repeatable: false,
  repeatable_type: 'weekly',
  repeatable_end: new Date(),
  repeatable_action: 'current',
  alertsList: [DEFAULT_ALERT_DATA],
};

const DEFAULT_STATE = {
  modalOpened: false,
  confirmModalOpened: false,
  printModalOpened: false,
  datePickerOpened: false,
  printAction: 'export',
  printSelectedUser: {},
  editedEvent: null,
  filters: {
    caretakers: [],
  },
  eventFormData: _.cloneDeep(DEFAULT_EVENT_FORM),
  eventToDelete: {},
  startTimePickerOpened: false,
  endTimePickerOpened: false,
  errorMessage: '',
};

class CalendarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = _.cloneDeep(DEFAULT_STATE);
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();

    setTimeout(() => {
      this.scrollCalendar();
    }, 1000);

    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const startDate = urlParams.get('start-date');

    this.clearFilters();
    this.setFilters(Object.keys(this.props.caretakers));
    if(startDate) {
      if(moment(startDate).isValid()) {
        this.setDate(startDate);
        this.setState({
          datePickerOpened: false,
        });
      } else {
        this.fetchEvents();
      }
    } else {
      this.fetchEvents();
    }

    showInitialWalkthrough('calendar', this.props.showWalkthrough);
  }

  setAlertsList = alertsList => {
    this.setState({ eventFormData: { ...this.state.eventFormData, alertsList } });
  }

  scrollCalendar = () => {
    const { calendar } = this.props;
    const viewType = calendar.viewType;
    const date = calendar.calendarDate;
    const dayNumber = parseInt(moment(date).format('d'), 10);
    const currentHour = parseInt(moment().format('H'), 10);
    const isCurrentDate = moment(date).format(DEFAULT_DISPLAYED_DATE_FORMAT) === moment().format(DEFAULT_DISPLAYED_DATE_FORMAT);

    const dayScrollPosition = (isCurrentDate ? currentHour : 7) * timeRowHeight;
    const arrayIndex = dayNumber === 0 ? 6 : dayNumber - 1;
    const weekScrollPosition = viewType === 'week' && document.getElementsByClassName('calendar-week-container-row')[arrayIndex].offsetTop - topBarHeight;
    const scrollPosition = viewType === 'day' ? dayScrollPosition : weekScrollPosition;

    const calendarsWrapper = document.querySelector('.calendars');
    const calendarContainer = document.querySelector('.calendar-container');

    if(calendarsWrapper) calendarsWrapper.scrollTop = scrollPosition;
    if(calendarContainer) calendarContainer.focus();
  }

  fetchEvents = (date, type) => {
    const { rangeStartDate, viewType } = this.props.calendar;
    this.props.fetchCalendarEvents(date || rangeStartDate, type || viewType).then(() => {
      if(date === undefined || !date) {
        this.clearFilters();
        this.setFilters(Object.keys(this.props.caretakers));
      }
      setTimeout(() => {
        this.scrollCalendar();
      }, 500);
      this.prepareEventsDataForCalendar();
    });
  }

  prepareEventsDataForCalendar = () => {
    const { calendar } = this.props;
    calendar.usersEvents.forEach(userEvent => {
      userEvent.calendar_events.sort((a, b) => {
        return a.duration < b.duration ? -1 : a.duration > b.duration ? 1 : 0;
      });
      userEvent.calendar_events.map(evt => {
        if(calendar.viewType === 'day') {
          const allDayEvent = isAllDayEvent(evt, calendar);
          const eventInStartDayPart = isEventInStartDayPart(evt, calendar);
          const eventInEndDayPart = isEventInEndDayPart(evt, calendar);

          evt.allDayEvent = allDayEvent;
          if(allDayEvent || eventInStartDayPart) {
            evt.start = moment(calendar.calendarDate).startOf('day').toDate();
          } else {
            evt.start = moment(`${evt.start_date}T${evt.start_time}`).toDate();
          }
          if(allDayEvent || eventInEndDayPart) {
            evt.end = moment(calendar.calendarDate).endOf('day').toDate();
          } else {
            evt.end = moment(`${evt.end_date}T${evt.end_time}`).toDate();
          }
        }

        if(evt.long_term) {
          evt.longTerm = true;
          const caretaker = this.props.caretakers[evt.caretaker_ids[0]];
          evt.title = caretaker && (caretaker.nickname ? `@${caretaker.nickname}` : `@${caretaker.first_name}`);
        } else {
          evt.longTerm = false;
        }

        evt.alertsList = this.getAlertsList(evt.alerts_intervals);
        evt.originalRepeatable = evt.repeatable;
        if(evt.repeatable && evt.event_sequence) {
          evt.repeatable_action = 'current';
          evt.repeatable_end = evt.event_sequence.ends_at;
          evt.repeatable_type = evt.event_sequence.interval;
        }
      });

      userEvent.calendar_events.map(evt => {
        evt.eventsInSameTime = [];
        if(calendar.viewType === 'day') {
          userEvent.calendar_events.map(ev => {
            if(ev.id !== evt.id && !ev.longTerm && evt.eventsInSameTime.indexOf(ev.id) === -1 && (
              (moment(ev.end).isBetween(evt.start, evt.end)) ||
              (moment(ev.start).isBetween(evt.start, evt.end)) ||
              (moment(ev.start).isSameOrAfter(evt.start) && moment(ev.end).isSameOrBefore(evt.end)) ||
              (moment(ev.start).isSameOrBefore(evt.start) && moment(ev.end).isSameOrAfter(evt.end))
            )) {
              evt.eventsInSameTime.push(ev.id);
            }
          });
        }
      });
    });
    this.forceUpdate();
  }

  setView = type => {
    this.props.setCalendarView(type);

    const { calendar } = this.props;
    const date = type === 'day' ? moment(calendar.calendarDate).toDate() : moment(calendar.calendarDate).startOf('isoWeek').toDate();
    this.fetchEvents(date, type);
  }

  setDate = date => {
    const { calendar } = this.props;
    const calendarDate = calendar.calendarDate;
    const numberOfDays = calendar.viewType === 'day' ? 1 : 7;
    let newDate;
    if(date === 'next') {
      newDate = new Date(calendarDate.setDate(calendarDate.getDate() + numberOfDays));
    } else if (date === 'prev') {
      newDate = new Date(calendarDate.setDate(calendarDate.getDate() - numberOfDays));
    } else if (date === 'today') {
      newDate = new Date();
    } else {
      newDate = new Date(date);
      this.toggleDatePicker();
    }
    this.props.setCalendarDate(newDate);
    this.fetchEvents(newDate);
  }

  clearFilters = () => {
    const { filters } = this.state;
    filters.caretakers = [];
    this.setState({
      filters,
    });
  }

  setFilters = caretakers => {
    const { filters } = this.state;
    caretakers.forEach(caretaker => {
      if(filters.caretakers.indexOf(caretaker) > -1) {
        filters.caretakers = _.without(filters.caretakers, caretaker);
      } else {
        filters.caretakers.push(caretaker);
      }
    });
    this.setState({
      filters,
    });
  }

  closeTimePickers = e => {
    const { startTimePickerOpened, endTimePickerOpened } = this.state;

    if(startTimePickerOpened || endTimePickerOpened) {
      if(e.target.className.indexOf('tp-cell') === -1 && e.target.className.indexOf('time-picker-input') === -1) {
        this.setState({
          startTimePickerOpened: false,
          endTimePickerOpened: false,
        });
      }
    }
  }

  toggleTimePicker = (open, type) => {
    if(type === 'start') {
      this.setState({
        startTimePickerOpened: !this.state.startTimePickerOpened,
        endTimePickerOpened: false,
      });
    }
    if(type === 'end') {
      this.setState({
        startTimePickerOpened: false,
        endTimePickerOpened: !this.state.endTimePickerOpened,
      });
    }
  }


  setPrintAction = action => {
    this.setState({
      printAction: action,
    });
  }

  showPrintModal = () => {
    this.props.cleanError('calendar');
    this.setState({
      printModalOpened: true,
    });
  }

  closePrintModal = () => {
    this.setState({
      printModalOpened: false,
      printSelectedUser: {},
      printAction: 'export',
    });
  }

  toggleDatePicker = () => {
    let { datePickerOpened } = this.state;
    datePickerOpened = !datePickerOpened;
    this.setState({
      datePickerOpened,
    });
  }

  showEventModal = confirm => {
    this.props.cleanError('calendar');
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: true,
    });
  }

  closeEventModal = confirm => {
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: false,
      eventFormData: _.cloneDeep(DEFAULT_EVENT_FORM),
      editedEvent: null,
    });
  }

  handleEventFormChange = (name, value) => {
    const { eventFormData } = this.state;
    const timeFields = ['start_date', 'end_date', 'repeatable_start', 'repeatable_end'];

    if(name === 'private') {
      eventFormData.private = value;
    } else {
      eventFormData[name] = value || '';
    }

    if(name === 'type' && value === 'care') {
      eventFormData.private = false;
    }

    if(name === 'type' && value === 'event') {
      eventFormData.private = true;
    }

    if(name === 'type' && value === 'care' && eventFormData.caretaker_ids.length > 0) {
      // if we switch to childcare type we have to select only 1 caretaker
      eventFormData.caretaker_ids = eventFormData.caretaker_ids.slice(0, 1);
    }
    if(name === 'repeatable') {
      eventFormData.repeatable_start = eventFormData.start_date;
      eventFormData.repeatable_end = eventFormData.start_date;
    }

    this.setState({
      eventFormData,
    }, () => {
      if(timeFields.indexOf(name) > -1) {
        this.validateTime();
      }
    });
  }

  handlePrintUserChange = userId => {
    const { homeUsers } = this.props;
    this.setState({
      printSelectedUser: homeUsers[userId],
    });
  }

  handleSelectedSlot = (slotInfo, user, userType) => {
    const momentStart = moment(slotInfo.start);
    const momentEnd = moment(slotInfo.end);
    const { eventFormData } = this.state;

    this.setState({
      eventFormData: Object.assign({}, eventFormData, {
        [`${userType}_ids`]: [user],
        start_date: momentStart.format('YYYY-MM-DD'),
        start_time: momentStart.format('HH:mm:SS'),
        end_date: momentEnd.format('YYYY-MM-DD'),
        end_time: momentEnd.format('HH:mm:SS'),
      }),
    }, () => {
      this.showEventModal();
    });
  }

  editEvent = (event, user) => {
    event.user_id = typeof user === 'object' ? user.id : user;
    event.type = event.longTerm ? 'care' : 'event';
    this.setState({
      eventFormData: _.cloneDeep(event),
      editedEvent: event.id,
    }, () => {
      this.showEventModal();
    });
  }

  validateTime = () => {
    const { eventFormData } = this.state;
    const start = `${eventFormData.start_date} ${eventFormData.start_time}`;
    const end = `${eventFormData.end_date} ${eventFormData.end_time}`;
    if(moment(start).isAfter(end, 'minute')) {
      eventFormData.end_date = eventFormData.start_date;
      eventFormData.end_time = eventFormData.start_time;
      eventFormData.repeatable_start = eventFormData.start_date;
      eventFormData.repeatable_end = eventFormData.start_date;
      this.setState({
        eventFormData,
      });
    } else if(moment(eventFormData.repeatable_start).isAfter(eventFormData.repeatable_end, 'day')) {
      eventFormData.repeatable_end = eventFormData.repeatable_start;
      this.setState({
        eventFormData,
      });
    } else {
      this.setState({
        errorMessage: '',
      });
    }
  }

  validateTimeDuringSave = () => {
    const { eventFormData } = this.state;
    const { t: translate } = this.context;

    const start = `${eventFormData.start_date} ${eventFormData.start_time}`;
    const end = `${eventFormData.end_date} ${eventFormData.end_time}`;
    let error = '';
    if(moment(start).isAfter(end, 'minute')) {
      error = translate('Event end date has to be later than start date');
    }
    if(eventFormData.repeatable && moment(eventFormData.repeatable_start).isAfter(eventFormData.repeatable_end, 'day')) {
      error = translate('Repeatable end date has to be later than start date');
    }
    this.setState({
      errorMessage: error,
    });
    return error;
  }

  validateChildCareEvents = () => {
    const { eventFormData } = this.state;
    const { t: translate } = this.context;

    let error = '';
    if(eventFormData.type === 'care' && eventFormData.kid_ids.length === 0) {
      error = translate('Please select at least one child for childcare event.');
    }

    this.setState({
      errorMessage: error,
    });
    return error;
  }

  getAlertsIntervals = data => {
    return data.map(el => {
      if(el) {
        if(el.timeUnit === 'D') return `P${el.amount}D`;

        return `PT${el.amount}${el.timeUnit}`;
      }
    }).filter(el => el);
  }

  getAlertsList = (data = []) => {
    return data.map(el => {
      if(el.timeUnit === 'D') return `P${el.amount}D`;

      return { amount: el[1] === 'T' ? el.slice(2, el.length - 1) : el.slice(1, el.length - 1), timeUnit: el.slice(el.length - 1) };
    });
  }

  saveCalendarEvent = e => {
    e.preventDefault();
    this.props.cleanError('calendar');
    const { editedEvent } = this.state;
    let { eventFormData } = this.state;
    const alertsIntervals = this.getAlertsIntervals(eventFormData.alertsList);
    eventFormData = { ...eventFormData, alertsIntervals };

    if(eventFormData.type === 'care') {
      const caretaker = this.props.caretakers[eventFormData.caretaker_ids[0]];
      eventFormData.title = caretaker && (`@${caretaker.nickname}` || `@${caretaker.first_name}`);
    }

    if(this.validateTimeDuringSave() === '' && this.validateChildCareEvents() === '') {
      if(editedEvent === null) {
        this.props.addCalendarEvent(eventFormData).then(() => {
          if(!this.props.error || this.props.error === null) {
            this.getDataAfterSave();
            this.closeEventModal();
          }
        });
      } else {
        this.props.updateCalendarEvent(eventFormData, editedEvent).then(() => {
          if(!this.props.error || this.props.error === null) {
            this.getDataAfterSave();
            this.closeEventModal();
          }
        });
      }
    }
  }

  getDataAfterSave = () => {
    this.clearFilters();
    this.setFilters(Object.keys(this.props.caretakers));
    this.fetchEvents();
    this.forceUpdate();
  }

  setEventToDelete = event => {
    const { id, user_id, repeatable } = event;
    this.setState({
      eventToDelete: {
        eventId: id,
        userId: user_id,
        repeatable,
      },
    }, () => {
      this.closeEventModal();
      this.showEventModal(true);
    });
  }

  deleteEvent = e => {
    e.preventDefault();
    const { rangeStartDate, viewType } = this.props.calendar;
    const { eventToDelete, eventFormData } = this.state;

    if(!_.isNil(eventToDelete.userId) && !_.isNil(eventToDelete.eventId)) {
      if(eventToDelete.repeatable) {
        eventToDelete.repeatable_action = eventFormData.repeatable_action;
      }
      this.props.deleteCalendarEvent(eventToDelete, rangeStartDate, viewType).then(() => {
        this.setState({
          eventToDelete: {},
        }, () => {
          this.closeEventModal(true);
          this.fetchEvents();
        });
      });
    }
  }

  togglePrintable = () => {
    const { printable } = this.props.layout;
    window.scrollTo(0, 0);
    if(printable) {
      this.props.unsetLayoutPrintable();
      document.querySelector('.calendar-container').focus();
      document.querySelector('.signedin-layout .signedin-layout__content aside+.loading').style.height = 'auto';
    } else {
      this.props.setLayoutPrintable();
      setTimeout(() => {
        document.querySelector('.signedin-layout .signedin-layout__content aside+.loading').style.height = `${document.querySelector('.calendar-week-container').offsetHeight}px`;
        window.print();
      }, 1000);
      setTimeout(() => {
        this.props.unsetLayoutPrintable();
        document.querySelector('.calendar-container').focus();
        document.querySelector('.signedin-layout .signedin-layout__content aside+.loading').style.height = 'auto';
      }, 1000);
    }
  }

  render() {
    return (
      <div>
        <Walkthrough currentPath={this.props.location.pathname} stepsPlacement={{ 2: 'left', 3: 'top-start' }} />

        <Calendar
          {...this.props}
          {...this.state}
          setCalendarView={this.setView}
          setCalendarDate={this.setDate}
          setFilters={this.setFilters}
          saveCalendarEvent={this.saveCalendarEvent}
          handleEventFormChange={this.handleEventFormChange}
          handlePrintUserChange={this.handlePrintUserChange}
          editEvent={this.editEvent}
          deleteEvent={this.deleteEvent}
          handleSelectedSlot={this.handleSelectedSlot}
          showEventModal={this.showEventModal}
          closeEventModal={this.closeEventModal}
          showPrintModal={this.showPrintModal}
          closePrintModal={this.closePrintModal}
          toggleDatePicker={this.toggleDatePicker}
          toggleTimePicker={this.toggleTimePicker}
          closeTimePickers={this.closeTimePickers}
          setEventToDelete={this.setEventToDelete}
          togglePrintable={this.togglePrintable}
          setPrintAction={this.setPrintAction}
          setAlertsList={this.setAlertsList}
        />
      </div>
    );
  }
}


CalendarContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
  setCalendarView,
  setCalendarDate,
  fetchCalendarEvents,
  addCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  fetchHomesIfNeeded,
  setLayoutPrintable,
  unsetLayoutPrintable,
  cleanError,
  showWalkthrough,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    calendar: state.calendar,
    homeUsers: state.homeUsers,
    caretakers: state.caretakers,
    kids: state.kids,
    error: state.errors.calendar,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarContainer);
