import React from 'react';
import PropTypes from 'prop-types';

import SignedInLayout from '../../../layouts/SignedInLayout';

import CaretakerPersonalInfo from './CaretakerPersonalInfo';


export const CaretakerProfile = ({ user, layout, loading, caretaker, ...props }) => {
  return (
    <SignedInLayout
      page="KidProfile"
      currentPath={`/caretaker/${caretaker.id}`}
      user={user}
      layout={layout}
      loading={loading}
    >

      <div className="kid-profile-info">
        {caretaker.email && (
          <CaretakerPersonalInfo
            editMode={props.editMode}
            caretaker={caretaker}
            handleFormChange={props.handleFormChange}
            saveCaretaker={props.saveCaretaker}
            error={props.error}
            loading={loading}
          />
        )}
      </div>
    </SignedInLayout>
  );
};


CaretakerProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CaretakerProfile;
