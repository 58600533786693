import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Badge, Form } from './../../components';
import { isEdge } from './../../utils/browser';


class TagKeys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newTag: '',
    };
  }

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({
      editMode: !editMode,
    }, () => {
      if(this.state.editMode) {
        this.props.handleFormChange('tag_key', this.state.newTag, true);
      }
    });
  }

  handleClick = category => {
    if(this.state.editMode) {
      this.toggleEditMode();
    }
    this.props.handleFormChange('tag_key', category, true);
  }

  handleTagChange = event => {
    const value = event.target.value;
    this.setState({
      newTag: value,
    }, () => {
      this.props.handleFormChange('tag_key', value, true);
    });
  }

  handleKeyPress = event => {
    if(event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  }

  render() {
    const { label, type, activeCategory, tagKeys } = this.props;
    const { t: translate } = this.context;
    const fixedTypeName = type.replace(/scheduled_/g, '');

    return (
      <div className="badges-section">
        <div className="title">{label}</div>

        {type && tagKeys[fixedTypeName].map((category, idx) => {
          return (
            <Badge key={idx}
              label={category}
              active={activeCategory === category}
              activeColor="bg-darkgrey"
              onClick={() => this.handleClick(category)}
            />
          );
        })}

        {!this.state.editMode && (
          <span className="pointer color-main f14 nowrap" onClick={() => this.toggleEditMode()}>{translate('+ add new')}</span>
        )}

        {this.state.editMode && (
          <Form className="p-t-20 p-b-20">
            <Form.Row>
              <input type="text"
                autoComplete="off"
                value={this.state.newTag}
                onChange={e => this.handleTagChange(e)}
                onKeyPress={e => this.handleKeyPress(e)}
              />
              <label className={isEdge ? 'edge' : ''}>{translate('new category')}</label>
            </Form.Row>
          </Form>
        )}
      </div>
    );
  }
}


TagKeys.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    tagKeys: state.tagKeys,
  };
};

export default connect(
  mapStateToProps,
)(TagKeys);
