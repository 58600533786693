import React from 'react';


const Leaf = ({ title, description, onEdit, onDelete, isWalkthroughOpen }) => {
  return (
    <div className="card leaf">
      <div className="content">
        <div className="actions pull-right right" style={{ visibility: isWalkthroughOpen && 'visible' }}>
          <i className="zmdi zmdi-edit" onClick={onEdit} />
          <i className="zmdi zmdi-delete p-l-10" onClick={onDelete} />
        </div>
        <div className="title">{title}</div>
        <div className="description-container">
          <div className="description">{description}</div>
        </div>
      </div>
    </div>
  );
};


export default Leaf;
