import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';


const customStyles = {
  container: () => ({
    width: '100%',
    borderBottom: '1px solid #b3b3b3',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#e59907' : isFocused ? '#e5990780' : '#fff',
    color: isSelected ? '#fff' : '#2e383e',
    fontFamily: 'Nunito-Light, sans-serif',
  }),
  placeholder: styles => ({
    ...styles,
    textAlign: 'center',
  }),
  menu: styles => ({
    ...styles,
    margin: '0',
  }),
};

const alertSelectStyles = {
  container: () => ({
    maxWidth: '110px',
    marginRight: '10px',
  }),
  control: styles => ({
    ...styles,
    border: 'none',
    backgroundColor: 'transparent',
  }),
  valueContainer: styles => ({
    ...styles,
    backgroundColor: '#FFF4E0',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    backgroundColor: '#FFF4E0',
    maxHeight: '28px',
    marginTop: '5px',
  }),
};

const CustomSelect = ({
  className, disabled, label, isSearchable = false, mode, value,
  onChange, options, placeholder, withDefaultValue,
}) => {
  const styles = mode === 'alertSelect' ? { ...customStyles, ...alertSelectStyles } : customStyles;

  return (
    <div className={classnames('CustomSelect flex flex-column space-between', className)}>
      {label ? (
        <span className="Label block">
          {label}
        </span>
      ) : null}
      <Select
        isDisabled={disabled}
        isSearchable={isSearchable}
        onChange={opt => onChange(opt.value)}
        options={options}
        defaultValue={withDefaultValue ? options[0] : null}
        value={value}
        styles={styles}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomSelect;

