import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import SignedInLayout from '../../../layouts/SignedInLayout';

import { ModalConfirm } from './../../../components';
import FamilyTree from './FamilyTree';

export const Family = ({ user, layout, home, homesEx, homeUsers, caretakers, kids, loading, ...props }, { t: translate }) => {
  const caretakersWithoutMe = home.caretaker_ids && _.without(home.caretaker_ids, user.id);
  const isPartnerSet = caretakersWithoutMe && caretakersWithoutMe.length > 0;

  const caretakersExWithoutMe = homesEx && homesEx.map(homeEx => {
    return homeEx.caretaker_ids && _.without(homeEx.caretaker_ids, user.id);
  });
  const isExSet = caretakersExWithoutMe && _.flatten(caretakersExWithoutMe).length > 0;

  return (
    <SignedInLayout
      page="Family"
      currentPath="/home"
      user={user}
      loading={loading}
      layout={layout}
    >
      <FamilyTree
        user={user}
        home={home}
        homesEx={homesEx}
        homeUsers={homeUsers}
        caretakers={caretakers}
        kids={kids}
        editMode={props.editMode}
        visibilityFilter={props.visibilityFilter}
        setUserVisibility={props.setUserVisibility}
        deleteInvitation={props.deleteInvitation}
        isPartnerSet={isPartnerSet}
        isExSet={isExSet}
        setVisibilityFilter={props.setVisibilityFilter}
      />

      <ModalConfirm
        isOpen={props.confirmDeleteModalOpened}
        closeModal={() => props.closeModal(true)}
        additionalClassNames="family-member-pending"
        question={translate('Are you sure you want to delete the invitation?')}
        deleteFunction={props.deleteInvitationConfirmed}
      />

      <ModalConfirm
        isOpen={props.confirmHideModalOpened}
        closeModal={() => props.closeModal(true)}
        question={translate('Are you sure you want to change user visibility?')}
        buttonLabel={translate('Yes, change')}
        deleteFunction={props.setUserVisibilityConfirmed}
      />
    </SignedInLayout>
  );
};


Family.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Family;
