import React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';

const Notification = ({ className: additionalClassName, text, type = 'info', exclude }) => {
  if (!text) {
    return null;
  }

  const className = classNames('notification', 'f14', additionalClassName, {
    'notification--success': (type === 'success'),
    'notification--info': (type === 'info'),
    'notification--error': (type === 'error'),
  });


  let errorsList = [];
  if(_.isArray(text) && text.length > 0) {
    if(exclude && exclude.length) {
      errorsList = _.differenceWith(text, exclude, (t, e) => t.toLowerCase().indexOf(e) > -1);
    } else {
      errorsList = text;
    }
  }

  return (
    <div className={className} data-e2e="notification-component">
      {errorsList && errorsList.length > 0 && errorsList.map((txt, idx) => (
        <span key={idx}>{txt}<br /></span>
      ))}
      {!_.isArray(text) && (
        <span>{text}</span>
      )}
    </div>
  );
};


export default Notification;
