import React, { useEffect } from 'react';
import { Payment } from 'src/components';
import useStore from 'src/hooks/useStore';
import SignedInLayout from 'src/layouts/SignedInLayout';
import { cleanError } from 'src/store/actions/errors';


export const Subscription = () => {
  const [state, dispatchEvent] = useStore();

  useEffect(() => {
    return () => dispatchEvent(cleanError('subscription'));
  }, []);

  return (
    <SignedInLayout
      page="subscription"
      currentPath={'/subscription'}
      user={state.user}
      layout={state.layout}
      loading={state.loading}
    >
      <Payment />
    </SignedInLayout>
  );
};

export default Subscription;
