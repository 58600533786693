import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setLanguage as setMomentLanguage } from 'redux-i18n';
import { useHistory } from 'react-router';

import { AuthForm, Inner, Link, Notification } from '../../../components';
import useStore from '../../../hooks/useStore';
import { api, BASE_URL, TWO_HOMES_APP_SCHEME } from '../../../utils/net';
import { getQueryParam } from '../../../utils/urls';


const Activation = ({}, { t: translate }) => {
  const [token] = useState(getQueryParam('token'));
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [activated, setActivated] = useState(false);
  const [isInvitation, setIsInvitation] = useState(false);
  const [isKidInvitation, setIsKidInvitation] = useState(false);
  const [language, setLanguage] = useState('en');

  const { push } = useHistory();

  // eslint-disable-next-line
  const [state, dispatch] = useStore();


  useEffect(() => {
    const invitation = getQueryParam('invitation');
    const languageParam = getQueryParam('language');
    const role = getQueryParam('role');

    if(invitation) setIsInvitation(true);
    if(languageParam) setLanguage(languageParam);
    if(role && role === 'kid') setIsKidInvitation(true);

    setProcessing(true);
  }, []);

  useEffect(() => {
    if(language) {
      dispatch(setMomentLanguage(language));
      moment.locale(language);

      if(language === 'nn') {
        moment.updateLocale('nn', {
          weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
          weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
        });
      }
    }
  }, [language]);

  useEffect(() => {
    if(processing) activateAccount();
  }, [processing]);

  useEffect(() => {
    let timeout;

    if(activated) {
      timeout = setTimeout(() => push('/sign-in'), 5000);
      window.location.replace(TWO_HOMES_APP_SCHEME);
    }
    return () => {
      if(timeout) () => clearTimeout(timeout);
    };
  }, [activated]);

  const activationSuccess = () => {
    setProcessing(false);
    setActivated(true);
  };

  const activationFailure = error => {
    setError(error);
    setActivated(false);
    setProcessing(false);
  };

  const activateAccount = async() => {
    const params = {
      token,
      client_token: api.getClientToken(),
    };
    const urlPost = `${BASE_URL}${isInvitation ? 'invitation' : 'email'}_confirmations`;

    try {
      const { data } = await api.post(urlPost, params);

      if(!data.confirmed_at && isInvitation && !isKidInvitation) {
        push(`/new-password?language=${language}&invitation_id=${data.id}&invitee_id=${data.invitee_id}&token=${token}`);
      }
      if(!isInvitation || isKidInvitation) activationSuccess();
    } catch(error) {
      const defaultMessage = translate('Could not activate your account.');
      const errorMessage = api.getRequestErrorMessage(error, defaultMessage);

      activationFailure(errorMessage);
    }
  };

  const renderAuthFormParagraph = () => {
    return activated ? (
      <AuthForm.Paragraph>
        <p className="activation-success-message" data-e2e="activation-success-message">
          {isInvitation ?
            translate('You accepted an invitation.') :
            translate('We have just activated your account.')}
          <br />
          {!isKidInvitation && (
            <>
              {translate('Please')}
              <Link to="/sign-in">{translate('log in')}</Link> {translate('to enjoy using 2homes')}.
            </>
          )}
        </p>
      </AuthForm.Paragraph>
    ) : (
      <AuthForm.Paragraph>
        <p>{translate('Please wait for account activation.')}</p>
      </AuthForm.Paragraph>
    );
  };


  return (
    <Inner>
      <AuthForm
        type="tada"
        title={!activated ? translate('Activating account') : translate('Ready!')}
        bottomContent={!activated && (
          <div>
            <span>{translate('Already have an account?')} </span>
            <Link to="/sign-in">{translate('Log in')}</Link>
          </div>
        )}
      >
        {error ? <Notification type="error" text={error} /> : renderAuthFormParagraph()}
      </AuthForm>
    </Inner>
  );
};


Activation.contextTypes = {
  t: PropTypes.func.isRequired,
};


export default Activation;
