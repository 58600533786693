import React, { Component } from 'react';
import { connect } from 'react-redux';

import PasswordRecoverView from '../PasswordRecoverView';
import { recoverPassword } from '../../../store/actions/auth';
import { cleanError } from '../../../store/actions/errors';
import { removeWhitespace } from '../../../utils/string';


class PasswordRecoverContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: localStorage.getItem('2homes-email') || '',
      isSubmitted: false,
    };
  }

  componentWillUnmount() {
    this.props.cleanError('passwordrecover');
  }

  handleFieldChange = name => {
    return event => {
      let value = event.target.value;
      if(name === 'email') value = removeWhitespace(value);

      this.setState({ [name]: value });
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      isSubmitted: true,
    });
    const { email } = this.state;
    this.props.recoverPassword({ email });
  }

  render() {
    const { email, isSubmitted } = this.state;
    const { error, loading } = this.props;

    return (
      <PasswordRecoverView
        email={email}
        isSubmitted={isSubmitted}
        error={error}
        loading={loading}
        handleFieldChange={this.handleFieldChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}


const mapStateToProps = ({ errors, loading }) => ({
  error: errors.passwordrecover,
  loading: loading,
});

const mapDispatchToProps = {
  recoverPassword,
  cleanError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordRecoverContainer);
