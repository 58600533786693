import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MODAL_NAMES } from 'src/utils/modals';

import {
  BlockedAccountInfo,
  InvitationSuccess,
  PaymentProblemInfo,
  PermissionsInfo,
  SubscriptionCancelInfo,
  SubscriptionCancelModal,
  SubscriptionCreateSuccess,
} from './components';


const GlobalModals = () => {
  return (
    <Fragment>
      <InvitationSuccess name={MODAL_NAMES.INVITATION_SUCCESS} />
      <SubscriptionCreateSuccess name={MODAL_NAMES.SUBSCRIPTION_CREATE_SUCCESS} />

      <BlockedAccountInfo name={MODAL_NAMES.BLOCKED_ACCOUNT_INFO} />
      <PaymentProblemInfo name={MODAL_NAMES.PAYMENT_PROBLEM_INFO} />
      <PermissionsInfo name={MODAL_NAMES.PERMISSIONS_INFO} />
      <SubscriptionCancelInfo  name={MODAL_NAMES.SUBSCRIPTION_CANCEL_INFO} />

      <SubscriptionCancelModal name={MODAL_NAMES.SUBSCRIPTION_CANCEL_CONFIRM} />
    </Fragment>
  );
};

GlobalModals.contextTypes = {
  t: PropTypes.func,
};


export default GlobalModals;
