import React from 'react';
import PropTypes from 'prop-types';

import Badge from './../../../../components/Badge';


export const CalendarNavigationView = ({ viewType, setCalendarDate, setCalendarView }, { t: translate }) => {
  return (
    <div className="badges">
      <Badge label={translate('day')} active={viewType === 'day'} activeColor="bg-darkgrey" onClick={() => setCalendarView('day')} />
      <Badge label={translate('week')} active={viewType === 'week'} activeColor="bg-darkgrey" onClick={() => setCalendarView('week')} />
      {/* <Badge label="month" active={viewType === 'month'} activeColor="bg-darkgrey" onClick={() => setCalendarView('month')} /> */}

      <div style={{ marginLeft: 50, display: 'inline-block' }}>
        <Badge circle labelIcon="chevron-left" active={false} activeColor="bg-darkgrey" onClick={() => setCalendarDate('prev')} />
        <Badge active activeColor="bg-main1" label={translate('today')} onClick={() => setCalendarDate('today')} />
        <Badge circle labelIcon="chevron-right" active={false} activeColor="bg-darkgrey" onClick={() => setCalendarDate('next')} />
      </div>
    </div>
  );
};


CalendarNavigationView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarNavigationView;
