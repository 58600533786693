import React from 'react';
import PropTypes from 'prop-types';

import Badge from './../Badge';

const FinancialFilters = ({ filterActive, setFilter }, { t: translate }) => {
  return (
    <div className="history-filters center">
      <div className="history-filters-container">
        <Badge
          label={translate('all transactions')}
          active={filterActive === 'all'}
          activeColor="bg-darkgrey"
          onClick={() => setFilter('all')}
        />

        <Badge
          label={translate('income only')}
          active={filterActive === 'income'}
          activeColor="bg-green"
          onClick={() => setFilter('income')}
          fontColor="#60991E"
        />

        <Badge
          label={translate('expenses only')}
          active={filterActive === 'expenses'}
          activeColor="bg-red"
          onClick={() => setFilter('expenses')}
          fontColor="#ED5229"
        />
      </div>
    </div>
  );
};


FinancialFilters.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FinancialFilters;
