import React, { Fragment } from 'react';

import { UnsupportedMobile } from '../components';

import { isMobile } from './browser';


const testEnvOrigins = [
  'http://localhost:3000',
  'https://2homes-development.netlify.app',
];

const RedirectToMobile = Component => {
  const Page = props => {
    const isInTestEnv = testEnvOrigins.indexOf(window.location.origin) > -1;

    if (isMobile()) {
      return (
        <UnsupportedMobile />
      );
    }

    return (
      <Fragment>
        <Component {...props} />
        {!isInTestEnv && <UnsupportedMobile />}
      </Fragment>
    );
  };

  return Page;
};

export default RedirectToMobile;
