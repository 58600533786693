import * as TYPE from '../types';

const INITIAL_STATE = {
  name: null,
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPE.MODAL_OPEN:
      return action.payload;

    case TYPE.MODAL_HIDE:
    case TYPE.SIGNOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
