import React from 'react';

import { Badge } from './../../../components/';


const ReportsSimpleSelect = ({ name, keyParam, list, filters, handleFormChange, colors, translate }) => {
  return (
    <div className="inline-block">
      <label>{name}</label>
      <div className="inline-block">
        {list.map((element, idx) => {
          return (
            <Badge
              key={idx}
              label={translate(element)}
              active={filters[keyParam].indexOf(element) > -1}
              activeColor={(colors && colors[idx]) || 'bg-darkgrey'}
              onClick={() => handleFormChange(keyParam, element)}
            />
          );
        })}
      </div>
    </div>
  );
};


export default ReportsSimpleSelect;
