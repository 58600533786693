import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { setNumberFormat } from './../../../../utils/tools';
import ReportChart from './ReportChart';


const ReportUser = ({ homeUsers, userData }, { t: translate }) => {
  const expenses = userData.expenses && (userData.expenses.total > 0 ? '-' : '') + setNumberFormat(userData.expenses.total, 0);
  const incomes = userData.incomes && (userData.incomes.total > 0 ? '+' : '') + setNumberFormat(userData.incomes.total, 0);
  const userName = homeUsers && userData.user_id && (
    `${homeUsers[userData.user_id].first_name || ''} ${homeUsers[userData.user_id].last_name || ''}`
  );

  return (
    <div className="report-container report-summary">
      {userData.hasHeader ?
        <Fragment>
          <div className="user-name">{userName}</div>
          <div className="amounts">
            <div>
              <span className="amount-type">{translate('Expenses')}</span>
              <div className="value error">{expenses}<span className="currency">NOK</span></div>
            </div>
            <div>
              <span className="amount-type">{translate('Income')}</span>
              <div className="value success">{incomes}<span className="currency">NOK</span></div>
            </div>
          </div>
        </Fragment> :
        null}

      {userData.expenses && userData.expenses.total > 0 && (
        <div className="categories">
          <p>{translate('Total expenses by category:')}</p>
          {userData.expenses.tag_keys.map((tagKeys, idx) => (
            <ReportChart
              key={idx}
              chartData={tagKeys}
              total={userData.expenses.total}
            />
          ))}
        </div>
      )}

      {userData.incomes && userData.incomes.total > 0 && (
        <div className="categories">
          <p>{translate('Total income by category:')}</p>
          {userData.incomes.tag_keys.map((tagKeys, idx) => (
            <ReportChart
              key={idx}
              chartData={tagKeys}
              total={userData.incomes.total}
            />
          ))}
        </div>
      )}
    </div>
  );
};


ReportUser.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReportUser;
