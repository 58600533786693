import React from 'react';
import PropTypes from 'prop-types';

import { Button, Logo, Modal } from '../../../../components';
import { APP_BASE_URL, APPLE_STORE_KIDS_URL, GOOGLE_STORE_KIDS_URL } from '../../../../utils/net';


export const InviteModal = ({ ...props }, { t: translate }) => {
  const mail = props.kidEmail || ' ';
  const redirectLink = `${APP_BASE_URL}2homeskids%3Ftoken=${props.loginToken}%26language=${props.parentLanguage}`;
  const subject = translate('2homes kids invitation');
  const mailBody = `${translate('Hi, You\'ve been invited to 2homes kids! Click on one of those links to download the app')}: %0A` +
    `${translate('If you are using iOS (Apple)')} - ${APPLE_STORE_KIDS_URL}, %0A ${translate('If you are using Android')} - ${GOOGLE_STORE_KIDS_URL}. %0A` +
    `${translate('AFTER or if you ALREADY have 2homes Kids app installed on your phone, click on this link to login to your account')}: %0A ${redirectLink}`;

  return (
    <Modal
      dataChanged
      small
      isOpen={props.modalOpened}
      closeModal={() => props.closeModal(false)}
      label="2homesKidsInvitation"
    >
      <div className="inviteModalContent">
        <div>
          <Logo type="kidsWelcome" />
          <h3 className="center m-b-50 f18">{translate('Invitation link copied to clipboard')}</h3>
          <p className="center modal-question w500">{translate('Make sure that your kid has 2homes Kids app installed. You can find them in App Store and Google Play.')}</p>
          <p className="center modal-question w400 m-t-30 f16">{translate('You can now share your link in the app of your choice or use the shortcuts below.')}</p>
        </div>
        <Button
          align="center"
          buttonClassName="m-t-50"
          href={`mailto:${mail}?subject=${subject}&body=${mailBody}`}
          label={translate('Send via email')}
          type="button"
        />
      </div>
    </Modal>
  );
};


InviteModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InviteModal;
