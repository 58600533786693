import { WALKTHROUGH_HIDE, WALKTHROUGH_SHOW, WALKTHROUGH_UPDATE_STEP_INDEX } from './../types';


const updateStepIndex = stepIndex => dispatch => {
  dispatch({ type: WALKTHROUGH_UPDATE_STEP_INDEX, payload: stepIndex });
};

export const showWalkthrough = pathName => dispatch => {
  dispatch({ type: WALKTHROUGH_SHOW, payload: pathName });
};

export const hideWalkthrough = () => dispatch => {
  dispatch({ type: WALKTHROUGH_HIDE });
};

export const changeWalkthroughStep = data => (dispatch, getState) => {
  const { action, lifecycle, status } = data;
  const { walkthrough: { stepIndex } } = getState();

  if(action === 'next' && lifecycle === 'complete') {
    const newIndex = stepIndex + 1;

    dispatch(updateStepIndex(newIndex));
  } else if(action === 'prev' && lifecycle === 'complete') {
    const newIndex = stepIndex - 1;

    dispatch(updateStepIndex(newIndex));
  }

  if(status === 'finished' || action === 'close' || action === 'skip') {
    dispatch(hideWalkthrough());
  }
};
