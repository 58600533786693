import React from 'react';
import PropTypes from 'prop-types';

import Badge from './../Badge';

const SplitRequestsFilters = ({ filterActive, setFilter }, { t: translate }) => {
  return (
    <div className="history-filters center">
      <Badge
        label={translate('all')}
        active={filterActive === 'all'}
        activeColor="bg-darkgrey"
        onClick={() => setFilter('all')}
      />

      <Badge
        label={translate('for me')}
        active={filterActive === 'for-me'}
        activeColor="bg-darkgrey"
        onClick={() => setFilter('for-me')}
        fontColor="#000"
      />

      <Badge
        label={translate('from me')}
        active={filterActive === 'from-me'}
        activeColor="bg-darkgrey"
        onClick={() => setFilter('from-me')}
        fontColor="#000"
      />
    </div>
  );
};


SplitRequestsFilters.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequestsFilters;
