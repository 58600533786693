import React from 'react';
import './Loader.scss';

const Loader = () => (
  <div className="Loader" >
    <div className="LoaderContent">
      <svg className="Circular" viewBox="25 25 50 50">
        <circle
          className="Path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  </div>
);

export default Loader;
