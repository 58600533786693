import React from 'react';
import classnames from 'classnames';

import { setNumberFormat } from './../../utils/tools';


export const SummaryBox = ({ name, value, valueStr, currency, possibleClasses, plusMinusIndicator, decimalPlaces = 2, dotClassName }) => {
  let valueString;
  let classname;
  if(valueStr) {
    valueString = valueStr;
  } else {
    if(value > 0) {
      valueString = `${plusMinusIndicator ? '+' : ''}${setNumberFormat(value, decimalPlaces)}`;
      classname = possibleClasses && possibleClasses.length ? possibleClasses[0] : '';
    } else {
      valueString = setNumberFormat(value, decimalPlaces);
      if(value < 0) {
        classname = possibleClasses && possibleClasses.length ? possibleClasses[1] : '';
      }
    }
  }

  // for now we don't want to show currency
  const currencyStyles = {
    display: 'none',
  };

  return (
    <div className="summary-box">
      <label>{name}
        {dotClassName && (
          <div className={classnames('dot', dotClassName)} />
        )}
      </label>
      <div className={`value ${classname}`}>{valueString}<span className="currency" style={currencyStyles}>{currency}</span></div>
    </div>
  );
};


export default SummaryBox;
