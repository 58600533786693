import moment from 'moment';
import { addMonths, addYears, addWeeks, isBefore, isSameDay } from 'date-fns';


export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE_FORMAT_FIRST_DAY = 'YYYY-MM-01';
const DEFAULT_TIME_FORMAT = 'HH:mm:SS';
const DEFAULT_DATETIME_START_FORMAT = `${DEFAULT_DATE_FORMAT}T00:00:00`;
const DEFAULT_DATETIME_END_FORMAT = `${DEFAULT_DATE_FORMAT}T23:59:59`;


export const DEFAULT_DISPLAYED_DATE_FORMAT = 'D MMM YY';
export const DEFAULT_DISPLAYED_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DISPLAYED_DATETIME_FORMAT = 'D MMM YY, HH:mm';
export const DEFAULT_DATEPICKER_DATE_FORMAT = 'D.MMM.yy';
export const DISABLED_DATEPICKER_DATE_FORMAT = 'DD MMM YY';
export const DEFAULT_BIRTHDATE_FORMAT = 'd-MM-yyyy';

export const DATEPICKER_DISPLAYED_DATE_FORMAT = 'd MMM YY';

export const getFormattedDate = (dateISO, begginingOfMonth) => {
  return moment(dateISO, 'YYYY-MM-DD HH:mm:ss ZZ').format(begginingOfMonth ? DEFAULT_DATE_FORMAT_FIRST_DAY : DEFAULT_DATE_FORMAT);
};


export const getFormattedTime = dateISO => {
  return getLocalFormatDate(dateISO, DEFAULT_TIME_FORMAT);
};


export const formatFinancialUrlDate = date => {
  return moment(date).format('MM-YYYY');
};


export const getDateRanges = (date, type = 'day') => {
  const startDate = getUTCFormatDate(date);
  const ranges = {
    start_date: '',
    end_date: '',
  };
  const dt = new Date(startDate);
  if(type === 'week') {
    const firstDay = dt.getDate() - dt.getDay();

    ranges.start_date = moment(moment(new Date(dt.setDate(firstDay))).format(DEFAULT_DATETIME_START_FORMAT)).utc();
    ranges.end_date = moment(ranges.start_date).add(9, 'days').utc();
  } else if(type === 'month') {
    const firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    const lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);

    ranges.start_date = moment(firstDay).format(DEFAULT_DATETIME_START_FORMAT);
    ranges.end_date = moment(lastDay).format(DEFAULT_DATETIME_END_FORMAT);
  } else {
    ranges.start_date = moment(moment(dt).format(DEFAULT_DATETIME_START_FORMAT)).utc();
    ranges.end_date = moment(moment(dt).format(DEFAULT_DATETIME_END_FORMAT)).utc();
  }
  return ranges;
};


export const getDaysList = (startDate, numberOfDays) => {
  const daysList = [];
  for(let i = 0; i < numberOfDays; i++) {
    const day = moment(startDate).add(i, 'days').format(DEFAULT_DATE_FORMAT);
    daysList.push(day);
  }
  return daysList;
};


export const getPrevious = (startDate, type = 'day') => {
  const dt = new Date(startDate);
  if(type === 'week') {
    const firstDay = dt.getDate() - dt.getDay() - 6;
    dt.setDate(firstDay);
  } else if(type === 'month') {
    if(dt.getMonth) {
      dt.setMonth(dt.getMonth() - 1);
    } else {
      dt.setYear(dt.getYear() - 1);
      dt.setMonth(12);
    }
  } else {
    dt.setDate(dt.getDate() - 1);
  }
  return getDateRanges(dt, type);
};


export const getNext = (startDate, type = 'day') => {
  const dt = new Date(startDate);
  if(type === 'week') {
    const firstDay = dt.getDate() - dt.getDay() + 8;
    dt.setDate(firstDay);
  } else if(type === 'month') {
    if(dt.getMonth) {
      dt.setMonth(dt.getMonth() + 1);
    } else {
      dt.setYear(dt.getYear() + 1);
      dt.setMonth(1);
    }
  } else {
    dt.setDate(dt.getDate() + 1);
  }
  return getDateRanges(dt, type);
};


export const isAllDayEvent = (event, calendar) => {
  const startOfTheDay = moment(calendar.calendarDate).startOf('day');
  const endOfTheDay = moment(calendar.calendarDate).endOf('day');
  if(
    moment(calendar.calendarDate).isBetween(`${event.start_date} ${event.start_time}`, `${event.end_date} ${event.end_time}`, 'minute', '[]') &&
    moment(startOfTheDay).isSameOrAfter(`${event.start_date} ${event.start_time}`) &&
    moment(endOfTheDay).isSameOrBefore(`${event.end_date} ${event.end_time}`)
  ) {
    return true;
  }
  return false;
};

export const isEventInStartDayPart = (event, calendar) => {
  const startOfTheDay = moment(calendar.calendarDate).startOf('day');
  if(moment(startOfTheDay).isSameOrAfter(`${event.start_date} ${event.start_time}`)) {
    return true;
  }
  return false;
};

export const isEventInEndDayPart = (event, calendar) => {
  const endOfTheDay = moment(calendar.calendarDate).endOf('day');
  if(moment(endOfTheDay).isSameOrBefore(`${event.end_date} ${event.end_time}`)) {
    return true;
  }
  return false;
};

export const getDatesList = (startDate, endDate, type, number) => {
  const dates = [];
  let start = moment(startDate);
  const end = moment(endDate);

  dates.push(start.format('YYYY-MM-DD'));
  if(start && end) {
    while(moment(start).add(number, type).isSameOrBefore(end)) {
      start = moment(start).add(number, type).format('YYYY-MM-DD');
      dates.push(start);
    }
  }
  return dates;
};

export const sortByDate = (list, direction, param) => {
  const sorted = list.sort((a, b) => {
    if(param) {
      return a[param] < b[param] ? -1 : a[param] > b[param] ? 1 : 0;
    }
    if(a.date === b.date && !param) {
      return a.id < b.id ? -1 : 1;
    }
    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
  });
  if(direction === 'desc') {
    return sorted.reverse();
  }
  return sorted;
};

export const generateValidDates = (interval, transactionDate) => {
  let today = new Date(transactionDate);
  const maxDate = addYears(new Date(), 1);
  const amount = interval === 'biweekly' ? 2 : 1;
  const isMonthInterval = interval === 'monthly';
  const dates = [];

  while(isBefore(today, maxDate) || isSameDay(today, maxDate)) {
    dates.push(new Date(today));

    today = isMonthInterval ? addMonths(new Date(today), amount) : addWeeks(new Date(today), amount);
  }

  return dates;
};

export const getUTCFormatDate = (date, format) => {
  const formattedDate = moment(date).format();

  return moment.utc(date.length && date.length > 10 ? formattedDate : date).format(format || '');
};

export const getLocalFormatDate = (date, format) => {
  return moment(date).local().format(format || '');
};
