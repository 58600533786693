import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from '../../../../components';


export const DocumentsShareModal = ({ ...props }, { t: translate }) => {
  const copyLink = () => {
    const fileLink = document.getElementById('file-link');
    fileLink.disabled = false;
    fileLink.select();
    document.execCommand('copy');

    const copyButton = document.getElementById('copy-button');

    if(copyButton) {
      copyButton.innerHTML = translate('Copied');
      copyButton.disabled = true;
    }

    window.addEventListener('blur', resetButton);
  };

  const resetButton = () => {
    const fileLink = document.getElementById('file-link');
    const copyButton = document.getElementById('copy-button');

    if(copyButton) {
      copyButton.innerHTML = translate('Copy link');
      copyButton.disabled = false;
    }

    if(fileLink) fileLink.disabled = true;
  };

  const handleCloseModal = () => {
    window.removeEventListener('blur', resetButton);
    props.closeModal();
  };

  return (
    <Modal
      small
      isOpen={props.modalShareOpened}
      label="Documents Share"
      closeModal={handleCloseModal}
    >
      <div className="documents-modal">
        {translate('Copy the link to share the file')}

        <div className="bg-lightgrey m-t-30 m-b-20 f14 nowrap p-v-10 p-h-10 text-overflow">
          <input disabled type="text" id="file-link" value={props.fileLink} className="bg-lightgrey f14 fullwidth" />
        </div>

        <div className="pull-right">
          <Button id="copy-button" type="button" label={translate('Copy link')} onClick={copyLink} />
        </div>

        <div className="pull-left m-t-30">
          <span className="block f14">{translate('You can send the copied link to anyone.')}</span>
          <span className="block f14">{translate('Every person with the link can download the file.')}</span>
        </div>
      </div>
    </Modal>
  );
};


DocumentsShareModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DocumentsShareModal;
