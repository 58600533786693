import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import useStore from 'src/hooks/useStore';

import InfoModal from './InfoModal';


const PermissionsInfo = ({ name }, { t: translate }) => {
  const [state] = useStore();
  const { modal = {}, user } = state;
  const { push } = useHistory();
  const amount = `${user?.subscription_plan?.currency} ${user?.subscription_plan?.price}`;

  const invitee = modal.data?.invitee === 'parent' ? 'caretaker' : modal.data?.invitee;

  return (
    <InfoModal
      buttonLabel={translate('Subscribe')}
      imgType="ups"
      name={name}
      onClose={() => push('/subscription')}
    >
      <h2 className="Title">{translate('You don\'t have permissions to invite {invitee}', { invitee })}</h2>

      <h3 className="InfoText">
        {translate('To access all the features of 2homes you have need a subscription. It costs only {amount},- per month.', { amount })}
      </h3>
    </InfoModal>
  );
};


PermissionsInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PermissionsInfo;
