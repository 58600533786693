import React from 'react';
import PropTypes from 'prop-types';

import { SplitRequestsFilters } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';

import SplitRequestsModal from './modal/SplitRequestsModal';
import SplitRequestsSummary from './SplitRequestsSummary';
import SplitRequestsTable from './SplitRequestsTable';


export const SplitRequests = ({ user, loading, layout, splitRequests, caretakers, ...props }, { t: translate }) => {
  const filteredHistoricalData = splitRequests && splitRequests.transactions && splitRequests.transactions.history.filter(transaction => {
    if(props.filterActive === 'for-me') {
      return transaction.for_me === true;
    } else if(props.filterActive === 'from-me') {
      return transaction.for_me !== true;
    }
    return transaction;
  });

  return (
    <SignedInLayout
      page="Accounting"
      currentPath="/split-requests"
      user={user}
      loading={loading}
      layout={layout}
    >

      <SplitRequestsModal
        homeUsers={props.homeUsers}
        user={user}
        modalOpened={props.modalOpened}
        handleFormChange={props.handleFormChange}
        closeModal={props.closeModal}
        editFormData={props.editFormData}
        saveSplit={props.saveSplit}
        error={props.error}
      />

      <div className="content-header content-summaries summaries-fixed">
        <SplitRequestsSummary summaryData={splitRequests} />
      </div>

      <div className="section-title center m-t-140 m-b-20">
        <span>{translate('For me')}</span>
      </div>
      <div className="finances-history" style={{ minWidth: 1200 }}>
        <SplitRequestsTable
          editable
          type="for-me"
          splitRequestsData={splitRequests.transactions && splitRequests.transactions.for_me}
          homeUsers={props.homeUsers}
          user={user}
          caretakers={caretakers}
          editSplit={props.editSplit}
          isWalkthroughOpen={props.isWalkthroughOpen}
        />
      </div>

      <div className="section-title center m-b-20">
        <span>{translate('From me')}</span>
      </div>
      <div className="finances-history from-me" style={{ minWidth: 1200 }}>
        <SplitRequestsTable
          editable
          type="from-me"
          splitRequestsData={splitRequests.transactions && splitRequests.transactions.from_me}
          homeUsers={props.homeUsers}
          user={user}
          caretakers={caretakers}
          editSplit={props.editSplit}
          isWalkthroughOpen={props.isWalkthroughOpen}
        />
      </div>

      <div className="section-title center">
        <span>{translate('History of')}</span>
      </div>

      <div className="horizontal-center-content">
        <SplitRequestsFilters
          filterActive={props.filterActive}
          setFilter={props.setHistoryFilter}
        />
      </div>

      <div className="finances-history history" style={{ minWidth: 1200 }}>
        <SplitRequestsTable
          type="history"
          splitRequestsData={filteredHistoricalData}
          homeUsers={props.homeUsers}
          user={user}
          caretakers={caretakers}
          filterActive={props.filterActive}
          setHistoryFilter={props.setHistoryFilter}
          editSplit={props.editSplit}
          isWalkthroughOpen={props.isWalkthroughOpen}
        />
      </div>

    </SignedInLayout>
  );
};


SplitRequests.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SplitRequests;
