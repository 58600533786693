import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import Logo from '../Logo';
import Button from '../Button';

import './_AccessDenied.scss';

const AccessDenied = (props, { t: translate }) => {
  const { replace } = useHistory();

  const redirectToHome = () => {
    replace('/home');
  };

  return (
    <div className="access-denied-container vertical-center-content horizontal-center-content flex-column">
      <Logo alt="2homes" type="ups" />
      <p className="text title">
        {translate('Access denied')}
      </p>
      <p className="text description">
        {translate("You don't have permissions to view this page")}
      </p>
      <Button
        className="redirect-home-container m-t-20"
        buttonClassName="redirect-home"
        label={translate('Return back home')}
        onClick={redirectToHome}
      />
    </div>
  );
};

AccessDenied.contextTypes = {
  t: PropTypes.func.isRequired,
};


export default AccessDenied;
