import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { da, enGB as en, nb as nn, sv } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';


export const CalendarDatePicker = ({ calendar, setCalendarDate, contentWidth, user }, { t: translate }) => {
  const calendarDateMoment = moment(calendar.calendarDate);
  const locales = {
    da,
    en,
    nn,
    sv,
  };
  const localeDateFns = locales[user.language] || en;

  const datePickerCalendarWidth = 330;
  const monthsToShow = parseInt(contentWidth / datePickerCalendarWidth, 10);

  return (
    <div className="datepicker-container">
      <DatePicker
        inline
        showWeekNumbers
        monthsShown={monthsToShow}
        onChange={date => setCalendarDate(date)}
        selected={new Date(calendarDateMoment)}
        locale={localeDateFns}
        weekLabel={translate('week')}
      />
    </div>
  );
};

CalendarDatePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CalendarDatePicker;
