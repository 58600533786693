import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Loading } from '../components';


const ProtectedRoute = Component => {
  const Route = props => {
    const authenticated = useSelector(({ authenticated }) => authenticated);
    const { replace } = useHistory();

    useEffect(() => {
      checkAuth();
    }, [authenticated]);

    const checkAuth = () => {
      if(!authenticated) {
        replace(`/sign-in${window.location.pathname ? `?next=${encodeURIComponent(window.location.pathname + window.location.search)}` : ''}`);
      }
    };

    return authenticated ? <Component {...props} /> : <Loading show />;
  };
  return Route;
};

export default ProtectedRoute;
