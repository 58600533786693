import React from 'react';
import PropTypes from 'prop-types';

import { BudgetTableRow } from './BudgetTableRow';
import { isEdge } from './../../../utils/browser';

export const BudgetTable = ({ budgetData, filterActive, homeUsers, ...props }, { t: translate }) => {
  return (
    <div className={`table ${isEdge ? 'edge' : ''}`}>
      <div className="thead">
        <div className="trow">
          <div className="tcell medium right">{translate('Date')}</div>
          <div className="tcell right p-r-15">{translate('Amount')}</div>
          <div className="tcell">{translate('By')}</div>
          <div className="tcell">{translate('Category')}</div>
          <div className="tcell">{translate('For')}</div>
          <div className="tcell center">{translate('Visibility')}</div>
          <div className="tcell">{translate('Notes')}</div>
          <div className="tcell" />
        </div>
      </div>
      <div className="tbody">
        {budgetData && budgetData.map((row, idx) => {
          return (<BudgetTableRow key={idx} row={row} filterActive={filterActive} homeUsers={homeUsers} {...props} />);
        })}
      </div>
    </div>
  );
};


BudgetTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BudgetTable;
