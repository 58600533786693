import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';

import { Walkthrough } from '../../../components';
import { deleteBudgetData, fetchBudgetData, saveBudgetData, setFilterDate } from '../../../store/actions/budget';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { fetchTagKeysIfNeeded } from '../../../store/actions/tags';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { DEFAULT_DATE_FORMAT } from '../../../utils/date';
import { showInitialWalkthrough } from '../../../utils/walkthrough';
import Budget from '../components/Budget';


const DEFAULT_FORM = {
  id: null,
  access: 'private',
  type: 'expense',
  tag_key: '',
  total_amount: '',
  repeatable: false,
  repeatable_type: 'current',
  interval: 'weekly',
  start_date: moment().format(DEFAULT_DATE_FORMAT),
  end_date: moment().format(DEFAULT_DATE_FORMAT),
  note: '',
  user_ids: [],
  transaction_date: moment(),
};

class BudgetContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateSelectorOpened: false,
      filterActive: 'all',
      modalOpened: false,
      confirmModalOpened: false,
      recordToDelete: {},
      editFormData: Object.assign({}, DEFAULT_FORM),
      date: new Date(),
      errorInDateTime: '',
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const date = urlParams.get('date');

    this.props.fetchTagKeysIfNeeded();
    if(date && date !== '') {
      const dateParams = date.split('-');
      this.setDate(dateParams[1], dateParams[0], true);
    } else {
      this.fetchData();
    }

    showInitialWalkthrough('budget', this.props.showWalkthrough);
  }

  componentWillUnmount() {
    this.props.cleanError('budget');
  }

  fetchData = date => {
    const dateMonth = date || this.state.date;
    return this.props.fetchBudgetData(dateMonth, this.filterActive);
  }

  setDate = (year, month, fetch) => {
    const date = new Date(`${year}-${month}-01`);
    this.props.setFilterDate(date);
    this.setState({
      date,
    });
    if(fetch) {
      this.fetchData(date);
    }
  }

  setFilter = filter => {
    this.setState({
      filterActive: filter,
    });
  }

  handleFormChange = (name, forcedValue, flat) => {
    const { editFormData } = this.state;

    if(flat) {
      editFormData[name] = forcedValue;

      if(name === 'transaction_date') {
        const startIsAfter = moment(forcedValue).isAfter(editFormData.end_date, 'day');

        if(startIsAfter) editFormData.end_date = forcedValue;
      }

      this.setState({ editFormData });
    } else {
      return event => {
        editFormData[name] = forcedValue || event.target.value;
        this.setState({ editFormData });
      };
    }
  }

  handleRepeatableTypeChange = (name, value) => {
    const { recordToDelete } = this.state;

    this.setState({ recordToDelete: { ...recordToDelete, repeatable_type: value } });
  }

  editData = data => {
    const { editFormData } = this.state;
    _.extend(editFormData, data, {
      user_ids: [data.user_id],
      transaction_date: data.date,
    });
    this.setState({
      editFormData,
    }, () => {
      this.showModal(false);
    });
  }

  showModal = confirm => {
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: true,
    });
  }

  closeModal = confirm => {
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: false,
      editFormData: Object.assign({}, DEFAULT_FORM, {
        user_ids: new Array(),
      }),
    });
  }

  setRecordToDelete = data => {
    this.setState({
      recordToDelete: { ...data, repeatable_type: 'current' },
    }, () => {
      this.showModal(true);
    });
  }

  deleteBudgetData = e => {
    e.preventDefault();
    const { recordToDelete, date } = this.state;
    this.props.deleteBudgetData(recordToDelete, date).then(() => {
      this.closeModal(true);
    });
  }

  validateForm = () => {
    const { eventFormData, errorInDateTime } = this.state;
    if(eventFormData.title && eventFormData.title.trim() === '') {
      return false;
    }
    if(errorInDateTime !== '' || _.isEmpty(eventFormData.total_amount)) {
      return false;
    }
    return true;
  }

  saveBudget = e => {
    e.preventDefault();
    this.props.cleanError('budget');

    const { editFormData, date } = this.state;
    const method = editFormData.id ? 'update' : 'add';

    this.props.saveBudgetData(editFormData, date, method)
      .then(() => {
        if(!this.props.error || this.props.error === null) {
          this.props.fetchTagKeysIfNeeded(editFormData.tag_key, editFormData.type);
          this.setState({
            editFormData: Object.assign({}, _.cloneDeep(DEFAULT_FORM), {
              user_ids: new Array(),
            }),
          }, () => {
            this.closeModal();
          });
        }
        this.fetchData(date);
      });
  }

  render() {
    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          stepsPlacement={{ 4: 'top-start' }}
        />

        <Budget
          {...this.props}
          {...this.state}
          setDate={this.setDate}
          handleFormChange={this.handleFormChange}
          setFilter={this.setFilter}
          editData={this.editData}
          showModal={this.showModal}
          closeModal={this.closeModal}
          setRecordToDelete={this.setRecordToDelete}
          deleteBudgetData={this.deleteBudgetData}
          validateForm={this.validateForm}
          saveBudget={this.saveBudget}
          handleRepeatableTypeChange={this.handleRepeatableTypeChange}
        />
      </div>
    );
  }
}


BudgetContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setFilterDate,
  fetchBudgetData,
  saveBudgetData,
  deleteBudgetData,
  fetchHomesIfNeeded,
  fetchTagKeysIfNeeded,
  cleanError,
  showWalkthrough,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
    loading: state.loading,
    budget: state.budget,
    homeUsers: state.homeUsers,
    error: state.errors.budget,
    isWalkthroughOpen: state.walkthrough.isOpen,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BudgetContainer);
