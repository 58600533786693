import * as _ from 'lodash';

import {
  SET_FINANCIAL_FILTER_DATE,
  SET_FINANCIAL_ACTIVE_DATE,
  SET_FINANCIAL_DATA_SUCCESS,
  SAVE_FINANCIAL_PENDING_FILE_SUCCESS,
  SAVE_FINANCIAL_DATA_SUCCESS,
  DELETE_FINANCIAL_DATA_SUCCESS,
  SET_SPLIT_REQUEST_SUCCESS,
  CONFIRM_SPLIT_REQUEST_SUCCESS,
  SET_SPLIT_REQUESTS_SUCCESS,
  CHANGE_SPLIT_REQUESTS_SUCCESS,
  ADD_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_SUCCESS,
} from './../types';


const DEFAULT_STATE = {
  filterDate: new Date(), // date used for showing in filter
  activeDate: new Date(), // date used for fetching
  pendingData: [],
  historyData: [],
  summaryData: {
    monthly_expenses: 0,
    monthly_income: 0,
    monthly_balance: 0,
    monthly_split_requests: 0,
  },
};


export function financeReducer(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case SET_FINANCIAL_FILTER_DATE:
      return Object.assign({}, state, {
        filterDate: action.payload,
      });

    case SET_FINANCIAL_ACTIVE_DATE:
      return Object.assign({}, state, {
        activeDate: action.payload,
      });

    case SET_FINANCIAL_DATA_SUCCESS:
      const pendingTransactions = _.orderBy(action.payload.monthly_pending_transactions, [ 'date', 'created_at' ], [ 'desc', 'desc' ]);
      const monthlyTransactions = _.orderBy(action.payload.monthly_transactions, [ 'date', 'created_at' ], [ 'desc', 'desc' ]);

      const newState = {
        pendingData: pendingTransactions,
        historyData: monthlyTransactions,
        summaryData: _.omit(action.payload, ['monthly_pending_transactions', 'monthly_transactions']),
      };
      return Object.assign({}, state, {
        ...newState,
      });

    case SAVE_FINANCIAL_PENDING_FILE_SUCCESS:
      const { pendingData } = state;
      pendingData.push(action.payload);
      return Object.assign({}, state, {
        pendingData,
      });

    case SAVE_FINANCIAL_DATA_SUCCESS:
      const ids = [];
      let objectsToAdd = action.payload;
      const reversed = state.historyData.reverse();
      reversed.forEach((data, idx) => {
        objectsToAdd.forEach(element => {
          if(data.id === element.id) {
            reversed[idx] = element;
            objectsToAdd = _.drop(objectsToAdd, 1);
            ids.push(element.id);
          }
        });
      });

      objectsToAdd.forEach(data => {
        reversed.push(data);
      });

      const newPending = [];
      state.pendingData.map(data => {
        if(ids.indexOf(data.id) === 0) {
          newPending.push(data);
        }
      });

      return Object.assign({}, state, {
        historyData: _.orderBy(reversed, [ 'date', 'created_at' ], [ 'desc', 'desc' ]),
        pendingData: newPending,
      });

    case ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        historyData: state.historyData.map(data => {
          if(data.id === action.payload.transactionId) {
            return {
              ...data,
              file: action.payload.file.file,
              filename: action.payload.file.filename,
              filetype: action.payload.file.filetype,
              attachmentChanged: true,
            };
          }
          return {
            ...data,
            attachmentChanged: false,
          };
        }),
      };

    case REMOVE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        historyData: state.historyData.map(data => {
          if(data.id === action.payload.transactionId) {
            return {
              ...data,
              file: null,
              filename: null,
              filetype: null,
              attachmentChanged: true,
            };
          }
          return {
            ...data,
            attachmentChanged: false,
          };
        }),
      };

    case DELETE_FINANCIAL_DATA_SUCCESS:
      const newPendingData = [];
      state.pendingData.map(file => {
        if(file.id !== action.payload.id) {
          newPendingData.push(file);
        }
      });

      const newHistoryData = [];
      state.historyData.map(file => {
        if(file.id !== action.payload.id) {
          newHistoryData.push(file);
        }
      });
      return Object.assign({}, state, {
        pendingData: newPendingData,
        historyData: newHistoryData,
      });

    default:
      return state;
  }
}


const historicalStatuses = ['paid'];

const prepareTransactions = transactions => {
  return {
    for_me: transactions.filter(transaction => transaction.for_me === true && historicalStatuses.indexOf(transaction.status) === -1).sort((a, b) => a.date < b.date ? 1 : -1),
    from_me: transactions.filter(transaction => transaction.for_me !== true && historicalStatuses.indexOf(transaction.status) === -1).sort((a, b) => a.date < b.date ? 1 : -1),
    history: transactions.filter(transaction => historicalStatuses.indexOf(transaction.status) > -1).sort((a, b) => a.date < b.date ? 1 : -1),
  };
};

const getPendingTransactions = (transactions, forMe) => {
  const pendingTransactions = transactions.filter(transaction => transaction.status === 'pending' && transaction.for_me === forMe);
  const sum = pendingTransactions.reduce((total, transaction) => total + parseFloat(transaction.split_amount), 0);
  return sum;
};


export function splitRequestsReducer(state = {}, action) {
  switch(action.type) {
    case SET_SPLIT_REQUESTS_SUCCESS:
      const splits = {
        for_me: { ...action.payload.for_me, pending: getPendingTransactions(action.payload.transactions, true) },
        from_me: { ...action.payload.from_me, pending: getPendingTransactions(action.payload.transactions, false) },
        transactions: prepareTransactions(action.payload.transactions),
      };
      return Object.assign({}, state, splits);

    case CHANGE_SPLIT_REQUESTS_SUCCESS:
      const transactionsList = _.concat(state.transactions.for_me, state.transactions.from_me, state.transactions.history);
      transactionsList.map(transaction => {
        transaction.status = transaction.id === action.payload.id ? action.payload.status : transaction.status;
        return transaction;
      });
      return Object.assign({}, state, {
        transactions: prepareTransactions(transactionsList),
      });

    default:
      return state;
  }
}


export function splitRequestReducer(state = {}, action) {
  switch(action.type) {
    case SET_SPLIT_REQUEST_SUCCESS:
      return {
        ...action.payload,
      };

    case CONFIRM_SPLIT_REQUEST_SUCCESS:
      return {};

    default:
      return state;
  }
}
