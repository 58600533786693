import * as _ from 'lodash';
import moment from 'moment';

import { sortByDate } from '../../utils/date';

import {
  SET_BUDGET_FILTER_DATE,
  SET_BUDGET_ACTIVE_DATE,
  SET_BUDGET_DATA_SUCCESS,
  SAVE_BUDGET_DATA_SUCCESS,
  DELETE_BUDGET_DATA_SUCCESS,
} from './../types';


const DEFAULT_STATE = {
  filterDate: new Date(), // date used for showing in filter
  activeDate: new Date(), // date used for fetching
  budgetData: [],
  summaryData: {
    monthly_expenses: 0,
    monthly_income: 0,
    monthly_balance: 0,
    monthly_split_requests: 0,
  },
};


export function budgetReducer(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case SET_BUDGET_FILTER_DATE:
      return Object.assign({}, state, {
        filterDate: action.payload,
      });

    case SET_BUDGET_ACTIVE_DATE:
      return Object.assign({}, state, {
        activeDate: action.payload,
      });

    case SET_BUDGET_DATA_SUCCESS:
      const fixedBudgetData = [];
      action.payload.monthly_transactions.forEach(transaction => {
        transaction.type = transaction.type.replace(/scheduled_/g, '');
        fixedBudgetData.push(transaction);
      });
      return Object.assign({}, state, {
        budgetData: sortByDate(fixedBudgetData, 'desc'),
        summaryData: _.omit(action.payload, ['monthly_transactions']),
      });

    case SAVE_BUDGET_DATA_SUCCESS:
      if(action.payload.length) {
        action.payload.forEach(ap => {
          ap.type = ap.type.replace(/scheduled_/g, '');
        });

        const listToAdd = [];
        action.payload.forEach(element => {
          if(moment(state.activeDate).format('YYYY-MM') === moment(element.date).format('YYYY-MM')) {
            listToAdd.push(element);
          }
        });
        return Object.assign({}, state, {
          budgetData: _.union(listToAdd, state.budgetData),
        });
      }
      const newData = [];
      state.budgetData.map(data => {
        if(data.id === action.payload.id) {
          action.payload.type = action.payload.type.replace(/scheduled_/g, '');
          newData.push(action.payload);
        } else {
          newData.push(data);
        }
      });
      return Object.assign({}, state, {
        budgetData: newData,
      });

    case DELETE_BUDGET_DATA_SUCCESS:
      const newBudgetData = [];
      state.budgetData.map(file => {
        if(file.id !== action.payload.id) {
          newBudgetData.push(file);
        }
      });
      return Object.assign({}, state, {
        budgetData: newBudgetData,
      });

    default:
      return state;
  }
}
