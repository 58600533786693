import React from 'react';
import PropTypes from 'prop-types';

import Badge from './../Badge';
import Form from './../Form';


export const RepeatableEdit = ({ editFormData, handleEventFormChange, label, type = 'calendar' }, { t: translate }) => {
  const filedName = type === 'budget' ? 'repeatable_type' : 'repeatable_action';
  const options = [
    { label: translate('only current'), value: 'current' },
    { label: translate('only upcoming'), value: 'upcoming' },
    { label: translate('all'), value: 'all' },
  ];

  return (
    <Form.Row>
      <div className="repeatable m-b-20">
        <span className="inline-block">{label || translate('Edit recurring event')}:</span>
      </div>
      <div className="repeatable">
        {options.map(option => (
          <Badge
            key={option.value}
            label={translate(option.label)}
            activeColor="bg-darkgrey"
            active={!editFormData[filedName] || editFormData[filedName] === option.value}
            onClick={() => handleEventFormChange(filedName, option.value, true)}
          />
        ))}
      </div>
    </Form.Row>
  );
};


RepeatableEdit.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RepeatableEdit;
