import {
  SET_TAG_KEYS_SUCCESS,
} from './../types';


const sortTags = (a, b) => {
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};


const DEFAULT_STATE = {
  income: [],
  expense: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case SET_TAG_KEYS_SUCCESS:
      const sortedIncomes = action.payload.tag_keys.income.sort(sortTags);
      const sortedExpenses = action.payload.tag_keys.expense.sort(sortTags);
      return Object.assign({}, state, {
        income: sortedIncomes,
        expense: sortedExpenses,
      });

    default:
      return state;
  }
};
