import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { AvatarPlaceholder, Button } from '../../../components';
import { prepareFileUrl } from '../../../utils/file';


const ButtonizedAvatar = ({ handleOnClick, user }) => {
  if(user) {
    const imageSrc = prepareFileUrl(user.thumbnail_url) || prepareFileUrl(user.file_url);

    return (user.thumbnail_url || user.file_url) ? (
      <img src={imageSrc} alt="" style={{ borderColor: user.color }} title={user.nickname} />
    ) : (
      <AvatarPlaceholder user={user} />
    );
  }
  return (
    <Button
      type="button"
      buttonClassName="circle"
      label="+"
      onClick={handleOnClick}
    />
  );
};

export const Relationship = ({ user, home, homeUsers, homeType, redirectToForm }, { t: translate }) => {
  const secondParent = home.secondParent && homeUsers[home.secondParent];

  const handleOnClick = invitee => {
    redirectToForm({ home, user, homeType, invitee });
  };

  return (
    <div className="FamiliesMembers">
      <div className="FamiliesMembersParents">
        <div className="avatar" title={`@${user.nickname}`} style={{ borderColor: user.color }}>
          {user.thumbnail_url ? (
            <img src={prepareFileUrl(user.thumbnail_url)} alt="" />
          ) : (
            <AvatarPlaceholder user={homeUsers[user.id]} />
          )}
        </div>

        <div
          className={classnames('avatar', { 'no-border': !secondParent || !secondParent.thumbnail_url })}
          title={secondParent && secondParent.nickname && `@${secondParent.nickname}`}
          style={{ borderColor: secondParent && secondParent.color }}
        >
          <ButtonizedAvatar
            handleOnClick={() => handleOnClick('parent')}
            user={homeUsers[home.secondParent]}
          />
        </div>
      </div>

      <div className="FamiliesMembersKids">
        {home.kids && home.kids.map(kid => {
          return (
            <div
              key={kid}
              className="avatar"
              style={{ borderColor: homeUsers[kid].color }}
              title={homeUsers[kid] && `@${homeUsers[kid].nickname}`}
            >
              <ButtonizedAvatar
                handleOnClick={() => handleOnClick('kid')}
                user={homeUsers[kid]}
              />
            </div>
          );
        })}

        <div className="avatar no-border" title={translate('Add new kid')}>
          <ButtonizedAvatar handleOnClick={() => handleOnClick('kid')} />
        </div>
      </div>
    </div>
  );
};


Relationship.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Relationship;
