import { api, BASE_URL } from '../../utils/net';

import {
  FETCH_NEW_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS_READ_SUCCESS,
  CLEAR_NEW_NOTIFICATIONS,
} from './../types';
import { showLoader, hideLoader } from './loading';
import { setError } from './errors';


export function fetchNewNotificationsSuccess(data) {
  return {
    type: FETCH_NEW_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
}

export function fetchNotificationsSuccess(payload) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

export function setNotificationsReadSuccess(data) {
  return {
    type: SET_NOTIFICATIONS_READ_SUCCESS,
    payload: data,
  };
}


export const fetchNewNotifications = () => async dispatch => {
  dispatch(showLoader());
  try {
    const { data } = await api.get(`${BASE_URL}notifications_counter`);
    dispatch(fetchNewNotificationsSuccess(data));
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('notifications', message));
  } finally {
    dispatch(hideLoader());
  }
};

export const fetchNotifications = (page, perPage, reCall = false) => async dispatch => {
  dispatch(showLoader());

  try {
    const { data } = await api.get(`${BASE_URL}notifications`, {
      page,
      per_page: perPage,
    });
    dispatch(fetchNotificationsSuccess({ data, page }));
  } catch(error) {
    if(error.response && error.response.status === 401 && !reCall) {
      api.reAuthorizeUser().then(() => {
        dispatch(fetchNotifications(page, perPage, true));
      });
    } else {
      const message = api.getRequestErrorMessage(error);
      dispatch(setError('notifications', message));
    }
  } finally {
    dispatch(hideLoader());
  }
};


export const setNotificationsRead = notifications_ids => async dispatch => {
  try {
    const { data } = await api.patch(`${BASE_URL}notifications`, {
      notifications_ids,
      status: 'read',
    });
    dispatch(setNotificationsReadSuccess(data));
    dispatch(fetchNewNotifications());
  } catch(error) {
    const message = api.getRequestErrorMessage(error);
    dispatch(setError('notifications', message));
  }
};

export const clearNewNotifications = () => dispatch => {
  dispatch({ type: CLEAR_NEW_NOTIFICATIONS });
};
