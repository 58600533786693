import React, { useEffect, useState } from 'react';
import {
  CardNumberElement, CardCvcElement, CardExpiryElement,
  useStripe, useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { BonusCode, Button, Form, Notification } from 'src/components';
import useStore from 'src/hooks/useStore';
import { cleanError, setError } from 'src/store/actions/errors';
import {
  createSubscription,
  createSubscriptionCustomer,
  updateCreditCardData,
} from 'src/store/actions/subscription';
import { PERMISSION } from 'src/utils/permissions';


const subscriptionPrices = {
  US: '9.99 USD',
  NO: '86.00 NOK',
  DK: '70.00 DKK',
  SE: '95.00 SEK',
  AU: '15.00 AUD',
  CA: '13.50 CAD',
  GB: '8.00 GBP',
  OTHER: '9.99 USD',
};

const cardElementOptions = {
  style: {
    base: {
      fontFamily: 'Quicksand',
    },
  },
};

const CheckoutForm = (props, { t: translate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isPending, setIsPending] = useState(false);
  const [bonusCode, setBonusCode] = useState('');
  const [state, dispatch] = useStore();
  const { push } = useHistory();


  const urlParams = new URLSearchParams(location.search);
  const mode = urlParams.get('mode');
  const isInEditMode = mode === 'edit';

  const { errors, loading, user } = state;
  const error = errors.subscription;
  const defaultSubscriptionPrice = subscriptionPrices[user.country_code];
  const amount = user.subscription_plan ? `${user.subscription_plan.currency} ${user.subscription_plan.price}` : defaultSubscriptionPrice;

  const customerSecret = user.subscription?.customerSecret;
  const isButtonDisabled = !stripe || !customerSecret || isPending || loading;

  const hasInitialPermission = user.permission === PERMISSION.INITIAL;

  useEffect(() => {
    dispatch(createSubscriptionCustomer());
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsPending(true);

    const { setupIntent, error: confirmationError } = await stripe.confirmCardSetup(customerSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement, CardCvcElement, CardExpiryElement),
      },
    });

    if(confirmationError) {
      const errorMessage = translate('Something went wrong. Check the card details and try again.');
      dispatch(setError('subscription', confirmationError.message || errorMessage));
    } else {
      if(isInEditMode) {
        dispatch(updateCreditCardData(setupIntent.payment_method));
      } else {
        dispatch(createSubscription(setupIntent.payment_method, bonusCode, hasInitialPermission));
      }
    }

    setIsPending(false);
  };

  const renderDescription = () => {
    const title = hasInitialPermission ?
      translate('Please enter card details') :
      isInEditMode ?
        translate('Update credit card') :
        translate('Register credit card');

    const infoText = hasInitialPermission ?
      `${translate('Your card will be charged')} ${amount} ${translate('per month after the trial period.')}.` :
      `${translate('Your card will be charged')} ${amount} ${translate('every month')}.`;

    return (
      <>
        <h2 className="m-b-30">{title}</h2>
        <p className="infoText">
          {infoText}
        </p>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} style={{ width: '100%', padding: '0 40px' }}>
      {renderDescription()}

      <div className="StripeCardElement">
        <CardNumberElement
          options={cardElementOptions}
          onFocus={() => dispatch(cleanError('first_steps'))}
        />
      </div>

      <div className="CardDetailsWrapper">
        <div className="StripeCardElement">
          <CardExpiryElement
            options={cardElementOptions}
            onFocus={() => dispatch(cleanError('first_steps'))}
          />
        </div>
        <div className="StripeCardElement">
          <CardCvcElement
            options={cardElementOptions}
            onFocus={() => dispatch(cleanError('first_steps'))}
          />
        </div>
      </div>

      <div className="vertical-center-content flex-column m-t-10">
        <Button disabled={isButtonDisabled}>
          {translate('Submit')}
        </Button>

        {!hasInitialPermission && (
          <Button
            buttonClassName="link m-t-10"
            type="button"
            label={translate('Cancel').toUpperCase()}
            onClick={() => push('/user-profile')}
          />
        )}
      </div>

      {hasInitialPermission && (
        <BonusCode
          bonusCode={bonusCode}
          setBonusCode={setBonusCode}
        />
      )}

      <div className="notificationContainer">
        <Notification
          className="horizontal-center-content p-t-20"
          type="error"
          text={error}
        />
      </div>
    </Form>
  );
};

CheckoutForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CheckoutForm;
