import React from 'react';
import moment from 'moment';

import { DateSelector } from './../../../components';


export const BudgetDateFilter = ({ budget, setDate }) => {
  const selectedYear = budget.filterDate.getFullYear();
  const selectedMonth = budget.filterDate.getMonth() + 1;

  return (
    <DateSelector
      selectedYear={selectedYear}
      selectedMonth={selectedMonth}
      activeDate={moment(budget.activeDate).toDate()}
      setDate={setDate}
    />
  );
};


export default BudgetDateFilter;
