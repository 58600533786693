import React from 'react';

const Avatar = ({ avatar = {} }) => (
  <div className="Avatar">
    <img src={avatar.thumbnailUrl} alt="" />
    {avatar.fileUrl ? <img src={avatar.fileUrl} alt="" /> : null}
  </div>
);

export default Avatar;
