import React from 'react';

import searchIcon from './assets/search-icon.png';


const SearchForm = () => (
  <div className="SearchForm">
    <form style={{ display: 'none' }}>
      <img src={searchIcon} alt="" />
      <input type="search" />
    </form>
  </div>
);

export default SearchForm;
