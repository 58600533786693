import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, FinancialFilters, Form, ModalConfirm, RepeatableEdit } from '../../../components';
import SignedInLayout from '../../../layouts/SignedInLayout';

import BudgetModal from './modal/BudgetModal';
import BudgetDateFilter from './BudgetDateFilter';
import BudgetSummary from './BudgetSummary';
import BudgetTable from './BudgetTable';
import { sortByDate } from './../../../utils/date';


export const Budget = ({ user, loading, layout, budget, homeUsers, ...props }, { t: translate }) => {
  const activeDateMoment = moment(budget.activeDate);
  const activeMonth = activeDateMoment.format('MMMM');
  const activeYear = activeDateMoment.format('YYYY');
  const budgetDataSorted = sortByDate(budget.budgetData, 'desc', 'date');

  return (
    <SignedInLayout
      page="Budget"
      currentPath="/budget"
      user={user}
      loading={loading}
      layout={layout}
    >
      <div className="content-header content-summaries summaries-fixed">
        <BudgetDateFilter budget={budget} setDate={props.setDate} />
        <BudgetSummary summaryData={budget.summaryData} activeMonth={activeMonth} activeYear={activeYear} />
      </div>

      <div className="section-title center clear">
        <span>{translate('Expenses and income')}</span>
      </div>

      <div className="add-new-budget">
        <Button
          type="button"
          label={translate('+ New scheduled transaction')}
          onClick={() => props.showModal()}
          data-e2e="budget-add-new-button"
        />
      </div>

      <FinancialFilters
        filterActive={props.filterActive}
        setFilter={props.setFilter}
      />

      <div className="finances-history">
        <BudgetTable
          budgetData={budgetDataSorted}
          filterActive={props.filterActive}
          editData={props.editData}
          homeUsers={homeUsers}
          isWalkthroughOpen={props.isWalkthroughOpen}
          setRecordToDelete={props.setRecordToDelete}
        />
      </div>

      {props.modalOpened && (
        <BudgetModal
          homeUsers={homeUsers}
          user={user}
          modalOpened={props.modalOpened}
          saveBudget={props.saveBudget}
          handleFormChange={props.handleFormChange}
          closeModal={props.closeModal}
          editFormData={props.editFormData}
          validateForm={props.validateForm}
          error={props.error}
          loading={loading}
        />
      )}

      {props.confirmModalOpened && (
        <ModalConfirm
          isOpen={props.confirmModalOpened}
          closeModal={() => props.closeModal(true)}
          question={translate('Are you sure you want to delete data?')}
          deleteFunction={props.deleteBudgetData}
          innerComponent={props.recordToDelete.repeatable && (
            <Form.Row>
              <RepeatableEdit
                editFormData={props.recordToDelete}
                handleEventFormChange={props.handleRepeatableTypeChange}
                label={translate('Choose transaction to delete')}
                type="budget"
              />
            </Form.Row>
          )}
        />
      )}
    </SignedInLayout>
  );
};


Budget.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Budget;
