import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SimpleSelect } from 'react-selectize';


const MonthSelect = ({ value, years, startDate, onChange, placeholder }, { t: translate }) => {
  let start;
  if(typeof startDate === 'string') {
    const split = startDate.split('-');
    start = moment(`${split[1]}-${split[0]}-01`);
  } else {
    start = startDate;
  }

  const options = [];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  years.forEach(year => {
    months.forEach(month => {
      if(!start || (
        start && moment(start).isSameOrBefore(moment(`${year}-${month}-01`)))
      ) {
        options.push({ label: `${month}-${year}`, value: `${year}-${month}-01` });
      }
    });
  });

  return (
    <SimpleSelect
      defaultValue={{ label: value, value }}
      options={options}
      placeholder={placeholder || translate('Select date')}
      theme="default"
      onValueChange={onChange}
    />
  );
};


MonthSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MonthSelect;
