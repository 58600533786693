import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AvatarPlaceholder from '../AvatarPlaceholder';
import { prepareFileUrl } from '../../utils/file';


class UserDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_expanded: false,
    };
  }

  toggle = () => {
    this.setState({
      is_expanded: !this.state.is_expanded,
    });
  }

  render() {
    const { isFirstStepsRoute, user } = this.props;

    const { t: translate } = this.context;

    return isFirstStepsRoute ? (
      <div className="UserLogout">
        <Link to="/sign-out">{translate('Logout')}</Link>
      </div>
    ) : (
      <div className="UserDropdown" onClick={this.toggle}>
        {user.thumbnail_url ? (
          <span className="avatar" style={{ borderColor: user.color }}>
            <img src={prepareFileUrl(user.thumbnail_url)} alt="" />
          </span>
        ) : (
          <AvatarPlaceholder user={user} />
        )}
        <p>
          <span className="name">{user.first_name}</span>
          <span className="username" style={{ color: user.color }}>@{user.nickname}</span>
        </p>
        {this.state.is_expanded && (
          <div className="dropdown">
            <ul>
              {!user.first_steps && <li><Link to="/user-profile">{translate('Profile')}</Link></li>}
              {/* <li><Link to="/change-password">Change password</Link></li>*/}
              <li><Link to="/sign-out">{translate('Logout')}</Link></li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}


UserDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default UserDropdown;
