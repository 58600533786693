export const FILE_ICONS = {
  image: 'image',
  document: 'file',
};

export const MAX_UPLOAD_FILE_SIZE = 52428800; // value presented in bytes - 50MB
export const MAX_UPLOAD_FILE_SIZE_MB = MAX_UPLOAD_FILE_SIZE / 1024 / 1024; // value presented in megabytes - 50MB

export const FILE_TYPES = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'application/pdf': 'document',
  'all': 'document',
};

export const getFileBaseName = filepath => {
  const pathParts = filepath.split('/');
  return pathParts[pathParts.length - 1];
};

export const getFileType = filetype => {
  if(filetype === null || filetype === undefined) {
    return {
      type: 'all',
      icon: FILE_ICONS[FILE_TYPES.all],
    };
  }
  return {
    type: FILE_TYPES[filetype],
    icon: FILE_ICONS[FILE_TYPES[filetype]],
  };
};

export const prepareFileUrl = file =>  (file ? file.split('?')[0] : null);
