import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FirstSteps from '../components/FirstSteps';
import { cleanError, setError } from '../../../store/actions/errors';
import { createSubscription } from '../../../store/actions/subscription';
import { updateUserProfile } from '../../../store/actions/user';
import { cleanNickname } from '../../../utils/tools';


class FirstStepsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarFile: null,
      firstSteps: props.user.first_steps || '',
      firstName: props.user.first_name || '',
      lastName: props.user.last_name || '',
      birthDate: props.user.birth_date || '',
      username: props.user.username || props.user.first_name.toLowerCase().slice(0, 10) || '',
      gender: props.user.gender || '',
      avatar: {
        fullpath: '',
        fullname: '',
      },
      email: props.user.email || '',
      language: props.language,
      step: 1,
    };
  }

  componentDidMount() {
    if(this.props.match.params.step) {
      this.setState({ step: parseInt(this.props.match.params.step, 10)  });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.step !== this.props.match.params.step) {
      this.setState({ step: parseInt(this.props.match.params.step, 10) }, () => {
        window.scrollTo(0, 0);
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanError('first_steps');
  }

  handleFieldChange = (name, forcedValue) => {
    if(forcedValue) {
      this.setState({ [name]: forcedValue });
    } else {
      return event => {
        this.setState({ [name]: event.target.value });
      };
    }
  }

  handleAvatarChange = file => {
    const { avatar } = this.state;
    avatar.fullpath = URL.createObjectURL(file);
    avatar.filename = file.name;
    this.setState({
      avatar,
      avatarFile: file,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.cleanError('first_steps');

    const { email, firstName, language, lastName, birthDate, gender, avatarFile, firstSteps } = this.state;
    let { username } = this.state;
    username = username === '' ? firstName.toLowerCase() : cleanNickname(username);

    this.props.updateUserProfile({ email, firstName, lastName, birthDate, username, gender, avatarFile, firstSteps, language }, true);
  }

  render() {
    return (
      <FirstSteps
        {...this.props}
        step={this.state.step}
        profile={this.state}
        onFieldChange={this.handleFieldChange}
        onAvatarChange={this.handleAvatarChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}


const mapStateToProps = state => ({
  user: state.user,
  layout: state.layout,
  loading: state.loading,
  language: state.i18nState.lang,
  error: state.errors.first_steps,
});

const mapDispatchToProps = {
  updateUserProfile,
  cleanError,
  createSubscription,
  setError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FirstStepsContainer));
