import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setLanguage } from 'redux-i18n';
import ipApi from 'ipapi.co';

import NewPasswordView from '../NewPasswordView';
import { setNewPassword, invitationConfirmation } from '../../../store/actions/auth';
import { cleanError, setError } from '../../../store/actions/errors';


class NewPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: '',
      newPassword: '',
      repeatedNewPassword: '',
      isSubmitted: false,
      invitation: false,
      language: 'en',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const invitation_id = urlParams.get('invitation_id');

    this.getUserLocationData();

    if(invitation_id) {
      this.setState({
        invitation: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanError('signin');
  }

  getUserLang = country => {
    const availableLanguagesMap = {
      GB: 'en',
      NO: 'nn',
      DK: 'da',
      SE: 'sv',
    };

    return availableLanguagesMap[country] || availableLanguagesMap.GB;
  }

  handleFieldChange = (name, forcedValue) => {
    if(forcedValue) {
      this.setState({ [name]: forcedValue });
      if(name === 'language') {
        this.handleLanguageChange(forcedValue);
      }
      if(name === 'country') {
        const userLang = this.getUserLang(forcedValue);
        this.handleLanguageChange(userLang);
      }
    } else {
      return event => {
        this.setState({ [name]: event.target.value });
      };
    }
  }

  getUserLocationData = () => {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const paramsLang = urlParams.get('language');

    // This package uses ipapi.co service with client api key (60 000 requests / month)
    ipApi.location(location => {
      const detectedLanguage = paramsLang || this.getUserLang(location.country);

      this.setState({ country: location.country });
      this.handleLanguageChange(detectedLanguage);
    }, '', process.env.REACT_APP_IPAPI_SECRET_KEY);
  }

  handleLanguageChange = language => {
    this.props.setLanguage(language);
    moment.locale(language);

    if(language === 'nn') {
      moment.updateLocale('nn', {
        weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const { country, language, newPassword, repeatedNewPassword, invitation } = this.state;
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const invitation_id = urlParams.get('invitation_id');
    const invitee_id = urlParams.get('invitee_id');

    if(invitation) {
      this.props.invitationConfirmation(invitation_id, invitee_id, newPassword, country, language)
        .then(() => {
          this.setState({ isSubmitted: true });
        })
        .catch(() => {
          this.setState({ isSubmitted: false });
        });
    } else {
      this.props.setNewPassword({ newPassword, repeatedNewPassword })
        .then(() => {
          this.setState({ isSubmitted: true });
        })
        .catch(() => {
          this.setState({ isSubmitted: false });
        });
    }
  }

  validForm = () => {
    const { newPassword, repeatedNewPassword } = this.state;
    return newPassword === repeatedNewPassword && newPassword.length >= 8;
  }

  render() {
    const { country, invitation, newPassword, repeatedNewPassword, isSubmitted } = this.state;
    const { error, loading } = this.props;

    return (
      <NewPasswordView
        country={country}
        invitation={invitation}
        newPassword={newPassword}
        repeatedNewPassword={repeatedNewPassword}
        isSubmitted={isSubmitted}
        error={error}
        loading={loading}
        handleFieldChange={this.handleFieldChange}
        handleSubmit={this.handleSubmit}
        validForm={this.validForm}
      />
    );
  }
}


const mapStateToProps = ({ errors, loading }) => ({
  error: errors.newpassword,
  loading: loading,
});

const mapDispatchToProps = {
  setNewPassword,
  invitationConfirmation,
  cleanError,
  setError,
  setLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPasswordContainer);
