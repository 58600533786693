import React from 'react';
import PropTypes from 'prop-types';

import { AuthForm, Button, Form, Link, Inner, Notification } from './../../components';
import { isEdge } from './../../utils/browser';

const PasswordRecoverView = ({
  email,
  isSubmitted,
  error,
  loading,
  handleFieldChange,
  handleSubmit,
}, { t: translate }) => (
  <Inner>
    <AuthForm
      type={isSubmitted ? 'tada' : 'forgot'}
      title={isSubmitted ? translate('Ready') : translate('Password recovery')}
      bottomContent={<div><span>{translate("Don't have an account?")} </span><Link to="/sign-up" style={{ textDecoration: 'underline' }}>{translate('Create it here')}</Link></div>}
    >
      {isSubmitted ? (
        <AuthForm.Paragraph>
          <p>{translate('Please check your email for further instructions.')}</p>
        </AuthForm.Paragraph>
      ) : (
        <Form
          onSubmit={handleSubmit}
          loading={loading}
        >
          <AuthForm.Paragraph>
            <p>{translate('Let\’s make your new password more memorable this time:')}</p>
          </AuthForm.Paragraph>

          <Notification
            type="error"
            text={error}
          />

          <Form.Row>
            <input
              required
              autoComplete="off"
              type="email"
              value={email || ''}
              onChange={handleFieldChange('email')}
            />
            <label className={isEdge ? 'edge' : ''}>email</label>
          </Form.Row>
          <Form.Summary>
            <Button
              type="submit"
              label={translate('Continue')}
              align="center"
            />
          </Form.Summary>
        </Form>
      )}
    </AuthForm>
  </Inner>
);


PasswordRecoverView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PasswordRecoverView;
