import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/nn';
import 'moment/locale/en-gb';
import { setLanguage } from 'redux-i18n';
import ipApi from 'ipapi.co';

import { signinUser } from '../../../store/actions/auth';
import { cleanError } from '../../../store/actions/errors';
import { fetchHomes } from '../../../store/actions/homes';
import { hideLoader } from '../../../store/actions/loading';
import { removeWhitespace } from '../../../utils/string';
import SignInView from '../SignInView';


const availableLanguagesMap = {
  GB: 'en',
  NO: 'nn',
  DK: 'da',
  SE: 'sv',
};

class SignInContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: localStorage.getItem('2homes-email') || '',
      password: '',
      language: props.language,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const lang = urlParams.get('lang');

    ipApi.location(location => {
      const detectedLanguage = lang || availableLanguagesMap[location.country] || availableLanguagesMap.GB;

      this.handleLanguageChange(detectedLanguage);
    }, '', process.env.REACT_APP_IPAPI_SECRET_KEY);
  }

  componentWillUnmount() {
    this.props.hideLoader();
  }

  handleLanguageChange = language => {
    this.props.setLanguage(language);
    moment.locale(language);
    if(language === 'nn') {
      moment.updateLocale('nn', {
        weekdays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ],
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fr', 'Lør'],
      });
    }
    this.setState({ language });
  }

  handleFieldChange = name => {
    return event => {
      let value = event.target.value;
      if(name === 'email') value = removeWhitespace(value);

      this.setState({ [name]: value });
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.cleanError('signin');
    const { email, password } = this.state;
    this.props.signinUser({ email, password });
  }

  render() {
    const { email, language, password } = this.state;
    const { error, loading } = this.props;

    return (
      <SignInView
        email={email}
        password={password}
        error={error}
        loading={loading}
        language={language}
        handleLanguageChange={this.handleLanguageChange}
        handleFieldChange={this.handleFieldChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    error: state.errors.signin,
    loading: state.loading,
    language: state.i18nState.lang,
  };
};

const mapDispatchToProps = {
  signinUser,
  cleanError,
  fetchHomes,
  hideLoader,
  setLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInContainer);
