/* global safari opr */
/* eslint spaced-comment: 0 */

export const getCssPrefix = () => {
  const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = /constructor/i.test(window.HTMLElement) || (function t(p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || safari.pushNotification);
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isChrome = !!window.chrome;

  if(isChrome || isSafari) {
    return '-webkit-';
  }
  if(isFirefox) {
    return '-moz-';
  }
  if(isOpera) {
    return '-o-';
  }
  if(isIE || isEdge) {
    return '-ms-';
  }
};

export const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;
export const isMac = navigator.userAgent.indexOf('Mac OS X') > -1;


export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const isMobile = () => {
  const testExp = new RegExp('Android|webOS|iPhone|iPad|' +
    'BlackBerry|Windows Phone|' +
    'Opera Mini|IEMobile|Mobile',
  'i');

  return testExp.test(navigator.userAgent);
};
