import { combineReducers } from 'redux';
import { i18nState } from 'redux-i18n';
import { connectRouter } from 'connected-react-router';

import locationReducer from './location';
import authReducer from './auth';
import loadingReducer from './loading';
import errorsReducer from './errors';
import operationsReducer from './operations';
import userReducer from './user';
import { homeCurrentReducer, homesExReducer, homesUsersReducer, homesUsersFamiliesReducer } from './homes';
import { caretakersReducer } from './caretakers';
import { kidsReducer, kidCurrentReducer } from './kids';
import { calendarReducer } from './calendar';
import { budgetReducer } from './budget';
import { financeReducer, splitRequestsReducer, splitRequestReducer } from './finance';
import { reportsReducer } from './reports';
import { notificationsReducer } from './notifications';
import { documentsReducer, documentsInFoldersReducer } from './documents';
import tagKeysReducer from './tags';
import layoutReducer from './layout';
import walkthroughReducer from './walkthrough';
import modalReducer from './modal';


const createRootReducer = history => combineReducers({
  location: locationReducer,
  loading: loadingReducer,
  errors: errorsReducer,
  operations: operationsReducer,
  authenticated: authReducer,
  user: userReducer,
  home: homeCurrentReducer,
  homesEx: homesExReducer,
  homeUsers: homesUsersReducer,
  homesUsersFamilies: homesUsersFamiliesReducer,
  caretakers: caretakersReducer,
  kids: kidsReducer,
  kidCurrent: kidCurrentReducer,
  calendar: calendarReducer,
  finance: financeReducer,
  splitRequests: splitRequestsReducer,
  splitRequest: splitRequestReducer,
  reports: reportsReducer,
  notifications: notificationsReducer,
  budget: budgetReducer,
  documents: documentsReducer,
  documentsInFolders: documentsInFoldersReducer,
  layout: layoutReducer,
  modal: modalReducer,
  router: connectRouter(history),
  tagKeys: tagKeysReducer,
  walkthrough: walkthroughReducer,
  i18nState,
});

// export const injectReducer = (store, { key, reducer }) => {
//   if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

//   store.asyncReducers[key] = reducer;
//   store.replaceReducer(makeRootReducer(store.asyncReducers));
// };

export default createRootReducer;
