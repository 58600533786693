import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { Walkthrough } from '../../../components';
import { fetchSplitRequestsData, changeSplitRequestStatus } from '../../../store/actions/finance';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { cleanError } from '../../../store/actions/errors';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { showInitialWalkthrough } from '../../../utils/walkthrough';
import SplitRequests from '../components/SplitRequests';

const DEFAULT_FORM = {
  id: null,
  status: 'pending',
  owner_id: '',
  recipient_id: '',
  note: '',
};


class SplitRequestsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterActive: 'all',
      modalOpened: false,
      editFormData: _.cloneDeep(DEFAULT_FORM),
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    await this.props.fetchSplitRequestsData();
    showInitialWalkthrough('split-requests', this.props.showWalkthrough);
  }

  componentWillUnmount() {
    this.props.cleanError('finances');
  }

  setHistoryFilter = filter => {
    this.setState({
      filterActive: filter,
    });
  }

  handleFormChange = (name, value) => {
    const { editFormData } = this.state;
    editFormData[name] = value;
    this.setState({
      editFormData,
    });
  }

  showModal = () => {
    this.setState({
      modalOpened: true,
    });
  }

  closeModal = () => {
    this.props.cleanError('finances');
    this.setState({
      modalOpened: false,
      editFormData: Object.assign({}, _.cloneDeep(DEFAULT_FORM)),
    });
  }

  editSplit = data => {
    const { editFormData } = this.state;
    _.extend(editFormData, data);
    this.setState({
      editFormData,
    }, () => {
      this.showModal(false);
    });
  }

  saveSplit = e => {
    e.preventDefault();
    this.props.cleanError('finances');
    const { editFormData } = this.state;
    this.props.changeSplitRequestStatus(editFormData)
      .then(() => {
        if(!this.props.error || this.props.error === null) {
          this.setState({
            editFormData: Object.assign({}, _.cloneDeep(DEFAULT_FORM)),
          });
          this.closeModal();
        }
      });
  }

  render() {
    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          stepsPlacement={{ 0: 'top-start', 1: 'top-start', 2: 'top-start', 3: 'left-start' }}
        />

        <SplitRequests
          {...this.props}
          {...this.state}
          handleFormChange={this.handleFormChange}
          setHistoryFilter={this.setHistoryFilter}
          closeModal={this.closeModal}
          saveSplit={this.saveSplit}
          editSplit={this.editSplit}
        />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.loading,
    layout: state.layout,
    splitRequests: state.splitRequests,
    homeUsers: state.homeUsers,
    caretakers: state.caretakers,
    error: state.errors.finances,
    isWalkthroughOpen: state.walkthrough.isOpen,
  };
};

const mapDispatchToProps = {
  fetchSplitRequestsData,
  changeSplitRequestStatus,
  fetchHomesIfNeeded,
  cleanError,
  showWalkthrough,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplitRequestsContainer);
