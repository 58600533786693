import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Badge, Button } from '../../../components';

import MembersList from './MembersList';

const FamilyTree = ({
  user, homeUsers, setUserVisibility, deleteInvitation,
  kids, setVisibilityFilter, visibilityFilter,
}, { t: translate }) => {
  const { push } = useHistory();

  const generateParents = () => {
    let parents = [];

    Object.entries(homeUsers).filter(item => item[1].familyStatus !== 'kid').forEach(parent => {
      const showParent = (parent[1].visibility && visibilityFilter !== 'hidden') ||
        (!parent[1].visibility && visibilityFilter === 'hidden') ||
        visibilityFilter === 'all';

      if(showParent) {
        parents.push({
          ...parent[1],
          current: parent[0] !== user.id,
          redirectPath: parent[0] === user.id ? '/user-profile' : `/caretaker/${parent[0]}`,
        });
      }
    });

    const currentUser = parents.filter(parent => parent.familyStatus === 'current' && parent.current === false);
    const partner = parents.filter(parent => parent.familyStatus === 'current' && parent.current === true);
    const partnerEx = parents.filter(parent => parent.familyStatus === 'ex' && parent.current === true);

    parents = [
      ...currentUser,
      ...partner,
      ...partnerEx,
    ];

    return parents;
  };

  const generateKids = () => {
    let children = [];

    Object.entries(homeUsers).filter(item => item[1].familyStatus === 'kid').forEach(kid => {
      const showKid = (kid[1].visibility && visibilityFilter !== 'hidden') ||
                (!kid[1].visibility && visibilityFilter === 'hidden') ||
                visibilityFilter === 'all';

      if(showKid) {
        children.push({ ...kid[1], redirectPath: `/kid/${kid[0]}` });
      }
    });

    children.sort((a, b) => {
      return a.first_name > b.first_name ? 1 : -1;
    });

    const ownChildren = [];
    const currentChildren = [];
    const partnerExChildren = [];

    children.forEach(child => {
      if(child.whichFamily === 'ex') {
        ownChildren.push(child);
      }
      if(child.whichFamily === 'current') {
        currentChildren.push(child);
      }
      if(child.whichFamily === 'partnerEx') {
        partnerExChildren.push(child);
      }
    });

    children = [
      ...ownChildren,
      ...currentChildren,
      ...partnerExChildren,
    ];

    return children;
  };

  const redirectToFamilies = () => {
    push('/families');
  };

  const renderFilters = () => {
    return (
      <div>
        <div className="visibility-filters right m-t-5">
          <Badge
            label={translate('visible')}
            active={visibilityFilter === 'visible'}
            activeColor="bg-darkgrey"
            onClick={() => setVisibilityFilter('visible')}
          />

          <Badge
            label={translate('hidden')}
            active={visibilityFilter === 'hidden'}
            activeColor="bg-darkgrey"
            onClick={() => setVisibilityFilter('hidden')}
          />

          <Badge
            label={translate('all')}
            active={visibilityFilter === 'all'}
            activeColor="bg-darkgrey"
            onClick={() => setVisibilityFilter('all')}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="FamilyTree">
      <div className="family_container">
        <div className="flex space-between p-l-10 p-r-10">
          <Button
            buttonClassName="AddNewButton m-b-30"
            label={translate('+ Add family member')}
            onClick={redirectToFamilies}
          />

          {renderFilters()}
        </div>

        <div className="members-container">
          <MembersList
            homeUsers={homeUsers}
            members={generateParents()}
            sectionTitle={translate('Caretakers')}
            deleteInvitation={deleteInvitation}
            setUserVisibility={setUserVisibility}
            visibilityFilter={visibilityFilter}
          />

          <MembersList
            homeUsers={homeUsers}
            kids={kids}
            members={generateKids()}
            sectionTitle={translate('Kids')}
            deleteInvitation={deleteInvitation}
            setUserVisibility={setUserVisibility}
            visibilityFilter={visibilityFilter}
          />
        </div>
      </div>
    </div>
  );
};


FamilyTree.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FamilyTree;
