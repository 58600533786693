import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';

import { Walkthrough } from '../../../components';
import { setError, cleanError } from '../../../store/actions/errors';
import {
  setFilterDate, fetchFinancialData, savePendingFile,
  deletePendingFile, saveFinanceData, saveSplitRequest,
  addAttachment, removeAttachment,
} from '../../../store/actions/finance';
import { fetchHomesIfNeeded } from '../../../store/actions/homes';
import { fetchTagKeysIfNeeded } from '../../../store/actions/tags';
import { showWalkthrough } from '../../../store/actions/walkthrough';
import { getAdditionalStepsData, showInitialWalkthrough } from '../../../utils/walkthrough';
import Finances from '../components/Finances';
import { MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_FILE_SIZE_MB } from '../../../utils/file';

const DEFAULT_FORM = {
  id: null,
  type: 'expense',
  tag_key: '',
  total_amount: '',
  split_caretaker_id: '',
  split_amount: '',
  split_type: '',
  note: '',
  caretaker_id: null,
  user_ids: [],
  access: 'private',
  datatype: 'history',
  transaction_date: moment(),
};


class FinancesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterActive: 'all',
      modalOpened: false,
      confirmModalOpened: false,
      modalType: 'normal',
      fileToDelete: {},
      editFormData: _.cloneDeep(DEFAULT_FORM),
      date: new Date(),
    };
  }

  async componentDidMount() {
    await this.props.fetchHomesIfNeeded();
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const date = urlParams.get('date');

    this.props.fetchTagKeysIfNeeded();
    if(date) {
      const dateParams = date.split('-');
      this.setDate(dateParams[1], dateParams[0], true);
    } else {
      this.fetchData();
    }

    showInitialWalkthrough('accounting', this.props.showWalkthrough);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.finance && nextProps.finance.historyData) {
      const transactionChanged = nextProps.finance.historyData.find(data => data.attachmentChanged);

      if(transactionChanged) {
        const updatedData = {
          ...this.state.editFormData,
        };

        updatedData.file = transactionChanged.file || null;
        updatedData.filename = transactionChanged.filename || null;
        updatedData.filetype = transactionChanged.filetype || null;

        this.setState({
          editFormData: { ...updatedData },
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanError('finances');
  }

  fetchData = date => {
    const dateMonth = date || this.state.date;
    return this.props.fetchFinancialData(dateMonth, this.filterActive);
  }

  setDate = (year, month, fetch) => {
    const date = new Date(`${year}-${month}-01`);
    this.props.setFilterDate(date);
    this.setState({
      date,
    });
    if(fetch) {
      this.fetchData(date);
    }
  }

  setHistoryFilter = filter => {
    this.setState({
      filterActive: filter,
    });
  }

  handleFormChange = (name, forcedValue, flat) => {
    const { editFormData } = this.state;
    if(flat) {
      editFormData[name] = forcedValue;
      this.setState({ editFormData });
    } else {
      return event => {
        editFormData[name] = forcedValue || event.target.value;
        this.setState({ editFormData });
      };
    }
  }

  editFile = (data, datatype) => {
    const { editFormData } = this.state;
    if(data.user_id) {
      data.user_ids = [data.user_id];
      data.recipient = this.props.kids[data.user_id];
    }
    data.datatype = datatype;
    _.extend(editFormData, data);
    this.setState({
      editFormData,
    }, () => {
      this.showModal(false);
    });
  }

  setModalType = type => {
    this.setState({
      modalType: type,
    });
  }

  showModal = confirm => {
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';
    this.setState({
      [name]: true,
    });
  }

  closeModal = confirm => {
    const { fileToDelete } = this.state;
    this.props.cleanError('finances');
    const name = confirm ? 'confirmModalOpened' : 'modalOpened';

    const updatedState = {
      ...this.state,
      [name]: false,
      modalType: 'normal',
    };

    if(!fileToDelete || !fileToDelete.isAttachment) {
      updatedState.editFormData = Object.assign({}, _.cloneDeep(DEFAULT_FORM), {
        user_ids: new Array(),
      });
    }

    this.setState({
      ...updatedState,
    });
  }

  addNewFile = (file, fileType) => {
    const { editFormData } = this.state;
    const { name } = file;
    this.setState({
      editFormData: {
        ...editFormData,
        filename: name,
      },
    }, () => {
      this.props.addAttachment(editFormData.id, file, fileType);
    });
  }

  removeFile = file => {
    const { editFormData } = this.state;
    this.setState({
      editFormData: {
        ...editFormData,
        file: '',
        filename: '',
        filetype: '',
      },
    }, () => {
      this.props.removeAttachment(editFormData.id, file);
    });
  }

  saveFile = (file, fileType) => {
    const { date } = this.state;

    this.props.cleanError('finances');

    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      return this.setTooLargeFileError();
    }

    this.props.savePendingFile(date, file, fileType);
  }

  setFileToDelete = (file, isAttachment) => {
    this.setState({
      fileToDelete: {
        ...file,
        isAttachment,
      },
    }, () => {
      this.showModal(true);
    });
  }

  deleteFile = e => {
    e.preventDefault();
    const { fileToDelete, date } = this.state;
    if(fileToDelete.isAttachment) {
      this.removeFile(fileToDelete);
      this.closeModal(true);
    } else {
      this.props.deletePendingFile(fileToDelete, date).then(() => {
        this.closeModal(true);
      });
    }
  }

  saveFinance = e => {
    e.preventDefault();
    this.props.cleanError('finances');
    const { editFormData, date } = this.state;
    const method = (editFormData.filename || editFormData.id) ? 'update' : 'add';

    this.props.saveFinanceData(editFormData, date, method)
      .then(() => {
        if(!this.props.error || this.props.error === null) {
          this.props.fetchTagKeysIfNeeded(editFormData.tag_key, editFormData.type);
          this.setState({
            editFormData: Object.assign({}, _.cloneDeep(DEFAULT_FORM), {
              user_ids: new Array(),
            }),
          });
          this.closeModal();
        }
        this.fetchData(date);
      });
  }

  saveSplitRequest = e => {
    e.preventDefault();
    this.props.cleanError('finances');
    const { editFormData, date } = this.state;
    if(editFormData.split_type === 'half') {
      editFormData.split_amount = parseFloat(editFormData.amount) / 2;
    }
    this.props.saveSplitRequest(editFormData, date)
      .then(() => {
        if(!this.props.error || this.props.error === null) {
          this.setState({
            editFormData: _.cloneDeep(DEFAULT_FORM),
          });
          this.closeModal();
        }
      });
  }

  setTooLargeFileError = () => {
    const { t: translate } = this.context;
    const { setError } = this.props;

    setError('finances', translate('File is too large. Max file size is') + ` ${MAX_UPLOAD_FILE_SIZE_MB.toFixed(0)}MB`);
  }

  generateAdditionalSteps = () => {
    const { finance: { historyData, pendingData }, location: { pathname } } = this.props;
    const { t } = this.context;
    const steps = getAdditionalStepsData(pathname, t);
    const { history, pending } = steps;

    const additionalSteps = [];

    if(pendingData.length) {
      additionalSteps.push(pending);
    }

    if(historyData.length) {
      additionalSteps.push(history);
    }

    return additionalSteps;
  }

  render() {
    const additionalSteps = this.generateAdditionalSteps();

    return (
      <div>
        <Walkthrough
          currentPath={this.props.location.pathname}
          additionalSteps={additionalSteps}
          stepsPlacement={{ 3: 'top-start', 4: 'top-start', 5: 'top-start', 6: 'top-start' }}
        />

        <Finances
          {...this.props}
          {...this.state}
          setDate={this.setDate}
          handleFormChange={this.handleFormChange}
          setHistoryFilter={this.setHistoryFilter}
          closeModal={this.closeModal}
          setModalType={this.setModalType}
          togglePendingTable={this.togglePendingTable}
          saveFile={this.saveFile}
          editFile={this.editFile}
          addNewFile={this.addNewFile}
          removeFile={this.removeFile}
          setFileToDelete={this.setFileToDelete}
          deleteFile={this.deleteFile}
          saveFinance={this.saveFinance}
          saveSplitRequest={this.saveSplitRequest}
        />
      </div>
    );
  }
}


FinancesContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading,
  layout: state.layout,
  finance: state.finance,
  homeUsers: state.homeUsers,
  homesUsersFamilies: state.homesUsersFamilies,
  caretakers: state.caretakers,
  error: state.errors.finances,
  isWalkthroughOpen: state.walkthrough.isOpen,
  kids: state.kids,
});

const mapDispatchToProps = {
  setFilterDate,
  fetchFinancialData,
  savePendingFile,
  deletePendingFile,
  saveFinanceData,
  saveSplitRequest,
  addAttachment,
  removeAttachment,
  fetchHomesIfNeeded,
  fetchTagKeysIfNeeded,
  cleanError,
  setError,
  showWalkthrough,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinancesContainer);
